import React from 'react'
import PropTypes from 'prop-types'

const Form = props => {
    const { action, method, name, view, onSubmit, className, children } = props;
    return (
        <form
            action={action}
            method={method}
            name={name}
            onSubmit={onSubmit}
            className={className}
        >
            {children}
        </form>
    )
}

Form.propTypes = {

}

export default Form
