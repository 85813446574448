import React from 'react';
import { Link } from "react-router-dom";
import logo from 'assets/images/logo-predictoria-new.svg';
import logoWhite from 'assets/images/main/icons/logo-white.svg';
import cn from 'classnames';

const Logo = (props) => {
    const { view, className, size } = props;
    const logoSrc = view === 'footer' ? logoWhite : logo;
    return (
      <Link className={cn('logo', `logo--size-${size}`, className)} to="/">
        <img className='logo__image' src={logoSrc} alt="Predictoria" />
      </Link>
    )
}

Logo.defaultProps = {
  size: 'default',
}

export default Logo;
