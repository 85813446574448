import React, { Component } from "react"
import AuthForm from "components/AuthForm"
import * as modalActions from "actions/ModalActions"
import Container from "utils/Container"

const BetAuth = ({ modalActions, user, isClosed, title, id, handlePrediction }) => (
  user.isAuthenticated ?
    <button onClick={isClosed ? null : () => handlePrediction(id)}>{title || " "}</button>
    :
    <button onClick={() => modalActions.toggleAuthModal(true, { activeTab: AuthForm.SIGNUP_TAB })} >{title || " "}</button>
)

BetAuth.mapDispatchToProps = () => ({
  modalActions
})

BetAuth.mapStateToProps = state => ({
  modals: state.modals,
  user: state.user
})

export default Container(BetAuth)
