import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import ModalContainer from './ModalContainer';
import * as ModalActions from "actions/ModalActions";

function mapStateToProps(state) {
    return {
      ...state.modals
    }
  }

function mapDispatchToProps(dispatch) {
    return {
        ModalActions: bindActionCreators(ModalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);