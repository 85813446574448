import React, { useState } from 'react'
import Button from 'components/_Button';
import Input from 'components/Input';
import Socials from '../Socials.jsx';
import Form from 'components/_Form';
import Checkbox from 'components/Checkbox';

import { trans } from 'utils/translator';

const Reg = (props) => {

  const { UserActions, t } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [refCode, setRefCode] = useState('');
  const [isAgree, setAgree] = useState(false);
  const [isSendMemes, setSendMemes] = useState(false);

  return <Form className='p4' onSubmit={e => {
    e.preventDefault();
    UserActions.register(email, password, refCode);
  }}>
    <Input
      name='email'
      label={t('_reg_1')}
      value={email}
      onChange={setEmail}
      className='mb2 t3'
    />
    <Input
      name='password'
      label={t('_reg_2')}
      value={password}
      onChange={setPassword}
      className='mb2 t3'
    />
    <Input
      name='refCode'
      label={t('_reg_3')}
      value={refCode}
      onChange={setRefCode}
      className='mb2 t3'
    />
    <Checkbox
      name='agree'
      label={t('_reg_4')}
      onChange={e => setAgree(!isAgree)}
      value={isAgree}
      className='mb2 t3'
    />
    <Button type='submit' theme='purple' className='block mx-auto mt4 mb3 t3'>
    {t('_reg_5')}
        </Button>
    <p className='center mb3 t3'>{t('_reg_6')}</p>
    <Socials />
  </Form>
}


export default trans(Reg);
