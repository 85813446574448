import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';

const ButtonViewStyles = {
    'ghost': 'view--ghost',
    'link': 'view--link',
    'default': 'view--default'
}

const ButtonSizeStyles = {
    'large': 'button-size--large',
    'medium': 'button-size--medium',
    'small': 'button-size--small'
}

const ButtonColorStyles = {
    'grey': 'button-colors--grey',
    'dark-magenta': 'button-colors--dark-magenta',
    'default': ''
}

const ButtonThemeStyles = {
    'purple': 'button-theme-purple',
    'white': 'button-theme-white'
}

const Button = props => {
    const { 
        onClick,
        type,
        children,
        className,
        view,
        color,
        size,
        // @TODO 
        theme
    } = props;
 
    return (
        <button
            type={type}
            onClick={onClick}
            className={cn('_button _button-base bold',
            ButtonViewStyles[view],
            ButtonSizeStyles[size],
            ButtonColorStyles[color], 
            className,
            ButtonThemeStyles[theme]
            )}
        >
            {children}
        </button>
    )
}

Button.propTypes = {
    // Обработчик клика по кнопке
    onClick: PropTypes.func.isRequired,
    // Дополнительный класс кнопки
    className: PropTypes.string,
    // Визуальное оформление кнопки
    // @TODO correct pt
    view: PropTypes.string,
}

Button.defaultProps = {
    view: 'default',
    size: 'large',
    // theme: 'purple',
    color: 'default',
}

export default Button
