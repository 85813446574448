import React, { Component } from "react"

import { groupBy } from "lodash"

import ChallengeNew from "./ActivitiesNew/Challenge"
import ChallengeTemplate from "./ActivitiesNew/ChallengeTemplate"
import { defaultChallenge } from "./DefaultPredictions"
import { trans } from 'utils/translator';

export default trans(class ChallengesBlock extends Component {
  constructor(props) {
    super(props)
    const { props: { challenges = [] } } = this
    this.state = {
      challengesCount: challenges.length
    }
  }

  componentDidMount() {
    const { handleVisibilityChange } = this
    let hidden 
    let visibilityChange
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden"
      visibilityChange = "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden"
      visibilityChange = "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden"
      visibilityChange = "webkitvisibilitychange"
    }
    document.addEventListener(visibilityChange, handleVisibilityChange(hidden), false)
  }
  componentWillReceiveProps(nextProps) {
    const { state: { challengesCount } } = this
    const { challenges: newChallenges } = nextProps
    const newChallengesCount = (newChallenges || []).length - challengesCount
    if (newChallengesCount) document.title = `You have ${newChallengesCount} new challenges`
  }
  
  handleVisibilityChange = hidden => (e) => {
    const { props: { challenges = [] } } = this
    if (!document[hidden]) {
      this.setState({
        challengesCount: (challenges || []).length
      })
      document.title = "Admin activities"
    }
  }

  renderList = (challenges = []) => {
    const { props: { ChallengeActions, ChallengeTemplateActions } } = this
    return challenges.map((item, i) => {
      const isTemplate = !item.status
      return isTemplate ? <ChallengeTemplate key={`challenge_${item.id}+${item.createdAt}`} {...{ challenge: item, ChallengeActions, ChallengeTemplateActions }} />
        : <ChallengeNew key={`challenge_${item.id}+${item.createdAt}`} challenge={item} ChallengeActions={ChallengeActions} />
    })
  }

  render() {
    const { props: { stream: { id }, challenges = [], challengeTemplates, ChallengeTemplateActions: { create } }, renderList } = this
    const filteredChallenges = challenges.filter(c => c.stream == id);
    const challengesByStatus = groupBy(filteredChallenges, "status");
    const filteredTmpls = challengeTemplates.filter(t => t.stream == id);
    const toRender = { ...challengesByStatus, ADD_NEW: filteredTmpls }
    const columnsOrder = ["WAITING_FOR_MODERATION", "WAITING_FOR_ACCEPTION", "ACCEPTED", "ADD_NEW"]
    const { t } = this.props
    const cols = [
    {
      value: 'WAITING_FOR_MODERATION',
      label: t('amdin_waiting_for_moderation')
    },
    {
      value: 'WAITING_FOR_ACCEPTION',
      label: t('amdin_waiting_for_acception')
    },
    {
      value: 'ACCEPTED',
      label: t('amdin_accepted')
    },
    {
      value: 'ADD_NEW',
      label: t('amdin_add_new')
    },
  ]
    return (
      <div className="row-container">
        {
          cols.map((column, i) => {
            return (
              <div className="activity-admin__activity-block">
                <h2 className="text head head_center activity-admin__header">{column.label}</h2>
                <div key={i} className="col-container col-container_center">
                  {
                renderList(toRender[column.value])
              }
                  {
                column.value === "ADD_NEW" ? <div onClick={() => create(defaultChallenge(id))} className="plus__icon" /> : null
              }
                </div>
              </div>)
          })
        }
      </div>
    )
  }
}
)