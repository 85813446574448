import { store } from "libs/redux"
import { updateUserBalance } from 'actions/UserActions'
import * as SocketAPI from "api/SocketAPI"

const patch = async (templateId, templateBody, callback) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/paidaction/template/${templateId}`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(templateBody)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}


const get = async (game, stream, callback) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/${stream}/paidaction/template/`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}

const create = async (templateBody, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/paidaction/template/`, {
    method: "POST",
    headers,
    body: JSON.stringify(templateBody)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED") {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}


const remove = async (id, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/paidaction/template/${id}`, {
    method: "DELETE",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}

const updated = async (callback, admin, stream) => {
  SocketAPI.paidActionTemplates(admin, stream, ({ error, response, type, socket }) => {

    if (error) {
      console.error("SocketAPI paidActionTemplates", error, socket)
      return
    }

    if (type == "connect" || type == "disconnect") {
      return
    }

    const paidActionTemplate = response.paidactionTemplate

    const paidActionTemplates = store.getState().templatePaidAction

    const index = paidActionTemplates.findIndex(item => item.id === paidActionTemplate.id)
   
    const oldPaidActionTemplate = index != -1 ? paidActionTemplates[index] : null
    const paid = update(paidActionTemplate, oldPaidActionTemplate)

    if (index != -1) {
      paidActionTemplates[index] = paid
    } else {
      paidActionTemplates.push(paid)
    }
    callback(paidActionTemplates)
  })
}

function update(paid, oldPaidActionTemplate, callback) {
  store.dispatch(updateUserBalance())
  const paidActionTemplate = oldPaidActionTemplate || Object()
  paidActionTemplate.createdAt = paid.createdAt
  paidActionTemplate.currency = paid.currency
  paidActionTemplate.description = paid.description
  paidActionTemplate.game = paid.game
  paidActionTemplate.id = paid.id
  paidActionTemplate.price = paid.price
  paidActionTemplate.status = paid.status
  paidActionTemplate.stream = paid.stream
  paidActionTemplate.type = paid.type
  paidActionTemplate.updatedAt = paid.updatedAt
 
  
  if (callback != null) {
    callback(paidActionTemplate)
  }
  return paidActionTemplate
}

export { create, get, remove, patch, updated }
