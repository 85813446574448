import collectionReducers from "utils/collectionReducers"

const initialState = {
  notificationsList: []
}

const NAMESPACE = "NOTIFICATIONS_HEADER"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function add(notification) {
  alert(notification)
  function _add(item, all) {
    // all.push(item)
    const array = [item, ...all]

    const result = array.slice(0, 5).map((item) => { return { ...item } })

    return result
  }

  return {
    namespace: NAMESPACE,
    type: "ADD_NOTIFICATION_HEADER",
    handle: (state) => {
      const newNotifications = _add(notification, state.notificationsList)
      return { ...state, notificationsList: newNotifications }
    }
  }
}
