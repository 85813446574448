import React, { Fragment } from "react"

import FormValidator from "components/Common/FormValidator"
import ExpandableInput from 'components/Common/ExpandableInput'
import Dropdown  from "components/Dropdown"

import { trans } from "utils/translator"
import { gte } from "utils/validator"

export default trans(class PredictionTemplate extends FormValidator {
  
  static get liveMode() { return 'LIVE_MODE'}
  static get futureMode() { return 'FUTURE_MODE' }

  constructor(props){
    super(props)
    const { prediction: { id, game, mode, outcomes, streamer, title, stream }, create } = props
    const { t } = this.props
    this.state = {
          form: { 
            id: { value: id },
            game: { value: game },
            title: { value: title, rules: [gte(10)], isValid: true },
            mode: { value: mode },
            win: { value: outcomes[0].title, rules: [gte(1)], isValid: true },
            lose: {value: outcomes[1].title, rules: [gte(1)], isValid: true },
            streamer: { value: streamer },
            stream: { value: stream }
          },
          isCreatingMode: create,
          games: [
            t('admin_custom'),
            "Dota 2",
            "CS:GO",
            "League of Legends",
            "Fortnite",
            "PUBG",
            "Overwatch",
            "Hearthstone",
            "World of Tanks", 
         ],
    }
  }
  componentDidMount(){
  }

  handleUse = (e) => {
    const { props: { PredictionsActions: { create: use, createInitialBets }, prediction: { outcomes } } } = this
    const { win, lose, ...prediction } = this.getFormData()
    e.preventDefault()
    if(!this.isValid()){
      alert('Some of parameters are wrong')
      return
    }
    use({ ...prediction, outcomes: [{ ...outcomes[0], title: win }, { ...outcomes[1], title: lose }] }, (err, prd) => {
      const token1 = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzUwfQ.HGg_hGey5BmjUQA5lPomtlGssfS-t5SMSQ8HTKmYbjQ"
      const token2 = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzUxfQ.sbpA7tvESI3TqpQHHPvc7TzlvvNj_HzADr-r1jfAwrc"
      createInitialBets(prd, token1, token2) 
    })
  }
  
  remove = (e) => {
    const { props: { PredictionTemplateActions: { remove },  prediction: { id } } } = this
    e.preventDefault()
    remove(id)
  }

  handleCreate = (e) => {
    const { props: { PredictionTemplateActions: { create }, prediction: { outcomes } } } = this
    const { win, lose, ...prediction } = this.getFormData()
    e.preventDefault()
    if(!this.isValid()){
      alert('Some of parameters are wrong')
      return
    }
    create({ ...prediction, outcomes: [{ ...outcomes[0], title: win }, { ...outcomes[1], title: lose }] },
    (prediction) => console.log(prediction))
  }

  edit = (e) => {
    const { props: { PredictionTemplateActions: { patch }, prediction: { id } } } = this
    e.preventDefault()
    // alert('This feature is not supported yet')
    // return
    patch(id, { ...this.getFormData() })
  }


  switchMode = (e) => {
    const { state: { form: { mode: {value } } } } = this
    const newMode = value == 'LIVE_MODE' ? 'FUTURE_MODE' : 'LIVE_MODE'
    e.preventDefault()
    this.setFieldData('mode', newMode)
  }

  selectOption = (option) => () => {
    const { refs: { dropdown } } = this
    this.setFieldData('game', option)
    dropdown.hide()
  } 

  render(){
  const { props: { t, create }, state: { isCreatingMode, games }, remove, switchMode, selectOption, edit } = this
  const { mode, game } = this.getFormData()

  const options = games.map(option => <div className='activity-create__option' onClick={selectOption(option)}>{option}</div>)
  const dropdown = <div className='activity-create__trigger'>{game}</div>
  return (
    <form className="adm-new-control-card adm-new-prediction adm-new-prediction-state4">
          <div className="adm-new-control-card__header">
            <div className="adm-new-control-card__option">
              <a className="link" onClick={switchMode}>{mode==PredictionTemplate.liveMode ? t('admin_live') : t('admin_future')}</a>
              <Dropdown ref="dropdown" component={dropdown}>
                {options}
              </Dropdown>
            </div>
            {
              isCreatingMode ? null : <div onClick={remove} className="adm-new-control-card__close purecss-close" />
            }
          </div>
          <div className="adm-new-control-card__content">
            <ExpandableInput 
            className="adm-new-control-card__description" 
            rows="3"
            {...this.bindInput('title', 'text')} />

            <input 
            className="adm-new-control-card__input"
            { ...this.bindInput('win', 'text') }
            />
            <input 
            className="adm-new-control-card__input"
            { ...this.bindInput('lose', 'text') }
            />
          </div>
          <div className="adm-new-control-card__actions">
          {
            isCreatingMode ?
              <button 
              className="adm-new-control-card__btn adm-new-control-card__btn--accent"
              onClick={this.handleCreate}
              >{t('admin_create')}</button>
              :
              <Fragment>
              <button
              className="adm-new-control-card__btn adm-new-control-card__btn--accent"
              onClick={this.handleUse}
              >{t('admin_use')}</button>
              <button
              className="adm-new-control-card__btn adm-new-control-card__btn--accent"
              onClick={edit}
              >{t('admin_edit')}</button>
              </Fragment>
        } 
        </div>
        </form>
  )
      }
}
)