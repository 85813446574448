import React, {Component} from "react"
import { trans } from "utils/translator";
import Dropdown from "components/Dropdown"

import * as PaymentAPI from "api/paymentAPI"

export default trans(class About extends Component{

  constructor(props){
    super(props)
    this.state = {
      amount: 10,
      exchange: {},
      availableCurrencies: ['USD', 'EUR', 'RUB'], 
      selectedCurrency: 'USD', 
      exchangeRate: {
        USD: 1,
        UAH: 1/27.31,
        EUR: 1/0.9,
        RUB: 1/74.98,
      },
      selectedTab: 'CARD',
      method: 'CARD'
    }
    this.handleAmountInputChange = this.handleAmountInputChange.bind(this)
    this.buy = this.buy.bind(this)
  }
  componentDidMount(){
    this.loadCurrencyExchangeRate('UAH')
    this.loadCurrencyExchangeRate('RUB')
  }

  buy = (preset) => () =>  {
   
    const ORB_TO_DOLLAR = 0.1
    const DOLLAR_TO_UAH = +this.state.exchange.buy
    const amount = preset || +this.state.amount
    const method = this.state.method
    const paymentMethod = method == 'PRIVAT' ? 'CARD' : 'ALTERNATIVE'
    const user = JSON.parse(localStorage.getItem('predictoria_currentUser') || '{}');
    const userId = user.id;

    gtag("event", "payments", { 
      event_category: 'user_actions',
      event_action: 'buy_orbs_clicked',
      amount,
    })
    PaymentAPI.pay((amount).toFixed(2), this.state.selectedCurrency, paymentMethod, userId) 
  }
  loadCurrencyExchangeRate = async (currency) => {
    const from = currency || this.state.selectedCurrency
    const to = 'USD' 
    const exchangeRateResponce = await fetch(`https://free.currencyconverterapi.com/api/v5/convert?q=${from}_${to}&compact=y`)
    const exchangeRateJSON = await exchangeRateResponce.json()
    const exchangeRate = exchangeRateJSON[`${from}_${to}`].val
    const prevExchangeRate = this.state.exchangeRate
    prevExchangeRate[currency] = exchangeRate
    this.setState({
      ...this.state,
      exchangeRate: prevExchangeRate
    })
  }
  handleCurrencyChange = option => (e) => {
    this.setState({
      selectedCurrency: option || e.target.value,
    })
  }
  handleAmountInputChange = (e) => {
    const value = e.target.value;
    
    if(isNaN(+value) || +value > 10000000) return

    this.setState({
      amount: +value
    })
  }
  selectTab = (tab) => () => {
    this.setState({
      selectedTab: tab,
      method: tab,
    })

    if(tab == 'CARD')
    this.handleCurrencyChange('USD')()

    if(tab == 'PRIVAT')
    this.handleCurrencyChange('UAH')()

    if(tab == 'YANDEX')
    this.handleCurrencyChange('RUB')()

    if(tab == 'QIWI')
    this.handleCurrencyChange('RUB')()

    if(tab == 'ALPHA')
    this.handleCurrencyChange('RUB')()

    if(tab == 'PSB')
    this.handleCurrencyChange('RUB')()
  } 

  render(){
  const { t } = this.props
  const { availableCurrencies } =  this.state
  const ORB_TO_DOLLAR = 0.1
  const DOLLAR_TO_UAH = +this.state.exchange.buy
  const options = availableCurrencies.map(currency => <div className='buy-orbs__option' onClick={this.handleCurrencyChange(currency)}>{t(`buy-orbs_${currency}`)}</div> )
  const dropdown = <div className='buy-orbs__trigger'>{t(`buy-orbs_${this.state.selectedCurrency}`)}</div>
  return (

<div className="buy-orbs">
       <div className="buy-orbs__buy">
       <h1 className="buy__header">{t('buy_buy_header')}</h1>
      
       <div className="buy-content">
        <a href="#payment-method">
        <div 
        className="buy-content__item buy-content__item_first"
        onClick={() => this.setState({
          amount: 50, 
        })}
        >

        <img className="buy-content__icon buy-content__icon_small" src="/assets/images/main/buy/16.png"/>
        <div className="buy-content__info">
        <span className="item__amount">50 <span>{t('buy_orbs')}</span></span>
        <span className="item__price"><span className="buy-content__value">{(50 * (ORB_TO_DOLLAR/this.state.exchangeRate[this.state.selectedCurrency])).toFixed(2)}</span> {this.state.selectedCurrency}</span>
        </div>
        </div>      
        </a>
        <a href="#payment-method">
        <div 
        className="buy-content__item buy-content__item_second"
        onClick={() => this.setState({
          amount: 100, 
        })}
        >
        <img className="buy-content__icon buy-content__icon_medium" src="/assets/images/main/buy/15.png"/>
        <div className="buy-content__info">
        <span className="item__amount">100 <span>{t('buy_orbs')}</span></span>
        <span className="item__price"><span className="buy-content__value">{(100 * (ORB_TO_DOLLAR/this.state.exchangeRate[this.state.selectedCurrency])).toFixed(2)}</span> {this.state.selectedCurrency}</span>
        </div>
        </div>  
        </a>
        <a href="#payment-method">
         <div 
         className="buy-content__item buy-content__item_third"
         onClick={() => this.setState({
          amount: 250, 
        })}
         >
        <img className="buy-content__icon buy-content__icon_large" src="/assets/images/main/buy/13.png"/>
        <div className="buy-content__info">
        <span className="item__amount">250 <span>{t('buy_orbs')}</span></span>
        <span className="item__price"><span className="buy-content__value">{(250 * (ORB_TO_DOLLAR/this.state.exchangeRate[this.state.selectedCurrency])).toFixed(2)}</span> {this.state.selectedCurrency}</span>
        </div>
        </div>  
        </a>
        <a href="#payment-method">
         <div 
         className="buy-content__item buy-content__item_forth"
         onClick={() => this.setState({
          amount: 500, 
        })}
         >
        <img className="buy-content__icon buy-content__icon_collosal" src="/assets/images/main/buy/11.png"/>
        <div className="buy-content__info">
        <span className="item__amount">500 <span>{t('buy_orbs')}</span></span>
        <span className="item__price"><span className="buy-content__value">{(500 * (ORB_TO_DOLLAR/this.state.exchangeRate[this.state.selectedCurrency])).toFixed(2)}</span> {this.state.selectedCurrency}</span>
        </div>
        </div>  
        </a>
       </div>
       </div>
       <div className='buy-orbs__select-payment-method' id='payment-method'>
       <h4 className="buy__header buy__header_small">{t('buy_buy_select_payment_method')}</h4>
       <div className="buy-orbs__container">
       <div className="buy-orbs__row"> 
            <div className={`buy-orbs__card-block ${this.state.selectedTab == 'CARD' ? 'selected' : ''}`} onClick={this.selectTab('CARD')}>
                {/* <div className="buy-orbs__card-icon"></div> */}
                <div className="buy-orbs__card-label">{t('buy_bank_card')}</div>
            </div>
            {/* <div className={`buy-orbs__card-block ${this.state.selectedTab == 'PRIVAT' ? 'selected' : ''}`} onClick={this.selectTab('PRIVAT')}>
            <div className="buy-orbs__card-icon"></div>
            <div className="buy-orbs__card-label">{t('buy_privat_liqpay')}</div>
            </div> */}
            <div className={`buy-orbs__money-block ${this.state.selectedTab == 'YANDEX' ? 'selected' : ''}`} onClick={this.selectTab('YANDEX')}>
                {/* <div className="buy-orbs__money-icon"></div> */}
                <div className="buy-orbs__money-label">{t('buy_yandex')}</div>
            </div>
            <div className={`buy-orbs__money-block ${this.state.selectedTab == 'QIWI' ? 'selected' : ''}`} onClick={this.selectTab('QIWI')}>
                {/* <div className="buy-orbs__money-icon"></div> */}
                <div className="buy-orbs__money-label">{t('buy_qiwi')}</div>
            </div>
            <div className={`buy-orbs__money-block ${this.state.selectedTab == 'ALPHA' ? 'selected' : ''}`} onClick={this.selectTab('ALPHA')}>
                {/* <div className="buy-orbs__money-icon"></div> */}
                <div className="buy-orbs__money-label">{t('buy_alpha')}</div>
            </div>
            <div className={`buy-orbs__money-block ${this.state.selectedTab == 'PSB' ? 'selected' : ''}`} onClick={this.selectTab('PSB')}>
                {/* <div className="buy-orbs__money-icon"></div> */}
                <div className="buy-orbs__money-label">{t('buy_psb')}</div>
            </div>
        </div>
       </div>
       <div className='buy-orbs__payment'>
       <div className='buy-orbs__label'>
       {t('buy_product')}
       </div>
       <input 
       className="buy-orbs__amount-input"
       type='text' 
       onChange={this.handleAmountInputChange}
       value={this.state.amount} 
       />
        <div className='buy-orbs__label'>
       {t('buy_for_payment')}
       <div className='buy-orbs__summ'>
       {(this.state.amount*(ORB_TO_DOLLAR/this.state.exchangeRate[this.state.selectedCurrency])).toFixed(2)} 
      {
        this.state.selectedTab == 'CARD' ?  <select value={this.state.selectedCurrency} className="profileInput buy-orbs__currency-select" name="currency" id="currency" onChange={this.handleCurrencyChange()}>
        {/* <option value="UAH" >UAH</option> */}
        <option value="RUB" >RUB</option>
        <option value="USD" >USD</option>
        <option value="EUR" >EUR</option>
</select> : this.state.selectedTab == 'PRIVAT' ? ' UAH' : ' RUB'
      }
      
       </div>
       <div className='buy-orbs__buy-button'
            onClick = {this.buy(this.state.amount)}
       >
       {t('buy_pay')}
       </div>
       </div>
       </div>
       </div>
       <div className='buy-orbs__footer'>
       {/* <div className='buy-orbs__supported-cards'>
       <div className="buy-orbs__card buy-orbs__card_small buy-orbs__visa" />
       <div className="buy-orbs__card buy-orbs__card_small buy-orbs__qiwi" />
       <div className="buy-orbs__card buy-orbs__card_small buy-orbs__yandex" />
       </div> */}
       {/* <div className='buy-orbs__powered-by'>Powered by<a href='https://ecommpay.com/'>Ecommpay</a></div> */}
       </div>
       </div>
  )
}
})

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    case 'header-three': return 'tabContent-header';
    default: return 'tabContent-paragraph';
  }
}
