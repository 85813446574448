import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"
import * as userActions from "actions/UserActions"
import EditProfileModal from "components/EditProfileModal"

class CompleteProfileContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isUploading: false
    }

    this.editPhoto = this.editPhoto.bind(this)
    this.saveAction = this.saveAction.bind(this)
  }
  

  editPhoto(files) {
    const clientId = "90f6583c00142d1"
    const bridge = this.props.userActions

    this.setState({
      isUploading: true
    })

    fetch("https://api.imgur.com/3/image", { // Your POST endpoint
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Client-ID ${clientId}`
      },
      body: files[0] // This is the content of your file
    }).then(response => response.json() // if the response is a JSON object
    ).then((success) => {
      if (success.success) {
        bridge.updateAvatar(success.data.link, (message, status) => {
          this.saveAction(message, status, "Image uploading")
        })
      }

      this.setState({
        isUploading: false
      })
    } // Handle the success response object
    ).catch((error) => {
      this.setState({
        isUploading: false
      })
      this.saveAction(error, "error", "Image uploading")
    } // Handle the error response object
    )
  }

  saveAction(message, status, title) {
    const { notify } = this.props
    notify({
      title: title || "Profile",
      message,
      status,
      dismissible: true,
      dismissAfter: 2000
    })

    setTimeout(() => {
      this.props.history.push("/")
    }, 1000)
  }

  render() {
    return (
      <div>
        <EditProfileModal {...this.props} saveAction={this.saveAction} editPhoto={this.editPhoto} isUploading={this.state.isUploading} />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfileContainer)
