import React, { Component } from "react"
import { Link } from "react-router-dom"

import { t } from "i18n";

export default () => {
  return (
    <div className='terms'>
    <div className="tabContent activeTab" id="tab2">
       <div className="tabContent-paragraph" dangerouslySetInnerHTML= {{__html: t('global_terms')}}>
        </div> 
    </div>
    </div>
  )
}
