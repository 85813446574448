import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import { Switch, Route, Link, withRouter } from "react-router-dom"
import Article from "./Article";
import { search } from "api/UsersApi";


class ArticlePreview extends Component {
  constructor(props) {
    super(props)
    const { user, article } =  this.props
    this.state = {
        categories: ["Cat1", "Cat4", "Cat2", "Cat3", "Cat5"],
        hasEditPermission: false,
    }
  } 
  getIndicesOf = (searchStr, str, caseSensitive) => {

    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    
    var startIndex = 0, index, indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
}
    componentDidMount(){
      const { user, article } =  this.props
      const userId = _.get(user, 'model.id')
      const userPermissions = _.get(user, 'model.permission')
      const authorId = article.author

      const isAdmin = _.isArray(userPermissions) && userPermissions.includes("SUPER")
      const isAuthor = userId == authorId
      const hasEditPermission = isAuthor || isAdmin
      this.setState({
        hasEditPermission
      })
      
    }
    render() {
    const { state, props } = this
    const { categories, hasEditPermission } = state
    const { t, article, user, closeSearchResults, searchTerm } = props
    // const temp = document.createElement("div")
    // temp.innerHTML = article.html
    // const articleTextContent = temp.textContent 
    const articleTextContent = article.html.replace(/(&nbsp;|<([^>]+)>)/ig, '')
    const indices = this.getIndicesOf(searchTerm, articleTextContent, false)
    return (  
      <div>
      {  
        article ?
        <div>
      <article className='help-center__article'>
        <div style={{
          display: "flex",
        }}>
          <h1 className="help-center__subheader"><Link onClick={closeSearchResults} to={{pathname: `/help/view/article/${article.title}`, state: {
              article,
              user,
            }}}>{article.title}</Link></h1>
          {
            hasEditPermission ? <Link to={{pathname: "/test-editor", state: {
              article,
              isEdit: true
            }}}>{t('hc_edit')}</Link> : null
          }
        </div>
        <div ref='container' className="help-center__article-content-preview" dangerouslySetInnerHTML={{
          __html: articleTextContent.substring(0, 200)+(articleTextContent.length > 200 ? '<span>...</span>': '')
        }} >
    </div>
      </article>
      </div> : null
      }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(trans(connect(mapStateToProps, mapDispatchToProps)(ArticlePreview)))