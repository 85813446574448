import React, { Component } from "react"
import Select from "react-select"
import "react-select/dist/react-select.css"

import Role from 'components/Common/Role'

export default class AdminUser extends Component {
  optionsPrms = [
    { label: "User", value: [] },
    { label: "Moderator", value: ["ACTIVITY"] },
    { label: "Super moderator", value: ["SUPER_ACTIVITY", "STREAM"]},
    { label: "Streamer", value: ["ACTIVITY","STREAM"] },
    { label: "Admin", value: ["SUPER", "SUPER_ACTIVITY"] },
    { label: "Partner", value: ["ACTIVITY", "STREAM", "PARTNER"] },
    { label: "Partner Plus", value: ["ACTIVITY", "STREAM", "PARTNER", "PARTNER_PLUS"] },
  ]

  constructor(props) {
    super(props)

    let { id, name, email, permissions, streams, taxes } = props.user
    streams = streams || []
    const role = this.optionsPrms.find(o => o.value.sort().join('') === permissions.sort().join(''))
    this.state = {
      selectedPrms: role,
      selectedStreams: _.map(streams, stream => ({ value: stream, label: stream })),
      usr: {
        id, name, email, permissions, streams, 
        taxes: taxes || {},
      },
      showTaxesFrom: Object.keys(taxes).length,
    }

    this.inputDataChange = this.inputDataChange.bind(this)
    // if (!props.currentUser.model.permissions.includes("USER_*")) { this.disablePrms() }
  }
 
  disablePrms() {
    const { optionsPrms } = this
    
    // disabled for picking
    const ar = ["BOOST_*", "USER_*", "PROMO_*"]
    ar.map((i) => { const r = optionsPrms.find(p => p.value == i); r.disabled = true; return r })

    // disallow to remove permissions 
    const ab = ["BOOST_*", "USER_*", "PROMO_*", "TEMPLATE_*", "PREDICTION_*"]
    ab.map((i) => { const r = optionsPrms.find(p => p.value == i); r.clearableValue = false; return r })
  }

  disableStreams(list) {
    list.map(s => s.clearableValue = false)
  }
  setTaxes = tax => (e) => {
    const { usr } = this.state
    usr.taxes[tax] = e.target.value
    this.setState({
      usr: usr
    })
  } 
  render() {
    const { optionsPrms, props, state } = this
    const { usr, selectedPrms, selectedStreams } = state

    if (!usr) {
      return null
    }

    const { id, name, email, permissions, streams, taxes } = usr
    const { save, streams: streamList } = props

   
    const optionsStreams = _.map(_.uniq(streamList), stream => ({
      label: stream.stream, 
      value: stream.id
    }))
  
    if (!props.currentUser.model.permissions.includes("SUPER")) { this.disableStreams(optionsStreams) }
 
    return (
      <Role roles={['ADMIN', 'SUPER_MODERATOR']}>
      <table className="predictionTemplate">
        <thead>
          <tr>
            <th width="27%">id:</th>
            <th width="73%">{id}</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>name:</td>
            <td><input type="text" id="name" defaultValue={name} onChange={this.inputDataChange.bind(this)} /></td>
          </tr>

          <tr>
            <td>email:</td>
            <td><input type="text" id="email" defaultValue={email} onChange={this.inputDataChange.bind(this)} /></td>
          </tr>

          <tr>
            <td>permissions:</td>
            <td>
              <Select
                name="form-field-permissions"
                value={selectedPrms}
                onChange={this.handleChangePermissions}
                placeholder="Select permission(s)"
                closeOnSelect={true}
                options={optionsPrms}
              />
            </td>
          </tr>
          {
            this.state.showTaxesFrom ?
          <tr>
            <td>taxes:</td>
            <tr><td>challenges:</td><td><input type="text"  defaultValue={taxes.challenge} onChange={this.setTaxes('challenge')} /></td></tr>
            <tr><td>predictions:</td><td><input type="text"  defaultValue={taxes.prediction} onChange={this.setTaxes('prediction')} /></td></tr>
            <tr><td>votings:</td><td><input type="text"  defaultValue={taxes.voting} onChange={this.setTaxes('voting')} /></td></tr>
            <tr><td>actions:</td><td><input type="text"  defaultValue={taxes.paidAction} onChange={this.setTaxes('paidAction')} /></td></tr>
          </tr>
          : 
          null
          }
          <tr>
            <td>streams:</td>
            <td>
              <Select.Creatable
                name="form-field-streams"
                value={selectedStreams}
                onChange={this.handleChangeStreams}
                placeholder="Select streams(s)"
                multi
                options={optionsStreams}
              />
            </td>
          </tr>

          <tr>
            <td>actions:</td>
            <td>
              <button onClick={() => save(state.usr, (err, msg) => {
                if(msg) alert(JSON.stringify(msg))
                else alert(err)
              })}>SAVE</button>
              <button onClick={() => remove(state.usr.id)} disabled>remove</button>
            </td>
          </tr>

        </tbody>
      </table>
      </Role>
    )
  }

  handleChangePermissions = (selectedPrms) => {
    this.setState({ selectedPrms })

    const { state } = this
    const { usr } = state
    if(!selectedPrms) return
    usr.permissions = selectedPrms.value.length ?  selectedPrms.value.flat(2) : selectedPrms.value   
    
    if(selectedPrms.value.includes("PARTNER_PLUS")){
      this.setState({
        showTaxesFrom: true,
      })
    }
  }

  handleChangeStreams = (selectedStreams) => {
    const { usr } = this.state
    this.setState({ selectedStreams })
    usr.streams = selectedStreams.map(s => s.value)
  }

  inputDataChange(e) {
    const { state } = this
    const { usr } = state

    switch (e.target.id) {
      case "permissions":
        usr.permissions = e.target.value
        break
      default:
        usr[e.target.id] = e.target.value
        break
    }
  }
}
