import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import * as UserActions from "actions/UserActions"
import * as StreamActions from "actions/StreamActions"

import * as StreamAPI from "api/StreamAPI"

class CreateStream extends Component {
  constructor(props) {
    super(props)

    const { title, stream, type, image, id, featured, online, owner, disable } = props.stream || {}

    this.state = {
      stream: {
        title: title || "",
        stream: props.justCreate ? "" : stream.stream || stream || "",
        type: type || "",
        image: image || "",
        id: id || 0,
        featured: featured || false,
        online: online || true,
        game: "Dota 2",
        moderators: [], 
        owner, 
        disable,
      },
      names: [
        "Dota 2",
        "CS:GO",
        "League of Legends",
        "POKER",
        "Fortnite",
        "PUBG",
        "Fighting",
        // `Overwatch`,
        "Hearthstone",
        "World of Tanks", 
        "Custom"
      ],
      images: [
        ["Dota 2", "/assets/images/main/slider/4.jpg"],
        ["CS:GO", "/assets/images/main/slider/1.jpg"],
        ["League of Legends", "/assets/images/main/slider/5.jpg"],
        ["POKER", "/assets/images/main/slider/3.jpg"],
        ["Fortnite", "/assets/images/main/slider/8.jpg"],
        ["PUBG", "/assets/images/main/slider/7.jpg"],
        ["Fighting", "/assets/images/main/slider/6.jpg"],
        // [`Overwatch`,         `/assets/images/main/slider/2.jpg`],
        ["Hearthstone", "/assets/images/main/slider/9.jpg"],
        ["World of Tanks", "/assets/images/main/slider/10.jpg"]
      ]

    }

    this.inputDataChange = this.inputDataChange.bind(this)
    this.searchInputChange = this.searchInputChange.bind(this)
    this.inviteModerator = this.inviteModerator.bind(this)
  }

  inviteModerator = moderatorId => () => {
    StreamAPI.givePermissions(this.props.stream.id, { id: moderatorId }, (err, msg) => {
      alert('Success!')
    })
  }
  render() {
    const { justCreate, StreamActions, user } = this.props
    const { createAdmin, remove, updateStreamAdmin, activate, deactivate } = StreamActions
    const { title, stream, type, image, id, featured, online, game, moderators, owner, disable } = this.state.stream

    const isAdmin = !!(user.model.permissions.includes('SUPER') || user.model.permissions.includes('SUPER_ACTIVITY'))
    if(!isAdmin) return <div style={{color: "white", fontSize: "60px"}}>ACCESS DENIED</div>
    return (
      <div className="tabContent activeTab">
        <div className="modalViewBlock" style={{ display: "block", width: "initial", marginBottom: "25px", padding: "20px" }}>
          <form className="profilePageForm" noValidate onSubmit={this.handleFormSubmit}>
         <div className = "inputBlock">
         <label className="profilePageInput-label" htmlFor="game">
         Stream game
          <select
                  value={game}
                  name="game"
                  className="profileInput profileInput_narrow"
                  onChange={this.inputDataChange.bind(this, "game")}
                >
                  <option value="" disabled hidden>Choose game type</option>
                  {this.state.names.map(name => <option value={name} key={name}>{name}</option>)}
            </select>
            </label>
            </div>
            <div className="inputBlock">

              <label className="profilePageInput-label" htmlFor="title">
                Stream name
                <input
                  className="profileInput profileInput_narrow"
                  name="title"
                  placeholder="Overwatch"
                  value={title}
                  onChange={this.inputDataChange.bind(this, "title")}
                />
              </label>
            </div>
            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="stream">
                Stream id
                <input
                  className="profileInput profileInput_narrow"
                  placeholder="v1lat"
                  name="stream"
                  defaultValue={stream}
                  onChange={this.inputDataChange.bind(this, "stream")}
                />
                <div style={{ width: "334px", marginTop: "10px", borderRadius: "8px", overflow: "hidden" }}>
                  <img src="/assets/images/admin/stream/twitch_stream_help.png" alt="twitch" style={{ width: "100%", display: type == "twitch" ? "block" : "none" }} />
                  <img src="/assets/images/admin/stream/youtube_user_stream_help.png" alt="youtube user" style={{ width: "100%", display: type == "youtube" ? "block" : "none" }} />
                  <img src="/assets/images/admin/stream/youtube_channel_stream_help.png" alt="youtube channel" style={{ width: "100%", display: type == "youtube" ? "block" : "none" }} />
                  <img src="/assets/images/admin/stream/goodgame_stream_help.png" alt="goodgame" style={{ width: "100%", display: type == "goodgame" ? "block" : "none" }} />
                </div>
              </label>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="type">
                Stream type
                <select
                  value={type}
                  name="types"
                  className="profileInput profileInput_narrow"
                  onChange={this.inputDataChange.bind(this, "type")}
                >
                  <option value="" disabled hidden>Choose stream type</option>
                  <option value="twitch">twitch</option>
                  <option value="youtube">youtube</option>
                  <option value="goodgame">goodgame</option>
                </select>
              </label>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="image">
                Stream image
                <input
                  className="profileInput profileInput_narrow"
                  name="image"
                  placeholder="/url/to/super/image.svg"
                  value={image}
                  onChange={this.inputDataChange.bind(this, "image")}
                />
              </label>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="image">
                Featured
                <select
                  value={featured}
                  name="featureds"
                  className="profileInput profileInput_narrow"
                  onChange={this.inputDataChange.bind(this, "featured")}
                >
                  <option value="" disabled hidden>Choose is featured</option>
                  <option value>YES</option>
                  <option value={false}>NO</option>
                </select>
              </label>
            </div>
            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="owner">
                Stream owner
                <input
                  className="profileInput profileInput_narrow"
                  name="owner"
                  value={owner}
                  onChange={this.inputDataChange.bind(this, "owner")}
                />
              </label>
            </div>
            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="image">
                Online
                <select
                  value={online}
                  name="online"
                  className="profileInput profileInput_narrow"
                  onChange={this.inputDataChange.bind(this, "online")}
                >
                  <option value="" disabled hidden>Choose is online</option>
                  <option value>Online</option>
                  <option value={false}>Offline</option>
                </select>
              </label>
            </div>
            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="moderator">
                Invite moderator
                <input
                  className="profileInput profileInput_narrow"
                  name="moderator"
                  placeholder="Start typing moderators nickname"
                  value={moderators}
                  onChange={this.inputDataChange.bind(this, "moderators")}
                />
              </label>
            </div>
            <div className='stream-edit__moderator-list'>
            {
              this.props.users.map( u => {
                return <div className='stream-edit__moderator'>
                  <div className='stream-edit__head'>
                  <div className='stream-edit__moderator-avatar'><img src={u.avatar} /></div>
                  <div className='stream-edit__moderator-name stream-edit__label'>{u.name}</div>
                  </div>
                  <div className='stream-edit__moderator-id stream-edit__label'>Moderator identifier: {u.id}</div>
                  <div className='stream-edit__moderator-reputation stream-edit__label'>Moderator reputation: {(5-Math.random()).toFixed(2)}/5</div>
                  <div className='stream-edit__invite ' onClick={this.inviteModerator(u.id)}>Invite</div>
                </div>
              })
            }
            </div>
            <div className='stream-edit__moderator-list'>
            <div className='stream-edit__label'>My moderators</div>
            {
              `Not implemented yet`
            }
            </div>
          </form>
          {justCreate ? (

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="save">
                Save stream
                <button onClick={() => createAdmin(this.state.stream, (error, stream) => { alert(`${error}, ${stream.stream}`) })} className="pre-btn pre-btn_blue pre-btn_modal">
                  SAVE
                </button>
              </label>
            </div>
          ) : (
            <div>
              <div className="inputBlock">
                <label className="profilePageInput-label" htmlFor="remove">
                    update stream
                  <button onClick={() => updateStreamAdmin(this.state.stream, (error, stream) => { alert(`${error}, ${stream.stream}`) })} className="stream-edit__button pre-btn pre-btn_blue pre-btn_modal">
                      SAVE
                  </button>
                </label>
              </div>
            {
              !disable ?
              <div className="inputBlock">
                <label className="profilePageInput-label" htmlFor="remove">
                    Disable stream
                  <button onClick={() => deactivate(this.state.stream.id, (error, stream) => { alert(`${error}, ${stream.stream}`) })} className="pre-btn pre-btn_red pre-btn_modal stream-edit__button ">
                    Disable
                  </button>
                </label>
              </div>
              : 
              <div className="inputBlock">
                <label className="profilePageInput-label" htmlFor="remove">
                    Activate stream
                  <button onClick={() => activate(this.state.stream.id, (error, stream) => { alert(`${error}, ${stream.stream}`) })} className="pre-btn pre-btn_red pre-btn_modal stream-edit__button ">
                    Activate
                  </button>
                </label>
              </div>
            }
            </div>
            )}
        </div>
      </div>
    )
  }
  searchInputChange(e) {
    const { props, state } = this
    const queue = {}
    queue.name = e.target.value
    if (e.target.value.length > 3) { this.props.UserActions.search(queue) }
  }

  inputDataChange(type, event) {

    switch (type) {
      case "game": 
        this.state.stream.game = event.target.value.trim()
        break
      case "title":
        this.state.stream.title = event.target.value
        break
      case "stream":
        this.state.stream.stream = event.target.value.trim()
        break
      case "type":
        this.state.stream.type = event.target.value.trim()
        break
      case "image":
        this.state.stream.image = event.target.value.trim()
        break
      case "featured":
        this.state.stream.featured = JSON.parse(event.target.value)
        break
      case "online":
        this.state.stream.online = JSON.parse(event.target.value)
        break
      case "owner": 
      this.state.stream.owner = event.target.value
      break
      case "moderators": 
        this.state.stream.moderators = event.target.value
        this.searchInputChange(event)
        break
      default: break
    }
    this.setState({
      stream: this.state.stream
    })
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamlist: state.streamList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStream)