import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { appRoutes } from 'utils/config';

const ProfileMenu = props => {
    // @TODO add closer
    return (
        <ul className='profile-menu bg-white black pl2 py4'>
            <Link to={appRoutes.PROFILE_MAIN} className='mb2 medium block'>ГЛАВНАЯ</Link>
            <Link to={appRoutes.PROFILE_PAYMENTS} className='mb2 medium block'>ИСТОРИЯ ПЛАТЕЖЕЙ</Link>
            <Link to={appRoutes.PROFILE_SETTINGS} className='mb2 medium block'>НАСТРОЙКИ</Link>
            <Link to={appRoutes.PROFILE_PERSONALIZATION} className='mb2 medium block'>ПЕРСОНАЛИЗАЦИЯ</Link>
            <Link to={appRoutes.PROFILE_WIDGET} className='mb2 medium block'>ВИДЖЕТ</Link>
            <Link to={appRoutes.PROFILE_PARTNERSHIP} className='mb2 medium block'>ПАРТНЕРСКОЕ СОГЛАШЕНИЕ</Link>
            <Link to={appRoutes.PROFILE_STREAM} className='mb2 medium block'>ПРОФИЛЬ СТРИМА</Link>
            <Link to={appRoutes.PROFILE_STREAMS} className='mb2 medium block'>МОДЕРИРУЕМЫЕ СТРИМЫ</Link>
        </ul>
    )
}

ProfileMenu.propTypes = {
    menuItems: PropTypes.array.isRequired,
}

ProfileMenu.defaultProps = {
    menuItem: []
}

export default ProfileMenu
