import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const StreamCard = props => {
    const { stream, key } = props;
    let image; 
    if(typeof stream.image === 'string'){
        image = stream.image.replace('{width}', '320').replace('{height}', '180');
    }
    return (
        <Link className="relative stream-card m1 block" to={`/stream/${stream.stream.trim().replace(/ /g, "_")}#watch`} key={key} activeClassName="activestreamItemList">
           <div className="stream-card__overlay"  style={{backgroundImage: `url(${image})`, backgroundSize: '100% 100%'}} />
            <div className="streamItem-amount flex flex-column">
                <span>{`${stream.stream.trim().trim()} `}</span>
                <span>{ stream.challengesCount ? `${stream.challengesCount} CH` : null }</span>
            </div>
            {/* {stream.online === true ? <span className="streamItem-live">LIVE</span> : null} */}
        </Link>
    )
}

StreamCard.propTypes = {

}

export default StreamCard
