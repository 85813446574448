import collectionReducers from "utils/collectionReducers"

const initialState = []

const NAMESPACE = "USERS"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function set(list) {
  return {
    namespace: NAMESPACE,
    type: "SET_USERS_LIST",
    handle: (state) => {
      return [...list]
    }
  }
}
