import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/_Button';
import Form from 'components/_Form';
import Textarea from 'components/Textarea';

const ReportStreamer = props => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
   
    return (
        <Form className='report-streamer py3 px5 bg-white black border' onSubmit={() => { alert('') }}>
        <h1 className="h1 mb4">Жалоба на стримера</h1>
        <label htmlFor="" className='mb2 t3 bold'>Категория проблемы</label>
        <Input
            className='mb2 t3'
            value={title}
            view='ghost'
            onChange={setTitle}
            placeholder='Введите название' />
        <label htmlFor="" className='mb2 t3 bold'>Опишите проблему</label>
        <Textarea
            value={description}
            onChange={setDescription}
            placeholder='Введите описание'
            className='mb2 t3'
        />
        {/* <div className="flex mb2 t3">
            <p>Добавить скриншот</p>
            <Button type='submit' className='mx-auto'>Файл</Button>
        </div> */}
        <Button type='submit' className='mx-auto'>ОТПРАВИТЬ ЖАЛОБУ</Button>
    </Form>
    )
}

ReportStreamer.propTypes = {

}

export default ReportStreamer
