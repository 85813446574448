import React from "react"
import ReactDOM from 'react-dom'
import './modal-window.scss';

const appRoot = document.getElementById('root');

const handleHideModal = handler => (e) => {
  if (e.target === e.currentTarget && 
      typeof handler === 'function') 
      handler(e);
}

export default({ children, content, show, hideModal, userClass }) => {
  return show ? ReactDOM.createPortal(
    <div className="overlay flex justify-center items-center" onClick={handleHideModal(hideModal)} role="Dialog">
      <div className={`custom-modal modal-animate ${userClass}`}>
        <img src="/assets/images/main/icons/icon-normal-close-dark.svg" className="closeButton" onClick={handleHideModal(hideModal)}/>
          {children || content || null}
      </div>
    </div>, appRoot
  ) : null
}
