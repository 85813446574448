
import React from 'react'; 
import { useMediaQuery } from 'react-responsive';
import './footer.scss';

import { DESKTOP_MIN_RESOLUTION } from 'utils/config';

import FooterDesktop from './Footer';
import FooterMobile from './Mobile/Footer';

const FooterContainer = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: DESKTOP_MIN_RESOLUTION });
    return isDesktopOrLaptop ? <FooterDesktop {...props}/> : <FooterMobile {...props} />;
} 

export default FooterContainer;