import React from 'react';
import times from 'lodash/times';

import PropTypes from 'prop-types';
import TaskCard from './TaskCard';

const TasksFeed = props => {
    return (
        <div className='tasks-feed bg-black p2 white flex flex-column'>
            <h6 className="h6 mb2">Доступные задания</h6>
            {
                times(25, () => <TaskCard className='mb2'/>)
            }
        </div>
    )
}

TasksFeed.propTypes = {

}

export default TasksFeed
