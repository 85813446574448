import * as Notifications from "../reducers/notificationsList"
import * as NotificationsAPI from "api/NotificationsAPI"

export function add(item) {
  return (dispatch) => {
    dispatch(Notifications.add(item))
  }
}

export function get() {
  return (dispatch) => {
    NotificationsAPI.get((list) => {
      (list || []).forEach((item) => {
        dispatch(Notifications.add(item))
      })
    })
  }
}

export function updated(callback) {
  return (dispatch) => {
    NotificationsAPI.updated((item) => {
      console.log('test666', item)
      // dispatch(Notifications.add(item))
      if (callback) callback(item)
    })
  }
}
