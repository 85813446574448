import React, { Fragment, Component } from "react"
import FormValidator from "components/Common/FormValidator"
import Timer from "../AdminPrediction/AdminPredictionTimerComponent"
import { trans } from 'utils/translator';
class PredictionNew extends FormValidator{

  constructor(props){
    super(props)
    const { props: {prediction: { closedAt } } } = this
    this.state = {
       isClosed: !!closedAt,
       shouldCreatePrd: false,
    }
  }

  create = (prd) => {
    const { props: { prediction: { id }, PredictionsActions: { create, createInitialBets } } } = this
    create({ ...prd, outcomes: [{title: prd.outcomes[0].title, desc: ""}, {title: prd.outcomes[1].title, desc: ""}] },
    (err, prd) => {
      const token1 = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzUwfQ.HGg_hGey5BmjUQA5lPomtlGssfS-t5SMSQ8HTKmYbjQ"
      const token2 = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzUxfQ.sbpA7tvESI3TqpQHHPvc7TzlvvNj_HzADr-r1jfAwrc"
      createInitialBets(prd, token1, token2) 
    })
  }

  calculateBet = (id, outcome) => {
    const { props: { prediction: {stream, mode, outcomes, title, game }, PredictionsActions: { calculate } }, state: { shouldCreatePrd }, create, setState } = this
    const { t } = this.props
    if(!window.confirm(`${t('admin_calculate_bet_msg_1')} ${outcome} ${t('admin_calculate_bet_msg_2')}`)) return
    calculate(id, outcome, (error, prd) => { 
      if( shouldCreatePrd ) {
        create({
          stream,
          game,
          title, 
          mode, 
          outcomes: [{title: outcomes[0].title, desc: ""}, {title: outcomes[1].title, desc: ""}]})
        setState({ shouldCreatePrd: false })
      }
    })
  }
 
  done = (outcome) => (e) => {
    const { calculateBet, props: { prediction: { id } } } = this
    e.preventDefault(e)
    calculateBet(id, outcome)
  }

  cancel = shouldCreatePrd => (e) => {
    const { props: { prediction: { id, stream, mode, outcomes, title, game }, PredictionsActions: { cancel } }, create, setState } = this
    e.preventDefault()
    cancel(id, (err, prd) => {
      if(shouldCreatePrd){
        create({
          stream, 
          mode,
          game, 
          title, 
          outcomes: [{title: outcomes[0].title, desc: ""}, {title: outcomes[1].title, desc: ""}]
        })
      }
    })   
  }

  close = shouldCreatePrd => (e) => {
    const { props: { prediction: { id }, PredictionsActions: { close } } } = this
    const { t } = this.props
    e.preventDefault()
    if(!confirm(t('admin_close_prediction_comfirmation_msg'))) return
    this.setState({shouldCreatePrd})
    close(id, () => {
      this.setState(prevState => ({ isClosed: !prevState.isClosed })) 
    })
  }

  render(){ 
    const { state: { isClosed }, props: { prediction: { createdAt, title, outcomes: [lose, win] } } } = this
    const { t } = this.props
    return (
    <form className="adm-new-control-card adm-new-control-card--active adm-new-prediction adm-new-prediction-state2">
      <div className="adm-new-control-card__header">
        <div className="adm-new-control-card__option">
          <span className="adm-new-control-card__timer">
          <Timer show={'SECONDS'} desc={t('admin_from_the_beginning')} from={new Date(createdAt).getTime()} />
          </span>
        </div>
      </div>
      <div className="adm-new-control-card__content">
        { title }
        {/* <input 
        className="adm-new-control-card__input"
        onChange={(e) => e.preventDefault()}
        value={win.title}
        />
        <input 
        className="adm-new-control-card__input"
        onChange={(e) => e.preventDefault()}
        value={lose.title} 
        /> */}
      </div>
      <div className="adm-new-control-card__actions">
      {
          isClosed ? <Fragment>
              <button 
              className="adm-new-control-card__btn adm-new-control-card__btn--accent"
              onClick={this.done(0)}
              >{lose.title}</button>
              <button 
              className="adm-new-control-card__btn adm-new-control-card__btn--blue"
              onClick={this.done(1)}
              >{win.title}</button>
            </Fragment> :
            <Fragment>
                <div className="adm-new-control-card__btn-group adm-new-prediction__btn-group">
                  <button 
                  className="adm-new-prediction__btn adm-new-control-card__btn adm-new-control-card__btn--accent"
                  onClick={this.close(false)}
                  >{t('admin_close')}</button>
                  <button
                  className="adm-new-prediction__btn adm-new-control-card__btn adm-new-control-card__btn--accent adm-new-control-card__btn--bordered"
                  onClick={this.close(true)}
                  >& {t('admin_create')}</button>
                </div>
                <div className="adm-new-control-card__btn-group adm-new-prediction__btn-group">
                  <button
                  className="adm-new-prediction__btn adm-new-control-card__btn adm-new-control-card__btn--red"
                  onClick={this.cancel(false)}
                  >{t('admin_cancel')}</button>
                  <button
                  className="adm-new-prediction__btn adm-new-control-card__btn adm-new-control-card__btn--red adm-new-control-card__btn--bordered"
                  onClick={this.cancel(true)}
                  >& {t('admin_create')}</button>
                </div>
            </Fragment>
          }
      </div>
    </form>
  );
  }
}
export default trans(PredictionNew)

