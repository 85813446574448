import React from 'react'
import PropTypes from 'prop-types'
import fb from 'assets/images/main/icons/small-facebook-white.svg';
import discord from 'assets/images/main/icons/small-discord-white.svg';
import Logo from 'components/Logo';
import { Link } from 'react-router-dom';
import { trans } from 'utils/translator';

const FooterMobile = props => {
    const { t } = props;
    return (
        <div className='footer-mobile flex justify-between p2'>
            <div className="footer-mobile__logo-box flex flex-column">
                <Logo view='footer' />
                <div className="flex mt4">
                    <img src={fb} alt="Facebook"/>
                    <img className='ml2' src={discord} alt="Discord"/>
                </div>
            </div>
            <nav className='footer-mobile__nav flex flex-column'>
                <Link className='mb2' to="/streams/">{t('header_nav_streams')}</Link>
                <Link className='mb2' to="/help">{t('header_nav_helpcenter')}</Link>
                <Link className='mb2' to={`/admin/stream/`}>
                    {t('admin_panel')}
                </Link>
            </nav>
        </div>
    )
}

FooterMobile.propTypes = {

}

export default trans(FooterMobile);
