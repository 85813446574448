import React, { Component, Fragment } from 'react';


 
 const Stub = ({title, message}) => { 
     return <div className="stub">
        <h1 className="head text stub__head">{title}</h1>
        <p className="text stub_message">{message}</p>
     </div>
 }

 export default Stub