import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import StreamPage from './StreamPage';
import * as StreamActions from "actions/StreamActions"
import * as ModalActions from "actions/ModalActions"
import './stream-page.scss';


function mapStateToProps(state, ownProps) {
    return {
        streamList: state.streamList.streamlist,
        challenges: state.challenges.challenges,
        votings: state.challenges.votings,
        predictions: state.challenges.challenges,
        paidActions: state.challenges.paidActions,
        brandquests: state.brandquests
    }
}

function mapDispatchToProps(dispatch) {
    return {
        StreamActions: bindActionCreators(StreamActions, dispatch),
        ModalActions: bindActionCreators(ModalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamPage)
