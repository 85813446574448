import React from 'react'
import PropTypes from 'prop-types'
import iconGoogle from '../../assets/images/main/icons/normal-google-grey.svg';
import iconTwitch from '../../assets/images/main/icons/normal-twitch-grey.svg';
import iconSteam from '../../assets/images/main/icons/normal-steam-grey.svg';

const Socials = props => {
    return (
        <div className="flex justify-around">
            <img
                className="social-icon circle"
                src={iconGoogle}
                alt="Google" />
            <a href='/api/auth/twitch'>
                <img
                    className="social-icon circle"
                    src={iconTwitch}
                    alt="Twitch" />
            </a>
            <img
                className="social-icon circle"
                src={iconSteam}
                alt="Steam" />
        </div>
    )
}

Socials.propTypes = {

}

export default Socials
