import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as StreamActions from "actions/StreamActions"
import StreamListSlider from "components/StreamListSlider"

class PredictionsContainer extends Component {
  render() {
    return (
      <StreamListSlider {...this.props} StreamActions={this.props.StreamActions} key="streamList" />
    )
  }
}


function mapStateToProps(state) {
  return { 
    // streamList: state.streamList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PredictionsContainer)
