import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import Slider from "react-slick"
import * as BettyAPI from "api/BettyAPI"

export default class StreamListSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isNeedUpdate: false,
      fifaTeamlist: [],
      dotaHeroes: [],
      bets: this.props.bets || [],
    }
    }
  componentDidMount(){
      BettyAPI.getFifaTeams((err, msg) => {
       this.setState({
           fifaTeamlist: msg.teams,
       })
       
   })
      BettyAPI.getDotaHeroes((err, msg) => {
      this.setState({
       dotaHeroes: msg.heroes,
   })
   })
      // BettyAPI.bets((err, msg) => {
      //   this.setState({
      //     bets: msg,          
      //   })
      // })
   }
  toTitle(id) {
    return ["Dota 2", "Counter-Strike: Global Offensive", "FIFA"][id-1]
  }

  renderStreamLinks(data) {
    let result = Array()
    const {fifaTeamlist, dotaHeroes, csTeams} = this.state
    const teams1 = [
      '',
      'Radiant',
      'Counter-T',
      'Team 1',
  ]
  const teams2 = [
      '',
      'Dire',
      'Terrorist',
      'Team 2',
  ]

    //https://static-cdn.jtvnw.net/previews-ttv/live_user_dotatv247-400x225.jpg
  const list = [
    [],
    dotaHeroes || [],
    csTeams || [],
    fifaTeamlist || [],
  ] 

  const defaultImage1= [
    {
      image: ''
    },
    {
      image: 'https://d1u5p3l4wpay3k.cloudfront.net/dota2_gamepedia/3/33/Unknown_Hero_icon.png?version=551ac1fa463f9299465ba7cfd14331ee'
    },
    {
      image: 'https://res.cloudinary.com/teepublic/image/private/s--L-imVlF8--/t_Preview/b_rgb:191919,c_limit,f_jpg,h_630,q_90,w_630/v1479252809/production/designs/821539_1.jpg'
    },
    {
      image: '',
    }
  ]

  const defaultImage2= [
    {
      image: ''
    },
    {
      image: 'https://d1u5p3l4wpay3k.cloudfront.net/dota2_gamepedia/3/33/Unknown_Hero_icon.png?version=551ac1fa463f9299465ba7cfd14331ee'
    },
    {
       image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlY9BMGUHAQTjE3S0FJ8Lj5MDP8oQVj_ExMJ24Z6Auj2-bEha8'
    },
    {
      iamge: '',
    }
  ]

  // ULTIMATE GOVNOKOD
  const onlineEvents = data.filter(e => !!e.translation_link)
  const offlineEvents = data.filter(e => !e.translation_link)

  const betPlacedEvents = offlineEvents.filter(e => this.props.bets.find(b => (b.event_id == e.id && b.user_id == this.props.user.model.id)))
  const waitingForBetEvents = offlineEvents.filter(e => !this.props.bets.find(b => (b.event_id == e.id && b.user_id == this.props.user.model.id)))
 
  // MODERATE GOVNOKOD

  result = [...waitingForBetEvents, ...betPlacedEvents, ...onlineEvents].map(item => {
        const pair = item.pair || ""
        const teams = pair.split(',') || []
        const medianIndex = (teams.length ) / 2
        const team1 = teams.slice(0, medianIndex)
        const team2 = teams.slice(medianIndex, teams.length)
        const gameId = item.game_id
        const isOnline = !!item.translation_link
        const betPlaced = this.props.bets.find(b => (b.event_id == item.id && b.user_id == this.props.user.model.id))  

         return   <NavLink className="streamItem" to={`/betty/game/${this.toTitle(item.game_id).trim().replace(/ /g, "_")}/stream/${item.id}`} key={item.id} activeClassName="activestreamItemList">
        {/* <img className="streamItem-img" src={item.translation_link ? '/assets/images/betty/blurred.png' : '/assets/images/betty/blurred.png'} alt={item.game_id} /> */}
       
        <div className="streamItem-overlay betty__overlay" />
        <span className="streamItem-amount betty__amount">
        {isOnline ? 'Online ' : null} 
        {(betPlaced && !isOnline)  ? 'Bet placed ' : null} 
        {(!betPlaced && !isOnline) ? 'Place bet ' : null }
        </span>
         <div className='betty__event-preview' style={{
           flexDirection: gameId == 1 ? 'column' : 'row'
         }}
         >
         <div className="betty__team-one">
         {
             team1.map(team => {
               const teamImage = gameId == 1 ? (list[gameId].find(t => t.id == team) || defaultImage1[gameId]).image :
               (list[gameId].find(t => t.id == team) || defaultImage1[gameId]).image
                 return  <div className={`betty__event-preview-item ${gameId == 1 ? 'dota' : ''}`} style={{
                    backgroundImage: `url(${teamImage})` ,
                  }}/>
                })
         }
         </div>
         <div className="betty__team-two">
         {
            team2.map(team => {
              const teamImage = gameId == 1 ? (list[gameId].find(t => t.id == team) || defaultImage2[gameId]).image :
              (list[gameId].find(t => t.id == team) || defaultImage2[gameId]).image
              return  <div className={`betty__event-preview-item ${gameId == 1 ? 'dota' : ''}`}  style={{
                 backgroundImage: `url(${teamImage})` ,
               }}/>
             })
         }
         </div>
         
        {/* <div className="betty__team-label">{teams1[item.game_id]}{teams2[item.game_id]}</div> */}
        </div>
      </NavLink>
    })

    // Slider "не умеет" в пустые элементы. Если задавать ему определённую ширину, то он не будет заполнять пробелами это место.
    // Поэтому пришлось добавлять вручную пустые эллементы. Хотя можно было просто на фоне что-то сверстать с черными блоками, 
    // а слайдер добавить поверх этого пустого места
    const additional = 7 - data.length > 0 ? 7 - data.length : 0
    for (let i = 0; i < additional; i++) {
      result.push(<div className="streamItem" key={`stream_empty_${i}`} />)
    }

    return result
  }

  render() {
    const streamList = this.props.events || []
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        
      ]
    }

    const links = this.renderStreamLinks(streamList)

    return (
      <div className="twitchStream">
        <div className="streamSlider" id="streamSlider">
          {links.length <= 5 ? <PrevArrow /> : null}
          <Slider {...settings}>
            {links}
          </Slider>
          {links.length <= 5 ? <NextArrow /> : null}
        </div>
      </div>
    )
  }
}

function NextArrow({ className, style, onClick }) {
  return (
    <div
      className={`${className || "slick-arrow slick-next slick-disabled slider-mobile-hidder"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="streamItem-img">
        <div className="streamSlider__arrow-right" />
      </div>
    </div>
  )
}

function PrevArrow({ className, style, onClick }) {
  return (
    <div
      className={`${className || "slick-arrow slick-prev slick-disabled slider-mobile-hidder"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
       <div className="streamItem-img">
        <div className="streamSlider__arrow-left" />
      </div>
    </div>
  )
}