import React from 'react'
import PropTypes from 'prop-types'

const Icon = props => {
    const { size, name, className } = props;
    return (
        <i className={`icon icon-sizes--${size} icon-names--${name} ${className}`} />
    )
}

Icon.defaultProps = {
    size: 'default'
}

Icon.propTypes = {

}

export default Icon
