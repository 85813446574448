import React, {Component} from "react"
import { trans } from "utils/translator";
import  {Editor, convertFromRaw, EditorState} from "draft-js";
import {Link} from "react-router-dom";
import BecomeStreamerModal from "components/Feedback"
import AuthForm from "components/AuthForm"

import * as PaymentAPI from "api/paymentAPI"
const BecomeStreamer = trans(class BecomeStreamer extends Component{
    constructor(props){
      super(props)
      this.state = {
        showModal: false
      }
      this.handleClick = this.handleClick.bind(this)
      this.hide = this.hide.bind(this)
    }
    hide = () => {
      this.setState({
        showModal: false
      })
      this.props.hideHelloModal()
    }
    handleClick = () => {
      this.setState({
        showModal: !this.state.showModal
      })
    }
    render(){
    const { t } = this.props
    const { showModal } = this.state
    
    return (
      <div className="become-streamer">
      
      <div onClick={this.handleClick} className="become-streamer__button how-it-works__become-streamer pre-btn">
          {t('global_become_streamer')}
      </div>
     
      {
        showModal ? <div onClick={this.handleClick} className='become-streamer__wrapper'><BecomeStreamerModal hide={this.hide}/></div> : null
      }
      </div>
    )
  }
  }
  )
export default trans(class HowItWorks extends Component{

  constructor(props){
    super(props)
    this.state = {
        activeContainer: -1,
        activeTab: 'VIEWER'
    }
    this.handleMouseOnContainer = this.handleMouseOnContainer.bind(this)
    this.handleContainerClick = this.handleContainerClick.bind(this)

  }
  handleContainerClick = container => () => {
        this.setState({
            activeTab: container,
        }) 
  }
  handleMouseOnContainer = container => () => {
      this.setState({
          activeContainer: container
     })
  }
  render(){
  const { t } = this.props
  
  return (
  <div onClick={(e) =>  e.stopPropagation()} className='how-it-works'>
  <div className="how-it-works__head how-it-works__bg">
  <div className='how-it-works__close' 
  onClick = {this.props.hide}
  />
    <p className="how-it-works__description">
    {t('hello_modal_description')}
    {t('hello_modal_our_mission')}
    </p>
    <a className="how-it-works__learn-more" target="_blank" href={`/help/view/${t('header_how_it_works')}`}>
    {t('hello_modal_join_predictoria')}
    </a>
    <br />
    <div className="how-it-works__registration-block">
    <button  onClick={ () => {
      this.props.ModalActions.toggleAuthModal({ activeTab: AuthForm.STREAMER_TAB })
      this.props.hide()
    }} className="how-it-works__registration how-it-works__registration_gold  pre-btn pre-btn_gold ">
    {t('hello_modal_im_streamer')}
    </button>
    <button  onClick={ () => {
      this.props.ModalActions.toggleAuthModal({ activeTab: AuthForm.VIEWER_TAB })
      this.props.hide()
    }} className="pre-btn how-it-works__registration">
    {t('hello_modal_im_a_viewer')}
    </button>
    </div>
  </div>
   </div>
  )
}
})
