import React, { Component } from "react"

import { groupBy } from "lodash"

import VotingNew  from "./ActivitiesNew/Voting"
import VotingTemplate from "./ActivitiesNew/VotingTemplate"
import { defaultVoting } from "./DefaultPredictions"
import { trans } from 'utils/translator';

class VotingsBlock extends Component {
  constructor(props) {
    super(props)
  }

  renderList = (votings=[]) => {
    const { props: { VotingActions,  VotingTemplateActions } } = this
    return votings.map((item, i) => {
      const isTemplate = !item.status
      return  isTemplate ? <VotingTemplate key={`voting_${item.id}+${item.createdAt}`}  { ...{ voting: item, VotingActions, VotingTemplateActions } } />
      : <VotingNew key={`voting_${item.id}+${item.createdAt}`}  { ...{voting: item, VotingActions} } />
    })
  }

  render() {
    const { props: { stream: { id }, votings=[], votingTemplates, VotingTemplateActions: { create } }, renderList } = this
    const filteredVotings = votings.filter(c => c.stream == id);
    const votingsByStatus = groupBy(filteredVotings, 'status')
    const toRender = { ...votingsByStatus, ADD_NEW: votingTemplates }
    const columnsOrder = ['IN_PROGRESS', 'WAITING', 'FINISHED', 'ADD_NEW']
    const { t } = this.props
    const cols = [
      {
        value: 'IN_PROGRESS',
        label: t('admin_in_progress')
      },
      {
        value: 'WAITING',
        label: t('admin_waiting')
      },
      {
        value: 'FINISHED',
        label: t('admin_finished')
      },
      {
        value: 'ADD_NEW',
        label: t('admin_add_new')
      }
    ]
    return (
      <div className="row-container">
        {
          cols
          .map((column, i) => {
            return  <div className="activity-admin__activity-block">
              <h2 className="text head head_center activity-admin__header">{column.label}</h2>
              <div key={i} className='col-container col-container_center'>
              {
                renderList(toRender[column.value])
              }
               {
                column.value == 'ADD_NEW' ? <div onClick={() => create(defaultVoting(id))} class="plus__icon" /> : null
              }
              </div>
            </div>
          })
        }
      </div>
    )
  }
}

export default trans(VotingsBlock)