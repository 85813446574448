import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/Logo';
import Navigation from './Navigation';
import Socials from './Socials';

const Footer = props => {
    return (
        <footer className='footer-desktop flex justify-between p4 items-center'>
            <div className="footer-desktop__left flex items-center">
            <Logo className='mr4' size='large' />
            <Navigation />
            </div>
            <div className="footer-desktop__right">
                <Socials /> 
            </div>
        </footer>
    )
}

Footer.propTypes = {

}

export default Footer
