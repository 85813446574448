import { modalsActionTypes } from './actionTypes';

/**
|--------------------------------------------------
| DEPRECATED
|--------------------------------------------------
*/
export const toggleAuthModal = (data) => ({
  type: 'TOGGLE_AUTH_MODAL', 
  data
})

export const toggleAuthModalNew = (data) => ({
  type: 'TOGGLE_AUTH_MODAL_NEW',
  data
})

export const switchAuthTabs = (tab) => ({
  type: 'SWITCH_AUTH_TABS',
  tab
})

export const toggleQuickDonationModal = (data) => ({
  type: 'TOGGLE_QUICK_DONATION_MODAL', 
  data
})

/**
|--------------------------------------------------
| NEW MODALS ACTIONS
|--------------------------------------------------
*/

export const showModal = ({ modalProps, modalType }) => dispatch => {
  dispatch({
    type: modalsActionTypes.SHOW_MODAL,
    modalProps,
    modalType
  });
}

export const hideModal = () => dispatch => {
  dispatch({
    type: modalsActionTypes.HIDE_MODAL
  });
}