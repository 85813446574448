import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import FormValidator from "components/Common/FormValidator"
import { required, email, } from "utils/validator"
import { notify } from "reapop"

import * as UserActions from "actions/UserActions"
import * as PredictionsActions from "actions/PredictionsActions"

const CHALLENGE_DESC_MAX_LENGTH = 200;

class ChallengeCreate extends FormValidator {
  constructor(props) {
    super(props)
    this.state = {
      isOpenMessage: false,
      cubesAmount: this.props.stream.minimumDonation,
      message: '',
      username: 'Anonymous' 
    }

    this.notificClick = this.notificClick.bind(this)
    this.handleOrbsAmountInputChange =  this.handleCubesAmountInputChange.bind(this)
    this.handleDescInputChange = this.handleMessageInputChange.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
  }
  handleUsernameChange = (e) => {
    const value = e.target.value
    if(value.length > 30) return 
    this.setState({
      username: value,
    })
  }
  handleMessageInputChange = (e) =>{
    const value = e.target.value
    if(value.length > CHALLENGE_DESC_MAX_LENGTH) return 
    this.setState({
      message: value,
    })
  }
  handleCubesAmountInputChange = (e) => {
    const value = e.target.value
    const valueInt = (parseInt(value))

    if(!value.length) 
    this.setState({
      cubesAmount: ''
    })

    if(value.length > 5) return 
    
    if(isNaN(valueInt)) return 
    this.setState({
        cubesAmount: valueInt 
    })
  }
  handleChat() {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
    }))
  }

  handleMsg(e, props) {
    e.preventDefault()
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const { username, message, cubesAmount } = this.state 
    const minimumDonation = this.props.stream.minimumDonation
    if (cubesAmount < minimumDonation) {
      this.notificClick("Error", `Minimum donation is ${minimumDonation} cubes (set by streamer)!`, "error")
      return
    }

    
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", user.token)
    e.preventDefault()
    fetch(`/api/messages`, {
      method: "POST",
      body: JSON.stringify({
        username,
        message,
        donationValue: cubesAmount,
        streamId: this.props.streamID
      }),
      headers
    }).then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code != undefined) {
        //error
        this.notificClick("Error", error, "error")
      } else {
        // process
        this.notificClick("Succsess", "Your message have been sent")
        this.props.UserActions.updateUserBalance();
      }
    })
  }

  render() {
    return (
      <div className="feedbackModal create-challenge">
        <div className="feedbackModalTitle">
          <p className="feedbackModal-title">Message</p>
          <div className='create-challenge__close-icon' onClick={this.props.handleCloseMessage} />
        </div>
        <form action="" className="feedbackModal-form">
        <div className="feedbackModalDesc" style={{ paddingTop: "10px" }}>
            <p className="feedbackModal-desc">Your nickname:</p>
          </div>
        <input
            ref="challengeUsername"
            style={{ height: "auto" }}
            onChange={this.handleUsernameChange}
            value={this.state.username}
            className="feedbackModal-text create-challenge__username"
          />
          <div className="feedbackModalDesc" style={{ paddingTop: "10px" }}>
            <p className="feedbackModal-desc">Message</p>
          </div>
          <textarea
            ref="challengeTitle"
            name="challengeTitle"
            className="feedbackModal-text create-challenge__description"
            placeholder="Hello world"
            onChange={this.handleMessageInputChange}
            value={this.state.message}
          />
          <div className='create-challenge__symbols-left'>{`${CHALLENGE_DESC_MAX_LENGTH - this.state.message.length} symbols left`}</div>
          <div className="feedbackModalDesc">
            <p className="feedbackModal-desc">Cubes</p>
          </div>
          <input
            ref="challengeValue"
            style={{ height: "auto" }}
            onChange={this.handleCubesAmountInputChange}
            value={this.state.cubesAmount}
            className="feedbackModal-text create-challenge__cubes-amount"
            placeholder="100"
          />
            <div className="feedbackModalDesc" style={{ paddingTop: "10px" }}>
            <p className="feedbackModal-desc create-challenge__disclamer">Check this out before posting <Link className='create-challenge__link' to='/info/terms'>Posting rules</Link></p>
          </div>
          <input
            onClick={e => this.handleMsg(e, this.props)}
            className="feedbackModal-submit"
            type="submit"
            value="Send"
          />
          <input type='hidden' name='stream' value={this.props.streamID} />
        </form>
        { this.state.isOpenMessage ?
          <div className="feedbackModal feedbackModal_result">
            <div className="feedbackModalTitle">
              <p className="feedbackModal-title">Message</p>
            </div>
            <div className="feedbackModal-img">
              <img src="/assets/images/feedback.svg" alt="feedback" />
              <p className="feedbackModal-result">Thank You for Your Message!<br />It will be avaiable after moderation.</p>
              <button
                onClick={this.props.handleCloseMessage}
                className="btn-blue"
                style={{ margin: "0 auto" }}
              >Close
              </button>
            </div>
          </div> : null}
      </div>
    )
  }

  notificClick(title, message, status) {
    const { notify } = this.props
    notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000
    })
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeCreate)
