import React, { Component } from "react"
import { notify } from "reapop"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"


import * as PredictionAPI from "api/PredictionAPI"

import * as botActions from "actions/BotsActions"

import Notification from "components/Notification"
import RenderWithPermission from 'components/Common/RenderWithPermission'

import Role from 'components/Common/Role'

class PredictoriaBot extends Component {
  constructor(props) {
    super(props)

  
    this.state = {
      users: [],
      tokens: [],
      fromBots: 0,
      botsCount: 10,
      delayBets: 15,
      delayDeltaBets: 30,
      valueBets: 20,
      valueDeltaBets: 41,
      logs: [],
      isLoaded: false,
      betsTimeouts: [],
      betsIntervals: [],
      tokensIntervals: []
    }


    this.onChange = this.onChange.bind(this)
    this.onChangeAuthFrom = this.onChangeAuthFrom.bind(this)
    this.onChangeAuthCount = this.onChangeAuthCount.bind(this)

    this.handleStopBot = this.handleStopBot.bind(this)
    this.handleStartBot = this.handleStartBot.bind(this)

    this.loginBot = this.loginBot.bind(this)
    this.loginBots = this.loginBots.bind(this)
    this.createBot = this.createBot.bind(this)
    this.createBots = this.createBots.bind(this)
  }

  render() {

    const { bots } = this.props

    const { botsCount, fromBots, valueBets, delayBets, delayDeltaBets, valueDeltaBets } = this.state
    const users = this.state.users.length
    const tokens = this.state.tokens.length

    return (
    <Role roles={['ADMIN']}>
      <div className="tabContent activeTab createPredictionBlock">
        <div className="modalViewBlock" style={{ display: "block", width: "initial", marginBottom: "25px", padding: "20px", overflow: "hidden" }}>
          <form className="profilePageForm" noValidate onSubmit={e => e.preventDefault()} style={{ width: "460px", float: "left" }}>
            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="bots_count">
                Bots count
                <input
                  type="text"
                  className="profileInput"
                  name="bots_count"
                  id="bots_count"
                  defaultValue={botsCount}
                  onChange={this.onChangeAuthCount.bind(this)}
                />
              </label>
              <button onClick={this.createBots.bind(this, fromBots, botsCount)} className="profileSaveBtn pre-btn pre-btn_blue disabled bot__button" disabled="true">Create Bots</button>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="bots_created">
                Bots created
                <input
                  type="text"
                  className="profileInput"
                  name="bots_created"
                  id="bots_created"
                  defaultValue={botsCount}
                  onChange={this.onChangeAuthCount.bind(this)}
                />
              </label>
              <input
                type="text"
                className="profileInput"
                name="bots_fromBots"
                id="bots_fromBots"
                defaultValue={fromBots}
                onChange={this.onChangeAuthFrom.bind(this)}
                style={{ width: "100px", marginRight: "15px" }}
              />
              <button onClick={this.loginBots.bind(this, fromBots, botsCount)} className="profileSaveBtn pre-btn pre-btn_blue bot__button">Auth Bots</button>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="delay_between_bets">
                Minimum delay between two predictions (sec)
                <input
                  type="text"
                  className="profileInput"
                  name="delay_between_bets"
                  id="delay_between_bets"
                  defaultValue={this.state.delayBets}
                  onChange={this.onChangeBetsSetup.bind(this)}
                />
              </label>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="delay_delta_between_bets">
                Delta delay between two predictions (sec)
                <input
                  type="text"
                  className="profileInput"
                  name="delay_delta_between_bets"
                  id="delay_delta_between_bets"
                  defaultValue={this.state.delayDeltaBets}
                  onChange={this.onChangeBetsSetup.bind(this)}
                />
              </label>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="delay_delta_between_bets">
                Total will be: {delayBets} + delta(0 - {delayDeltaBets}) sec
              </label>
            </div>

            _____________________________________________________________________________

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="value_bets">
                Minimum prediction value (cubes)
                <input
                  type="text"
                  className="profileInput"
                  name="value_bets"
                  id="value_bets"
                  defaultValue={this.state.valueBets}
                  onChange={this.onChangeBetsSetup.bind(this)}
                />
              </label>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="delta_value_bets">
                Delta value predictions (cubes)
                <input
                  type="text"
                  className="profileInput"
                  name="delta_value_bets"
                  id="delta_value_bets"
                  defaultValue={this.state.valueDeltaBets}
                  onChange={this.onChangeBetsSetup.bind(this)}
                />
              </label>
            </div>

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="delay_delta_between_bets">
                Total will be: {valueBets} + delta(0 - {valueDeltaBets}) cubes
              </label>
            </div>
            _____________________________________________________________________________

            <div className="inputBlock">
              <label className="profilePageInput-label" htmlFor="bots_authorized">
                Bots authorized
                <input type="text" className="profileInput" name="bots_authorized" id="bots_authorized" value={this.state.tokens.length} onChange={this.onChange} />
              </label>
              <button onClick={this.handleStartBot} className="profileSaveBtn pre-btn pre-btn_blue bot__button">Start main logic</button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button onClick={this.handleStopBot} className="profileSaveBtn pre-btn pre-btn_blue bot__button">Stop main logic</button>
            </div>
          </form>

          <div id="logsBlock" style={{ float: "left", width: "320px", padding: "0 0 0 30px", height: "1055px", overflow: "auto" }}>
            {this.state.logs.map((item, index) => {
              return <p key={index}><b>{item.code}</b> {item.message}</p>
            })}
          </div>

          {/* <div className="inputBlock">
            <label className="profilePageInput-label" htmlFor="img">
              Save stream
              <button onClick={() => handleCreate(this.state.stream)} className="pre-btn pre-btn_blue pre-btn_modal">
                SAVE
              </button>
            </label>
          </div> */}
        </div>
      </div>
    </Role>
    )
  }

  sendNotification(title, message, status) {
    const { notify } = this.props

    notify({
      title,
      message,
      status,
      dismissible: true,
      dismissAfter: 1000
    })
  }

  onChange(e) {
    e.preventDefault()
  }

  onChangeAuthFrom(e) {
    this.setState({ fromBots: e.target.value })
  }
  onChangeAuthCount(e) {
    this.setState({ botsCount: e.target.value })
  }


  onChangeBetsSetup(e) {
    let obj = {}
    switch (e.target.id) {
      case "delay_between_bets":
        obj = {
          delayBets: parseInt(e.target.value.trim())
        }
        break
      case "delay_delta_between_bets":
        obj = {
          delayDeltaBets: parseInt(e.target.value.trim())
        }
        break
      case "value_bets":
        obj = {
          valueBets: parseInt(e.target.value.trim())
        }
        break
      case "delta_value_bets":
        obj = {
          valueDeltaBets: parseInt(e.target.value.trim())
        }
        break
      default: break
    }
    this.setState(obj)
  }

  addLog(data) {
    const logs = this.state.logs
    logs.push(data)
    this.setState(prev => ({ logs: [...logs] }))

    const elem = document.getElementById("logsBlock")
    elem.scrollTop = elem.scrollHeight
  }

  handleStopBot() {
    this.addLog({
      code: "stopBot",
      message: "stop bots"
    })

    this.state.betsTimeouts.forEach((element) => {
      window.clearTimeout(element)
    })
    this.state.betsTimeouts = []
    this.state.betsIntervals.forEach((element) => {
      window.clearInterval(element)
    })
    this.state.betsIntervals = []
    this.state.tokensIntervals.forEach((element) => {
      window.clearInterval(element)
    })
    this.state.tokensIntervals = []
  }

  handleStartBot() {

    this.addLog({
      code: "startBot",
      message: "place bots to work"
    })
    /*
    
    1) для каждого авторизированного пользователя
    2) берём текущий набор ставок
    3) делаем случайную задержку в ставках
    4) для каждой ставки делаем прогноз
    5) проверяем - можем ли мы поставить (тело ставки или ставка слишком маленькая)
    6) ставим случайную величину на случайный исход

    */


    const bridge = this
    const tokens = this.state.tokens

    //  1) для каждого авторизированного пользователя
    tokens.forEach((token) => {
      //  2) берём текущий набор ставок
      // bridge.getPredictions(token, bridge.props.stream, (predictions) => {
        const predictions = this.props.predictions
        bridge.state.predictions = predictions


        predictions.forEach((prd) => {
          // 3) делаем случайную задержку в ставках
          const delay = bridge.state.delayBets + Math.random() * bridge.state.delayDeltaBets
          bridge.addLog({
            code: "startBot",
            message: `run for each prd with delay ~${parseInt(delay)} seconds`
          })

          const interval = setTimeout(() => {
            // 4) для каждой ставки делаем прогноз

            // 5) проверяем - можем ли мы поставить (тело ставки или ставка слишком маленькая)

            // 6) ставим случайную величину на случайный исход
            const value = parseInt(bridge.state.valueBets + Math.random() * bridge.state.valueDeltaBets)
            let outcome = parseInt(Math.round(Math.random()))

            console.table({ token, id: prd.id, value, outcome })
            bridge.addLog({
              code: "startBot",
              message: `place prediction for each token with sum: ${value} for: ${outcome}`
            })


            // 7) challange logic
            if (prd.mode == "CHALLENGE_MODE") {
              outcome = 0
            }

            bridge.placeBet(token, prd.id, value, outcome)
          }, delay * 1000)
          bridge.state.betsTimeouts.push(interval)
        })
      })
    // })
  }


  createBots(from, number, button) {

    this.addLog({
      code: "createBot",
      message: `from: ${from}, number: ${number}`
    })

    for (let i = from; i < number; i++) {
      const body = {
        name: "Predictoria Bot",
        email: `bot.${i}@predictoria.com`,
        password: `prd_bot_pwr_2402${i}`,
        promo: "BOTMODERATOR100000"
      }

      this.createBot(body)
    }
  }

  loginBots(from, number, button) {

    this.addLog({
      code: "loginBots",
      message: `from: ${from}, number: ${number}`
    })

    for (let i = from; i < number; i++) {
      const body = {
        email: `bot.${i}@predictoria.com`,
        password: `prd_bot_pwr_2402${i}`
      }
      this.loginBot(body)
    }
  }

  createBot(body) {
    const bridge = this

    this.addLog({
      code: "createBot",
      message: "try to create new bot"
    })

    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    fetch("/api/user", {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        bridge.state.logs.push({ code: json.code, message: json.message })

        if (json.code == 1002 || json.code == 1001) {
          // this.sendNotification('create bot error', json.message, 'error')

          this.addLog({
            code: json.code,
            message: json.message
          })
          return
        }
        if (json.code == "UNKNOWN_ERROR" && json.message == "Validation error: Validation isEmail on email failed") {
          // this.sendNotification('create bot error', json.message, 'error')

          this.addLog({
            code: json.code,
            message: json.message
          })
          return
        }
        if (json.code == "ALREADY_EXISTS") {
          // this.sendNotification('create bot error', json.message, 'error')

          this.addLog({
            code: json.code,
            message: json.message
          })
          return
        }


        // валидация уровня бог
        if (json.id != null) {
          const user = Object()

          user.id = json.id
          user.email = json.email
          user.password = json.password
          user.promo = json.promo
          user.yourPromo = json.yourPromo
          user.name = json.name
          user.balance = json.balance
          user.permissions = json.permissions
          user.facebookId = json.facebookId

          const users = bridge.state.users || []
          users.push(user)

          bridge.props.botActions.setBots(users)

          this.addLog({
            code: "createBot",
            message: "success"
          })
        } else {
          this.addLog({
            code: json.code,
            message: json.message
          })
        }
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("handleFormSubmit error:", error)
        this.sendNotification("create bot error", error, "error")
      })
  }

  loginBot(body) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    const bridge = this

    this.addLog({
      code: "loginBot",
      message: "try to login bot"
    })

    fetch("/api/auth/signin", {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {

        if (json.token) {
          const { tokens } = bridge.state
          tokens.push(json.token)
          bridge.props.botActions.setTokens(tokens)
        }

        this.addLog({
          code: json.code || "loggined with:",
          message: json.message || json.token
        })
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("loginUserAPI error:", error)
        this.sendNotification("create bot error", jerror, "error")
      })
  }

  getPredictions(token, stream, callback) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)

    fetch("/api/prediction/", {
      method: "GET"
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json.code == "UNKNOWN_ERROR") {
          console.log("PredictoriaBot error", json)
          return
        }

        // фильтруем только активные
        const predictions = json.filter((item) => {
          return item.finishedAt == null && item.closedAt == null && item.yourBet == null && item.stream.toLowerCase() == stream.toLowerCase()
        })

        callback(predictions)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
  }

  placeBet(token, id, value, outcome, callback) {
    this.addLog({
      code: "willPlaceBet",
      message: `place prediction ${id} with: ${value}`
    })

    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)

    const body = { value, outcome }

    fetch(`/api/prediction/${id}/bet`, {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json.code == "BET_TOO_SMALL") {
          this.addLog({
            code: json.code,
            message: `try again with: ${value + 20}`
          })
          this.placeBet(token, id, value + 20, outcome, callback)
          return
        } else if (json.code == "FEW_BONUSES") {
          this.addLog({
            code: json.code,
            message: "BOT don't have cubes anymore"
          })
        } else if (json.code) {
          this.addLog({
            code: json.code,
            message: json.message
          })
          return
        }

        this.addLog({
          code: "placeBet",
          message: "success"
        })
      })
      .catch((error) => {
        // AHHHH! An Error!
        // callback(error)
      })
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
    bots: state.bots,
    streamlist: state.streamList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    notify: bindActionCreators(notify, dispatch),
    botActions: bindActionCreators(botActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PredictoriaBot)
