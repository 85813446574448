import collectionReducers from "utils/collectionReducers"

const initialState = {
  games: [
      {
          id: 1,
          title: "Game"
      }
  ],
  signature: ""
}

const NAMESPACE = "BETTY_GAMES"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function getGameItem() {
  return {
    namespace: NAMESPACE,
    type: "GET_BETTY_GAMES_ITEM",
    handle: (state) => { 
      return { ...state, games: state.games 
      }
    }
  }
}

export function setNewList(streamlist) {
  return {
    namespace: NAMESPACE,
    type: "SET_BETTY_GAMES_LIST",
    handle: (state) => {
      return { ...state, games: streamlist }
    }
  }
}
