import "whatwg-fetch"
import * as SocketAPI from "api/SocketAPI"
import { store } from "libs/redux"

const get = async (callback) => {

  const headers = new Headers()
  headers.append("Content-Type", "application/x-www-form-urlencoded") // This one sends body

  fetch("/api/betty/games", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const events = async (callback) => {

  const headers = new Headers()
  headers.append("Content-Type", "application/x-www-form-urlencoded") // This one sends body

  fetch("/api/betty/events", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const bets = async (callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch("/api/betty/bets", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const updates = async (callback) => {
  const token = store.getState().user.model.token || ""

  SocketAPI.betty(({ error, response, type, socket }) => {
    if (error) {
      console.error("SocketAPI betty", type, error)
      return
    }

    if (callback && type == "notify") {
      callback(response)
    }
  })
}


const placeBet = async (id, bet, callback, _token) => {
  console.log("Betty | PredictionAPI placeBet", id, bet)

  const token = _token || store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  if (bet.value < 1) {
    callback("Bet value can't be less then 1")
  }

  fetch(`/api/betty/bets`, {
    method: "POST",
    headers,
    body: JSON.stringify(bet)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "BET_TOO_SMALL" || json.code == "FEW_BONUSES" || json.code == "DOUBLE_BETS_DENIED" || json.code == "PREDICTION_ENDED"
          || json.code != undefined) {
        callback(json.message)
      } else if (json.id != null) {
        // success
        const prd = update(json)
        // update prd at props
        callback(null, prd)
      } else {
        callback(json.message)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const getFifaTeams = async (callback) => {
  console.log("BettyAPI get FIFA teams")

  const headers = new Headers()
  headers.append("Content-Type", "application/x-www-form-urlencoded") // This one sends body

  fetch("/api/betty/fifa-teams", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const getDotaHeroes = async (callback) => {
  console.log("BettyAPI get FIFA teams")

  const headers = new Headers()
  headers.append("Content-Type", "application/x-www-form-urlencoded") // This one sends body

  fetch("/api/betty/dota-heroes", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

export { get, events, updates, placeBet, bets, getFifaTeams, getDotaHeroes }
