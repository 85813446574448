const NAMESPACE = "COMMON"

const initialState = {
  showCreateChallenge: false,
}

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const showCreateChallenge = (show, data) => {
  return {
    namespace: NAMESPACE,
    type: "SHOW_CREATE_CHALLENGE",
    handle: (state) => {
      show = show || !state.showCreateChallenge
      return { ...state, showCreateChallenge: show }
    }
  }
}
