import { store } from "libs/redux"

const get = async (game, stream, callback) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/${stream}/challenge/template/`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}

const create = async (templateBody, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/challenge/template/`, {
    method: "POST",
    headers,
    body: JSON.stringify(templateBody)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED") {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}


const remove = async (id, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []
  
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/challenge/template/${id}`, {
    method: "DELETE",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}

const patch = async (id, template, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []
  
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/challenge/template/${id}`, {
    method: "PATCH",
    body: JSON.stringify(template),
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}

export { create, get, remove, patch }
