const initialState = {
  bots: [],
  tokens: []
}

const NAMESPACE = "BOTS"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const setTokens = (tokens) => {
  return {
    namespace: NAMESPACE,
    type: "SET_TOKENS",
    handle: (state) => {
      return { ...state, tokens }
    }
  }
}

export const setBots = (bots) => {
  return {
    namespace: NAMESPACE,
    type: "SET_BOTS",
    handle: (state) => {
      return { ...state, bots }
    }
  }
}
