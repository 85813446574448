import { ActivitiesBoxWidget } from 'components/ProfilePage/OverlayCreator/Mocks/overlay1';


export const widgetsList =  [
    {
        id: 'dsu723', 
        name: 'Viewer Engagement',
        description: 'Fun ways to allow your audience to engage with you.',
        widgets: [
            {
                title: 'Predictoria Activities Widget', 
                author: 'Predictor',
                widget_content: ActivitiesBoxWidget,
                tags: ['Overlay', 'Predictoria'],
                description: 'Real-time activities for your audience', 
                image: '/assets/images/widgets/1.png'
            },
            {
                title: 'Predictoria Alerts Box',
                author: 'Aziz',
                widget_content: () => {},
                tags: ['Overlay', 'Predictoria'],
                description: 'Coming soon', 
                image: '/assets/images/widgets/1.png'
            },
            {
                title: 'Predictoria Chat Box', 
                author: 'Predictor',
                widget_content: () => {},
                tags: ['Overlay', 'Predictoria'],
                description: 'Coming soon', 
                image: '/assets/images/widgets/1.png'
            },
        ]
    }
]

export const myWidgets = [
    {
        title: 'Predictoria Alerts Box', 
        author: 'Aziz',
        tags: ['Fun', 'Creative'],
        description: 'Show activities alerts to your audience to engage donates bla bla', 
    },
    {
        title: 'Predictoria Alerts Box', 
        author: 'Aziz',
        tags: ['Fun', 'Creative'],
        description: 'Show activities alerts to your audience to engage donates bla bla', 
    },
    {
        title: 'Predictoria Alerts Box', 
        author: 'Aziz',
        tags: ['Fun', 'Creative'],
        description: 'Show activities alerts to your audience to engage donates bla bla', 
    }
]