import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"
import * as userActions from "actions/UserActions"

class ConfirmRegistration extends Component {
  render() {
    return (
      <div className="modalView">
        <div className="profileContainer modalViewContainer">
          <div className="modalViewBlock">
            <h3 className="modalViewBlock-title">Confirm your email address</h3>
            <p className="modalViewBlock-desc">We sent a verification email to <span className="modalViewBlock-email">{this.props.user.model.email}. </span>
                  This is the last step that separates you from the game!
            </p>
            <a target="_blank" href={`http://${this.props.user.model.email.split("@")[1]}`} className="pre-btn pre-btn_blue pre-btn_modal">Check Your Mailbox</a>
            <a className="modalViewBlock-link" href="">Email didn't arrive or want to use a different email?</a>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRegistration)
