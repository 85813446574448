import React, { Component } from "react"
import NotificationBlockContainer from "../../containers/NotificationBlockContainer/"
import * as SocketAPI from "../../api/SocketAPI"


export default class Notification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.handleBlock = this.handleBlock.bind(this)
    this.handleOutSideBlock = this.handleOutSideBlock.bind(this)
  }

  handleBlock() {
    if (!this.state.isOpen) {
      document.addEventListener("click", this.handleOutSideBlock, false)
    } else {
      document.removeEventListener("click", this.handleOutSideBlock, false)
    }

    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  handleOutSideBlock(e) {
    if (this.node.contains(e.target)) {
      return
    }

    this.handleBlock()
  }

  render() {
    alert('sdfsdf')
    return (
      <div ref={(node) => { this.node = node }} onClick={this.handleBlock} className="notification" id="notification">
        <a id="notificationBlock-link" role="display-notification-menu">
          <div className="ringer">
            <div className="icon svgIcon-ringer svgIcon-ringer-dims" />
          </div>
        </a>
        {this.state.isOpen ? <NotificationBlockContainer {...this.props} /> : ""}
      </div>
    )
  }
}
