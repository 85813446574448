import React from "react"

export default (props) => {
  const { notification } = props

  let title, 
    message, 
    status, 
    type = ""

  if (notification.type == "USER_WIN") {
    title = "You WIN!"
    message = `Your prediction \`${notification.payload.prediction.game}\` is over`
    status = "success"
    type = "green"
  }

  if (notification.type == "USER_INCOME") {
    title = "Cubes income"
    message = `You gathered ${parseInt(notification.payload.amount)} cubes`
    status = "info"
    type = "green"
  }

  if (notification.type == "USER_LOOSE") {
    title = "Prediction is over"
    message = "Sorry, another prediction came true. Try again!"
    status = "info"
    type = "yellow"
  }

  if (notification.type == "PREDICTION_CANCEL") {
    title = "Prediction canceled"
    message = `Your prediction \`${notification.payload.prediction.game}\` was canceled`
    status = "info"
    type = "yellow"
  }

  const notificationClassName = `img icon svgIcon-${type} svgIcon-${type}-dims`
  
  const prediction = notification.payload != null ? notification.payload.prediction : null
  
  let finishedAt = notification.createdAt || Date.now()
  if (prediction != null) {
    finishedAt = notification.payload.prediction.finishedAt || notification.createdAt || Date.now()
  }
  const seconds = new Date() - new Date(finishedAt)
  const timeAgo = secondsToString(seconds)

  return (
    
    <li className="profileMenuList-item">
      <div className={notificationClassName} />
      <div className="notificationAllDesc">
        <h3 className="notification-header">{title}</h3>
        <p className="notification-condition">{message}</p>
        <span className="notification-time">{timeAgo} ago</span>
      </div>
    </li>
  )
}

function secondsToString(miliseconds) {
  if (miliseconds < 1000 * 60) {
    return "now"
  }
  const date = new Date(miliseconds)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  let rr = `${mm} min`
  if (hh > 0) {
    rr = `${hh} hours ${rr}`
  }
  return rr
}
