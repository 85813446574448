export default (state={
    widgetsActiveTab: 'RECOMMENDED', 
    showSuccessModal: false, 
}, action) => {
    switch(action.type){
        case 'CHANGE_WIDGETS_TAB': 
        return { ...state, widgetsActiveTab: action.tab };
        case 'INSTALL_WIDGET': 
        // request
        return { ...state, showSuccessModal: true };
        case 'CLOSE_SUCCESS_MODAL': 
        return { ...state, showSuccessModal: false };
        default: return state;
    }
}