import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CategoryItem = props => {
    const { name } = props;
    return (
        <Link to={`/help/view/${name}`} className="help-center__category">
            <div className={`help-center__category-icon help-center__category-icon_${name.toLowerCase()}`} />
            <div className="help-center__category-name">
                {name}
            </div>
        </Link>
    )
}

CategoryItem.propTypes = {

}

export default CategoryItem


