import React, { Component } from "react"

export default class AdminStreamPageStats extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="tabContent activeTab createPredictionBlock">
        <main className="twitchSlider">
          <div className="container">
            <div className="liveStream">
              <p className="predictionParagraph">Stats</p>
            </div>
          </div>
        </main>
      </div>
    )
  }
}
