import React, { Fragment } from "react"

import { trans } from "utils/translator"
import { val_gte, val_lte, gte } from "utils/validator"

import FormValidator from "components/Common/FormValidator"
import ExpandableInput from 'components/Common/ExpandableInput'

export default trans(class VotingTemplate extends FormValidator {
  constructor(props){
    super(props)
    const { voting: { id, duration, description, currency = 'CUBES', options, stream }, create } = props
    const mappedOptions = options.map(option => {
      return {
        value: option,
        rules: [gte(3)],
        isValid: true,
      }
    })
    this.state = {
          form: { 
            id: { value: id },
            description: { value: description },
            currency: { value: currency },
            durationHours: { value: Math.floor(duration/(60*60)), rules: [val_lte(72), val_gte(0)], isValid: true, checkImmediately: true },
            durationMinutes: { value: duration%(60*60)/(60), rules: [val_gte(0), val_lte(60)], isValid: true, checkImmediately: true },
            stream: { value: stream },
            ...mappedOptions
          },
          options,
          isCreateMode: create
    }
  }
  componentDidMount(){
  }
  edit = (e) => {
    const { props: { VotingTemplateActions: { patch }, voting: { id }, voting }, makeTimeSec } = this
    const { durationHours, durationMinutes, description, id: votingId, currency = 'ORBS', stream, ...options } = this.getFormData()
    const votingPrms = { description, currency, stream, duration: makeTimeSec(durationHours, durationMinutes), options: [ ...Object.values(options)]}
    const votingOldParms = { description: voting.description, currency: voting.currency, stream: voting.stream, duration: voting.duration, options: voting.options}
    e.preventDefault()
    if(JSON.stringify(votingPrms)!=JSON.stringify(votingOldParms)) patch(id, votingPrms, (res) => {
    })
    else return 
  }
  handleUse = (e) => {
    const { props: { VotingActions: { createAdmin: use } }, makeTimeSec } = this
    const { durationHours, durationMinutes, id, description, currency = 'ORBS', stream, ...options } = this.getFormData()
    // wtf currency fallback doesnt work
    const { t } = this.props
    e.preventDefault()
    if(!this.isValid() || makeTimeSec(durationHours, durationMinutes) < 60 || Object.values(options).some(option => option.length < 3) ){
      alert(t('admin_some_parameters_wrong'))
      return
    }
    use({ description, currency, stream, duration: makeTimeSec(durationHours, durationMinutes), options: [ ...Object.values(options)]   })
  }
  handleRemove = () => {
    const { props: { VotingTemplateActions: { remove } , voting: { id } } } = this
    remove(id)
  }
  makeTimeSec = (hours, minutes) => (hours * 60 * 60) + minutes * 60

  handleCreate = (e) => {
    const { props: { VotingTemplateActions: { create } }, makeTimeSec } = this
    const { durationHours, durationMinutes, id, description, currency = 'ORBS', stream, ...options } = this.getFormData()
    const { t } = this.props
    e.preventDefault()
    if(!this.isValid() || makeTimeSec(durationHours, durationMinutes) < 60 ){
      alert(t('admin_some_parameters_wrong'))
      return
    }
    create({ id, description, currency, stream, duration: makeTimeSec(durationHours, durationMinutes), options: [ ...Object.values(options)] },
    (voting) => console.log(voting))
  }
  addVoting = () => {
    this.setState(prevState => ({
      options: [...prevState.options, 'new option']
      }))
  }
  selectCurrency = currency => e => {
    this.setFieldData('currency', currency)
  }
  render(){
  const { props: { t, create }, state: { options, isCreateMode }, selectCurrency, handleRemove, edit } = this
  const { currency, description } = this.getFormData()
  return (
    <div 
    className="adm-new-control-card adm-new-voting adm-new-voting-state3"
    onMouseLeave={edit}
    >
    <div className="adm-new-control-card__header">
      <div className="adm-new-control-card__option adm-new-voting__option-group">
        <div className="adm-new-control-card__input-group adm-new-voting__input-group">
          <i className={`adm-new-voting__input-cube ${currency !== 'CUBES' ? 'greyscale' : ''}`} onClick={selectCurrency('CUBES')} />
          <i className={`adm-new-voting__input-orb  ${currency !== 'ORBS' ? 'greyscale' : ''}`} onClick={selectCurrency('ORBS')} />
          <div className="adm-new-voting__input-time-container">
            <input 
            max={72}
            min={0}
            className="adm-new-control-card__input time-input time-input_h"
            {...this.bindInput('durationHours', 'number')}
            />
            :
            <input 
            max={60}
            min={0}
            className="adm-new-control-card__input time-input time-input_m"
            {...this.bindInput('durationMinutes', 'number')}
            />
          </div>
        </div>
      </div>
      { isCreateMode ? '' : <div className="adm-new-control-card__close purecss-close" onClick={handleRemove}/> }
      </div>
    <div className="adm-new-control-card__content">
      <ExpandableInput 
      className="adm-new-control-card__description" 
      rows="3"
      {...this.bindInput('description', 'text')} />
      {
        options.map((option, i) => {
          return <input
          key={i} 
          className="adm-new-control-card__input"
          { ...this.bindInput(`${i}`, 'text', [gte(3)], "new option")}/>
        }) 
      }
    </div>
    <div className="adm-new-control-card__actions">
      <button 
      className="adm-new-control-card__btn adm-new-control-card__btn--accent"
      onClick={this.addVoting}
      >{t('admin_add_option')}</button>
      {
        create ?
      <button
      className="adm-new-control-card__btn adm-new-control-card__btn--accent"
      onClick={this.handleCreate}
      >{t('admin_create')}</button>
      :
      <button
      className="adm-new-control-card__btn adm-new-control-card__btn--accent"
      onClick={this.handleUse}
      >{t('admin_use')}</button>
      }
    </div>
  </div>
  )
      }
}
)