import React, { Component } from "react"

import PredictionsList from "./Betty-PredictionsList"
import TeamVersusBlock from "./Betty-TeamVersusBlock"
import TimerComponent from "./Prediction/PredictionTimerComponent"


export default class extends Component {

  gameTitle(id) {
    return ["Dota 2", "CS:GO"][id-1] || "Event"
  }
  
  showResults = () => {
    BettyActions.showResults(this.props.stream)
  }

  render() {

    const { props, state } = this
    let { stream, StreamActions, bets } = props
    const { game_id } = stream
    if (!stream) stream = StreamActions.defaultStream()

    return (
      <div>
        <div className="prediction">
          <div className="prediction-stream">
            <div className="predictionColStream">
              <div className="predictionParagraphWrapper">
                <p className="predictionParagraph betty__title">{this.gameTitle(stream.game_id)} #{stream.id}</p>
              </div>
              <div className="predictionTwitchStream" style={{minHeight: "285px"}}>
                
                {/* if event is not running: */}
                { !stream.translation_link ?
                <div className="hover">
                  <img src="/assets/images/betty/blurred.png" alt="hover" />
                  <TimerComponent createdAt={stream.bets_end_at} />
                </div>
                : null }
                {/*  style={{ width: "100%", height: "480px" }} */}
                <div className="container">
                  {stream.translation_link ?
                    <div className="top">
                      <div className="wrapper">
                          <iframe title="Stream For Fun" height="100%" width="100%" src={`${stream.translation_link.replace(/ /g, "")}${game_id == 3 ? '#t=2m5s' : ''}?title=0&byline=0&portrait=0&transparent=0&autoplay=1`} frameBorder="0" allow="autoplay;" webkitallowfullscreen="false" mozallowfullscreen="false" allowFullScreen="false" scrolling="no" />
                      </div>
                    </div>
                    
                  : null }
                </div>
              </div>


              {/* versus block */}
              <TeamVersusBlock teamRating1={stream.Rat1 || ""} teamRating2={stream.Rat2 || ""} pair={stream.pair || ""} game={stream.game_id || ""} />
              
              {/* video desctiption */}
              <div className="desc_text regular">
                {/* Follow the Kansas City Star newspaper for the latest headlines on Missouri news. Find daily local breaking news, opinion columns, videos and community. Follow the Kansas City Star newspaper. */}
              </div>

            </div>
            <div className="predictionColPred">
            <div className="betty__row">
              <p className="predictionParagraph" >Markets</p>
              <button className="betty__show-results"
              onClick={this.showResults}>  
              Show Result
              </button>
              </div>
              {/* style={{ height: "700px", maxHeight: "700px"}} */}
              <div className="predictionMainWindowWrapper" >
                
            
                <PredictionsList stream={stream} bets={bets} handleChallenge={this.handleChallenge} showResults={stream.translation_link ? (new Date(stream.bets_end_at.replace(/-/g, "/")) - new Date())/1000+stream.duration < 60*5 : false }/>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
