import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"

import Role from "components/Common/Role"
import AdminStreams from "containers/AdminContainer/AdminStreams"

import PredictoriaBot from "../PredictoriaBot"
import CreateStream from "./CreateStream"
import Stats from "./AdminStreamPage-Stats"

import Predictions from "./AdminStreamPage-Predictions"
import Challenges from "./AdminStreamPage-Challenges"
import PaidActions from "./AdminStreamPage-PaidActions"
import Votings from "./AdminStreamPage-Voting"
import { trans } from 'utils/translator'

export default trans(class AdminStreamPage extends Component {
  constructor(props) {
    super(props)
    const { props: { stream, StreamActions, predictions }, defaultPage } = this
    this.state = {
      activeTab: "Challenges",
      routes: {
        dashboard: { path: "", render: () => defaultPage() },
        edit: { path: "edit", render: () => <CreateStream {...{ stream, StreamActions }} /> },
        stats: { path: "stats", render: () => <Stats /> },
        boost: { path: "boost", render: () => <PredictoriaBot {...{ stream: stream.stream, predictions }} /> }
      },
      tabs: {
        Challenges: props => <Challenges {...props} />,
        Votings: props => <Votings {...props} />,
        Predictions: props => <Predictions {...props} />,
        PaidActions: props => <PaidActions {...props} />
      },
      isPreviewOpened: false
    }
  }

  componentDidMount() {
    const { props: { stream: { id }, StreamActions: { getById }, PredictionsActions, ChallengeActions, VotingActions, PaidActionActions, PaidActionTemplateActions } } = this
    window.scrollTo(0, 0);
    getById(id, (err, stream) => {
      if (!stream.stream.length || !stream.id || !stream.stream) return
      PredictionsActions.updated((list) => {
      }, null, stream.stream)
      ChallengeActions.updated((list) => {
      }, null, stream.id)
      VotingActions.updated((list) => {
      }, null, stream.id)
      PaidActionActions.updated((list) => {
      }, null, stream.id)
      PaidActionTemplateActions.updated((list) => {
      }, null, stream.id)
    })
  }
  selectTab = tab => () => {
    this.setState({ activeTab: tab })
  }
  createRoutes = () => {
    const { state: { routes }, props: { stream: { stream } } } = this
    return Object
      .keys(routes)
      .map((route, i) => {
        return <Route exact path={`/admin/stream/${stream}/${routes[route].path}`} render={routes[route].render} />
      })
  }
  togglePreview = () => {
    this.setState(prevState => ({
      isPreviewOpened: !prevState.isPreviewOpened
    }))
  }

  defaultPage = () => {
    const { state: { activeTab, isPreviewOpened }, selectTab, renderTab, togglePreview, renderPreview } = this
    const { t } = this.props 
    const tabs = [{ value: 'Challenges', label: t('admin_challenges')},
    { value: 'Predictions', label:  t('admin_predictions')},
    { value: 'PaidActions',  label: t('admin_paid_actions')},
    { value: 'Votings', label: t('admin_votings')}]

    return (
      <div className="activity-admin__container">
        <div
          className="activity-admin__toggler head head_center"
          onClick={togglePreview}
        >{t('admin_toggle_preview')}
        </div>
        {
          isPreviewOpened ? renderPreview() : null
        }
        <div className="row-container row-container_space">
          {
            tabs.map((tab, i) => {
              
              const tabClass = tab.value == activeTab ? "button button_gold" : "button button_gold-b"
              return <button className={tabClass} onClick={selectTab(tab.value)}>{tab.label}</button>
            })
          }
        </div>
        <div className={`activity-admin__tab-content ${activeTab.toLowerCase()}-tab`}>
          {
            renderTab(activeTab)
          }
        </div>
      </div>
    )
  }
  
  renderSidebarLinks = (links) => {
    const { props: { stream: { stream } } } = this
    return Object
      .keys(links)
      .map((link, i) => {
        return (
          <li key={i} className="admin__stream-list-item">
            <NavLink to={`/admin/stream/${stream}/${links[link].path}`} activeClassName="activeItem">{link}</NavLink>
          </li>)
      })
  }

  renderTab = (tab) => {
    const { state: { tabs }, props } = this
    return tabs[tab](props)
  }

  renderPreview = () => {
    const { props: { StreamActions: { streamUrl, streamChat, streamID }, stream: { stream, type } } } = this
    const videoID = streamID(type, stream)
    const streamURL = streamUrl(type, videoID)
    const chatURL = streamChat(type, videoID)
    return (
      <div style={{ position: "relative", paddingBottom: "28px", display: "flex" }}>
        <iframe title="Stream For Fun" style={{ borderRadius: "6px" }} src={streamURL} frameBorder="0" height="440px" width="100%" allowFullScreen="true" allow="encrypted-media" scrolling="no" />
        <div >
          <iframe
            src={chatURL}
            frameBorder="0"
            scrolling="no"
            height="440px"
            width="100%"
          />
        </div>
      </div>)
  }

  render() {
    const { renderSidebarLinks, createRoutes, props: { streamlist: { adminStreamlist }, StreamActions, stream: { id }, user: { model: { permissions, streams } } } } = this
    const userHasStream = streams.includes(id)
    const userStreams = streams
    .map(stream => adminStreamlist.find(s => s.id == stream))
    .filter(stream => typeof stream == 'object')
    const {t} = this.props;
    console.log("usstreams", userStreams)
    return (
      <Role roles={userHasStream ? ["STREAMER", "MODERATOR", "SUPER_MODERATOR", "ADMIN"] : ["SUPER_MODERATOR", "ADMIN"]}>
        <div className="about">
          <div>
            <div className="admin__content row-container">
              <div className="admin__sidebar">
                <Role roles={["ADMIN", "SUPER_MODERATOR"]}>
                <h3 className="head head">{t('admin_all_streams')}</h3>
                  <AdminStreams />
                </Role>
                
                <Role roles={["MODERATOR", "STREAMER"]}>
                <h3 className="head head">{t('admin_your_streams')}</h3>
                  <AdminStreams userStreams={userStreams} />
                </Role>
              </div>
              <div className="faqContent">
                {createRoutes()}
              </div>
            </div>
          </div>
        </div>
      </Role>
    )
  }
}
)