import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const Socials = props => {
    return (
        <div className='socials flex'>
            <Icon size='medium' name='medium-twitch-grey' />
            <Icon size='medium' className='ml2' name='medium-steam-grey' />
            <Icon size='medium' className='ml2' name='medium-fb-grey' />
            <Icon size='medium' className='ml2' name='medium-discord-grey' />
        </div>
    )
}

Socials.propTypes = {

}

export default Socials
