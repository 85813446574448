import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { trans } from 'utils/translator';

import TabSwitcher from 'components/ds/tabSwitcher';
import Panel from 'components/ds/Panel';
import { Page, RowLayout } from 'components/ds/Layouts';
import PlaceholderComponent from 'components/ds/PlaceholderComponent/index';
import { widgetsList, myWidgets } from './widgets-list.mock';
import Slider from 'react-slick';
import WidgetCard from 'components/ds/WidgetCard';
import cn from 'classnames';
import ModalWindow from 'components/ModalWindow';
import Tabs from 'components/ds/Molecules/Tabs';
import { MessageCard } from 'components/ds/Molecules/Cards';


export const WidgetsExplorerTabs = (t) => [
    { value: 'RECOMMENDED', label: t('widget_tab_recommended') },
    { value: 'MY_WIDGETS', label: t('widget_tab_my_widgets'), isDisabled: true }
]


const renderCards = (widgetCards, installWidget, setupWidget) =>  _.map(widgetCards, ({title, author, tags, description, id, widget_content, image}) => <WidgetCard 
title={title}
author={author}
tags={tags}
description={description}
image={image}
onInstall={
    widget_content() ? () => installWidget(widget_content) : null
}
/>)

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

const RecommendedWidgets = ({categories, installWidget, setupWidget}) => {

    
   return <RowLayout gutter='medium'>
    {
        _.map(categories, ({ name, description, id, widgets}) => {
            // todo make cat component
            return <div key={id} className="ds-widgets-explorer__category">
                <h5 className="ds-widgets-explorer__category-title">{name}</h5>
                <p className='ds-widgets-explorer__category-description'>{description}</p>
                <div className="ds-widgets-explorer__category-widgets">
                    <Slider {...settings}>{renderCards(widgets, installWidget, setupWidget)}</Slider>
                </div>
            </div>
        })
    }
</RowLayout>   
}

const MyWidgets = ({ myWidgets }) => {
    
    return <RowLayout>
        {
            _.map(myWidgets, widget => <PlaceholderComponent styles={{width: '248px', height: '200px'}} />)
        }


    </RowLayout>
}



class WidgetsExplorer extends Component {
    state = {
        selected: 0
    }

    handleSelected = (selected) => {
        alert(selected)
        this.setState({ selected })
    }

    downloadOverlay = (contentFunc) => {
        const { userId, userToken, streams } = this.props

        if(!_.isArray(streams) || ! streams.length > 0 ) {
            alert('Streams was not properly loaded!');
            return 
        }
        if(!userId){
            alert('User wa not properly loaded!');
        }

        const stream = _.find(streams, str => str.owner === userId)
       
        if(!stream){
            alert(`Unable to detect stream`);
            return 
        }

        this.downloadHelper(contentFunc({ streamId: stream.id, token: userToken, userid: userId}), 'Widget.html', 'html')
    }
    
    downloadHelper = (text, name, type) => {
        const file = new Blob([text], {type: type});
        const link = document.createElement('a');
        link.setAttribute("type", "hidden"); 
        link.download = name;
        link.href = URL.createObjectURL(file);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    render() {
        const { 
            categories,
            t,
            setupWidget,
            streams,
            } = this.props
        return (
            <div className="ds-widgets-explorer">
                <Page>
                    {
                        streams ?  <Panel>
                            <RecommendedWidgets 
                                categories={categories} 
                                installWidget={this.downloadOverlay}
                                setupWidget={setupWidget}
                                /> 
                        </Panel> : "Content is loading, please wait."
                    }
                </Page>
            </div>
        )
    }

    static propTypes = {
        widgetsList: PropTypes.array,
        t: PropTypes.func
    }

    static defaultProps = {
        categories: widgetsList,
        myWidgets: myWidgets,
    }
}


function mapStateToProps(state, ownProps) {
    return {
        overlay: state.overlay.data, 
        errors: state.overlay.errors, 
        pending: state.overlay.pending,
        userId: _.get(state, 'user.model.id'),
        streams: _.get(state, 'streamList.streamlist'),
        userToken: state.user.model.token,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
    
      },
      dispatch
    );
  }
  
  export default trans(connect(
    mapStateToProps,
    mapDispatchToProps
  )(WidgetsExplorer));
  

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={cn(className, 'ds-widgets-explorer__arrow')}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        // component? 
      <div
        className={cn(className, 'ds-widgets-explorer__arrow')}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }

