import React from "react"
import { trans } from "utils/translator"
import Timer from "../AdminStreamsPage/AdminPrediction/AdminPredictionTimerComponent"
import {history} from "libs/router"
import * as ChallengeAPI from "api/ChallengeAPI"

export default trans(class Challenge extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      amount: 10,
      showPlaceBet: false, 
      donators: [],
      isDonatorsContainerOpened: false,
    }
    // this.handlePredictionBetPlace = this.handlePredictionBetPlace.bind(this)
    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.handlePlaceClick = this.handlePlaceClick.bind(this)
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.handleBetInputChange = this.handleBetInputChange.bind(this)
    this.handleDonate = this.handleDonate.bind(this)
  }
  notificClick(title, message, status, buttons=[]) {
    const { notify } = this.props
    notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000,
      buttons,
    })
  }

  handleShowDonatorsClick = () => {
    const donators = this.props.challenge.participants
    const donations = this.props.challenge.ChallengeDonates
    
    if(!this.state.isDonatorsContainerOpened)
    ChallengeAPI.loadDonators((donators) => {
      const mappedDonators = donators.map(donator => {
        return {
         name: donator.name, 
         donationValue: donations.filter(donation => donation.user == donator.id)
        .reduce((donationValue, donation) => {return donationValue + donation.value}, 0)
        }
      })
      this.setState({
        donators: mappedDonators
      })
    }, donators)
    this.setState(prevState => ({
        isDonatorsContainerOpened: !prevState.isDonatorsContainerOpened
    }))
  }

  handleDonate = () => {
    const { user, toggleQuickDonationModal, challenge } = this.props;
    const { amount } = this.state;

    if(!user.isAuthenticated){
      toggleQuickDonationModal({
        donationValue: amount,
        action: 'donate_to_challenge', 
        params: `${challenge.id}`
      });
      return;
    }

    if(!confirm(`${this.state.amount} orbs will be debited from your account. Do you want to proceed?`)) return
    this.props.ChallengeActions.donate((error, challenge) => {
      
      if(challenge && challenge.id) {

      this.setState({
          showPlaceBet: false
      })
      this.notificClick('Success', 'Your donation have been accepted')
      const price = this.state.amount
      this.props.UserActions.updateUserBalance()
      gtag("event", "purchase", {
        transaction_id: `${challenge.id}`,
        affiliation: challenge.stream,
        value: price,
        price: price,
        currency: "USD",
        items: [
          {
            id: `${challenge.id}`,
            name: challenge.description,
            quantity: 1,
            price: price,
            value: price,
          }
        ]
      })
      }
      else {
        this.notificClick('Error', `${error}`, "error", [{
          name: 'Пополнить баланс',
          primary:true,
          onClick: () => {
            history.push('/buy')
          }
        }] )
        gtag("event", "challenge", { 
          event_category: 'activity_interraction',
          event_action: 'error',
          msg: error,
        })
      }
    },
    this.props.challenge.id, this.state.amount)
  }
handleDoneClick = () => {
  const {challenge, ChallengeActions } = this.props
  gtag("event", "challenge", { 
    event_category: 'activity_interraction',
    event_action: 'done_click',
    challenge,
  })
  this.props.ChallengeActions.voteDoneUser(this.props.challenge.id)
}
handleFailClick = () => {
  const {challenge, ChallengeActions} = this.props
  gtag("event", "challenge", { 
    event_category: 'activity_interraction',
    event_action: 'fail_click',
    challenge,
  })
  this.props.ChallengeActions.voteFailUser(this.props.challenge.id)
}
handleCloseClick = () => {
  const {challenge, ChallengeActions} = this.props
  gtag("event", "challenge", { 
    event_category: 'activity_interraction',
    event_action: 'close_bets_click',
    challenge,
  })
    this.setState({
        showPlaceBet: !this.state.showPlaceBet,
    })
} 
handleOptionClick = option => () => {
  const {challenge, ChallengeActions} = this.props
  gtag("event", "challenge", { 
    event_category: 'activity_interraction',
    event_action: 'option_click',
    challenge,
    option,
  })
  this.setState({
      selectedOption: option,
      showPlaceBet: true
  })
} 
handlePlaceClick = amount => () => {
  const {challenge, ChallengeActions} = this.props
  gtag("event", "challenge", { 
    event_category: 'activity_interraction',
    event_action: 'place_click',
    challenge,
    amount,
  })
    this.setState( prevState => ({
        amount: parseInt(prevState.amount) + amount,
    }))
}
handleBetInputChange = (e) => {
  const value = e.target.value
  const valueInt = (parseInt(value))

  if(!value.length) 
  this.setState({
    amount: ''
  })

  if(value.length > 5) return 

  if(isNaN(valueInt)) return
  this.setState({
    amount: valueInt
  })
}
  render(){
  const {t, challenge, user } = this.props
  const { donators, isDonatorsContainerOpened } = this.state
  const donates = challenge.ChallengeDonates
  const votesDone = challenge.done
  const votesFail = challenge.fail 

  const userId = !!user.model ? user.model.id : null
  let hasVotedDone = false
  let hasVotedFail = false
  let isDonator = false

  if(Array.isArray(donates)){
    isDonator = !!challenge.ChallengeDonates.find( d => d.user == userId)
  }
  if(Array.isArray(votesDone)){
    hasVotedDone = !!votesDone.find( v => v === userId)
  }
  if(Array.isArray(votesDone)){
    hasVotedFail = !!votesFail.find( v => v === userId)
  }

  let bank = 0
  if(challenge.ChallengeDonates)
  challenge.ChallengeDonates.map(d => {
    bank+=d.value
  })
  return (
    <div className={`activity__wrapper ${challenge.status === 'WAITING_FOR_ACCEPTION' ? 'show': 'hide' }`}>
    <div className='activity activity_frame'>
        <div className='activity__header'>
            <span className='activity__label activity__label_underline'>Challenge</span>
            <div className='activity__timer'>
            {
              challenge.status === 'WAITING_FOR_ACCEPTION' ? 
            <Timer prediction={challenge} to={new Date(challenge.forAcceptionAt).getTime() + challenge.acceptDuration * 1000 } show='SECONDS' reverse desc='to reach goal'/>
            : 
            <span className='activity__label'> Now </span>
          }
            </div>
        </div>
        <div className="activity__subheader activity__subheader--client">
          <p>By {challenge.displayName}</p>
        </div>
        <div className='activity__underline' />
        {
          isDonatorsContainerOpened ? <div className='activity__donators'>
            <ul>
            {
            donators.map(donator => {
              return  <li className="activity__donator-row">
              <div>  { donator.name || 'Predictor' } : { donator.donationValue }</div>
              <div className="activity__orb"/></li>
            })
          }
          </ul>
          
          <a className='activity__show-donators'
          onClick={this.handleShowDonatorsClick}>
          {t('global_hide_donators')}
          </a>
          </div>
          :
          !this.state.showPlaceBet ? 
          <div>
          <div className='activity__description'>
          {challenge.description} 
          </div>
          
          <div className='activity__join'>
          <div className='activity__bank'>
          {bank}
          <div className='activity__orb' />
          <div>{challenge.minBankToAccept}</div>
          </div>
          {
            isDonator && challenge.status  === 'ACCEPTED' && !(hasVotedDone || hasVotedFail) ?
            <div className='activity__doit'>
            <div className="activity__ok" onClick={this.handleDoneClick} />
            <div className='activity__button' onClick={this.handleOptionClick(0)}>DO IT</div>
            <div className="activity__fail" onClick={this.handleDoneClick} />
            </div>
          : 
             <div className='activity__button' onClick={this.handleOptionClick(0)}>DO IT</div>
          }
          </div>
          <a className='activity__show-donators'
          onClick={this.handleShowDonatorsClick}>
          {t('global_show_donators')}
          </a>
          
          </div>
          :
          <div>
          <div className='activity__bet-desc'>
          <div className='activity__bet-label'>DO IT</div>
          <div className='activity__close-icon' onClick={this.handleCloseClick}/>
          </div>
          <div className='activity__option'>
          <div className='activity__button_inline activity__button_gold' onClick={this.handleDonate}>
          {'Ok'}</div>
          <div className='activity__bank_inline'>
          <input className='activity__bank_inline activity__input' value={this.state.amount} onChange={this.handleBetInputChange}/><div className='activity__orb' />
          </div>
          </div>
          <div className='activity__place'>
          <div className='activity__place-button activity__place-button_gold' onClick={this.handlePlaceClick(10)}>+10</div>
          <div className='activity__place-button activity__place-button_gold' onClick={this.handlePlaceClick(100)}>+100</div>
          <input
                  type="range"
                  value={this.state.amount}
                  min = {1}
                  max = {this.props.user.model.orbs}
                  className="predictionContainerAdd__body__footer__controlls__slider"
                  onChange = {(e) => this.setState({
                      amount: e.target.value,
                  })}
                />
          </div>
          </div>
        }
        
    </div>
    </div>
  )
      }
}
)