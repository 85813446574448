import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import WidgetsExplorer from './WidgetsExplorer';
import { changeWidgetsTab, installWidget, setupWidget, closeSuccessModal } from 'actions/uiActions';

import './widgets-explorer.scss';

const mapStateToProps = (state) => ({
    widgetsActiveTab: state.ui.widgetsActiveTab,
    showSuccessModal: state.ui.showSuccessModal
})
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        changeWidgetsTab,
        installWidget,
        setupWidget,
        closeSuccessModal,
     }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsExplorer);