import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"
import FormValidator from "components/Common/FormValidator"
import * as ModalActions from "actions/ModalActions"
import * as userActions from "actions/UserActions"
import { required, gte } from "utils/validator"

class ConfirmPassword extends FormValidator {
  
  componentDidMount() {
    const queryString = require("query-string")
    const parsed = queryString.parse(this.props.location.search)
    const token = parsed.token
    this.state.token = token
  }

  createNotification = (data) => {
    const { notify } = this.props
    this.setState({ notification: data })
    if (this.state.notification) {
      notify({
        title: this.state.notification.title,
        message: this.state.notification.message,
        status: this.state.notification.status,
        dismissible: this.state.notification.dismissible,
        dismissAfter: 5000,
        dismissible: true
      })
    }
  }

  callback = (response, error) => {
    if (error != null) {
      this.createNotification({
        title: error.title,
        message: error.message,
        status: "error"
      })
      return
    }

    this.createNotification({
      title: response.title,
      message: response.message,
      status: "success"
    })

    setTimeout(() => {
      window.location.href = "/"
    }, 1000)
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    if (!this.isValid()) {
      this.createNotification({
        title: "Validation error",
        message: "Please, fill correct password",
        status: "warning"
      })
      return
    }
    const { password, repassword } = this.state.form
    if (password.value != repassword.value) {
      this.createNotification({
        title: "Validation error",
        message: "Passwords are not equal",
        status: "warning"
      })
      return
    }
    const res = userActions.updatePassword(this.state.token, password.value, this.callback)
  }

  render() {
    return (
      <div className="modalView">
        <div className="profileContainer modalViewContainer">
          <div className="modalViewBlock">
            <h3 onClick={this.executeCaptcha} className="modalViewBlock-title">Confirm password</h3>
            <p className="modalViewBlock-desc">Enter your new password below to begin password confirm process.</p>
            <form className="formContainerForm" noValidate onSubmit={this.handleFormSubmit}>
              <div className="inputBlock">

                <input
                  {...this.bindInput("password", "password", [required, gte(6)])}
                  className={`authInput ${this.isValid("password") || "authInput authInput_error"}`}
                  placeholder="Type your password here"
                />
                <span className="inputBlock-icon">
                  <div className="inputBlock-img">
                    <div className="icon svgIcon-password-icon svgIcon-password-icon-dims" />
                  </div>
                </span>
              </div>
              <div className="inputBlock">
                <input
                  {...this.bindInput("repassword", "password", [required, gte(6)])}
                  className={`authInput ${this.isValid("password") || "authInput authInput_error"}`}
                  placeholder="Repeat your password here"
                />
                <span className="inputBlock-icon">
                  <div className="inputBlock-img">
                    <div className="icon svgIcon-password-icon svgIcon-password-icon-dims" />
                  </div>
                </span>
              </div>
              <div className="inputBlock">
                <input
                  id="authInput-signIn"
                  className="authInput authSendInput_modal"
                  type="submit"
                  value="Complete Confirmation"
                  disabled={!this.isValid()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    modals: state.modals
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword)
