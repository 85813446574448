import React from "react"
import { trans } from "utils/translator"
import * as PredictionAPI from "../../api/PredictionAPI"

export default trans(class Prediction extends React.Component{
 constructor(props){
    super(props)
    const prediction = this.props.prediction;
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const cubes = user.balance || 0
    const min = 0
    const max = cubes

    this.state = {
        selectedOption: 0,
        amount: 100,
        showPlaceBet: false, 
        min,
        max,
    }
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.handlePredictionBetPlace = this.handlePredictionBetPlace.bind(this)
    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.handlePlaceClick = this.handlePlaceClick.bind(this)
    this.handleBetInputBlur = this.handleBetInputBlur.bind(this)
    }
    
  notificClick(title, message, status) {
    const { notify } = this.props
    notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000
    })
  }


    handleBetInputBlur= (e) => {
      const value = e.target.value  
      let valueInt = parseInt(value)
      const {state} = this
       
      if( valueInt > state.max) valueInt = state.max
      if( valueInt < state.min) valueInt = state.min
         
      this.setState({
        amount: valueInt
      })

    }
    handleBetInputChange = (e) => {
      const value = e.target.value
      const valueInt = (parseInt(value))
    
      if(!value.length) 
      this.setState({
        amount: ''
      })
    
      if(value.length > 5) return 
    
      if(isNaN(valueInt)) return
      this.setState({
        amount: valueInt
      })
    }   
 handleCloseClick = () => {
  const { prediction } = this.props.prediction
  gtag("event", "prediction", { 
    event_category: 'activity_interraction',
    event_action: 'close_bets_clicked',
  })
     this.setState({
         showPlaceBet: !this.state.showPlaceBet,
     })
 } 
 handleOptionClick = option => () => {
  const { prediction } = this.props.prediction
  gtag("event", "prediction", { 
    event_category: 'activity_interraction',
    event_action: 'option_selected',
    option
  })
     this.setState({
         selectedOption: option,
         showPlaceBet: true
     })
 } 
 handlePlaceClick = amount => () => {
  const { prediction } = this.props.prediction
  gtag("event", "prediction", { 
    event_category: 'activity_interraction',
    event_action: 'bet_preset_clicked',
  })
     this.setState(prevState => ({
         amount: parseInt(prevState.amount) + amount,
     }))
 }
 handlePredictionBetPlace(button) {

  const { prediction } = this.props.prediction
  gtag("event", "prediction", { 
    event_category: 'activity_interraction',
    event_action: 'place_bet',
  })

    if (this.state.amount < 1) {
      this.notificClick("Place prediction", "You can't place prediction less then 1", "error")
      return
    }
    // api call
    this.placeBetAPI(this.props.prediction.id)
  }
  placeBetAPI(id) {

    // bridge to callbacks
    const _bridge = this.props.PredictionsActions

    const body = {
      value: this.state.amount,
      outcome: this.state.selectedOption || 0
    }

    PredictionAPI.placeBet(id, body, (error, prd) => {
      if (error != null) {
        console.error("Prediction | bet", error)
        this.notificClick("Something went wrong", error, "error")
        return
      }

      const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
      const userID = userL.id || -1

      gtag("event", "prediction", { 
        event_category: 'activity_interraction',
        event_action: 'bet_placed',
      })
      this.props.UserActions.updateUserBalance()
      // _bridge.update(prd)

      const newBet = this.props.prediction
      newBet.isOpen = !newBet.isOpen,
      this.setState({
        bet: newBet,
        showPlaceBet: false,
      })
    })
  }

 calcExpectedWin(item) {
    const { t } = this.props
  if (item.mode == "FUTURE_MODE") {
    const selected = this.state.selectedOption
    const enemy = Math.abs(selected - 1)

    // если противоположный банк пустой, то мы возвращаем тело ставки
    if (item.outcomes[enemy].bank == 0) {
      return this.state.amount
    }

    var bank = item.outcomes[selected].bank || this.state.amount
    const enemyBank = item.outcomes[enemy].bank 

    // тотал банк минус комиссия
    const totalPool = bank + enemyBank * 0.85

    // solve inf problem
    bank = bank == 0 ? totalPool : bank

    // какая победная сумма приходиться на каждый куб из ставки
    const wageredPerCube = totalPool / bank

    // рассчитываем победные кубы для каждого юзера
    const r = this.state.amount * wageredPerCube
    
    return parseInt(r)
  } else if (item.mode == "LIVE_MODE") {
    // todo progressive formula
    const selected = this.state.selectedOption
    const enemy = Math.abs(selected - 1)

    // если противоположный банк пустой, то мы возвращаем тело ставки
    if (item.outcomes[enemy].bank == 0) {
      return this.state.amount
    }

    var bank = item.outcomes[selected].bank || this.state.amount
    const enemyBank = item.outcomes[enemy].bank

    // тотал банк минус комиссия
    
    const totalPool = +bank + (enemyBank * 0.85)
    // solve inf problem
    bank = bank == 0 ? totalPool : bank

    // какая победная сумма приходиться на каждый куб из ставки
    const wageredPerCube = totalPool / bank

    // рассчитываем победные кубы для каждого юзера
    const r = this.state.amount * wageredPerCube
    
    return parseInt(r)
  }

  return 0
}
 render(){
     const {t, prediction} = this.props
     const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
     const cubes = user.balance || 0
     const min = 0
     const max = cubes
  return (
    <div className='activity'>
        <div className='activity__header'>
            <span className='activity__label'>Prediction</span><img/>
        </div>
        <div className='activity__underline activity__underline_blue' />
        {
            !this.state.showPlaceBet ? 
            <div>
            <div className='activity__description'>
            {prediction.title}
            </div>
            <div className='activity__options'>
            <div className='activity__option'>
            <div tooltip={prediction.outcomes[0].title.length > 10 ? prediction.outcomes[0].title : ''}
            flow='right'
            className='activity__button_inline activity__button_cyan'
            onClick={this.handleOptionClick(0)}>
            {prediction.outcomes[0].title.length > 10 ? `${prediction.outcomes[0].title.slice(0, 10)}...` : prediction.outcomes[0].title || 'Yes'}</div>
            <div className='activity__bank_inline'>{prediction.outcomes[0].bank}  <div className='activity__cube' /></div>
            </div>
            <div className='activity__option'>
            <div 
            tooltip={prediction.outcomes[1].title.length > 10 ? prediction.outcomes[1].title : ''}
            flow='right' className='activity__button_inline activity__button_purple' onClick={this.handleOptionClick(1)}>
            {prediction.outcomes[1].title.length > 10 ? `${prediction.outcomes[1].title.slice(0, 10)}...` : prediction.outcomes[1].title || 'No'}</div>
            <div className='activity__bank_inline'>{prediction.outcomes[1].bank}<div className='activity__cube' /></div>
            </div>
            </div>
            </div>
            : 
            <div>
            <div className='activity__bet-desc'>
            <div className='activity__bet-label'>{prediction.outcomes[this.state.selectedOption].title}</div>
            <div className='activity__close-icon' onClick={this.handleCloseClick}/>
            </div>
            <div className='activity__expected-win'>Expected win: {this.calcExpectedWin(prediction)}</div>
            <div className='activity__option'>
            <div className='activity__button_inline activity__button_cyan' onClick={this.handlePredictionBetPlace}>
            {'Place'}</div>
            <div className='activity__bank_inline'>
            <input className='activity__bank_inline activity__input' value={this.state.amount} onBlur={this.handleBetInputBlur} onChange={this.handleBetInputChange}/><div className='activity__cube' />
             </div>
            </div>
            <div className='activity__place'>
            <div className='activity__place-button' onClick={this.handlePlaceClick(100)}>+100</div>
            <div className='activity__place-button' onClick={this.handlePlaceClick(1000)}>+1000</div>
            <input
                    type="range"
                    value={this.state.amount}
                    min={min}
                    max={max}
                    className="predictionContainerAdd__body__footer__controlls__slider"
                    onChange = {(e) => this.setState({
                        amount: e.target.value,
                    })}
                  />
            </div>
            </div>
        }
       
    </div>
  )
}
}
)