import { challengeActiveStatuses } from "./config";
import { activityTypes } from './enums';

export function params(data) {
  return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join("&")
}


export function removeUserFromLocalStore(){
  localStorage.removeItem('predictoria_currentUser');
}

export function getUserFromLocalStore() {
  let user;
  try{
    user = JSON.parse(localStorage.getItem('predictoria_currentUser'))
  } 
  catch(e){
    console.error('Unable to get user from local store');
    user = {};
  }

  if(!(typeof user === 'object' && user !== null)){
    console.error('Unable to parse user object from local store');
    user = {};
  }
  
  return user;
}

export function getSuccessNotify(title, message){
  return {
    title,
    message,
    status: 'success',
    dismissible: true,
    dismissAfter: 3000
  }
}


export function getErrNotify(error) {
  let title;
  let message;
  if (typeof error === 'object') {
    title = `Error with code ${error.code}`;
    message = error.message;
  }
  else if (typeof error === 'string') {
    title = `Error happened`;
    message = error;
  }
  else {
    title = 'Unspecified error happened';
    message = 'Please, contact support';
  }

  return {
    title,
    message,
    status: 'error',
    dismissible: true,
    dismissAfter: 3000
  };
}


// decide if challenge is visible to user and ready to accept donations
export function isChallengeActive(status){
  return challengeActiveStatuses.includes(status);
}

// @REFACTOR THIS, fix naming
export const isActive = {
  [activityTypes.CHALLENGE]: isChallengeActive
};

export function isActivityCardActive(activity){
  const type = activity.type || activityTypes.CHALLENGE;

  return isActive[type](activity.status);

}