import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './button.scss';
import { StackLayout } from 'components/ds/Layouts';


export const ButtonAppearanceStyles = {
  default: 'ds-button-appearance--default', 
  accent: 'ds-button-appearance--accent',
  primary: 'ds-button-appearance--primary',
  secondary: 'ds-button-appearance--secondary',
  special: 'ds-button-appearance--special',
  danger: 'ds-button-appearance--danger',
  'ghost-special': 'ds-button-appearance--ghost-special'
}


const Button = props => {
  const {
    appearance,
    isDisabled,
    isLoading,
    isSelected,
    iconAfter,
    iconBefore,
    spacing,
    type,
    shouldFitContainer,
    children,
    onClick,
    className
  } = props;

  return (
    <button
      type='button'
      onClick={() => {
        if(isDisabled) return
        onClick()
      }
      }
      className={cn('ds-button', className,
      ButtonAppearanceStyles[appearance], 
      {
        'ds-button--disabled': isDisabled,
        'ds-button--selected': isSelected,
        'ds-button--loading': isLoading,
        'ds-button--fit': shouldFitContainer,
      })}
    >
      <StackLayout>
        {iconBefore && <div className="button__icon-wrapper">{iconBefore}</div>}
        {children && <span className="button__content">{children}</span>}
        {iconAfter && <div className="button__icon-wrapper">{iconAfter}</div>}
      </StackLayout>
    </button>
  );
};

export default Button;  