import React, { Component } from "react"
import NotificationsSystem from "reapop"

// 1. import Bootstrap and Font Awesome
// import '!style-loader!css-loader!bootstrap/dist/css/bootstrap.min.css';
import "!style-loader!css-loader!font-awesome/css/font-awesome.min.css"
// 2. import reapop theme
import theme from "reapop-theme-wybo"

export default class ATopLevelComponent extends Component {
  render() { 
    return <NotificationsSystem theme={theme} />
  }
}
