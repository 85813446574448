import React, { useState } from 'react';
import Tabs from 'components/ds/Molecules/Tabs';
import Register from './Register';
import Login from './Login';

import { trans } from 'utils/translator';

const AuthModal = (props) => {
    const { activeTab, t } = props;
    const [selected, setSelected] = useState(activeTab);
    return (
        <div className='auth-modal bg-white black'>
            <Tabs
                onSelect={nextIndex => setSelected(nextIndex)}
                selected={selected}>
                <Tabs.Tab label={t('_auth_1')}>
                        <Login />
                </Tabs.Tab>
                <Tabs.Tab label={t('_auth_2')}>
                        <Register />
                </Tabs.Tab>
            </Tabs>
        </div>
    )
}

AuthModal.defaultProps = {
    activeTab: 0,
};

export default trans(AuthModal);
