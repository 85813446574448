import React from "react"
import { trans } from "utils/translator"
import Timer from "../AdminStreamsPage/AdminPrediction/AdminPredictionTimerComponent"
import {history} from "libs/router"

export default trans(class Voting extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      amount: props.voting.currency === 'ORBS' ? 10 : 100,
      showPlaceBet: false, 
      expand: false,
    }
    // this.handlePredictionBetPlace = this.handlePredictionBetPlace.bind(this)
    this.handleCloseClick = this.handleCloseClick.bind(this)
    this.handlePlaceClick = this.handlePlaceClick.bind(this)
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.handleBetInputChange = this.handleBetInputChange.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
  }
  notificClick(title, message, status, buttons=[]) {
    const { notify } = this.props
    notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000,
      buttons
    })
  }
  handleExpandClick = () => {
    gtag("event", "voting", { 
      event_category: 'activity_interraction',
      event_action: 'expand_clicked',
    })
    this.setState(prevState => ({
      expand: !prevState.expand
    }))
  }
 
  handlePlaceBet = () => {
    const { toggleQuickDonationModal, user, voting } = this.props;
    if(!confirm(`${this.state.amount} ${voting.currency == "CUBES" ? 'cubes' : 'orbs' } will be debited from your account. Do you want to proceed?`)) return
    
    if(!user.isAuthenticated && voting.currency === 'ORBS'){
      toggleQuickDonationModal({
        donationValue: this.state.amount,
        action: 'donate_to_voting',
        params: `${voting.id},${this.state.selectedOption}`
      });
      return;
    }
    
    
    gtag("event", "voting", { 
      event_category: 'activity_interraction',
      event_action: 'place_bet_clicked',
    })
    this.props.VotingActions.placeBet((err, vote) => {
          if(vote && vote.id) {
          const { voting } = this.props 
          const price = this.state.amount
          if(voting.currency === 'ORBS'){
            gtag("event", "purchase", {
              transaction_id: `${voting.id}`,
              affiliation: voting.stream,
              value: price,
              price: price,
              currency: "USD",
              items: [
                {
                  id: `${voting.id}`,
                  name: voting.description,
                  quantity: 1,
                  price: price,
                  value: price,
                }
              ]
            })
          }
          else {
            gtag("event", "voting", { 
              event_category: 'activity_interraction',
              event_action: 'bet_placed',
            })
          }
          this.setState({
            showPlaceBet: false
          })
          this.notificClick('Success', 'Your vote have been accepted')
          this.props.UserActions.updateUserBalance()
          }
          else {
            gtag("event", "voting", { 
              event_category: 'activity_interraction',
              event_action: 'error',
              msg: err
            })
            this.notificClick('Error', err, "error", [{
              name: 'Пополнить баланс',
              primary:true,
              onClick: () => {
                history.push('/buy')
              }
            }])
          }
        },
        this.props.voting.id,
        this.state.selectedOption,
        this.state.amount)
    }
  handleCloseClick = () => {
    gtag("event", "voting", { 
      event_category: 'activity_interraction',
      event_action: 'close_bets_clicked',
    })
    this.setState({
        showPlaceBet: !this.state.showPlaceBet,
    })
} 
handleOptionClick = option => (e) => {
  gtag("event", "voting", { 
    event_category: 'activity_interraction',
    event_action: 'option_clicked',
    option
  })
  this.setState({
      selectedOption: option,
      showPlaceBet: true
  })
} 
  handlePlaceClick = amount => () => {
    gtag("event", "voting", { 
      event_category: 'activity_interraction',
      event_action: 'bet_preset_clicked',
    })
    this.setState(prevState => ({
        amount: parseInt(prevState.amount) + amount,
    }))
}
handleBetInputChange = (e) => {
  const value = e.target.value
  const valueInt = (parseInt(value))

  if(!value.length) 
  this.setState({
    amount: ''
  })

  if(value.length > 5) return 

  if(isNaN(valueInt)) return
  this.setState({
    amount: valueInt
  })
}
  render(){
  const {t, voting} = this.props
  let options
  let defaultDonationVals = voting.currency === 'ORBS' ? [10, 100] : [100, 1000] 
  if(!this.state.expand) 
  options = voting.options.slice(0,5)
  else 
  options = voting.options

  return (
    <div className='activity activity_frame' onClick={this.props.onClick}>
        <div className='activity__header'>
            <span className='activity__label activity__label_underline-cyan'>Voting</span>
            {
             voting.status == 'IN_PROGRESS' ?
            <div className='activity__timer'>
            <Timer prediction={voting} to={new Date(voting.createdAt).getTime() + voting.duration*1000} show='SECONDS' reverse desc='to the end'/>
            </div>
            :
            <div className='activity__status'>
            Waiting for <strong>LAST</strong> vote
            </div>
          }
        </div>
        <div className='activity__description'>
          {voting.description}
          </div>
        
        {
          !this.state.showPlaceBet ? 
          <div className='activity__voting-options'>
          { 
            options.map((o, i) => {
              return   <div className='activity__option' >
              <div tooltip={o.length > 10 ? o : ''} flow='right' style={{
                  backgroundColor: `hsl(200, ${(50 + 10*i)}%, 50%)` 
              }} className='activity__button_inline activity__button_cyan'
              onClick={this.handleOptionClick(i)}>
                 {
                    o.length > 10 ?
                    <span>{o.split('').slice(0, 10)}...</span>
                    :
                    <span>{o}</span>
                  }
              </div>
              
              <div className='activity__bank_inline'>{voting.optionsBank && voting.optionsBank[i] || 0} 
              {
                    voting.currency === 'CUBES' ? <div className='activity__cube' /> : <div className='activity__orb' />
              }  
              </div>
              </div>
            })
          }
          {
               voting.options.length > 5 ? <div className='activity__label activity__label_show-more' onClick={this.handleExpandClick}>{this.state.expand ? 'Show less' : 'Show more'}</div> : null
          }
        </div>
          : 
          <div>
          <div className='activity__bet-desc'>
          <div className='activity__bet-label'>{`Vote for ${voting.options[this.state.selectedOption]}`}</div>
          <div className='activity__close-icon' onClick={this.handleCloseClick}/>
          </div>
          <div className='activity__option'>
          <div className={`activity__button_inline  activity__button${voting.currency=='ORBS' ? '_gold' : '_cyan'}`} onClick={this.handlePlaceBet}>
          {'Ok'}</div>
          <div className='activity__bank_inline'>
          <input className='activity__bank_inline activity__input' value={this.state.amount} onChange={this.handleBetInputChange}/>
          {
                    voting.currency === 'CUBES' ? <div className='activity__cube' /> : <div className='activity__orb' />
          }  
          </div>
          </div>
          <div className='activity__place'>
              <div className={`activity__place-button activity__place-button${voting.currency == 'ORBS' ? '_gold' : ''}`} onClick={this.handlePlaceClick(defaultDonationVals[0])}>{`+${defaultDonationVals[0]}`}</div>
              <div className={`activity__place-button activity__place-button${voting.currency == 'ORBS' ? '_gold' : ''}`} onClick={this.handlePlaceClick(defaultDonationVals[1])}>{`+${defaultDonationVals[1]}`}</div>
          <input
                  type="range"
                  value={this.state.amount}
                  min = {1}
                  max = {voting.currency === 'CUBES' ? this.props.user.model.balance : this.props.user.model.orbs}
                  className="predictionContainerAdd__body__footer__controlls__slider"
                  onChange = {(e) => this.setState({
                      amount: e.target.value,
                  })}
                />
          </div>
          </div>
        }
        
    </div>
  )
      }
}
)