import React, { Component } from "react"

import AdminUser from "../AdminUser"

export default class AdminUserList extends Component {
  render() {
    const { props } = this
    const { user, users, streams, UserAction } = props

    return (
      <div style={{ width: "100%", overflow: "hidden", minHeight: "800px" }}>
        {
            users.map((usr, index) => {
              return (<AdminUser
                user={usr}
                streams={streams}
                save={UserAction.save}
                remove={UserAction.remove}
                currentUser={user}
                key={usr.id}
              />)
            })
          }
      </div>
    )
  }
}
