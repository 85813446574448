// require('dotenv').config()
import { challengeStatuses } from './enums';

// socket 
export const SOCKET_HOST_URL = process.env.SOCKET_PORT
export const DOMAIN_URL = process.env.DOMAIN_URL

// Overlay defaults
export const BASE_ACTIVITIES_FIELD_WIDTH = 339;
export const BASE_ACTIVITIES_FIELD_HEIGHT = 140;
export const ACTIVITIES_FIELD_MAX_SCALE = 2;
export const ACTIVITIES_FIELD_SLIDER_DELAY = 5000;

export const MESSAGES_FIELD_BASE_WIDTH = 600;
export const MESSAGES_FIELD_BASE_HEIGHT = 100;
export const MESSAGES_FIELD_MAX_SCALE = 2;
export const MESSAGES_FIELD_DISPLAY_DURATION = 10000;


// export const SERVER_URL = 'http://dev.predictoria.com'
// export const BASE_URL = '/api'

export const DESKTOP_MIN_RESOLUTION = 1224;
// Overlay urls

export const apiRoutes = {
    USER_SIGNIN: '/api/auth/signin',
    USER_REGISTER: '/api/user',
    USER_READ: '/api/user',

}

export const appRoutes = {
    LOGIN: '/auth/login',
    REGISTRATION: '/auth/registration',
    PROFILE: '/profile', 
    STREAMS: '/streams',
    STREAM: '/stream/:id',
    PROFILE_MAIN: '/profile/main',
    PROFILE_PAYMENTS: '/profile/payments',
    PROFILE_SETTINGS: '/profile/settings', 
    PROFILE_PERSONALIZATION: '/profile/personalization',
    PROFILE_WIDGET: '/profile/widget',
    PROFILE_PARTNERSHIP: '/profile/partnership',
    PROFILE_STREAM: '/profile/stream',
    PROFILE_STREAMS: '/profile/streams' 
}
export const OVERLAY_SETTINGS_URL = '/api/overlays';

// CLIENT INTERACTION SETTINGS

export const CHALLENGE_MINIMAL_DONATION_SUM = 10;
export const CHALLENGE_MAXIMUM_DONATION_DESCRIPTION_LEN = 200;


// statuses allowed for accepting donations by user
export const challengeActiveStatuses = [
    challengeStatuses.ACCEPTED,
    challengeStatuses.WAITING_FOR_ACCEPTION
];

