import React, { Component } from "react"

import { notify } from "reapop"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import PredictionTimerComponent from "./PredictionTimerComponent"

import * as UserActions from "actions/UserActions"

import PredictionFinishedState from "./PredictionFinishedState"
import PredictionClosedState from "./PredictionClosedState"
import PredictionPlacedState from "./PredictionPlacedState"
import PredictionDefaultState from "./PredictionDefaultState"
import * as BettyAPI from "api/BettyAPI"


class Prediction extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isHover: false
    }
    this.setBetState()

    this.notificClick = this.notificClick.bind(this)
    this.handlePrediction = this.handlePrediction.bind(this)
    this.handleChangeRange = this.handleChangeRange.bind(this)
    this.handleChangeMultiplayer = this.handleChangeMultiplayer.bind(this)
    this.handlePredictionBetPlace = this.handlePredictionBetPlace.bind(this)
    this.handleHoverTrue = this.handleHoverTrue.bind(this)
    this.handleHoverFalse = this.handleHoverFalse.bind(this)
    this.renderHoverOverlay = this.renderHoverOverlay.bind(this)
  }


  setBetState() {
    let { props, state } = this
    const { bet } = state
    const { prediction } = props
    let { userBets } = prediction
    userBets = userBets || []

    const bets = prediction.bets || [
      { bank: 0, users: 0 },
      { bank: 0, users: 0 }
    ]

    const min = parseInt((bets[0].bank + bets[1].bank) * 0.12)

    // reset by default. sooooo monkeycode
    const isOpen = bet ? bet.isOpen : false
    const selected = bet ? bet.selected : (userBets[0] ? userBets[0].outcome || 0 : 0)
    const value = bet ? bet.value : (userBets[0] ? userBets[0].value || min + 100 : min + 100)

    state = {
      bet: {
        isOpen,
        selected,
        value,
        min,
        max: min * 6
      },
      bets,
      isHover: state.isHover
    }
    this.state = state
  }

  handleHoverTrue() {
    this.setState(prevState => ({
      isHover: true,
    }))
  }
  handleHoverFalse() {
    this.setState(prevState => ({
      isHover: false,
    }))
  }

  handlePrediction(button) {
    const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const isAuthenticated = userL.token != null && userL.token != ""
    if (isAuthenticated) {
      const newBet = this.state.bet
      newBet.isOpen = !newBet.isOpen
      if (button != -1) {
        newBet.selected = button

        // send analytics
        gtag("event", "begin_checkout", {
          items: [
            {
              id: this.props.prediction.id,
              name: this.props.prediction.title,
              brand: this.props.prediction.type,
              list_position: button,
              quantity: 1
            }
          ]
        })
      }
      this.setState({
        bet: newBet
      })
    }
  }

  handleChangeMultiplayer(name, event) {
    const bet = this.state.bet
    const oldValue = parseInt(bet.value)

    function checkMax(newValue) {
      return bet.max <= newValue ? bet.max : newValue
    }

    switch (name) {
      case 0:
        // +100
        // bet.value = checkMax(oldValue + 100)
        bet.value = 10
        break
      case 1:
        // x2
        // bet.value = checkMax(oldValue * 2)
        bet.value = 50
        break
      case 2:
        // x5
        // bet.value = checkMax(oldValue * 5)
        bet.value = 100
        break
      case 3:
        // x10
        // bet.value = checkMax(oldValue * 10)
        bet.value = 200
        break
      default: break
    }

    this.setState({
      bet
    })
  }

  handleChangeRange(name, event) {
    // We set the state value depending on input that is clicked
    if (name === "betRange" || name === "betInput") {
      const newBet = this.state.bet
      const value = event.target.value || 0
      newBet.value = parseInt(value)
      this.setState({
        bet: newBet
      })
    }
  }


  handlePredictionBetPlace(button) {
    if (this.state.bet.value < 1) {
      this.notificClick("Place prediction", "You can't place prediction less then 1", "error")
      return
    }

    // api call
    
    // api call
    this.placeBetAPI(this.props.prediction.id, this.props.eventId)
  }


  placeBetAPI(market_id, event_id) {

    // bridge to callbacks
    // const _bridge = this.props.PredictionsActions

    const body = {
      amount: this.state.bet.value,
      market_result: this.state.bet.selected + 1,
      market_id,
      event_id
    }
    
    BettyAPI.placeBet(event_id, body, (error, prd) => {
      if (error != null) {
        console.error("Betty | Prediction | bet", error)
        this.notificClick("Betty | Place prediction", error, "error")
        return
      }
    
      this.props.UserActions.updateUserBalance()


      // _bridge.update(prd)

      const newBet = this.state.bet
      newBet.isOpen = !newBet.isOpen,
      this.setState({
        bet: newBet
      })
    })
  }

  notificClick(title, message, status) {
    const { notify } = this.props

    notify({
      title,
      message,
      status,
      dismissible: true,
      dismissAfter: 5000
    })
  }

  renderTypeString(type, not_accepted) {
    switch (type) {
      case "done":
        return { title: "Done", desc: "Duration" }
      case "waiting_for_result":
        return { title: "Waiting for result", desc: "Duration" }
      case "live_event":
        return { title: "Live Match", desc: "From the Start" }
      case "next_event":
        return { title: "Next Match", desc: "From the Start" } // To the End" }
      case "challenge_event":
        const title = not_accepted == "" ? "Accepted by Streamer" : "Challenge for Streamer"
        return { title, desc: "From the Start" } // To the End" }
      default:
        return { title: "Waiting for result", desc: "Duration" }
    }
  }

  calcExpectedWin(bet,K1,K2) {

    const selected = this.state.bet.selected
    // const enemy = Math.abs(selected - 1)
    
    return selected == 0 ? parseInt(bet*K1) : parseInt(bet*K2)

  }

  renderHoverOverlay(type) {
    let text = ""
    switch (type) {
      case "live_event":
        text = "Predict the result of the CURRENT match (game session). After the end of the match a common bank is divided between those predictors whose prediction came true, depending on TIME of prediction and its amount. Earlier predictions get more cubes."
        break
      case "next_event":
        text = "Predict the result of the NEXT match (game session). Prediction will be closed for participation at the beginning of such match. Common bank is divided between those predictors whose prediction came true, depending on the amount of their predictions."
        break
      case "challenge_event":
        text = this.props.prediction.description || "Challenge is the event where viewers ask streamer to do something for the cubes. Challenge will be active (GREEN) when streamer accepts it. If streamer succeeds, he will receive all amount, if he loses or reject challenge - viewers will get their stakes back."
        break
      default: break
    }

    return (
      <div className={this.state.isHover && (type == "live_event" || type == "next_event" || type == "challenge_event") ? "hoverOverlayPrediction_active hoverOverlayPrediction" : "hoverOverlayPrediction"} >
        <span onClick={this.handleHoverFalse} className="hideButtonTip">close</span>
        <p style={{ color: "white" }}>&nbsp;&nbsp;&nbsp;&nbsp;{text}</p>
      </div>
    )
  }

  renderPrediction(prediction) {
    let { userBet } = this.props
    let { market_result, amount } = (userBet || [])[0] || {}
    let { id, title, result1, winMarket, result2, K1, K2, winnerMarkets } = prediction

    if (winnerMarkets) {
      winMarket = winnerMarkets.filter(m => m.id == id).result
    }

    // если нет такого айдишника (к сожалению это возможно сейчас), то ничего не отображать
    if (!id) { return null }

    const { state } = this
    const { bet, bets } = state
    const { isOpen } = bet

    const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const userID = userL.id || -1
    
    const isClosed = !(!Array.isArray(this.props.winnerMarkets) || !this.props.winnerMarkets.length)
    const isFinished = this.props.showResults
    const isPlaced = !(!Array.isArray(userBet) || !userBet.length)
    const isLocked0 = false
    const isLocked1 = false
    const betOutcome = parseInt(winMarket)-1 || 0
    const isWin = this.props.showResults ? market_result ? (market_result == winMarket) : winnerMarkets : false

    //TODO
    const winValue = isPlaced ? ( market_result == 1 ? K1 * amount : K2 * amount ) : 1

    const winBtn = result1
    const loseBtn = result2

    const log = {
      id,
      title,
      betOutcome,
      isWin,
      isFinished,
      isClosed,
      isPlaced,
      isLocked0,
      isLocked1,
      hover: state.isHover
    }
    console.table(log)



    return (
      <div className="predictionContainer" onMouseLeave={this.handleHoverFalse}>
        
        <div className="predictionContainerBlock">
          <div className="predictionContainerBlock__top">
            <div className="predictionContainerBlock__top__headerRow">
              {/* <PredictionTimerComponent prediction={prediction} desc={typeString.desc} /> */}
              {/* <div onMouseEnter={this.handleHoverTrue} className={classes}>{typeString.title}</div> */}
            </div>
            <div className="predictionContainerBlock__top__footerRow">{title}</div>
          </div>
          {isFinished ? (
            <PredictionFinishedState
              bets={bets}
              userBet={this.props.userBet}
              selected={market_result}
              winBtn={winBtn}
              loseBtn={loseBtn}
              isWin={true}
              K1={K1}
              K2={K2}
              winValue={winValue}
              selected={bet.selected}
              isPlaced={isPlaced}
            />
          ) : (
              isClosed ? (
                <PredictionClosedState
                  bets={bets}
                  userBet={this.props.userBet}
                  winBtn={winBtn}
                  loseBtn={loseBtn}
                  isWin={isWin}
                  winValue={winValue}
                  selected={market_result}
                  isPlaced={isPlaced}
                  K1={K1}
                  K2={K2}
                />
              ) : (
                  isPlaced ? (
                    <PredictionPlacedState
                      id={id}
                      bets={bets}
                      winBtn={winBtn}
                      loseBtn={loseBtn}
                      winValue={winValue}
                      selected={market_result}
                      predictionOver={this.props.PredictionsActions.predictionOver}
                      notificClick={this.notificClick}
                      stream={prediction.stream}
                      handlePrediction={this.handlePrediction}
                      K1={K1}
                      K2={K2}
                    />
                  ) : (
                    <PredictionDefaultState
                      bets={bets}
                      winBtn={winBtn}
                      loseBtn={loseBtn}
                      winValue={winValue}
                      isLocked0={isLocked0}
                      isLocked1={isLocked1}
                      handlePrediction={this.handlePrediction}
                      K1={K1}
                      K2={K2}
                    />
                    )
                )
            )
          }
        </div>
        {/* <!-- prediction add --> */}
        {isOpen ? (
          <div className="predictionContainerAdd">
            <div onClick={this.handlePrediction.bind(this, -1)} className="predictionContainerAdd__back" role="handle-prediction">
              <img className="predictionContainerAdd__back-img" src="/assets/images/left-arrow.svg" alt="back-arrow" />
            </div>
            <div className="predictionContainerAdd__body">

              <div className="predictionContainerAdd__body__header">
                <h3>{bet.selected == 0 ? winBtn : loseBtn}</h3>
                <h5>Expected win: {parseInt(this.calcExpectedWin(bet.value, K1, K2))} cubes</h5>
              </div>

              <div className={`predictionContainerAdd__body__footer challenge`}>
                <div className="predictionContainerAdd__body__footer__controlls">
                  <input
                    type="range"
                    value={bet.value}
                    onChange={this.handleChangeRange.bind(this, "betRange")}
                    min={bet.min}
                    max={bet.max == 0 ? 2000 : bet.max}
                    className="predictionContainerAdd__body__footer__controlls__slider"
                  />
                  <input
                    type="text"
                    value={bet.value}
                    onChange={this.handleChangeRange.bind(this, "betInput")}
                    className="predictionContainerAdd__body__footer__controlls__value"
                  />
                  <input type="button" onClick={this.handlePredictionBetPlace} className="predictionContainerAdd__body__footer__controlls__place" value="Place" />
                </div>
                <div className="predictionContainerAdd__body__footer__buttons">
                  <button
                    onClick={this.handleChangeMultiplayer.bind(this, 0)}
                    className="predictionContainerAdd__body__footer__buttons__button"
                  >10
                  </button>
                  <button
                    onClick={this.handleChangeMultiplayer.bind(this, 1)}
                    className="predictionContainerAdd__body__footer__buttons__button"
                  >50
                  </button>
                  <button
                    onClick={this.handleChangeMultiplayer.bind(this, 2)}
                    className="predictionContainerAdd__body__footer__buttons__button"
                  >100
                  </button>
                  <button
                    onClick={this.handleChangeMultiplayer.bind(this, 3)}
                    className="predictionContainerAdd__body__footer__buttons__button"
                  >200
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  render() {
    this.setBetState()
    const item = this.props.prediction
    return this.renderPrediction(item)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    notify: bindActionCreators(notify, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(Prediction)
