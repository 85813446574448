import collectionReducers from "utils/collectionReducers"

const initialState = {
  predictions: [],
  pdone: []
}

const NAMESPACE = "PREDICTIONS"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function set(predictions) {
  const copyPredictions = predictions.map(item => Object.assign({}, item))
  return {
    namespace: NAMESPACE,
    type: "SET_PREDICTIONS_LIST",
    handle: (state) => { return { ...state, predictions: copyPredictions } }
  }
}

export function setPdones(list) {
  return {
    namespace: NAMESPACE,
    type: "SET_PDONE_LIST",
    handle: (state) => { return { ...state, pdone: list } }
  }
}

export function update(prd) {
  
  function _update(predictions) {
    // получаем индекс ставки в этом стриме
    const index = predictions.findIndex(item => item.id === prd.id)

    // заменяем/добавляем
    if (index != -1) {
      predictions[index] = prd
    } else {
      predictions.push(prd)
    }

    return predictions
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_PREDICTION",
    handle: (state) => {
      const newlist = _update(state.predictions)
      const copyNewlist = newlist.map(item => Object.assign({}, item))
      return { ...state, predictions: copyNewlist }
    }
  }
}

export function addPdone(item) {
  return {
    namespace: NAMESPACE,
    type: "ADD_PDONE",
    handle: (state) => {
      state.pdone.push(item.pdone)
      return { ...state, pdone: state.pdone }
    }
  }
}
