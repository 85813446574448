import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const HelpSubheader = props => {
    const { t } = props;
    return (
        <div className="flex p2 justify-between py4 bg-dark-magenta">
        <h1 className="h1">
        <Link to='/help'>
        {t('hc_header')}
        </Link>
        </h1>
        <div className="help-center__search disabled">
        <input onChange={() => {}} onKeyPress={() => {}} className="help-center__search-input" placeholder={t('hc_search_placeholder')}/>
        <div onClick={() => {}} className="help-center__search-icon"/>
        </div>
        </div>
    )
}

HelpSubheader.propTypes = {

}

export default HelpSubheader
