import React, { Component, isValidElement } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import cx from 'classnames'


export default class Dropdown extends Component {
  static propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'center']),
    disabled: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    active: PropTypes.bool,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    className: '',
  };

  componentDidMount() {
    window.addEventListener('click', this._onWindowClick);
    window.addEventListener('touchstart', this._onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this._onWindowClick);
    window.removeEventListener('touchstart', this._onWindowClick);
  }

  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    this._onWindowClick = this._onWindowClick.bind(this);
    this._onToggleClick = this._onToggleClick.bind(this);
  }

  isActive() {
    return typeof this.props.active === 'boolean' ? this.props.active : this.state.active;
  }

  hide() {
    this.setState(
      {
        active: false,
      },
      () => {
        if (this.props.onHide) {
          this.props.onHide();
        }
      }
    );
  }

  show() {
    this.setState(
      {
        active: true,
      },
      () => {
        if (this.props.onShow) {
          this.props.onShow();
        }
      }
    );
  }

  _onWindowClick(event) {
    const dropdownElement = findDOMNode(this);

    if (event.target !== dropdownElement && !dropdownElement.contains(event.target) && this.isActive()) {
      this.hide();
    }
  }

  _onToggleClick(event) {
    event.preventDefault();
    if (this.isActive()) {
      this.hide();
    } else {
      this.show();
    }
  }

  onClick = e => {
    const { disabled, onClick } = this.props;

    if (!disabled) {
      this._onToggleClick(e);
      if (onClick) {
        onClick();
      }
    }
  };

  render() {
    let { children, className, disabled, position, component, ...rest } = this.props;

    if (false === isValidElement(component)) {
      component = <span>{component}</span>;
    }

    const trigger = (
      <div onClick={this.onClick} className="b-dropdown__trigger">
        {component}
      </div>
    );

    const dropdownClasses = cx(
      'b-dropdown',
      {
        'b-dropdown_active': this.isActive(),
        'b-dropdown_disabled': disabled,
      },
    );

    return (
      <div {...rest} className={dropdownClasses}>
        {trigger}
        <div style={this.state.style}
             className={cx('b-dropdown__wrapper', `b-dropdown__wrapper_${position}`)}>
          <div className="b-dropdown__arrow">
            <svg viewBox="0 0 30 30">
              <path
                className="pt-popover-arrow-border"
                d="M8.11 6.302c1.015-.936 1.887-2.922 1.887-4.297v26c0-1.378-.868-3.357-1.888-4.297L.925 17.09c-1.237-1.14-1.233-3.034 0-4.17L8.11 6.302z"
              />
              <path
                className="pt-popover-arrow-fill"
                d="M8.787 7.036c1.22-1.125 2.21-3.376 2.21-5.03V0v30-2.005c0-1.654-.983-3.9-2.21-5.03l-7.183-6.616c-.81-.746-.802-1.96 0-2.7l7.183-6.614z"
              />
            </svg>
          </div>
          <div {...rest} className={cx('b-dropdown__content', className)}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
