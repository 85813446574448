import React, { Component } from "react"
import Container from "utils/Container"

import AnimatedNumber from "react-animated-number"
import Transition from "react-transition-group/Transition"

export default class PredictionPlacedState extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHoverBtn: false,
      isHoverLocked: false,

      bank1: 0,
      users1: 0,
      bank2: 0,
      users2: 0,
      users1P: 0,
      users2P: 0
    }

    this.callbackPrdOver = this.callbackPrdOver.bind(this)
    this.handleHover = this.handleHover.bind(this)
    this.handleHoverLeave = this.handleHoverLeave.bind(this)
    this.handleHoverLocked = this.handleHoverLocked.bind(this)
  }

  componentDidMount() {
    const { bets } = this.props
    this.setBank(bets)
  }

  componentWillReceiveProps(props) {
    const { bets } = props
    // console.log('PredictionDefaultState componentWillReceiveProps', bets)
    this.setBank(bets)
  }

  setBank(bets) {
    setTimeout(() => {
      this.setState({
        bank1: bets[0].bank,
        users1: bets[0].users,
        bank2: bets[1].bank,
        users2: bets[1].users
      })
    }, 300)
  }

  handleHover = (e) => {
    this.setState(prevState => ({ isHoverBtn: !prevState.isHoverBtn }))
  }

  handleHoverLocked = (e) => {
    this.setState(prevState => ({ isHoverLocked: !prevState.isHoverLocked }))
  }

  handleHoverLeave = () => {
    this.setState({ isHoverBtn: false })
  }

  callbackPrdOver(error, prediction) {
    if (error) {
      this.props.notificClick(
        "Predictoria",
        error,
        "error"
      )
      return
    }
    this.props.notificClick(
      "Predictoria",
      "Thanks for contributing in Predictoria.com, your notifications will be handle soon",
      "success"
    )
  }

  render() {
    // это просто звезда, я уже с ума схожу с этими анимациями для предикшинов, 
    // потратил кучу времени, а результата - ноль

    const { props, state } = this
    const { bank1, bank2, users1, users2, users1P, users2P } = state
    const { id, winBtn, loseBtn, winValue, selected, stream, predictionOver, K1, K2 } = props

    const animationDuration = 500
    const defaultStyleCounter = {
      transition: `top ${animationDuration}ms, opacity ${4 * animationDuration}ms`,
      opacity: 0,
      position: "absolute",
      right: 0,
      top: "-100%",
      height: "14px",
    }
    const transitionStylesCounter = {
      entering: {
        opacity: 1,
        top: "-100%",
      },
      entered: {
        opacity: 0,
        top: "-180%",
      }
    }
    const Plus1Component = ({ newValue, prev }) => (
      <span style={{ position: "relative" }}>

        <Transition
          in
          timeout={0}
          appear={newValue != prev}
        >
          {state => (
            <span style={{
              ...defaultStyleCounter,
              ...transitionStylesCounter[state]
            }}
            >
              {"+1"}
            </span>
          )}
        </Transition>

        {newValue}
      </span>
    )

    const r = (
      <div className="predictionContainerBlock__bottom">
        <table cellSpacing="0">
          <thead>
            <tr>
              <th width="42%">Your Prediction</th>
              <th width="29%">Profit</th>
              <th width="29%">Coefficient</th>
            </tr>
          </thead>

          {selected == 1 ? (
            <tbody>
              <tr className="team_one prediction">

                
                <td className="hoverTipWrapper">
                  <button onClick={() => predictionOver(id, 0, stream, this.callbackPrdOver)}>{winBtn}</button>
                  <div className="hoverButtonOverlay"><p>Event is over. This option wins. Summon referee!</p></div>
                </td>
                <td><span>{parseInt(winValue)}</span></td>
                <td><span>{K1}</span></td>
              </tr>
              
                <tr className="team_two disabled">
                  <td className="hoverTipWrapper">
                    <button onClick={() => predictionOver(id, 1, stream, this.callbackPrdOver)}>{loseBtn}</button>
                    <div className="hoverButtonOverlay"><p>Event is over. This option wins. Summon referee!</p></div>
                  </td>
                  <td colSpan="2">
                    <span>
                    {K2}
                    </span>
                  </td>
                </tr>
            </tbody>
          ) : (
            <tbody>
              <tr className="team_one disabled">
                
                <td className="hoverTipWrapper">
                  <button onClick={() => predictionOver(id, 0, stream, this.callbackPrdOver)}>{winBtn}</button>
                  <div className="hoverButtonOverlay"><p>Event is over. This option wins. Summon referee!</p></div>
                </td>
                <td colSpan="2">
                  <span>
                  {K1}
                  </span>
                </td>
              </tr>
              
                <tr className="team_two prediction">
                  <td className="hoverTipWrapper">
                    <button onClick={() => predictionOver(id, 1, stream, this.callbackPrdOver)}>{loseBtn}</button>
                    <div className="hoverButtonOverlay"><p>Event is over. This option wins. Summon referee!</p></div>
                  </td>

                  <td><span>{parseInt(winValue)}</span></td>
                  <td>
                    <span>
                      {K2}
                    </span>
                  </td>
                </tr>
                  
            </tbody>
            )}

        </table>
      </div>
    )
    state.users1P = users1
    state.users2P = users2
    return r
  }
}
