import React from 'react'
import PropTypes from 'prop-types'
import ProfileSectionHead from '../ProfileSectionHead';

const ProfileWidget = props => {
    return (
        <div className='profile-widget bg-white p4'>
            <ProfileSectionHead
                title='Виджет'
                description='Наша специальная программа для упрощения взаимодействия нашего сервиса с вами'
            />
            <div className="profile-widget__widget bg-gray mx-auto" />
        </div>
    )
}

ProfileWidget.propTypes = {

}

export default ProfileWidget




