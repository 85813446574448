import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const Input = props => {
    const { name, label, value, view, onChange, placeholder, className } = props;

    return (
        <div className={`flex flex-column ${className}`}>
        {/* @TODO remove label from input. Make component FormField, Label */}
            <label className='mb1 t3' htmlFor={name}>{label}</label>
            <input
                className={`_form-field input-views--${view}`}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

Input.defaultProps = {
    view: 'default'
}
export default Input;
