import React, { Component } from "react"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import { Switch, Route, Link, withRouter } from "react-router-dom"
import ArticleList from "./ArticleList"
import Article from "./_Article"
import * as ArticleAPI from "../../api/ArticleAPI"
import ArticlePrewiew from "./ArticlePreview"

class CategoryView extends Component {
  constructor(props) {
    super(props)
    this.state = {
        categories: props.categories || [],
        articles: [],
        activeIndex: 0,
        category: {
          title: "",
          html: "<p>Empty</p>"
        }
    }
  }
   handleEditArticles = (articles) => {
    this.setState(prevState => ({
      articles
    }))
  }
    componentWillReceiveProps(nextProps){
      const { match } = nextProps
      const { categories, selectedCategory } = nextProps
      const category = categories.find((category) => match.params.category == category.title) || selectedCategory
     
      if(category){
      ArticleAPI.getArticles((articles) => {
        this.setState({
          articles: articles.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)),
          category
        })
      },
      {parent: category.id})
    }
    }
    componentDidMount(){
      const { match } = this.props
      const { categories, selectedCategory } = this.props
      const category = categories.find((category) => match.params.category == category.title) || selectedCategory

      if(category){
      ArticleAPI.getArticles((articles) => {
        this.setState({
          articles: articles.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)),
          category
        })
      },
      {parent: category.id})
    }
  }
    selectArticle = articleIndex => (e) => {
      // e.preventDefault()
      this.setState({
        activeIndex: articleIndex,
      })
    }
    render() {
    const { state, props } = this
    const { categories, articles, activeIndex, category } = state
    const { t, user, results, keywords } = props

    const article = articles[activeIndex] || {};

    return (    
      <div className='category-view'>
      <div className="help-center__row" style={{
        alignItems: 'normal'
      }}>
      <div className="help-center__50-container" style={{
        borderRight: '1px solid rgb(247, 148, 21)'
      }} > 
      <ArticleList  handleEditArticles={this.handleEditArticles} articles={this.state.articles} selectArticle={this.selectArticle} category={category}/>
      </div>
      <div className="help-center__50-container">
      <Article title={article.title} html={article.html} />
      </div> 
      </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(trans(connect(mapStateToProps, mapDispatchToProps)(CategoryView)))