import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/Icon';

const Toggler = props => {
    const { children, isActive } = props;
    return (
        <div className='toggler flex'>
            <p>{children}</p>
            <Icon name={ isActive ? 'normal-arrow-down' : 'normal-arrow-up'} />
        </div>
    )
}

Toggler.propTypes = {

}

export default Toggler
