import React from 'react';
import PropTypes from 'prop-types';
import ProfileSectionHead from '../ProfileSectionHead';
import Checkbox from 'components/Checkbox';

const ProfileSetting = props => {
    return (
        <div className='profile-settings p4 bg-white'>
            <ProfileSectionHead 
                title='Настройки' 
                description=' Тут вы сможете что-то настроить. А может быть и нет' 
            />
            <h5 className="bold mb3">Уведомления</h5>
            <Checkbox className='mb2' label='Получать уведомления от стримеров'/>
            <Checkbox className='mb2' label='Показывать в видео аннотации и уведомления'/>
            <h5 className="bold mb3">Конфиденциальность</h5>
            <Checkbox className='mb2' label='Получать уведомления от стримеров'/>
            <Checkbox className='mb2' label='Показывать в видео аннотации и уведомления'/>
            <h5 className="bold mb3">Уведомления</h5>
            <Checkbox className='mb2' label='Получать уведомления от стримеров'/>
            <Checkbox className='mb2' label='Показывать в видео аннотации и уведомления'/>
            <h5 className="bold mb3">Конфиденциальность</h5>
            <Checkbox className='mb2' label='Получать уведомления от стримеров'/>
            <Checkbox className='mb2' label='Показывать в видео аннотации и уведомления'/>
        </div>
    )
}

ProfileSetting.propTypes = {

}

export default ProfileSetting
