import React, { Fragment } from "react"
import { trans } from "utils/translator"
import FormValidator from "components/Common/FormValidator"

export default trans(class PaidActionTemplate extends FormValidator {
  constructor(props){
    super(props)
    const { paidAction: {id, description, price, acceptDuration, type="ONETIME", status, currency }, create } = props
    const stream = props.stream
    this.state = {
          form: { 
            id: { value: id },
            description: { value: description },
            price: { value: price },
            acceptDuration: { value: acceptDuration },
            stream: { value: stream },
            type: { value: type },
            currency: { value: currency }
          },
          isActivated: status === 'ACTIVE',
          isCreateMode: create,
    }
  }
  componentDidMount(){
  }
  edit = (e) => {
    const { props: { PaidActionTemplateActions: { patch }, paidAction: { id }, paidAction } } = this
    const { description, currency = 'ORBS', type, price } = this.getFormData()
    const paidActionPrms = { description, currency, type, price }
    const paidActionOldPrms  = { description: paidAction.description, currency: paidAction.currency, type: paidAction.type, price: paidAction.price}
    e.preventDefault()
    if(JSON.stringify(paidActionOldPrms)!=JSON.stringify(paidActionPrms)) patch(id, paidActionPrms, (res) => {
    
    })
    else return 
  }
  handleUse = () => {
    const { props: { createPaidAction } } = this
    const { acceptDuration, ...paidAction } = this.getFormData()
    e.preventDefault()
    createPaidAction({...paidAction, acceptDuration: acceptDuration * 60 })
  }
  handleRemove = () => {
    const { props: { paidAction: { id } }, props: { PaidActionTemplateActions: { remove } } } = this
    remove(id)
  }

  handleCreate =  e => {
    const { props:  { PaidActionTemplateActions: { create } } } = this
    const { acceptDuration, ...paidAction } = this.getFormData()
    e.preventDefault()
    create({ ...paidAction, acceptDuration: acceptDuration * 60 },
    (paidAction) => console.log(paidAction))
  }

  activate = (e) => {
    const { props: {  PaidActionTemplateActions: { patch }, paidAction: { id } } } = this
    e.preventDefault(e)
    const data = this.getFormData()
    patch(id, {...data, status: 'ACTIVE' })
  }

  deactivate = (e) => {
    const { props: { PaidActionTemplateActions: { patch }, paidAction: { id } } } = this
    e.preventDefault()
    patch(id, { status: 'DISABLE' })
  }

  switchType = (e) => {
    const { state: { form: { type: { value } } } } = this
    const newMode = value == 'ONETIME' ? 'REPETABLE' : 'ONETIME'
    e.preventDefault()
    this.setFieldData('type', newMode)
  }

  selectCurrency = currency => e => {
    this.setFieldData('currency', currency)
  }
  render(){
  const { props: { t, create, paidAction: { status } }, state: { isActivated, isCreateMode }, activate, deactivate, selectCurrency, switchType, handleRemove, edit } = this
  const { currency, type } = this.getFormData()
  return (
    <form onMouseLeave={edit} className="adm-new-control-card adm-new-paid-action adm-new-paid-action-state4">
          <div className="adm-new-control-card__header">
            <div className="adm-new-control-card__option adm-new-paid-action__option-group">
              <div className="adm-new-control-card__input-group adm-new-paid-action__input-group">

              <i className={`adm-new-paid-action__input-cube ${currency !== 'CUBES' ? 'greyscale' : ''}`} onClick={selectCurrency('CUBES')} />
              <i className={`adm-new-paid-action__input-orb  ${currency !== 'ORBS' ? 'greyscale' : ''}`} onClick={selectCurrency('ORBS')} />
              <input className="adm-new-control-card__input" 
              { ...this.bindInput('price', 'number') }
              />
               <a onClick={switchType}>{type == 'ONETIME' ? t('admin_onetime') : t('admin_repetable')}</a>
              </div>
            </div>
            { isCreateMode ? '' : <div className="adm-new-control-card__close purecss-close" onClick={handleRemove}/> }
          </div>
          <div className="adm-new-control-card__content">
            <textarea
            className="adm-new-control-card__description"
            rows="3"
            { ...this.bindInput('description', 'text') }
            >
            </textarea>
          </div>
          <div className="adm-new-control-card__actions">
            {
              create ?
              <button 
              className="adm-new-control-card__btn adm-new-control-card__btn--accent"
              onClick={this.handleCreate}
              >{t('admin_create')}</button>
              :
              <button 
              className="adm-new-control-card__btn adm-new-control-card__btn--accent"
              onClick={ status === 'ACTIVE' ? deactivate : activate}
              >{ status === 'ACTIVE' ? t('admin_deactivate') : t('admin_activate') }</button>
            }
          </div>
        </form>
  )
      }
}
)