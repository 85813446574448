import React, { Component } from "react"

// import ReactPlayer from "react-player"
import Feedback from "components/Feedback"
import ChallengeCreate from "components/Feedback/ChallengeCreate"
import MessageCreate from "components/Feedback/MessageCreate"
import PredictionsList from "components/PredictionsList"
import WidgetBot from "@widgetbot/react-embed"
import {trans} from "utils/translator"
import Dropdown  from "components/Dropdown"
import MediaQuery from 'react-responsive'
import * as StreamActions from "actions/StreamActions"
import MobileStreamSlider from "components/StreamListSlider/MobileStreamSlider"
import MessageModal from "components/Feedback/MessageCreateModal"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {Carousel} from "react-materialize"

import * as PredictionsActions from "actions/PredictionsActions"
import * as ChallengeActions from "actions/ChallengeActions"
import * as VotingActions from "actions/VotingActions"
import * as PaidActionActions from "actions/PaidActionActions"
import * as PaidActionTemplateActions from "actions/PaidActionTemplateActions"

class Stream extends Component {
  constructor(props) {
    super(props)
    //@refactoring 
    // state chaotic, make tabs. activeTab = |chat|activities|feedback ...
    this.state = {
      isOpenChat: false,
      isOpenActivities: true, 
      isOpenFeedback: false,
      isOpenChallenge: false,
      isOpenMessage: false,
      chatTab: 'TWITCH', 
      streamInfo: false,
      selectedOption: 'All',
      filter: '',
      isMessageModalOpen: false,
      isActivityPinned: false,
    }
    this.handleChat = this.handleChat.bind(this)
    this.handleFeedback = this.handleFeedback.bind(this)
    this.handleChallenge = this.handleChallenge.bind(this)
    this.handleChatTabSwitch = this.handleChatTabSwitch.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleAcivities = this.handleAcivities.bind(this)
    this.handleCloseChallenge = this.handleCloseChallenge.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleCloseMessage = this.handleCloseMessage.bind(this)
    }

 

  handleOptionChange = (option) => () => {
    this.setState({
      selectedOption: option,
    }, () => {
      // this.refs.dropdown.hide()
      gtag("event", "activities_list", { 
        event_category: 'activity_interraction',
        event_action: 'activities_switched',
        option
      })  
      let filter = 'CHALLENGES' 
      // this is stupid 
      switch(option){
        case 'All': filter = ''
        break
        case 'Challenges': filter = 'CHALLENGES'
        break
        case 'Actions': filter = 'PAID_ACTIONS'
        break
        case 'Votings': filter = 'VOTINGS'
        break
        case 'Predictions': filter = 'PREDICTIONS'
        break
        default: filter = 'CHALLENGES'
      }
      this.setState({
        filter: filter
      })
    })
    
  }
  componentDidMount(){
    
    if(!this.props.stream) return
    const { stream } = this.props
    this.props.StreamActions.getById(this.props.stream.id, (err, res) => {
    //   const { Predictions, Votings, PaidActions, PaidActionTemplates } = res
    //   if(
    //      Challenges.length
    //   || Predictions.length
    //   || Votings.length
    //   || PaidActions.length
    //   || PaidActionTemplates.length
    //   ){
    //   this.setState({
    //     isOpenChat: false,
    //     isOpenActivities: true,
    //   })
    // }
    }) 
    
    this.initSocket(stream)
  }
  initSocket = (stream) => {
    const { props: { PredictionsActions, ChallengeActions, VotingActions, PaidActionActions, PaidActionTemplateActions } } = this
    if(!stream.stream.length || !stream.id || !stream.stream || stream.stream=='Loading') return 
    PredictionsActions.updated((list) => {
    }, null, stream.stream)
    
    ChallengeActions.updated((list) => {
    }, null, stream.id)

    VotingActions.updated((list) => {
    }, null, stream.id)

    PaidActionActions.updated((list) => {
    }, null, stream.id)
    PaidActionTemplateActions.updated((list) => {
    }, null, stream.id)
  }
  componentWillReceiveProps(nextProps){
    const { challenges, predictions, votings, paidActions, paidActionTemplates } = nextProps 
    if(!nextProps.stream) return 
    if(nextProps.stream && nextProps.stream.id!=this.props.stream.id)this.initSocket(nextProps.stream)
    if(!isNaN(+nextProps.stream.id)){
      const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || {}
      user.lastStreamWatched = nextProps.stream.id
      localStorage.setItem("predictoria_currentUser", JSON.stringify(user))
    }

    if(nextProps.stream.id != this.props.stream.id)
    this.props.StreamActions.getById(nextProps.stream.id, (err, res) => {
    //   const { Challenges, Predictions, Votings, PaidActions, PaidActionTemplates } = res

    //   if(
    //     Challenges.length
    //   || Predictions.length
    //   || Votings.length
    //   || PaidActions.length
    //   || PaidActionTemplates.length
    //   ){
  
    //   this.setState({
    //     isOpenChat: false,
    //     isOpenActivities: true,
    //   })
    // }

    }) 

   
 
}
  handleChat() {
    gtag("event", "chat-activities", { 
      event_category: 'webpage_interraction',
      event_action: 'tabs_switched',
    })
    this.setState(prevState => ({
      isOpenChat: !prevState.isOpenChat,
      isOpenActivities: !prevState.isOpenActivities
    }))
  }
  handleAcivities() {
    this.setState(prevState => ({
        isOpenActivities: !prevState.isOpenActivities,
      }
    ))
  }
  handleFeedback() {
    this.setState(prevState => ({
      isOpenFeedback: !prevState.isOpenFeedback,
    }))
  }

  handleChallenge() {
    gtag("event", "chat-activities", { 
      event_category: 'webpage_interraction',
      event_action: 'challenge_create_click',
    })
    this.setState(prevState => ({
      isOpenChallenge: true,
      isOpenChat: false,
      isOpenActivities: true,
    }))
  }
// че за наркомания в этом компоненте, пздц????
  handleMessage() {
    // this.setState(prevState => ({
    //   isOpenMessage: true,
    //   isOpenChat: false,
    //   isOpenActivities: true,
    // }))
    this.handleMessageClick()
  }
  handleCloseMessage() {
    this.setState(prevState => ({
      isOpenMessage: false,
    }))
  }
  handleCloseChallenge() {
    gtag("event", "chat-activities", { 
      event_category: 'webpage_interraction',
      event_action: 'challenge_create_close_click',
    })
    this.setState(prevState => ({
      isOpenChallenge: false,
    }))
  }
  handleChatTabSwitch(tab){
 
    this.setState({chatTab: tab}, () => {
      // tab === 'TWITCH' ?  this.refs.twitch_button.className='chat__twitch-tab chat__twitch-tab_active' :
      // this.refs.discord_button.className='chat__discord-tab chat__discord-tab_active'
    })
  }
  handleMessageClick = () => {
    this.setState(prevState => ({
      isMessageModalOpen: !prevState.isMessageModalOpen
    }))
  }
  pinActivities = () => {
    var streamContainer = document.getElementById('test')
    streamContainer.onwebkitfullscreenchange = this.handleEsc
    streamContainer.onmozfullscreenchange = this.handleEsc
    streamContainer.onfullscreenchange = this.handleEsc
    this.setState({
      isActivityPinned: true,
    }, () => {
      if(streamContainer.requestFullScreen){
        streamContainer.requestFullScreen()
      }
      if(streamContainer.webkitRequestFullscreen){
      streamContainer.webkitRequestFullscreen()
      }
      else if(streamContainer.mozRequestFullScreen){
      streamContainer.mozRequestFullScreen()
      }
    })
  }
  // toggleOverlay = () => {
  //   this.setState(prevState => ({
  //     isOverlayOn: !prevState.isOverlayOn
  //   }))
  // }
  handleEsc = () =>  {
    if(!document.webkitFullscreenElement && !document.mozFullscreenElement && !document.fullscreenElement)
      this.setState({
        isActivityPinned: false,
      })
  }
  exitFullscr = () =>  {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { 
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { 
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
      this.setState({
        isActivityPinned: false,
      })
  }
  render() {
    const { props, state } = this
    let { stream, StreamActions, t } = props
    const { isActivityPinned } = state

    if (!stream) stream = StreamActions.defaultStream()
    const { type } = stream

    const videoID = StreamActions.streamID(type, stream.stream)
    const streamID = stream.id
  
    const streamURL = StreamActions.streamUrl(type, videoID)
    const chatURL = StreamActions.streamChat(type, videoID)
    const activities = ['All', 'Challenges', 'Actions', 'Votings', 'Predictions']
    const options = ['All', 'Challenges', 'Actions', 'Votings', 'Predictions'].map( option => <div className='activities__option' onClick={this.handleOptionChange(option)}>{option}</div>)
    const dropdown = <div className='activities__trigger'>{this.state.selectedOption}</div>
    return (
      <div>
        <div className="prediction">
          <div className="prediction-stream" >
         
            <div className="predictionColStream">
              <div className="predictionTwitchStream">
              {
                    this.state.isMessageModalOpen ?  
                    <div onClick={this.handleMessageClick} className='message-modal__wrapper'><MessageModal streamID={stream.id} stream={stream} handleCloseMessage={this.handleCloseMessage}/></div>
                    :
                    null
                  }
              <MediaQuery query="(min-device-width: 480px)">
              {
                isNaN(+stream.id) ? null :  <div id="test"  style={{display: "flex",
                 width: "100%",
                 position: 'relative'}}>
                 <div className="overlay-trigger" style={{
                   visibility: isActivityPinned ? 'hidden': 'visible'
                 }} onClick={this.pinActivities}>Overlay ON</div>
                {
                  isActivityPinned ?
                <div className="stream-overlay" tabIndex="0" onKeyPressed={this.handleEsc}>
                <div className="overlay-trigger-overlay" onClick={this.exitFullscr} />
                <div className="overlay-activities-container">
                   {this.state.isOpenChat ? (
             
             <div className="chat"> 
             <MediaQuery query="(min-device-width: 480px)">
             <div className={`chat__tabs ${this.state.chatTab}`}>
            <div className={`chat__${type}-tab ${this.state.chatTab === 'TWITCH' ? 'active' : '' }`} ref={'twitch_button'} onClick={() => this.handleChatTabSwitch('TWITCH')}></div>
           <div className={`chat__activities-tab ${this.state.chatTab}`} onClick={this.handleChat}><div className='chat__activities-tab-icon' />{t('global_activities_button')}</div>
           <div className={`chat__discord-tab ${this.state.chatTab === 'DISCORD' ? 'active' : '' }`} ref={'discord_button'} onClick={() => this.handleChatTabSwitch('DISCORD')}></div>
            </div>
            </MediaQuery>
            <MediaQuery query="(max-device-width: 480px)">
             <div className={`chat__tabs ${this.state.chatTab}`}>
             <div className='chat__main-tab'>
             <div className='chat__activities-tab' onClick={this.handleChat}><div className='chat__activities-tab-icon' /></div>
             <div className='chat__tab-name'>{this.state.isOpenChat? 'Chat' : 'Activities'}</div>
             <div className='chat__chat-tab'>{t('global_chat_button')}</div>
             </div>
             <div className='chat__sub-tab'>
             <div className={`chat__discord-tab`} onClick={() => this.handleChatTabSwitch('DISCORD')}>Discord</div>
             <div className={`chat__${type}-tab`} onClick={() => this.handleChatTabSwitch('TWITCH')}>Twitch</div>
            </div>
            </div>
            </MediaQuery>
             {this.state.chatTab === 'TWITCH' ?  
               <iframe
               className='chat__twitch'
               title={`${stream.game} For Fun`}
               src={chatURL}
               frameBorder="0"
               scrolling="no"
               height="560px"
               width="350px"
             /> :
             <iframe 
             src="https://titanembeds.com/embed/430996872719761409?defaultchannel=436093885899341824&theme=DiscordDark" 
             height="560px" 
             width="350px" 
             frameborder="0">
             </iframe>
               }                  
     </div>) : (
        <div className="predictionColPred" id="predictionColPred">
        <div className="predictionMainWindowWrapper">
        <div className="activities">
        <MediaQuery query="(min-device-width: 480px)">
        <div className="activities__tabs">
           {/* <div className='activities__dropdown'>
           <Dropdown ref="dropdown" component={dropdown}>
           {options}
           </Dropdown>
           </div> */}
           <div className="activities__chat-tab" onClick={this.handleChat}>{t('global_chat_button')}</div>
         
           {/* <div className="activities__create" onClick={this.handleChallenge}>+</div> */}
            </div>
           <div className='activities__selector'>
          {
            activities.map(a => {
              return <div className={`activities__selector-btn ${this.state.selectedOption == a && 'activities__selector-btn_selected'}`}  onClick={this.handleOptionChange(a)}>{a}</div>
            })
          } 
           
           </div>
            </MediaQuery>
        <MediaQuery query="(max-device-width: 480px)">
            <div className="activities__tabs">
           <div className='activities__activities-tab'><div className='chat__activities-tab-icon' /></div>
           <div className="activities__tab-name">{this.state.isOpenChat? 'Chat' : 'Activities'}</div>
           <div className="activities__chat-tab" onClick={this.handleChat}>{t('global_chat_button')}</div>
            </div>
            </MediaQuery>
            
          <PredictionsList filter={this.state.filter}  stream={stream} streamInfo={this.state.streamInfo} handleChallenge={this.handleChallenge} />
        
          {this.state.isOpenFeedback ?
            <Feedback /> : null}
          {this.state.isOpenChallenge ?
            <ChallengeCreate streamID={stream.id} stream={stream} handleCloseChallenge={this.handleCloseChallenge} /> : null}
            {this.state.isOpenMessage ?
            <MessageCreate streamID={stream.id} stream={stream} handleCloseMessage={this.handleCloseMessage}/> : null}
        </div>
      </div>
      </div>
     )
     }  
     </div>
     </div> : null
                }
               
                  <iframe title="Stream For Fun" height={isActivityPinned ? "100%" : "440px"} width="100%" src={streamURL} frameBorder="0" allowFullScreen="true" allow="autoplay; encrypted-media" scrolling="no" />
                  </div>
              }
              </MediaQuery>
              <MediaQuery query="(max-device-width: 480px)">
              <MobileStreamSlider {...this.props} streamlist={this.props.streamlist}/>
              </MediaQuery>
           
              </div>
              {
                 isNaN(+stream.id) ? null :
              <div className='stream__bottom'>
                <div className="stream__left">
            
                <h3 className="stream__header">{`${this.props.stream.stream.toUpperCase()}`}
                {
                  this.props.stream.game ? 
                    <span className="stream__header"> {`${t('stream_playing')} ${this.props.stream.game}`}</span> 
                    : null
                }
                </h3>   
                <h3 className="stream__header">{this.props.stream.title}</h3>             
                <br />
                {
                true ? 
                <h5 className="stream__subheader">{t('stream_streamer_can')}</h5>
                : 
                null
                }
                </div>
                <div className="stream__right">
                {/* <button 
                  className="pre-btn pre-btn_blue stream__overlay-on"
                 onClick={this.toggleOverlay}>{t('stream_overlay_on')}</button> */}
                {/* <a href='mailto:support@predictoria.com' target="_blank" className="stream__report">{t('stream_report')}</a> */}
                <a href="#predictionColPred">
                <button className="pre-btn pre-btn_gold stream__create-challenge" onClick={this.handleChallenge}>{t('stream_create_challenge')}</button>
               </a>
               {
                 this.props.stream.allowDonations ?
               <a href="#predictionColPred">
                <button className="pre-btn stream__create-challenge stream__create-challenge_send" onClick={this.handleMessage}>{t('stream_send_message')}</button>
               </a>
               : 
               null
               }
                </div>
              </div>
              }
            </div>
            { isActivityPinned ? null :
            <div>
            {this.state.isOpenChat ? (
             
                    <div className="chat"> 
                    <MediaQuery query="(min-device-width: 480px)">
                    <div className={`chat__tabs ${this.state.chatTab}`}>
                   <div className={`chat__${type}-tab ${this.state.chatTab === 'TWITCH' ? 'active' : '' }`} ref={'twitch_button'} onClick={() => this.handleChatTabSwitch('TWITCH')}>
                   <div />
                   </div>
                  <div className={`chat__activities-tab ${this.state.chatTab}`} onClick={this.handleChat}><div className='chat__activities-tab-icon' />{t('global_activities_button')}</div>
                  <div className={`chat__discord-tab ${this.state.chatTab === 'DISCORD' ? 'active' : '' }`} ref={'discord_button'} onClick={() => this.handleChatTabSwitch('DISCORD')}>
                  <div />
                  </div>
                   </div>
                   </MediaQuery>
                   <MediaQuery query="(max-device-width: 480px)">
                    <div className={`chat__tabs ${this.state.chatTab}`}>
                    <div className='chat__main-tab'>
                    <div className='chat__activities-tab' onClick={this.handleChat}><div className='chat__activities-tab-icon' /></div>
                    <div className='chat__tab-name'>{this.state.isOpenChat? 'Chat' : 'Activities'}</div>
                    <div className='chat__chat-tab'>{t('global_chat_button')}</div>
                    </div>
                    <div className='chat__sub-tab'>
                    <div className={`chat__discord-tab`} onClick={() => this.handleChatTabSwitch('DISCORD')}>Discord</div>
                    <div className={`chat__${type}-tab`} onClick={() => this.handleChatTabSwitch('TWITCH')}>Twitch</div>
                   </div>
                   </div>
                   </MediaQuery>
                    {this.state.chatTab === 'TWITCH' ?  
                      <iframe
                      className='chat__twitch'
                      title={`${stream.game} For Fun`}
                      src={chatURL}
                      frameBorder="0"
                      scrolling="no"
                      height="560px"
                      width="350px"
                    /> :
                    <iframe 
                    src="https://titanembeds.com/embed/430996872719761409?defaultchannel=436093885899341824&theme=DiscordDark" 
                    height="560px" 
                    width="350px" 
                    frameborder="0">
                    </iframe>
                      }                  
            </div>) : (
               <div className="predictionColPred" id="predictionColPred">
               <div className="predictionMainWindowWrapper">
               <div className="activities">
               <MediaQuery query="(min-device-width: 480px)">
               <div className="activities__tabs">
                  {/* <div className='activities__dropdown'>
                  <Dropdown ref="dropdown" component={dropdown}>
                  {options}
                  </Dropdown>
                  </div> */}
                  <div className="activities__chat-tab" onClick={this.handleChat}>{t('global_chat_button')}</div>
                
                  {/* <div className="activities__create" onClick={this.handleChallenge}>+</div> */}
                   </div>
                  <div className='activities__selector'>
                 {
                   activities.map(a => {
                     return <div className={`activities__selector-btn ${this.state.selectedOption == a && 'activities__selector-btn_selected'}`}  onClick={this.handleOptionChange(a)}>{a}</div>
                   })
                 } 
                  
                  </div>
                   </MediaQuery>
               <MediaQuery query="(max-device-width: 480px)">
                   <div className="activities__tabs">
                  <div className='activities__activities-tab'><div className='chat__activities-tab-icon' /></div>
                  <div className="activities__tab-name">{this.state.isOpenChat? 'Chat' : 'Activities'}</div>
                  <div className="activities__chat-tab" onClick={this.handleChat}>{t('global_chat_button')}</div>
                   </div>
                   </MediaQuery>
                   
                 <PredictionsList filter={this.state.filter}  stream={stream} streamInfo={this.state.streamInfo} handleChallenge={this.handleChallenge} />
               
                 {this.state.isOpenFeedback ?
                   <Feedback /> : null}
                 {this.state.isOpenChallenge ?
                   <ChallengeCreate streamID={stream.id} stream={stream} handleCloseChallenge={this.handleCloseChallenge} /> : null}
                   {this.state.isOpenMessage ?
                   <MessageCreate streamID={stream.id} stream={stream} handleCloseMessage={this.handleCloseMessage}/> : null}
               </div>
             </div>
             </div>
            )
            }   
          
          </div>
            }
        </div>
        {/* <iframe src="https://widgetbot.io/channels/430996872719761409/433967224630280202/" width="100%" height="500" allowtransparency="true" frameBorder="0"></iframe> */}
       
      </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    predictions: state.predictions.predictions,
    challenges: state.challenges.challenges,
    votings: state.votings.votings,
    paidActions: state.paidActions.paidActions,
    paidActionTemplates: state.templatePaidAction,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch),
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    ChallengeActions: bindActionCreators(ChallengeActions, dispatch),
    VotingActions: bindActionCreators(VotingActions, dispatch),
    PaidActionActions: bindActionCreators(PaidActionActions, dispatch),
    PaidActionTemplateActions: bindActionCreators(PaidActionTemplateActions, dispatch), 
  }
}


export default trans(connect(mapStateToProps, mapDispatchToProps)(Stream))
