
import React, { Component } from "react"
import AuthForm from "components/AuthForm"
import * as modalActions from "actions/ModalActions"
import Container from "utils/Container"
import {trans} from "utils/translator"

const ChallengeAuth = ({ modalActions, user, handleChallenge, t }) => (
  <button
    onClick={
      user.isAuthenticated ?
        handleChallenge
        :
        () => modalActions.toggleAuthModal(true, { activeTab: AuthForm.SIGNUP_TAB })}
    className="predictionContainer predictionContainerBlock btn-blue"
    style={{ width: "calc(100% - 40px)", cursor: "pointer", backgroundColor: "#2193fb" }}
  >
  {t('global_create_challenge')}
  </button>
)

ChallengeAuth.mapDispatchToProps = () => ({
  modalActions
})

ChallengeAuth.mapStateToProps = state => ({
  modals: state.modals,
  user: state.user
})

export default trans(Container(ChallengeAuth))
