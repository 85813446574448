import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import { HashLink as Link } from 'react-router-hash-link';
import Slider from "react-slick"
import { groupBy, chain, toPairs, value } from "lodash"
import {trans} from "utils/translator"
import MediaQuery from 'react-responsive'

export default trans(class StreamList extends Component {
  constructor(props) {
    super(props)
  }
 
  renderLinks(data) {
    let result = Array()

    const { props } = this
    const { featured, limit, StreamActions } = props
    let  {predictions } = props
    
    predictions = predictions || [];

    if (featured) {
      data = data.filter(s => s.featured)
    }

    if (limit != null) {
      data = data.slice(0, limit)
    }
    
    if(!data.length) return ''
    result = data.map((item, key) => (
      <Link className="streamItem" to={`/stream/${item.stream.trim().replace(/ /g, "_")}#watch`} key={key} activeClassName="activestreamItemList">
       { item.spectators ? <img className="streamItem-img" src={item.image} alt={item.game} /> : null }
        
        <div className="streamItem-overlay" style={{
          backgroundImage: `url(${item.image})`,
          backgroundSize: '100% 100%',
          }} >
          </div>
        <span className="streamItem-amount">
        {`${item.stream.trim().trim()} `} 
         { predictions.filter(p => p.stream == item.stream).length ? `${predictions.filter(p => p.stream == item.stream).length} prediction` : '' }
         {predictions.filter(p => p.stream == item.stream).length > 1 ? 's': ''}</span>
        {item.spectators > 0 ? <span className="streamItem-live">LIVE</span> : null }
      </Link>
    ))

    return result
  }

  render() {
    const streamlist = this.props.streamlist || []
    const onlineStreams = streamlist.filter(stream => stream.spectators) || []
    const offlineStreams = streamlist.filter(stream => !stream.spectators) || []
    const links = this.renderLinks(onlineStreams.concat(offlineStreams))
    const {t}  = this.props
    return (
      <div className="streamList">
       <MediaQuery query="(min-device-width: 480px)">
        {  this.props.view==='ALL' ? null :
         <p className="predictionParagraph featured"> {t('main_featured_header')}</p>
        }
         {links}
         </MediaQuery>
         <MediaQuery query="(max-device-width: 480px)">
         <div className='streamlist__header'>
         {
          this.props.view==='ALL' ? null : 
         <span className="predictionParagraph featured">{t('main_featured_header')}</span>
         }
         {
          this.props.view==='ALL' ? null :
        <Link className='streamlist__see-all' to='/streams'>See all</Link>
        }
        </div>
        {links.slice(0, this.props.slice)}
        </MediaQuery>
       
      </div>
    )
  }
}
)