import React from "react"
import { trans } from "utils/translator"
import Timer from "../AdminStreamsPage/AdminPrediction/AdminPredictionTimerComponent"
import { groupBy, chain, toPairs, value } from "lodash"
import {history} from "libs/router"

export default trans(class PaidAction extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      showPlaceBet: false
    }
    // this.handlePredictionBetPlace = this.handlePredictionBetPlace.bind(this)
    this.handleDoItClick= this.handleDoItClick.bind(this)
    
  }
  notificClick(title, message, status, buttons=[]) {
    const { notify } = this.props
    notify({
      title: title || "Welcome",
      message: message || "you ",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000,
      buttons
    })
  }
  handleDoItClick = () => {
    const { toggleQuickDonationModal, user, paidAction } = this.props;

    if(!user.isAuthenticated && paidAction.currency === 'ORBS'){
      toggleQuickDonationModal({
        donationValue: paidAction.price,
        action: 'donate_to_paidaction', 
        params: `${paidAction.id}`
      });
      return;
    }

    this.props.PaidActionActions.create(this.props.paidAction.id, {displayName: 'omnomnimus'}, (error, paidAction) => {
      if(paidAction && paidAction.id) {
      
        gtag("event", "purchase", {
          transaction_id: `${paidAction.id}`,
          affiliation: paidAction.stream,
          value: paidAction.price,
          price: paidAction.price,
          currency: "USD",
          items: [
            {
              id: `${paidAction.id}`,
              name: paidAction.description,
              quantity: 1,
              price: paidAction.price,
              value: paidAction.price,
            }
          ]
        })
      this.setState({
        showPlaceBet: false
      })
      this.notificClick('Success', 'Your request have been sent to streamer')
      this.props.UserActions.updateUserBalance()
      }
      else {
        gtag("event", "paidaction", { 
          event_category: 'activity_interraction',
          event_action: 'error',
          msg: error,
        })
        this.notificClick('Error', error, "error", [{
          name: 'Пополнить баланс',
          primary:true,
          onClick: () => {
            history.push('/buy')
          }
        }])
      }
    })
  }
 handleDoneClick = () => {
  const { paidAction } = this.props
  gtag("event", "paidaction", { 
    event_category: 'activity_interraction',
    event_action: 'done_click',
    paidAction,
  })
  this.props.PaidActionActions.patchUser(this.props.paidAction.id, {
    status: 'CONFIRMED',
  } )
 }
 handleFailClick = () => {
  const { paidAction } = this.props
  gtag("event", "paidaction", { 
    event_category: 'activity_interraction',
    event_action: 'fail_click',
    paidAction,
  })
  this.props.PaidActionActions.patchUser(this.props.paidAction.id, {
    status: 'FAIL',
  } )
 }
  render(){
  const {t, paidAction} = this.props
//   const banks = groupBy(paidAction.Votes, "option");
  return (
    <div className='activity'>
        <div className='activity__header'>
            <span className='activity__label activity__label_underline-cyan'>Paid action</span>
            <div className='activity__price'>
            <span>{paidAction.price}</span>
            {
                    paidAction.currency === 'CUBES' ? <div className='activity__cube' /> : <div className='activity__orb' />
            }  
            </div>
        </div>
        <div className='activity__underline' />
          <div className='activity__description'>
          {paidAction.description}
          </div>
          {
            paidAction.status === 'DONE' ? 
            <div className='activity_admin__operator-actions'>
            <div className='activity-create__row'>
            <div className='activity__button activity__button_cyan'
            onClick={this.handleDoneClick}>
            Done
            </div>
            <div className='activity__button activity__button_cyan'
            onClick={this.handleFailClick}>
            Fail
            </div>
            </div>
            </div>
            : 
            <div className='activity_create__row'>
            <div className='activity__option'>
                <div className='activity__button_inline activity__button_cyan'
                onClick={this.handleDoItClick}>
                DO IT</div>
                <div className='activity__bank_inline'>{paidAction.price}
                {
                      paidAction.currency === 'CUBES' ? <div className='activity__cube' /> : <div className='activity__orb' />
                }  
                </div>
                </div>
            </div>
          }
    </div>
  )
      }
}
)