import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/ds/Molecules/Tabs';
import StreamCard from './StreamCard';
import Input from 'components/Input';
import { trans } from 'utils/translator';
import { DESKTOP_MIN_RESOLUTION } from 'utils/config';
import { useMediaQuery } from 'react-responsive';

const Streams = props => {
    const { streams, t } = props;
    const [tab, setTab] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: DESKTOP_MIN_RESOLUTION });

    if (!Array.isArray(streams)) {
        return ''
    }

    return (
        <div className='streams relative bg-white black p4'>
                <Tabs
                    onSelect={setTab}
                    selected={tab}
                    rightAddon={ isDesktopOrLaptop ? <Input 
                        value={searchQuery} 
                        onChange={setSearchQuery}
                        view='pink'
                        placeholder={t('_streams_search_1')}
                        className='streams__search'
                        /> : ''}
                    >
                    <Tabs.Tab label={t('_streams_tabs_1')}>
                        <div className="streams__main mt4">
                            {
                                streams
                                .filter(stream => stream.stream.includes(searchQuery))
                                .map((stream, index) => <StreamCard stream={stream} key={index} />)
                            }
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab label={t('_streams_tabs_2')}>
                        <div className="streams__main">
                            {
                                streams
                                .filter(stream => stream.online)
                                .filter(stream => stream.stream.includes(searchQuery))
                                .map((stream, index) => <StreamCard stream={stream} key={index} />)
                            }
                        </div>
                    </Tabs.Tab>
                    {
                        isDesktopOrLaptop ?   <Tabs.Tab label={t('_streams_tabs_3')}>
                        <div className="streams__main">
                            {
                                streams
                                .filter(stream => stream.stream.includes(searchQuery))
                                .map((stream, index) => <StreamCard stream={stream} key={index} />)
                            }
                        </div>
                    </Tabs.Tab> : null
                    }
                  
                </Tabs>
               
            </div>
    )
}

Streams.propTypes = {

}

export default trans(Streams);
