import React from 'react'
import PropTypes from 'prop-types'

const Leaderboard = props => {
    return (
        <table className='leaderboard p2'>
            <th className='bold'>
                <td>Таблица Лидеров</td>
                <td></td>
            </th>
            <tr>
               <td>Username1</td>
               <td>$123</td>
            </tr>
            <tr>
               <td>Username2</td>
               <td>$123</td>
            </tr>
            <tr>
               <td>Username3</td>
               <td>$123</td>
            </tr>
        </table>
    )
}

Leaderboard.propTypes = {

}

export default Leaderboard
