import React from 'react';
import times from 'lodash/times';
import ProfileSectionHead from '../ProfileSectionHead';


const getRow = (row) => {
    return <tr className='flex justify-around p2 border-bottom'>
            <td className='block' >Бренд-квест, успешное завершение</td>
            <td className='block'>+500</td>
            <td className='block'>10:00 05.06.07</td>
         </tr>
}

const ProfilePayments = props => {
    return (
        <div className='profile-payments p4 bg-white'>
            <ProfileSectionHead title='Твоя активность' description='Тут будет твоя активность во всех возможных видах активностей на Predictoria: От челенджей, до бренд-квестов' />
                           <table className="profile-payments__table block">
                <tr className='flex justify-around p2 bg-gray'>
                    <th className='block'>Вид активности</th>
                    <th className='block'>Количество</th>
                    <th className='block'>Время</th>
                </tr>
                {
                    times(100, () => getRow({
                        activity: 'Бренд-квест, успешное завершение', 
                        amount: '+500', 
                        time: new Date(),
                    }))
                }
            </table>
        </div>
    )
}

ProfilePayments.propTypes = {

}

export default ProfilePayments
