import * as Predictions from 'reducers/predictions'
import * as PredictionAPI from 'api/PredictionAPI'
import { notify } from 'reapop';
import { getErrNotify } from 'utils/helpers';
import { hideModal } from './ModalActions';
// export function cancel(predictionId, callback){
//   return (dispatch) => {
//     PredictionAPI.cancelPrediction(predictionId, (err, prediction) => {
//       dispatch(Predictions.update(prediction))
//     })
//   }
// }

export function set(predictions) {
  return (dispatch) => {
    dispatch(Predictions.set(predictions))
  }
}

export function update(prediction) {
  return (dispatch) => {
    dispatch(Predictions.update(prediction))
  }
}

export function get(admin) {
  return (dispatch) => {
    PredictionAPI.load((list) => {
      dispatch(Predictions.set(list))
    }, admin)
  }
}

export function updated(callback, admin, stream) {
  return (dispatch) => {
    PredictionAPI.updated((list) => {
      dispatch(Predictions.set(list))
      if (callback) callback(list)
    }, admin, stream)
  }
}

export function updatePrediction(request, callback) {
  return (dispatch) => {
    PredictionAPI.updatePrediction(request, (error, item) => {
      dispatch(Predictions.update(item))
      if (callback) callback(item)
    })
  }
}

export function approve(request, callback) {
  return (dispatch) => {
    PredictionAPI.updatePrediction(request, (error, item) => {
      dispatch(Predictions.update(item))
      if (callback) callback(item)
    })
  }
}

export function getPdone() {

  return (dispatch) => {
    PredictionAPI.getPdone((error, list) => {
      dispatch(Predictions.setPdones(list))
    })
  }
}

export function updatePdone(token, stream) {
  return (dispatch) => {
    PredictionAPI.updatePdone(token, stream, (item) => {
      dispatch(Predictions.addPdone(item))
    })
  }
}

export const create = (prediction, callback) => (dispatch) => {

  PredictionAPI.create(prediction, (error, prd) => {
    dispatch(Predictions.update(prd))

    if (callback)
      callback(null, prd)
  })
}

export const close = (predictionId, callback) => (dispatch) => {

  PredictionAPI.closePrediction(predictionId, (error, prd) => {
    dispatch(Predictions.update(prd))

    if (callback)
      callback(null, prd)
  })
}

export const cancel = (predictionId, callback) => (dispatch) => {

  PredictionAPI.cancelPrediction(predictionId, (error, prd) => {
    dispatch(Predictions.update(prd))
    if (callback)
      callback(null, prd)
  })
}

export const calculate = (predictionId, outcome, callback) => (dispatch) => {

  PredictionAPI.calculatePrediction(predictionId, outcome, (error, prd) => {
    dispatch(Predictions.update(prd))
    if (callback)
      callback(null, prd)
  })
}

/**
|--------------------------------------------------
| TODO move to challengeActions 
|--------------------------------------------------
*/




export const createChallenge = (prediction, callback) => (dispatch) => {
  PredictionAPI.createChallenge(prediction, (error, prd) => {
    if (error) {
      if (callback) {
        callback(error);
      }
      dispatch(notify(getErrNotify(error)));
      return;
    }
    if (callback){
      callback(null, prd);
    }
    dispatch(hideModal());
    dispatch(notify({
      title: 'Challenge suggested',
      status: 'success'
    }))
  })
}


export const predictionOver = (id, outcome, callback) => (dispatch) => {

  if (!confirm('Are you sure?')) {
    return
  }

  PredictionAPI.predictionOver(id, outcome, callback)
}

export const placeBet = (id, request, callback) => (dispatch) => {
  PredictionAPI.placeBet(id, request, (error, prd) => {
    if (error) {
      if (callback)
        callback(error)
      return
    }
    if (callback)
      callback(null, prd)
  })
}

export const placeBetAdmin = (id, request, callback) => (dispatch) => {
  PredictionAPI.placeBetAdmin(id, request, (error, prd) => {
    if (error) {
      if (callback)
        callback(error)
      return
    }
    if (callback)
      callback(null, prd)
  })
}

export const createInitialBets = (prd, token1, token2, callback) => (dispatch) => {

  PredictionAPI.placeBet(prd.id, { value: 500, outcome: 0 }, (error, prd) => {
    if (error) {
      if (callback)
        callback(error)
      return
    }
    if (callback)
      callback(null, prd)
  }, token1)

  setTimeout(() => {
    PredictionAPI.placeBet(prd.id, { value: 500, outcome: 1 }, (error, prd) => {
      if (error) {
        if (callback)
          callback(error)
        return
      }
      if (callback)
        callback(null, prd)
    }, token2)

  }, 500)

}