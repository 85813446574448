
export default function overlay(state = {
  data: {

  },
  errors: {}, 
  pending: false
}, action) {
  switch (action.type) {
    case 'GET_OVERLAY_SETTINGS_SUCCESS':
        action.overlay.settings.shouldDisplayActivities = action.overlay.settings.shouldDisplayActivities === 'true' ? true : false
        action.overlay.settings.shouldDisplayDonations = action.overlay.settings.shouldDisplayDonations === 'true' ? true : false
        action.overlay.settings.allowDonationsOrbs = action.overlay.settings.allowDonationsOrbs === 'true' ? true : false
        action.overlay.settings.allowDonationsCubes = action.overlay.settings.allowDonationsCubes === 'true' ? true : false
        action.overlay.settings.watchChallenges = action.overlay.settings.watchChallenges === 'true' ? true : false
        action.overlay.settings.watchPaidActions = action.overlay.settings.watchPaidActions === 'true' ? true : false
        action.overlay.settings.watchVotings = action.overlay.settings.watchVotings === 'true' ? true : false
        return { ...state, data: action.overlay } 
    case 'GET_OVERLAY_SETTINGS_ERROR':
        return state 
    case 'GET_OVERLAY_SETTINGS_PENDING': 
        return state
    case 'UPDATE_OVERLAY_SETTINGS_ERROR': 
        return state
    case 'CREATE_OVERLAY_SETTINGS_ERROR': 
        return state
    default:
      return state
  }
}