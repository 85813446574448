import { store } from "libs/redux"
import "whatwg-fetch" 

const get = async (period, request = {}, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/leaderboard/?period=${period}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const getStreamers = async (period, request = {}, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/leaderboard/streamer/challenge/?period=${period}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

export { get, getStreamers }
