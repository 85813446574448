import React, { Component } from "react"
import Container from "utils/Container"

export default (props) => {
  const { K1, K2, bets, winBtn, loseBtn, winValue } = props

  const head = (<tr>
    <th width="40%">Your Prediction</th>
    <th width="30%">Profit</th>
    <th width="30%">Coefficient</th>
                </tr>)

  const head2 = (<tr>
    <th width="42%">Prediction</th>
    <th width="58%">Coefficient</th>
                 </tr>)


  if (props.isPlaced) {
    if (props.selected == 1) {
      return (
        <div className="predictionContainerBlock__bottom">
          <table cellSpacing="0">
            <thead>
              {head}
            </thead>
            <tbody>
              <tr className="team_one prediction">
                <td>
                  <button>{winBtn}</button>
                </td>
                <td>
                  <span>{parseInt(props.winValue)}</span>
                </td>
                <td>
                  <span>{K1}</span>
                </td>
              </tr>
              
                <tr className="team_two disabled">
                  <td>
                    <button>{loseBtn}</button>
                  </td>
                  <td colSpan="2">
                    <span>{K2}</span>
                  </td>
                </tr>
                
            </tbody>
          </table>
        </div>
      )
    } 
    return (
      <div className="predictionContainerBlock__bottom">
        <table cellSpacing="0">
          <thead>
            {head}
          </thead>
          <tbody>
            <tr className="team_one disabled">
              <td>
                <button>{props.winBtn}</button>
              </td>
              <td colSpan="2">
                <span>{K1}</span>
              </td>
            </tr>
            
              <tr className="team_two prediction">
                <td>
                  <button>{loseBtn}</button>
                </td>
                <td>
                  <span>{parseInt(props.winValue)}</span>
                </td>
                <td>
                  <span>{K2}</span>
                </td>
              </tr>
                
          </tbody>
        </table>
      </div>
    )
  } 
  return (
    <div className="predictionContainerBlock__bottom">
      <table cellSpacing="0">
        <thead>
          {head2}
        </thead>
        <tbody>
          <tr className="team_one disabled">
            <td>
              <button>{props.winBtn}</button>
            </td>
            <td>
              <span>{K1}</span>
            </td>
          </tr>
          
            <tr className="team_two disabled">
              <td>
                <button>{props.loseBtn}</button>
              </td>
              <td>
                <span>{K2}</span>
              </td>
            </tr>
              
        </tbody>
      </table>
    </div>
  )
}
