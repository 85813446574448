import React from 'react'
import PropTypes from 'prop-types'
import ModalWindow from 'components/ModalWindow';
import * as Modals from 'components/Modals';

const MODAL_TYPES = {
    'auth': Modals.Auth,
    'feedback': Modals.Feedback,
    'suggest_challenge': Modals.SuggestChallenge,
    'report_streamer': Modals.ReportStreamer,
    'notify': Modals.Notify
  }

const ModalContainer = props => {
    const { modalType, modalProps, ModalActions } = props;
    const SpecifiedModal = MODAL_TYPES[modalType];

   
    return (
        <ModalWindow show={!!modalType} hideModal={ModalActions.hideModal}>
            <SpecifiedModal {...modalProps} />
        </ModalWindow>
    )
}

ModalContainer.propTypes = {

}

export default ModalContainer
