export const ActivitiesBoxWidget = (userSettings) => {

  return `<!DOCTYPE html>
    <html>
    <head>
        <link href="https://fonts.googleapis.com/css?family=Ubuntu&display=swap" rel="stylesheet">
        <script src="https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.0.1/socket.io.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
        <script type="text/javascript">
         const userSettings = ${JSON.stringify(userSettings)}
        </script>
        <script>
        // timer 
        function makeTimer(countdownTime, timerNode){
          console.log(timerNode, countdownTime)
          let timer = setInterval(function() {
            const now = new Date().getTime();
            let distance = 0; 

            if(countdownTime.to)
            distance = Math.abs(countdownTime.to - now)
            else if(countdownTime.from)
            distance = Math.abs(now - countdownTime.from)
           

          console.log(distance, 'distance')
            let hours = Math.floor(distance / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
           
            if(hours < 10) hours = "0" + hours;
            if(minutes < 10) minutes = "0" + minutes;
            if(seconds < 10) seconds = "0" + seconds;

            timerNode.innerHTML = hours + ":"
            + minutes + ":" + seconds + "";
          }, 1000);
        }
       
        </script>
            <style>
            body {
                padding: 0;
                margin: 0;
            }
            p{
                margin: 0;
            }
        
            .overlay {
                background-color: transparent;
                width: 100vw;
                height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .wrap {
              position: absolute;
              visibility: hidden;
              margin: auto;
              transform: scale(2.2, 2.2);
              box-sizing: border-box;
            }

            .window {
              overflow: hidden;
              position: relative;
              background: transparent;
            }

            #carousel {
              width: 10000px;
              position: relative;
              top: 0; left: 0;
            }

            .slide {
              cursor: pointer;
              float: left;
              display: flex;
              flex-direction: column;
              justify-content: center;
              box-sizing: border-box;
              transition: all 0.7s;
            }


            .transition {
              transition: .7s;
            }

            .overlay-card{
              color: #f7f7f7;;
              display: inline-block;
              width: 100%;
              box-sizing: border-box;
              font-family: 'Ubuntu', sans-serif;
              letter-spacing: normal!important;
              border-radius: 24px 30px 24px;
              opacity: 0.8;
        border: 2px solid black;
        width: 337px;
            }

            .overlay-card p {
                    display: inline-block;
            }

    .overlay-card-appearance--default{
      background-color: #575757;
    }

    .overlay-card-head-layout, .overlay-card-footer-layout {
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    .overlay-card-footer-layout {
      align-items: center;
    }
    .overlay-card-head-layout{
        margin-bottom: 10px;
    }
    .overlay-card-body-layout{
        min-height: 20px;
    }

    .overlay-card-header-layout{
        display: flex;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        justify-content: space-between;
    }
    
    .overlay-card-header{
      display: flex;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      height: 40px;
      border-radius: 12px 12px 0 0;
    }
    .overlay-card-header__left{
          width: 201px;
          max-height: 39px;
          background-color: #333333;
          padding: 12.3px 0;
          border-top-left-radius: 24px;
          box-sizing: border-box;
      }
  .overlay-card-header__img{
          width: 179px;
          height: 14.9px;
          margin: 0 auto;
          display: block;
      }
  .overlay-card-header__right {
          width: calc(100% - 67%);
      }

  .overlay-card-header-title{
          color: #f7f7f7;
      text-align: center;
      vertical-align: middle;
      line-height: 20px;
      display: block;
      box-sizing: border-box;
      font-weight: bold;
      padding: 9px 0 11px;
      height: 41px;
      border-top-right-radius: 24px;
      border-left: 2px solid black;
      border-bottom: 2px solid black;
      top: -2px;
      right: -2px;
      position: absolute;
      width: 137px;
  }
  .overlay-card-header-title--challenge{
      background-color: #f7b731;
  }
  .overlay-card-header-title--voting{
      background-color: #2d8660;
  }
  .overlay-card-header-title--paidAction{
      background-color: #5b9bf0;
  }
  .overlay-card-body-layout{
      padding: 8px;
  }
  .overlay-card-b-radius--default {
  }
  .overlay-card-appearance--default{
      background-color: #333333;
  }
  .overlay-card-appearance--accent{
    /* background-blend-mode: hard-light;
      background-image: linear-gradient(to top right, #fd963a 4%, #333333 47%);*/
      background-color: #333333;
  }
  .timer-text{
    min-width: 25%;
    padding: 0 10px;
  }
  .overlay-card-head-description{
    max-height: 5em;
    line-height: 1em;
    overflow: hidden;
    white-space: all;
    word-break: break-all;
    text-align: justify;
  }

  .voting .overlay-card-head-description{
    max-height: 1em;
  }

  ul, li{
      list-style: none;
  }
  ul{
    padding: 0;
    width: 100%;
  }
  li{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  /*   li:first-child{
    width: 65%;
    font-size: 2em;
    font-weight: bold;
  }*/

  .overlay-card-author--accent{
    color: #f7b731;
  }
  .glow-container{
    position: relative;
    padding: 8px;
    background-image: radial-gradient(ellipse farthest-corner at 50% 50%, rgba(247, 183, 49, 0.6) 0%, rgba(51, 51, 51, 0.1) 75%);
  }

        </style>
    </head>
    
    <body>
        <div class='overlay'>
              <div class="wrap" id='wrap'>
              <div class="window">
                <div id="carousel">
                </div>
              </div>
            </div>
        </div>
     
          <script>
            const streamId = 640
            let activities = {}
            let settings = {}
            let filteredActivities = {}
            let headers = new Headers()
            let challengeSocket = null;
            let paidActionSocket = null;
            let votingSocket = null;
            let carousel = null;
            let threshold = null;
            let slideWidth = null;
            let dragStart = null;
            let direction = 1; 
            let dragEnd = null;
            let carouselTimer = null;
            const CHALLENGE_STATES = {
              WAITING_FOR_MODERATION: 'WAITING_FOR_MODERATION',
              WAITING_FOR_ACCEPTION: 'WAITING_FOR_ACCEPTION',
              ACCEPTED: 'ACCEPTED'
            }
            const VOTING_STATES = {
              IN_PROGRESS: 'IN_PROGRESS', 
              FINISHED: 'FINISHED', 
              WAITING: 'WAITING'
            }
            headers.append('content-type', 'application/json');
              headers.append('Authorization', userSettings.token);

              function makeCard(card){
                let moneyString = '';
                let bodyString = '';
                let typeString = '';
                let cardClass = 'challenge'
                let timerString = '<div class="timer-text" id="timer' + card.id + '">00:00</div>';
                let titleAppearance = 'challenge';
                let currencyString = '<i style="background-image: url(https://predictoria.com/assets/images/main/redesign/' + (card.currency === 'ORBS' ? 'orb' : 'cube') + '.png); background-size: contain; margin-left: 8px; width: 9px; height: 9px; display: inline-block;"></i>'

                let appearance = 'default';
                let authorString = '';

                let isCreatedByStreamer = false;
                if(userSettings && userSettings.userid && card.createdBy === userSettings.userid){
                  isCreatedByStreamer = true;
                }


                if(card.type === 'Challenges') {
                  const authorStringClass = isCreatedByStreamer ? "overlay-card-author--accent" :  "";

                  moneyString = getChallengeBank(card) +' / ' + card.minBankToAccept + '<div style="margin-left: 8px; display: inline-block;"><i style="background-image: url(https://predictoria.com/assets/images/main/redesign/orb.png); background-size: contain; width: 9px; height: 9px; display: inline-block;"></i></div>';
                  authorString = '<p>by <span class="'+ authorStringClass + '">' + card.displayName + '</span></p>';
                  typeString = "Challenge";
                  if(card.status === CHALLENGE_STATES.ACCEPTED){
                    moneyString = '<p class="glow-container">'+ moneyString + '</p>'
                  }
                }
                if(card.type === 'PaidActionTemplates'){
                    moneyString = card.price + '<div style="display: inline-block;">' + currencyString + '</div>'
                    typeString = 'Paid Action';
                    timerString = '';
                    titleAppearance = 'paidAction';
                    cardClass = 'paid-action';
                }
                if(card.type === 'Votings'){
                    moneyString = ''
                    typeString = "Voting"
                    let options = prepareOptionsObj(card.options, card.optionsBank);
                   bodyString = '<ul>' + options.map((option, i) => '<li style="font-size: 14px"> <span>' + (i + 1) + '. ' + option.label + '</span><span>' + ' ' + option.bank + ' ' +currencyString + '</span></li>').join('')+ '</ul>'
                   titleAppearance = 'voting';
                   cardClass = 'voting';
                  }
                   return '<div><div class="overlay-card ' + cardClass + ' overlay-card-appearance overlay-card-appearance--' + appearance + ' overlay-card-b-radius--default"><div class="overlay-card-header-layout"><div class="overlay-card-header"><div class="overlay-card-header__left"><img class="overlay-card-header__img" src="https://predictoria.com/assets/images/predictoria-logo-new.png" alt="predictoria logo"></div><div class="overlay-card-header__right"><span class="overlay-card-header-title overlay-card-header-title--' + titleAppearance + '">' + typeString + '</span></div></div></div><div class="overlay-card-body-layout"><div class="overlay-card-head-layout"><p class="overlay-card-head-description">' + card.description + '</p><div class="timer">' + timerString + '<div class="timer__desc"></div></div></div><div class="overlay-card-body-layout">'+ bodyString + '</div><div class="overlay-card-footer-layout"><div>' + moneyString + '</div>'+ authorString + '</div></div></div></div>'
                }

           function prepareOptionsObj(options, optionsBank){
              const opts = options.map( (opt, i) => ({
                  label: opt, 
                  bank: optionsBank[i]
               }))
               return opts.sort((a,b) => (a.bank > b.bank) ? -1 : ((b.bank > a.bank) ? 1 : 0));
           }
            function getVotingTimerParams(voting){
              const { createdAt, duration } = voting
              return { to: new Date(createdAt).getTime() + duration*1000} 
            }

            function getPaidActionTimerParams(paidAction){
              return { from: new Date(paidAction.createdAt) }
            }

            function getChallengeTimerParams(challenge){
              const { status, createdAt, forAcceptionAt, acceptDuration, acceptedAt } = challenge
              if(status === 'WAITING_FOR_ACCEPTION')
                  return { 
              
                    from: new Date(forAcceptionAt).getTime(),
                    to: new Date(forAcceptionAt).getTime() + new Date(acceptDuration*1000).getTime(),
                  }
                else if(status === 'ACCEPTED')
                return {
                  from: new Date(acceptedAt).getTime(),
                }
            }
            function getChallengeBank(challenge){
              const { ChallengeDonates } = challenge
              if(!_.isArray(ChallengeDonates)) return 0; 
              return ChallengeDonates.reduce((totalBank, donate) => totalBank + donate.value, 0);
            }
            
            async function restartCarousel(){
              activities = await getStreamActivities();
              // del old carousel data
              document.querySelector('#carousel').innerHTML = ''
              window.clearInterval(carouselTimer);
              console.log(activities, 'activities');
              filteredActivities = prepareData(activities);
              carousel = null;
              threshold = null;
              slideWidth = null;
              dragStart = null;
              direction = 1; 
              dragEnd = null;

              initCarousel();
              makeSlides(filteredActivities);
            }
            function initSocket(){
              let challengeSocket = io("https://predictoria.com:2053/challenges");
              challengeSocket.on('update', function(msg) {
                  restartCarousel()
              });
              challengeSocket.on('connect', function() {
                 
                  challengeSocket.emit('join', userSettings.streamId);
              });

              let votingSocket = io("https://predictoria.com:2053/votings");
              votingSocket.on('update', function(msg) {
                restartCarousel()
              });
              votingSocket.on('connect', function() {
                console.log('voting socket')
                votingSocket.emit('join', userSettings.streamId);
              });

              let paidActionSocket = io("https://predictoria.com:2053/paidaction_template");
              paidActionSocket.on('update', function(msg) {
                restartCarousel()
              });
              paidActionSocket.on('connect', function() {
                console.log('paidaction_template socket')
                paidActionSocket.emit('join', userSettings.streamId);
              });
            }
            function makeSlides(slidesData){
                slidesData.forEach(slideData => {
                let slide = document.createElement("span");
                slide.classList.add('slide')
                slide.innerHTML = makeCard(slideData)
                slide.style.width = '360px';
                slide.style.height = '210px';
                document.querySelector('#carousel').appendChild(slide)
                if(slideData.type === "Challenges")
                makeTimer(getChallengeTimerParams(slideData), document.getElementById('timer' + slideData.id))
                if(slideData.type === "Votings")
                makeTimer(getVotingTimerParams(slideData), document.getElementById('timer' + slideData.id))
                if(slideData.type === 'PaidActionTemplate')
                makeTimer(getPaidActionTimerParams(slideData), document.getElementById('timer' + slideData.id))
              })
            }

            function getChallengeBank(challenge){
            const { ChallengeDonates } = challenge
            if(!Array.isArray(ChallengeDonates)) return 0; 
            return ChallengeDonates.reduce((totalBank, donate) => totalBank + donate.value, 0);
            }

            async function init(){
                activities = await getStreamActivities();
                settings = await getOverlaySettings();
                filteredActivities = prepareData(activities) 
                initCarousel()
                makeSlides(filteredActivities)
                applyStyling()
                initSocket()
            }

            function applyStyling(){
                const activitiesBlock = document.getElementById('wrap');
                activitiesBlock.style.width = '360px';
                activitiesBlock.style.height = '210px';
                
                activitiesBlock.style.visibility = 'visible'
            }

            function getStreamActivities(){

               return fetch('https://predictoria.com/api/stream/' + userSettings.streamId, { headers})
                .then(res => res.json())
            }

            function getOverlaySettings(){
               return fetch('https://predictoria.com/api/overlays', { headers, method: 'GET'})
               .then(res => res.json())
            }

            function createSlides(){
                let slide = document.createElement("span");
                slide.classList.add('slide')
                slide.innerHTML = makeCard({description: 'test', displayName: 'test'})
                document.querySelector('body').appendChild(slide)
            }

            function prepareData(activities){
                const userActivities = {
                    'Challenges': activities['Challenges'],
                    'Votings': activities['Votings'],
                    'PaidActionTemplates': activities['PaidActionTemplates']
                }
              
                let filteredActivities = [] 
                const possibleTypes = ['Challenges', 'PaidActionTemplates', 'Votings']
                possibleTypes.forEach(type => {
                     userActivities[type].forEach(activity => 
                     {
                        if( type === 'Challenges'
                         && (activity.status === 'ACCEPTED' || activity.status === 'WAITING_FOR_ACCEPTION')
                        || type === 'PaidActionTemplates' 
                        && ( activity.status !== 'DISABLE')
                        || type === 'Votings' 
                        && ( activity.status === "IN_PROGRESS" || activity.status === 'WAITING' ))
                        filteredActivities.push({...activity, type})
                     })
                }) 
            
          console.log(filteredActivities, 'filteredActivities')
            return  filteredActivities
            }
           
        init()
        </script>
         <script>
          function initCarousel(){
              carousel = $('#carousel'),
              threshold = 150,
              slideWidth = 360,
              dragStart, 
              direction=1, 
              dragEnd;
              carouselTimer = setInterval(() => shiftSlide(-1), 10000);
            }   
       
        function dragPos() {
          return dragEnd - dragStart;
        }

        function shiftSlide(direction) {
          if (carousel.hasClass('transition')) return;
          dragEnd = dragStart;
          $(document).off('mouseup')
          carousel.off('mousemove')
                  .addClass('transition')
                  .css('transform','translateX(' + (direction * slideWidth) + 'px)'); 

          setTimeout(function(){
            if (direction === 1) {
              $('.slide:first').before($('.slide:last'));
            } else if (direction === -1) {
              $('.slide:last').after($('.slide:first'));
            }
            carousel.removeClass('transition')
                carousel.css('transform','translateX(0px)'); 
          },700)
}
        </script>
       
    </body>
    </html>`}