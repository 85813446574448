export const userActionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST', 
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    // 
    READ_USER_REQUEST: 'READ_USER_REQUEST', 
    READ_USER_SUCCESS: 'READ_USER_SUCCESS', 
    READ_USER_FAILURE: 'READ_USER_FAILURE',
    // 
    REGISTER_REQUEST: 'REGISTER_REQUEST', 
    REGISTER_SUCCESS: 'REGISTER_SUCCESS', 
    REGISTER_FAILURE: 'REGISTER_FAILURE'
};

export const modalsActionTypes = {
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
};