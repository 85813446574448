import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import BrandQuestCard from 'components/Cards/BrandQuestCard';
import ChallengeCard from 'components/Cards/ChallengeCard';
import Tabs from 'components/ds/Molecules/Tabs';
import Button from 'components/_Button';
import Leaderboard from './Leaderboard';
import { activityTypes } from './constants';
import { trans } from 'utils/translator';
import { isActivityCardActive } from 'utils/helpers';
import { DESKTOP_MIN_RESOLUTION } from 'utils/config';
import { useMediaQuery } from 'react-responsive';

const getCardByActivityType = (activityObj, activityType) => {
    let CardComponent;
    if (activityType === activityTypes.CHALLENGE) {
        CardComponent = <ChallengeCard
            displayName={activityObj.displayName}
            status={activityObj.status}
            description={activityObj.description}
            id={activityObj.id}
        />;
    }
    else if (activityType === activityTypes.BRANDQUEST) {
        CardComponent = BrandQuestCard;
    }

    return CardComponent;
}



const StreamPage = props => {
    const streamName = get(props, 'match.params.id');
    const {
        StreamActions,
        ModalActions,
        streamList,
        challenges,
        brandquests,
        votings,
        predictions,
        paidActions,
        t
    } = props;
    let stream;
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: DESKTOP_MIN_RESOLUTION });
    if (Array.isArray(streamList)) {
        stream = streamList.find(stream => stream.stream === streamName);
    }
    //@REFACTOR
    const videoID = StreamActions.streamID('twitch', streamName);
    const streamURL = StreamActions.streamUrl('twitch', videoID);
    const chatURL = StreamActions.streamChat('twitch', videoID);

    const [activeActivity, setActiveActivity] = useState(activityTypes.CHALLENGE);

    let activityList = activeActivity === activityTypes.CHALLENGE ? challenges : brandquests;

    if (!Array.isArray(activityList)) {
        activityList = [];
    }

    useEffect(() => {
        if (!(typeof stream === 'object')) {
            console.warn('Stream not found');
            return;
        }
        StreamActions.getById(stream.id, (err, res) => {
        });
    }, [streamName, streamList]);

  

    return (
        <div className='stream-page flex px4 pb4 bg-white'>
            <div className="stream-page__main">
                <div className="stream-page__stream">
                    <iframe title="Stream For Fun" height={"440px"} width="100%" src={streamURL} frameBorder="0" allowFullScreen="true" allow="autoplay; encrypted-media" scrolling="no" />
                </div>
                <Tabs
                    onSelect={nextIndex => { }}
                    selected={0}
                    rightAddon={isDesktopOrLaptop ? <div className='flex black'>
                        <Button view='link' onClick={() => setActiveActivity('CHALLENGE')}>
                            <p className='black'>{t('_stream_page_tabs_1')}</p></Button>
                        <Button view='link' className='stream-page__activity-button disabled' onClick={() => setActiveActivity('BRANDQUEST')}>
                            <p className='black'>{t('_stream_page_tabs_2')}</p>
                        </Button>
                        <Button view='link' className='stream-page__activity-button disabled' onClick={() => setActiveActivity('BRANDQUEST')}>
                            <p className='black'>{t('_stream_page_tabs_3')}</p>
                        </Button>
                        <Button view='link' className='stream-page__activity-button disabled' onClick={() => setActiveActivity('BRANDQUEST')}>
                            <p className='black'>{t('_stream_page_tabs_4')}</p>
                        </Button> 
                    </div> : ''}
                >
                    <Tabs.Tab label={t('_stream_page_tabs_5')}>
                        <div className='stream-page__tasks mt3'>
                            {
                                activityList
                                    .filter((activity) => isActivityCardActive(activity))
                                    .map((activityObj) => getCardByActivityType(activityObj, activeActivity))
                            }
                        </div>
                    </Tabs.Tab>
                    <Tabs.Tab label={t('_stream_page_tabs_6')} isDisabled>
                        {
                            activityList
                                .filter((activity) => true)
                                .map(activityObj => getCardByActivityType(activityObj, activeActivity))
                        }
                    </Tabs.Tab>
                </Tabs>
                { !isDesktopOrLaptop ?      <Button theme='purple' className='my3 mx-auto' onClick={() => ModalActions.showModal({
                   modalType: 'suggest_challenge',
                   modalProps: {
                       stream: stream.id,
                   }
               })}>{t('_stream_page_button_1')}</Button> : null}
            </div>
            {
               isDesktopOrLaptop ?  <div className="stream-page__right ml2">
               <iframe
                   title={`Game For Fun`}
                   src={chatURL}
                   frameBorder="0"
                   scrolling="no"
                   height="560px"
                   width="350px"
               />
               <Button theme='purple' className='my3 mx-auto' onClick={() => ModalActions.showModal({
                   modalType: 'suggest_challenge',
                   modalProps: {
                       stream: stream.id,
                   }
               })}>{t('_stream_page_button_1')}</Button>
               <div className='disabled'>
                   <Leaderboard />
               </div>
               <p onClick={() => ModalActions.showModal({
                   modalType: 'report_streamer'
               })} className='red mt4 center pointer'>{t('_stream_page_button_2')}</p>
           </div> : ''
            }
            
        </div>
    )
}

StreamPage.propTypes = {

}

export default trans(StreamPage);
