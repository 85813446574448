import React from 'react'
import cn from 'classnames';
import PropTypes from 'prop-types';

const TaskCard = props => {
    const { className } = props;
    return (
        <div className={cn('profile-task-card', 'bg-gray', 'black', 'rounded', 'p2', className)}>
            TASK CARD
        </div>
    )
}

TaskCard.propTypes = {

}

export default TaskCard
