import i18next from 'i18next'
import { reactI18nextModule } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18next
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init(
    {
      fallbackLng: 'en',
      defaultNS: 'translations',
      load: 'languageOnly',
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
        defaultTransParent: 'div',
      },
      resources: {
      },
      detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: 'language',
      caches: ["localStorage"]
    },
      // parseMissingKeyHandler: () => {
      // 	return ('')
      // }
    },
    (err, t) => {
      if (err) {
        return console.log('something went wrong loading', err);
      }
    }
  );

export default i18next;

const dict = 'translation';
const t = i18next.getFixedT(null, 'translation');

export {
  dict,
  t,
};

export const languages = [
{
  code: 'en',
  fullname: 'English'
},
{
  code: 'ru',
  fullname: 'Русский'
},
{
  code: 'es',
  fullname: 'Español'
},
{
  code: 'ch', 
  fullname: '中文'
}, 
{
  code: 'kr', 
  fullname: '한국어'
}

];