import React, { Component } from "react"
import { Route, NavLink, Link } from "react-router-dom"
import Slider from "react-slick"
import MediaQuery from 'react-responsive'
import { groupBy, chain, toPairs, value } from "lodash"
import { trans } from "utils/translator"
import * as GameAPI from "api/GameAPI"



export default trans(class GamesListSlider extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      games: []
    }
  }

  parseImagesBetty(gameList) {
    return gameList.map((value) => { 
      value.image = this.pickImg(value.title) 
      return value
    })
  }

  parseImages(gameList) {
    return gameList.map((value) => { 
      if(value.game!='Other')
      value.image = this.pickImg(value.game, 380, 440)
      else value.image = "/assets/images/main/games/unknown.png";
      return value
    })
  }

  groupByGame(streamList) {
    const r = chain(streamList)
      .groupBy("game")
      .map((value, key) => ({ game: key == 'null'? 'Other' : key,
          streams: value })
          )
      // .toPairs()
      .value()
      r.sort((a, b) => {
        if(a.game==='Other') return 1
      else return -1
    })
    return r
  }

  componentDidMount(){
    GameAPI.loadGameStats((err, msg) => {
      this.setState({
        games: msg
      })
    })
  }
  failIdea() { return "../images/main/games/unknown.png" }

  pickImg(game, width, height) {
   return `https://static-cdn.jtvnw.net/ttv-boxart/${game}-${width}x${height}.jpg`
  }

  renderLinks(_data) {
    let result = Array()
    const {t} = this.props

    let data = this.parseImages(this.state.games.filter(g => g.game).sort((a,b) => b.length - a.length))
    if (this.props.betty) {
      data = this.parseImagesBetty(_data)
    }

    result = data.map(item => {
      return <NavLink className="streamItem"
        to={`${this.props.betty || ''}/games/${(item.game || '').trim().replace(/ /g, "_")}`}
        key={(item.game || '').trim()} activeClassName="activestreamItemList">
        
        <span className={item.image != this.failIdea() ? "hidden" : null}>{item.game}</span>  
        <img className="streamItem-img" src={item.image} alt={item.game} />
        <div className="streamItem-overlay " />
        <div className='streamItem__game-info'>
        <div className='streamItem__streamers-number'>
          {item.length || 0} {item.length > 1? t('global_streamers_are_playing') : t('global_streamer_is_playing')}
        </div>
        <div className='streamItem__title'>
          { item.title || item.game }
        </div>
        {/* <div className='streamItem__bank'>
          { item.bank || 0 }
        </div> */}
        </div>
      </NavLink>
    }
    )  
      // lol kek cheburek
    const additional = 5 - data.length > 0 ? 5 - data.length : 0
    for (let i = 0; i < additional; i++) {
      result.push(<div className="streamItem" key={`stream_empty_${i}`} />)
    }

    return result
  }

  render() {
    const streamList = this.props.streamlist || []
    const { t } = this.props

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    const links = this.renderLinks(streamList)
    return (
      <div className="topGames">
        <MediaQuery query="(min-device-width: 480px)">
        <p className="predictionParagraph">{ t('main_top_games_header') }</p>
        <div className="gamesSlider" id="gamesSlider">
          {links.length <= 5 ? <PrevArrow /> : null}
          <Slider {...settings}>
            {links}
          </Slider>
          {links.length <= 5 ? <NextArrow /> : null}
        </div>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 480px)">
        <div className='gamesSlider__header'>
        <span className="predictionParagraph">{ t('main_top_games_header') }</span>
        <Link className='gamesSlider__see-all' to='/games'>See all</Link>
        </div>
        <div className="gamesSlider__mobile">
        {links.slice(0, this.props.slice)}
        </div>
        </MediaQuery>
      </div>
    )
  }
}
)

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className || "slick-arrow slick-next slick-disabled"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="streamItem-img">
      <div className="gamesSlider__arrow-right" />
      </div>
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className || "slick-arrow slick-prev slick-disabled"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="streamItem-img">
      <div className="gamesSlider__arrow-left" />
      </div>
    </div>
  )
}
