import collectionReducers from "utils/collectionReducers"

const initialState = 'default'

const NAMESPACE = "THEME"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function applyTheme(theme) {

  return {
    namespace: NAMESPACE,
    type: "APPLY_THEME",
    handle: (state) => {
      return theme
    }
  }
}
