import React from 'react'
import PropTypes from 'prop-types'

const _Article = (props) => {
    const { title, html } = props;
    return (
        <div className='article flex flex-column white p2'>
            <h2 className='h2 bold dark-magenta'>{title}</h2>
            <div className="" dangerouslySetInnerHTML={{
                __html: html
            }} />
        </div>
    )
}

_Article.propTypes = {

}

export default _Article
