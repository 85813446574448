import React, { Component } from "react"
import { connect } from "react-redux"
import { notify } from "reapop"
import { bindActionCreators } from "redux"
import StreamerForm from "./StreamerForm"
import ViewerForm from "./ViewerForm"
import SignIn from "./SignIn"
import AuthSocial from "./AuthSocial"
import * as ModalActions from "actions/ModalActions"
import "./styles/index.scss"
import {trans} from "utils/translator"

// @DEPRECATED. cant be removed so easily
class AuthForm extends Component {
  static SIGNIN_TAB = "SIGNIN_TAB"
  static SIGNUP_TAB = "SIGNUP_TAB"
  static VIEWER_TAB = "VIEWER_TAB"
  static STREAMER_TAB = "STREAMER_TAB"
  constructor(props) {
    super(props)
    this.createNotification = this.createNotification.bind(this)
  }

  state = { activeTab: this.props.activeTab || AuthForm.STREAMER_TAB, notification: {}, referalCode: this.props.referalCode }

  swithTab(activeTab) {
    this.setState({ activeTab })
    this.props.ModalActions.switchAuthTabs(activeTab)
  }

  createNotification(data) {
    const { notify } = this.props
    this.setState({ notification: data })
    if (this.state.notification) {
      notify({
        title: this.state.notification.title,
        message: this.state.notification.message,
        status: this.state.notification.status,
        dismissible: true,
        dismissAfter: 5000
      })
    }
  }

  renderSocialList() {
    return (
      <div className="socialContainerList">
        <AuthSocial {...this.props} provider="twitch" />
        <AuthSocial {...this.props} provider="steam" />
        <AuthSocial {...this.props} provider="google" />
      </div>
    )
  }
  renderSocialListStreamer() {
    return (
      <div className="socialContainerList">
        <AuthSocial {...this.props} provider="twitch" />
      </div>
    )
  }
  renderSignInTab() {
    const {t} = this.props
    return (
      <div className="formTab formTab_active" id="in">
        <div className="formContainer">
          <SignIn createNotification={this.createNotification} onSubmit={this.props.onSignInSubmit} />
        </div>
        <div className="socialContainer">
          <span className="socialContainer-desc">{t('global_or_log_with')}</span>
          {this.renderSocialList()}
        </div>
      </div>
    )
  }
  renderViewerTab() {
    const {t} = this.props
    return (
      <div className="formTab formTab_active" id="in">
        <div className="formContainer">
          <ViewerForm createNotification={this.createNotification} onSubmit={this.props.onSignInSubmit} />
        </div>
        <div className="socialContainer">
          <span className="socialContainer-desc">{t('global_or_log_with')}</span>
          {this.renderSocialList()}
        </div>
      </div>
    )
  }
  renderStreamerTab() {
    const {t} = this.props
    return (
      <div className="formTab formTab_active" id="up">
        <div className="formContainer">
          <StreamerForm createNotification={this.createNotification} onSubmit={this.props.onSignUpSubmit} referalCode={this.props.referalCode} />
        </div>
        <div className="socialContainer">
        </div>
      </div>
    )
  }

  render() {
    const { activeTab } = this.state
    const { t } = this.props
    return (
      <div className="modalDesc-form">
      {
        this.props.activeTab != AuthForm.SIGNIN_TAB ? <div className="modalForm-links">
          <div className="modalForm-signIn">
            <button
              onClick={() => this.swithTab(AuthForm.VIEWER_TAB)}
              className={activeTab === AuthForm.VIEWER_TAB ? "activeFormLink" : ""}
            >
                {t('global_viewer')}
            </button>
          </div>
          <div className="modalForm-signUn">
            <button
              onClick={() => this.swithTab(AuthForm.STREAMER_TAB)}
              className={activeTab === AuthForm.STREAMER_TAB ? "activeFormLink" : ""}
            >
                 {t('global_streamer')}
            </button>
          </div>
        </div> 
        :
        null
      }
        <div className="AuthTabs" style = {activeTab == AuthForm.SIGNIN_TAB ? {paddingTop: "20px"} : {}} > 
          <div className={`AuthTabs-tab ${activeTab === AuthForm.STREAMER_TAB ? "AuthTabs-tab_active" : ""}`}>
            {this.renderStreamerTab()}
          </div>
          <div className={`AuthTabs-tab ${activeTab === AuthForm.VIEWER_TAB ? "AuthTabs-tab_active" : ""}`}>
            {this.renderViewerTab()}
          </div>
          <div className={`AuthTabs-tab ${activeTab === AuthForm.SIGNIN_TAB ? "AuthTabs-tab_active" : ""}`}>
            {this.renderSignInTab()}
          </div>
        </div>
      </div>
    )
  }
}


function mapDispatchToProps(dispatch) {
  return {
    notify: bindActionCreators(notify, dispatch),
    ModalActions: bindActionCreators(ModalActions, dispatch)
  }
}

export default trans(connect(null, mapDispatchToProps)(AuthForm))

