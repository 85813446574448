import React, {Component} from "react"
import {get} from "api/TransactionAPI"
import { Link } from "react-router-dom"
import { trans } from "utils/translator"
import * as StreamAPI from "api/StreamAPI"

export default trans(class StreamModeration extends Component {
    constructor(props) {
        super(props)
        this.state = {
        updates: ~~(Math.random()*10) ,
        moderators: [],
        checkboxes: []
        }

    }
    componentDidMount(){
       const { user } = this.props
       const streamId = user.model.streams[0]

        gtag("event", "profile", { 
            event_category: 'webpage_interraction',
            event_action: 'profile_stream_moderation_page_visited',
            user: this.props.user
          })
         
         StreamAPI.getModerators(streamId, (err, msg) => {
            
          Array.isArray(msg) ? msg.forEach(moder => {
                const taxes = Object.keys(moder.moderatorTaxes[streamId])
                taxes.forEach(tax => {
                    moder.moderatorTaxes[streamId][tax] *= 100
                })
            }) : null

                this.setState({
                moderators: msg,
            })
         })
    }
    saveChanges = () => {
        const { moderators } = this.state
        const { user } = this.props
        const streamId = user.model.streams[0]

        Array.isArray(moderators) ? moderators.forEach(moder => {
            const taxes = Object.keys(moder.moderatorTaxes[streamId])
            taxes.forEach(tax => {
                moder.moderatorTaxes[streamId][tax] /= 100
            })
        }) : null

        StreamAPI.updateModerators(streamId, moderators, (err, msg) => {
            const { notify } = this.props
            
            if(!err) notify({
      title: "Success",
      message: "Changes saved",
      status: "success",
      dismissible: true,
      dismissAfter: 2000
    })
    else notify({
        title: "Error",
        message: err.message,
        status: "error",
        dismissible: true,
        dismissAfter: 2000
      })
        })
    }
    handleDataChenge = (index, field) => (e) => {
        const { user } = this.props
        const streamId = user.model.streams[0]
        const { moderators } = this.state
        moderators[index].moderatorTaxes[streamId][field] = e.target.value
        this.setState({
            moderators
        })
    }
    handleCheckboxChange = (index, field) => (e) => {
        const { user } = this.props
        const streamId = user.model.streams[0]
        const { moderators } = this.state
        if(!e.target.checked)
        moderators[index].moderatorTaxes[streamId][field] = 0
        else{
        moderators[index].moderatorTaxes[streamId][field] = 1
        }
        this.setState({
            moderators
        })
    }
    renderModeratorRow = (moderator, index) => {
        const { user } = this.props
        const streamId = user.model.streams[0]
        const { name, moderatorTaxes } = moderator
        const { challenge, voting, paidAction } = moderatorTaxes[streamId] 
        return <tr className='stream-moderation__row'>
            <td>{name || 'Predictor'}</td>
            <td><input type='checkbox' defaultChecked={true} onChange={this.handleCheckboxChange(index, 'challenge')} /><input value={challenge} onChange={this.handleDataChenge(index, 'challenge')}/></td>
            <td><input type='checkbox' defaultChecked={true} onChange={this.handleCheckboxChange(index, 'voting')}/><input value={voting} onChange={this.handleDataChenge(index, 'voting')}/></td>
            <td><input type='checkbox' defaultChecked={true} onChange={this.handleCheckboxChange(index, 'paidAction')}/><input value={paidAction} onChange={this.handleDataChenge(index, 'paidAction')}/></td>
        </tr>
    }
    render(){
        const {transactionList, moderators} = this.state
        const {user, streamList, t} = this.props
        return (
            <div className='profilePage'>
           <div className='stream-moderation'>
           <div className='stream-moderation__stream-list'>
           <div className='stream-moderation__label stream-moderation__header'>
           {t('profile_streamlist')}
           </div>
           <div className='stream-moderation__streams'>
           {
               streamList.filter(s => user.model.streams.includes(s.id) || user.model.streams.includes(s.id.toString())).map( s => {
                   return <Link to={`/admin/stream/${s.stream}`} className='stream-moderation__label stream-moderation__stream'>{s.stream} ({this.state.updates})</Link>
               } )
           }
           </div>
           <div className="stream-moderation__moderators">  
           <h3 className="stream-moderation__header">
           {t('profile_your_moderators')}
           </h3>
           <table cellSpacing="0">
            <thead className='stream-moderation__header'>
            <tr>
            <th>{t('profile_moderator_name')}</th>
            <th>{t('profile_activity_challenge')}</th>
            <th>{t('profile_activity_voting')}</th>
            <th>{t('profile_activity_paidaction')}</th>
            </tr>
            </thead>
            <tbody className='transactions__body'>
            {
                Array.isArray(moderators) ? 
                moderators.map( (m, i) => {
                    return this.renderModeratorRow(m, i)
                }) : null
            }
            </tbody>
            </table>
            <button className="stream-moderation__save-btn pre-btn" onClick={this.saveChanges}>
            Save
            </button>
           </div>
           </div>
           </div>
            </div>
  )

}
})
