import * as Bots from "../reducers/bots"

export function setBots(bots) {
  return (dispatch) => {
    dispatch(Bots.setBots(bots))
  }
}

export function setTokens(tokens) {
  return (dispatch) => {
    dispatch(Bots.setTokens(tokens))
  }
}
