import React, { Component } from "react"
import Dropdown from "components/Dropdown.js"
import { trans } from "utils/translator"
import i18next, { languages } from "i18n"

class ThemeSwitcher extends Component {

  constructor(props){
    super(props)
    let storedTheme = localStorage.getItem('theme') || 'default'
    this.state = { 
        theme: storedTheme,
        themes: ['default', 'gold'] 
     };
  }
  
  handleLinkClick = theme => e => {
    e.preventDefault();

    // apply theme
    // store theme to localStore
    this.props.ThemesActions.applyTheme(theme)
    
    localStorage.setItem('theme', theme)
    this.setState({ theme });
      
    this.refs.dropdown.hide();
  };
  componentDidMount() {
    this.props.ThemesActions.applyTheme(this.state.theme)
  }
  render() {
    const {t} = this.props
     const nodes = this.state.themes.map((theme, i) => (
      <div key={i}>
        <button className="b-dropdown__link"
                onClick={this.handleLinkClick(theme)}>
          <i className={`theme-${theme}`}/> <span>{theme}</span>
        </button>
      </div>
    ));

    const component = (
     
      <div className='theme-switcher'>
        <div className={`theme-switcher__trigger`}/>
        <div className="theme-switcher__label">{this.state.theme}</div>
      </div>
    );

    return (
      <Dropdown
        ref="dropdown"
        component={component}>
        {nodes}
      </Dropdown>
    );
  }
}

export default trans(ThemeSwitcher);
