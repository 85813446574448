// todo declare ststuses here


export const DIRECTION_RIGHT = 0;
export const DIRECTION_BOTTOM = 1;
export const DIRECTION_LEFT = 2;
export const DIRECTION_TOP = 3;


export const challengeStatuses = {
    WAITING_FOR_MODERATION: 'WAITING_FOR_MODERATION',
    FAILED_MODERATION: 'FAILED_MODERATION',
    WAITING_FOR_ACCEPTION: 'WAITING_FOR_ACCEPTION',
    FAILED_ACCEPTION: 'FAILED_ACCEPTION',
    ACCEPTED: 'ACCEPTED',
    DONE: 'DONE',
    FAILED: 'FAILED'
}

export const activityTypes = {
    CHALLENGE: 'CHALLENGE', 
    VOTING: 'VOTING', 
    PAID_ACTION: 'PAID_ACTION',
    PREDICTION: 'PREDICTION', 
    BRAND_QUEST: 'BRAND_QUEST'
}