import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useMediaQuery } from 'react-responsive';

import * as ModalActions from "actions/ModalActions";

import HeaderMobile from './Mobile/Header';
import HeaderDesktop from './Header';
import './header.scss';

import { DESKTOP_MIN_RESOLUTION } from 'utils/config';
import { isAuthenticated, getUser } from 'selectors';


const HeaderContainer = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: DESKTOP_MIN_RESOLUTION });
    return isDesktopOrLaptop ? <HeaderDesktop {...props}/> : <HeaderMobile {...props}/>;
} 

function mapStateToProps(state) {
    return {
        isAuthenticated: isAuthenticated(state),
        user: getUser(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ModalActions: bindActionCreators(ModalActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
