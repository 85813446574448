import React from 'react'
import PropTypes from 'prop-types';

const NewsFeed = props => {
    return (
        <div className='news-feed'>
            
        </div>
    )
}

NewsFeed.propTypes = {

}

export default NewsFeed
