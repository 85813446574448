import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import Select from 'components/Select';
import i18next from "i18n";
import useOnClickOutside from 'utils/hooks/UseClickOutside';
import { trans } from 'utils/translator';
import { removeUserFromLocalStore } from 'utils/helpers';

const items = [
    {
        label: 'Русский',
        value: 'ru'
    },
    {
        label: 'English',
        value: 'en'
    }
];

/**
|--------------------------------------------------
| @NEEDS REFACTOR
|--------------------------------------------------
*/
const HeaderMenu = (props) => {
    const { user, setShowMenu, t } = props;
    console.log(i18next.language, 'test lang');
    let prevLang = items.find(lang => lang.value === (i18next.language));
    console.log('prevLang', prevLang)
    if(!prevLang){
        prevLang = items[1];
    }
    console.log('prevLang', prevLang)
    const ref = useRef(null);
    const [ lang, setLang ] = useState(prevLang.value);

    useOnClickOutside(ref, () => {
        setShowMenu(false)
    });

    const selectLang = (index) => {

        i18next.changeLanguage(items[index].value, (err, t) => {
            if (err) {
              return console.log('error', err);
            }
            setLang(items[index].value);
          })
    }
    return (
        <div  ref={ref} className='header-menu bg-white black'>
            <div className="header-menu__top flex p2 border-bottom items-center">
                <Avatar image={user.avatar} />
                <div className="header-menu__name-block flex flex-column ml2">
                    <h5 className="h5 bold mb2">
                        {user.name}
                    </h5>
                    <p>
                       {`Level: ${ user.level ? user.level : '0' }`}
                    </p>
                </div>
                <div className="header-menu__balance flex ml2">
                    <p className=''>{user.orbs}</p> <Icon name='normal-orb' className='ml2' />
                    <p className='ml2' >{user.balance}</p> <Icon name='normal-cube' className='ml2' />
                </div>
            </div>
            <ul className=' bg-white black pl2 py4'>
                <Link to='/profile' className='mb2 medium block'>{t('_header_menu_1')}</Link>
                <Link to='/help' className='mb2 medium block'>{t('_header_menu_2')}</Link>
                <li className='mb2 medium block'>{t('_header_menu_3')}</li>
                <Link to='#' onClick={() => {
                    removeUserFromLocalStore();
                    window.location = '/';
                }} className='mb2 medium block red'>{t('_header_menu_4')}</Link>
                <Select items={items} onChange={selectLang} selected={items.findIndex(item => item.value === lang )} />
            </ul>
        </div>
    )
};

HeaderMenu.propTypes = {

}

export default trans(HeaderMenu);
