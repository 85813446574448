import { store } from 'libs/redux'

const token = store.getState().user.model.token || ''

const headers = new Headers()
headers.append("Accept", "application/json")
headers.append("Content-Type", "application/json")
headers.append("Authorization", token)

export const loadLocalizationData = (callback) => {
 
     fetch(`/api/i18n/`, {
       method: 'GET',
       headers,
     })
     .then(res => res.json())
     .then(json => {
        if(json[0].lang)
        callback(json)})
   }

   export const updateLocalizationData = (locale, callback) => {
    fetch(`/api/i18n/`, {
      method: "POST",
      headers,
      body: JSON.stringify({
          lang: locale.lang,
          fields: Object.keys(locale.translation)
      })
    })
    .then(res => res.json())
    .then(json => {

      json.map(field => field.value = locale.translation[field.key])

        fetch(`/api/i18n/`,{
            method: 'PATCH',
            headers, 
            body: JSON.stringify(json)
        })
        .then(res => res.json())
        .then(json => callback(json))
  })
   }
