import React from 'react'
import PropTypes from 'prop-types'

const Hamburger = props => {
    const { onClick } = props;
    return (
        <div onClick={onClick} className='hamburger flex flex-column justify-between'>
            <span className='hamburger__line'></span>
            <span className='hamburger__line'></span>
        </div>
    )
}

Hamburger.propTypes = {

}

export default Hamburger
