import React, { Component } from "react"

import PredictionNew  from "./ActivitiesNew/Prediction"
import PredictionTemplate from "./ActivitiesNew/PredictionTemplate"
import * as DefaultPredictions from "./DefaultPredictions"

import { groupBy } from 'lodash'

const makeid = length => {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};
export default class AdminStreamPagePredictions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedGame: 'Custom',
      games: [
        "Custom",
        "Dota 2",
        "CS:GO",
        "League of Legends",
        "Fortnite",
        "PUBG",
        "Overwatch",
        "Hearthstone",
        "World of Tanks", 
     ],
    }
  }

  componentDidMount() {
  
  }

  renderList = (predictions=[]) => {
    const { props: { PredictionsActions,  PredictionTemplateActions }, state: { selectedGame} } = this
    return predictions.map((item, i) => {
      const isTemplate = item.isTemplate
      // this is ultimate govnokod plaese fix
      return  isTemplate ? <PredictionTemplate key={`prediction_template_${item.id}`}  { ...{ prediction: item, PredictionsActions, PredictionTemplateActions } } />
      :<PredictionNew key={`prediction_${item.id}+${item.createdAt}`}  prediction={item} PredictionsActions={PredictionsActions} />
    })
  }
  selectGame = (e) => {
    this.setState({ selectedGame: e.target.value })
  }
  render() {
    
    const { 
      props: { stream: { stream }, predictions, predictionTemplates, PredictionTemplateActions: { create } },
      state: { games, selectedGame },
      renderList, selectGame } = this
    const filteredPredictions = predictions.filter(c => c.stream === stream);
    const { defaultVoting, defaultChallenge, defaultPaidAction, def, defaultPrediction, ...defaultPredictions } = DefaultPredictions
    const _def = Object.values(defaultPredictions).map(def => def(stream))
    const allTemplates = [...predictionTemplates, ..._def].map(template => { 
      return { 
        ...template,
        isTemplate: true,
        id: template.id ? template.id : makeid(12),
        game: template.game ? template.game : 'Custom'
      }
    })
    const filteredAllTemplates = allTemplates.filter(c => c.stream === stream);
    const allTemplatesByGame = groupBy(filteredAllTemplates, 'game')
    console.log(predictionTemplates, _def, allTemplatesByGame, 'debug predictions');
    return (
      <div className="row-container">
        <div className="activity-admin__activity-block">
          <div className='col-container col-container_center all_predictions'>{ renderList(filteredPredictions.filter(p => !p.finishedAt)) } </div>
        </div>
        <div className="activity-admin__activity-block activity-admin__activity-block_prd">
          <select style={{
            margin: '0 auto',
            display: 'block'
          }} onChange={selectGame}>
          {
             games.map((g, i) => {
              return <option key={i} value={g}>{g}</option>
              })
          }
          </select>
          <div className='row-container row-container_wrap activity-admin__templates'>
            { renderList(allTemplatesByGame[selectedGame]) }
         </div>
           <div onClick={() => create(DefaultPredictions.def(stream))} className="plus__icon" />
        </div>
      </div>
    )
  }
}
