import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SuggestChallenge from './SuggestChallenge';
import * as PredictionsActions from "actions/PredictionsActions"
import './suggest-challenge.scss';

function mapStateToProps(state) {
    return {
      user: state.user
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestChallenge);