import React, { Component } from 'react';
import TextEditor from '../TextEditor';
import { Switch, Route, Link } from "react-router-dom";
import { languages } from "i18n";
import { loadLocalizationData, updateLocalizationData } from "api/i18n"
import { store } from 'libs/redux'
import Role from 'components/Common/Role'


export default class AdminTexts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trans: [],
      locale: {},
      scope: 'main',
      lang: 'en',
    }

  }
  // componentDidMount(){
  //   const {
  //       lang,
  //     } = this.props;
     
  //   loadLocalizationData((json) => {
  //     this.setState({
  //     trans: json,
  //     locale: json.find(locale => locale.lang == lang),
  //   })
  //   })
    
  //   }
handleClick(scope, lang){
  this.setState({
    scope: scope,
    lang: lang
  })
}
handleLangClick(lang){
  this.setState({
    lang: lang
  })
}
 render() {
  //  if(!hasPermisson){
  //    return('')
  //  }
   const {
     match
   } = this.props;
  const {lang, scope} = this.state;
 
  return (
    <Role roles={['ADMIN']}>
    <div style={{
      'color': '#fff',
      'fontSize': '1em',
    }}>
    <div className="controls">
     <h2 className='language__title title'>Language</h2>
      <div className='controls__language row'>
      {
        languages.map((lang, i)=> {
          return (
            <Link to={`/admin/texts/${lang.code}/${scope}`} onClick={() => this.handleLangClick(lang.code)} className='pre-btn pre-btn_blue'>{lang.fullname}</Link>
            )
        })
      }
      </div>
      <hr className='hr'/>
      <h2 className='pages__title title'>Pages</h2> 
      <div className='controls__pages row'>
      <Link to={`/admin/texts/${lang}/global`} onClick={() => this.handleClick('global', `${lang}`)} className='pre-btn pre-btn_blue'>Global</Link>
      <Link to={`/admin/texts/${lang}/main`}  onClick={() => this.handleClick('main', `${lang}`)} className='pre-btn pre-btn_blue'>Main</Link>
      <Link to={`/admin/texts/${lang}/faqtab`}  onClick={() => this.handleClick('faqtab', `${lang}`)} className='pre-btn pre-btn_blue'>F.A.Q.</Link>
      <Link to={`/admin/texts/${lang}/legal`}  onClick={() => this.handleClick('legal', `${lang}`)} className='pre-btn pre-btn_blue'>Legal</Link>
      <Link to={`/admin/texts/${lang}/about`}  onClick={() => this.handleClick('about', `${lang}`)} className='pre-btn pre-btn_blue'>About</Link>
      <Link to={`/admin/texts/${lang}/buy`}  onClick={() => this.handleClick('buy', `${lang}`)} className='pre-btn pre-btn_blue'>Buy</Link>
     </div>
    </div>
    <TextEditor lang={lang} scope={scope}/>
    </div>
    </Role>
    )
 }
}
