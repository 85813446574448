import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import { Switch, Route, Link, withRouter } from "react-router-dom"
import * as ArticleAPI from "../../api/ArticleAPI"

class ArticleList extends Component {
  constructor(props) {
    super(props)
    this.state = {
        categories: this.props.categories
    }
  }
  changeCategoryPosition = (article, direction) => () => {
    const { articles, handleEditArticles } = this.props
    const oldIndex = articles.indexOf(article)
    let newIndex = oldIndex + direction

    // check range
    if(newIndex < 0 || newIndex > articles.length) {
      alert('Out of range!')
      return ''
    }  
    
    // clone array
    const newArticles = Array.from(articles)

    // switch articles
    let temp = newArticles[newIndex]
    newArticles[newIndex] = newArticles[oldIndex]
    newArticles[oldIndex] = temp
    
    // ensure article positions dont messed up
    newArticles.forEach((article, i) => {
      article.position = i
    })

    handleEditArticles(newArticles)
    // send to server
  }
  
    render() {
    const { state, props } = this
    const { categories, t, articles, selectArticle, category } = props
    const publishedArticles = articles.filter(article => !article.sandbox)
    const { a} = props

    let isAdmin = false
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    
    if(user && user.permissions)
    isAdmin = user.permissions.includes("SUPER")

    const hasEditPermission = isAdmin

    const visibleArticles = hasEditPermission ? articles : publishedArticles
    return (    
      <div className='help-center__article-list'>
     <h1 className="help-center__subheader">{category.title}</h1>
     <div className="help-center__articles">
     <div className="help-center__1-col">
     {
       visibleArticles.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)).map((article, i) => {
         return    <div style={{display: "flex",
          }}><a onClick={selectArticle(i)} href="#article" className="help-center__article-name">
         {article.title}
         </a>
         {
           article.sandbox ? <Link className="help-center__add-new-article" style={{marginLeft: "15px"}} to={{pathname: "/test-editor", state: {
            article,
            isEdit: true
          }}}>{t('hc_draft')}</Link>: null
         }
         {
           hasEditPermission ?
            <React.Fragment> 
            <div style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={this.changeCategoryPosition(article, -1)}>&#8593;</div> 
            <div style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={this.changeCategoryPosition(article, 1)}>&#8595;</div> 
            </ React.Fragment> :
            null
         } 
         </div>
       })
    }
      </div>
      {
      hasEditPermission ?
      <Link className="help-center__add-new-article" to={{ pathname: "/test-editor", state: {
        article: {
          parent: category.id
        },
        isEdit: false
      }}}>{t("hc_add_new_article")}</Link> : null
    }
      </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(trans(connect(mapStateToProps, mapDispatchToProps)(ArticleList)))