import React, { Component } from "react"
import {trans} from "utils/translator"
import { Link } from "react-router-dom"
import * as ZendeskAPI from "api/ZendeskAPI"


export default trans(class CashboxModal extends Component {
  
  constructor(props){
    super(props)
    this.state = {
      username: '',
      email: '',
      channel: '',
      comment: '',
    }
  }
  
  sendRequest = e => {
    e.persist();
    this.requestHasSend() // Sending req
      .then(() => this.handleModal(e)) // then close modal
      .catch(e => this.notifyAlert('Error', e, 1800)) // or notify Error
  }

  requestHasSend = () => {
    const { streams = [] } = this.props.user.model;
    return new Promise((resolve, reject) => {
      if (!streams.length) reject('Error occured while sending request. Try hard reloading');
      resolve(this.jivoSendMsg());
    })
  };

  jivoSendMsg = () => {
    const { streams = [] } = this.props.user.model;
    const { username, email, channel, comment } = this.state;
    return jivo_api.sendMessage({ name: 'Текст' }, `
      (Note: Please check CONTROL INFO before giving any permissions to this user)
      NEW PARTNERSHIP REQUEST \n
      CLIENT INFO: \n
      Username: ${ username}, \n
      Email: ${ email}, \n
      Channel: ${ channel}, \n
      Comment: ${ comment}, \n
      CONTROL INFO: \n
      STREAMS: ${ JSON.stringify(streams)} \n
      `)
  }

  handleModal = e => {
    this.notifyAlert('Success', 'Request was send successfully', 1200); // notify with delay 1200 ms
    setTimeout(() => this.props.closeModal(e), 1300); // close modal after notify
  }

  notifyAlert = (status, msg, delay) => {
    return this.props.notify({
      title: status,
      message: msg,
      status: status.toLowerCase(),
      dismissible: true,
      dismissAfter: delay,
    })
  }

  handleInputChange = e => this.setState({ [e.target.name]: e.target.value });
  
  render() {
    const { t } = this.props;
    const { username, email, channel, comment } = this.state;
    return (
      <div className='cashbox-modal partnership-modal' onClick={e => e.stopPropagation()}>
        <div className='ask-modal__head'>
          <div className='cashbox-modal__label'>{t('profile_askPartnership_modal_form_heading')}</div>
        </div>
        <form action="#" className="profilePageForm cashbox-modal__form" method="post">
          <div className="profilePageInputBlock">
            <label className="profilePageInput-label" htmlFor="username">{t('profile_askPartnership_modal_yourname')}
              <input type="text"
                onChange={this.handleInputChange}
                className="profileInput cashbox-modal__card-number"
                name="username"
                value={username} 
              />
            </label>
          </div>
          <div className="profilePageInputBlock">
            <label className="profilePageInput-label" htmlFor="channel">{t('profile_askPartnership_modal_link')}
              <input type="text"
                onChange={this.handleInputChange}
                className="profileInput cashbox-modal__card-number"
                name="channel"
                value={channel} 
              />
            </label>
          </div>
          <div className="profilePageInputBlock">
            <label className="profilePageInput-label" htmlFor="email">Email
              <input type="text"
                onChange={this.handleInputChange}
                className="profileInput cashbox-modal__card-number"
                name="email"
                value={email}
              />
            </label>
          </div>
          <div className="profilePageInputBlock">
            <label className="profilePageInput-label" htmlFor="comment">{t('profile_askPartnership_modal_message')}
              <input type="text"
                onChange={this.handleInputChange}
                className="profileInput cashbox-modal__card-number"
                name="comment"
                value={comment}
              />
            </label>
          </div>
        </form>
        <div className="inputBlock cashbox-modal__confirm">
          <button
            className="pre-btn pre-btn_blue pre-btn_modal cashbox-modal__button"
            onClick={this.sendRequest}
          >
            {t('profile_askPartnership_modal_sendbtn')}
          </button>
        </div>
      </div>
    )
  }
})