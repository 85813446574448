import collectionReducers from "utils/collectionReducers"

const initialState = {
  streamlist: [
    {
      title: "Loading...",
      id: "Loading",
      stream: "",
      type: "twitch",
      img: "https://i.imgur.com/NyfDfeh.gif",
      spectators: "loading",
      featured: false
    }
  ],
  adminStreamlist: []
}

const NAMESPACE = "STREAMLIST"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function getStreamItem() {
  return {
    namespace: NAMESPACE,
    type: "GET_STREAM_ITEM",
    handle: (state) => { 
      return { ...state, streamlist: state.streamlist 
      }
    }
  }
}

export function setNewList(streamlist) {
  return {
    namespace: NAMESPACE,
    type: "SET_STREAM_LIST",
    handle: (state) => {
      return { ...state, streamlist }
    }
  }
}

export function setAdminList(streamlist) {
  return {
    namespace: NAMESPACE,
    type: "SET_ADMIN_STREAM_LIST",
    handle: (state) => {
      return { ...state, adminStreamlist: streamlist }
    }
  }
}


export function setNewPredictions(predictions) {
  return {
    namespace: NAMESPACE,
    type: "SET_PREDICTIONS_LIST",
    handle: (state) => { return { ...state, predictions } }
  }
}

export function update(prd) {
  function _update(streamList) {
    // фильтруем нужный стрим
    const stream = streamList.filter((stream) => {
      return stream.stream.toLowerCase() == prd.stream.toLowerCase()
    })

    if (stream.length > 0) {
      // получаем индекс ставки в этом стриме
      const index = stream[0].predictions.findIndex(item => item.stream === prd.id)
      const indexStream = streamList.findIndex(item => item.stream === stream[0].stream)

      // console.log('redux stream update')
      // console.table({
      //   index: index, 
      //   indexStream: indexStream,
      //   prd: prd,
      //   streamlist: streamList
      // })
      
      // заменяем/добавляем
      if (index != -1) {
        streamList[indexStream].predictions[index] = prd
      } else {
        streamList[indexStream].predictions.push(prd)
      }
    }

    return streamList
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_PREDICTION",
    handle: (state) => {
      const newlist = _update(state.streamlist)
      return { ...state, streamlist: newlist }
    }
  }
}
