import * as SocketAPI from "api/SocketAPI"
import { updateUserBalance } from 'actions/UserActions'
import { store } from "libs/redux"
import "whatwg-fetch"

const load = async (callback, streamID) => {
    const predictions = store.getState().predictions.predictions
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`/api/voting/stream/${streamID}`, {
      method: "GET",
      headers
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
  }

  const createAdmin = async (callback, votingBody) => {

    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const token = user.token || ""
    const permissions = user.permissions || []

    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/voting/`, {
      method: "POST",
      headers,
      body: JSON.stringify(votingBody)
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
  }

  const placeBet = async (callback, votingId, selectedOption, amount) => {
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const token = user.token || ""
    const permissions = user.permissions || []
  
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)
  
  fetch(`/api/vote/`, {
    method: "POST",
    headers, 
    body: JSON.stringify({voting: votingId, option: selectedOption, amount }),
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "UNKNOWN_ERROR" || json.code != undefined) {
        callback(json.message)
      } else {
         callback(null, json)
      }
    })
  }


  const cancel = async (callback, votingId) => {
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const token = user.token || ""
    const permissions = user.permissions || []
    
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
    
    fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/voting/${votingId}/cancel`, {
      method: "PATCH",
      headers, 
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
    }

    const close = async (callback, votingId) => {
      const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
      const token = user.token || ""
      const permissions = user.permissions || []
      
      const headers = new Headers()
      headers.append("Accept", "application/json") // This one is enough for GET requests
      headers.append("Content-Type", "application/json") // This one sends body
      headers.append("Authorization", token)
      
      fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/voting/${votingId}/close`, {
        method: "PATCH",
        headers, 
      })
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          callback(json)
        })
        .catch((error) => {
          // AHHHH! An Error!
          console.log("getPredictionItem error:", error)
        })
      }

    const updated = async (callback, admin, stream) => {
      SocketAPI.votings(admin, stream, ({ error, response, type, socket }) => {

        if (error) {
          console.error("SocketAPI votings", error, socket)
          return
        }
    
        if (type == "connect" || type == "disconnect") {
          return
        }
    
        const voting = response.voting
    
        const votings = store.getState().votings.votings
    
        const index = votings.findIndex(item => item.id === voting.id)
       
        const oldVoting = index != -1 ? votings[index] : null
        const vot = update(voting, oldVoting)
    
        if (index != -1) {
          votings[index] = vot
        } else {
          votings.push(vot)
        }
        callback(votings)
      })
    }

    function update(vot, oldVoting, callback) {
      store.dispatch(updateUserBalance())
      const voting = oldVoting || Object()
      voting.createdAt = vot.createdAt
      voting.currency = vot.currency
      voting.description = vot.description
      voting.duration = vot.duration
      voting.id = vot.id
      voting.options = vot.options
      voting.status = vot.status
      voting.stream = vot.stream
      voting.updatedAt = vot.updatedAt
      voting.optionsBank = vot.optionsBank
      voting.winner = vot.winner
      voting.winnerBank = vot.winnerBank
      
      if (callback != null) {
        callback(voting)
      }
      return voting
    }
  export {
    load,
    createAdmin,
    placeBet,
    cancel,
    updated,
    close
  }