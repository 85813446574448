import * as SocketAPI from "api/SocketAPI"
import { store } from "libs/redux"
import "whatwg-fetch"

const sendMsg = async (callback, request) => {

    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`https://predictoria.zendesk.com/api/v2/requests`, {
      method: "POST",
      headers,
      body: JSON.stringify({
          request
      })
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
  }

  

  export {
    sendMsg,
  }