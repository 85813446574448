
export const HAMBURGER_MENU_ITEMS_LOGGED_IN = [
    {
        label: 'МОЙ ПРОФИЛЬ',
        icon: 'icon',
        link: '/profile/main'
    },
    {
        label: 'HELP CENTER',
        icon: 'icon',
        isDisabled: true,
    },
    {
        label: 'ОБРАТНАЯ СВЯЗЬ',
        icon: 'icon',
        isDisabled: true
    },
    {
        label: 'ВЫЙТИ',
        isDisabled: true
    }
];


export const HAMBURGER_MENU_ITEMS_LOGGED_OUT = [
    {
        label: 'МОЙ ПРОФИЛЬ',
        icon: 'icon',
        link: '/profile/main'
    },
    {
        label: 'HELP CENTER',
        icon: 'icon',
        isDisabled: true,
    },
    {
        label: 'LOG OUT',
        isDisabled: true
    }
];
