import React, { useRef, useEffect, useState  } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import Select from 'components/Select';
import { trans } from 'utils/translator';
import { removeUserFromLocalStore } from 'utils/helpers';
import i18next from "i18n";
const items = [
    {
        label: 'Русский',
        value: 'ru'
    },
    {
        label: 'English',
        value: 'en'
    }
];

const MenuDropdown = (props) => {
    const { onClose, t } = props;
    let prevLang = items.find(lang => lang.value === (i18next.language));
    console.log('prevLang', prevLang)
    if(!prevLang){
        prevLang = items[1];
    }
    console.log('prevLang', prevLang)
    const ref = useRef(null);
    const [ lang, setLang ] = useState(prevLang.value);
    const selectLang = (index) => {

        i18next.changeLanguage(items[index].value, (err, t) => {
            if (err) {
              return console.log('error', err);
            }
            setLang(items[index].value);
          })
    }
    return <ul className='menu-dropdown bg-white black pl2 py4'>
        <Link onClick={onClose} to='/profile' className='mb2 medium block'>{t('_header_menu_1')}</Link>
        <Link onClick={onClose} to='/streams' className='mb2 medium block'>STREAMS</Link>
        <Link onClick={onClose} to='/help' className='mb2 medium block'>{t('_header_menu_2')}</Link>
        <li onClick={onClose} className='mb2 medium block'>{t('_header_menu_3')}</li>
        <Link to='#' onClick={() => {
            removeUserFromLocalStore();
            window.location = '/';
        }} className='mb2 medium block red'>{t('_header_menu_4')}</Link>
        <Select items={items} onChange={selectLang} selected={items.findIndex(item => item.value === lang)} />
    </ul>
}

export default trans(MenuDropdown);
