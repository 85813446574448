import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as StreamActions from "actions/StreamActions"
import StreamListSlider from "./Betty-EventsSlider"

class EventsSliderContainer extends Component {
  
  toIng(title) {
    return ["Dota 2", "Counter-Strike: Global Offensive", "FIFA"].indexOf(title)+1 || 1
  }
  
  render() {
    const game = (location.pathname.split("/")[3] || "Dota 2").replace(/_/g, " ")
    let filtered = this.props.streamlist.filter(el => {
      return el.game_id == this.toIng(game)
    })
 
    return (
      <StreamListSlider {...this.props} events={filtered} StreamActions={this.props.StreamActions} key="streamList" />
    )
  }
}


function mapStateToProps(state) {
  return { 
    // streamList: state.streamList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsSliderContainer)
