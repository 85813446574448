import * as leaderboard from "../reducers/leaderboard"
import * as LeaderboardAPI from "api/LeaderboardAPI"

export function setNewList(list) {
  return (dispatch) => {
    dispatch(leaderboard.setNewList(list))
  }
}

export const get = (period, request, callback) => (dispatch) => {
  LeaderboardAPI.get(period, request, (error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(leaderboard.setNewList(list))
    if (callback) { callback(null, list) }
  })
}


export const getStreamers = (period, request, callback) => (dispatch) => {
  LeaderboardAPI.getStreamers(period, request, (error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(leaderboard.setNewListStreamers(list))
    if (callback) { callback(null, list) }
  })
}
