import openSocket from "socket.io-client"
import { SOCKET_HOST_URL, DOMAIN_URL } from 'utils/config'

const host = `${DOMAIN_URL}:${SOCKET_HOST_URL}`

function predictions(admin = "", stream, cb) {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token
  const socket = openSocket(`${host}/predictions`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
      stream,
    }
  })
  socket.on("connect", () => {
  socket.emit("join", stream)
  cb({ type: "connect", socket })
  })
 
  socket.on(`update`, response => {
    
    cb({ response, type: "update", socket })
  })
  
  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}

function notifications(cb) {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token
  const socket = openSocket(`${host}/notifications`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
    }
  })


  if (!cb) {
    console.error("SocketAPI notifications callback is null", cb)
    return
  }

  socket.on("connect", () => cb({ type: "connect", socket }))

  socket.on("notify", response => cb({ response, type: "notify", socket }))
  socket.on("betty", response => cb({ response, type: "betty", socket }))

  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}

function pdones(cb) {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token
  const socket = openSocket(`${host}/admin`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
    }
  })

  if (!cb) {
    console.error("SocketAPI admin callback is null", cb)
    return
  }

  socket.on("connect", () => cb({ type: "connect", socket }))

  socket.on(`pdone_${stream}`, response => cb({ response, type: "pdone", socket }))

  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}

function betty(cb) {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token
  const socket = openSocket(`${host}/betty`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
    }
  })

  if (!cb) {
    console.error("SocketAPI betty callback is null", cb)
    return
  }

  socket.on("connect", () => cb({ type: "connect", socket }))

  socket.on(`update`, response => cb({ response, type: "update", socket }))
  socket.on(`notify`, response => cb({ response, type: "notify", socket }))

  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}

function challenges(admin = "", stream, cb) {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
const token = user.token
  const socket = openSocket(`${host}/challenges`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
    }
  })

  if (!cb) {
    console.error("SocketAPI admin callback is null", cb)
    return
  }

  socket.on("connect", function() {
    socket.emit("join", stream)
    cb({ type: "connect", socket })
  })

  socket.on(`update`, response => cb({ response, type: "challenge", socket }))

  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}

function votings(admin = "", stream, cb) {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
const token = user.token
  const socket = openSocket(`${host}/votings`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
      stream
    }
  })

  if (!cb) {
    console.error("SocketAPI admin callback is null", cb)
    return
  }

  socket.on("connect", function (){
    socket.emit("join", stream)
    cb({ type: "connect", socket })   
  })

  socket.on(`update`, response => cb({ response, type: "votings", socket }))

  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}


function paidActions(admin = "", stream, cb) {
  
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token
  const socket = openSocket(`${host}/paidaction`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
      stream
    }
  })

  if (!cb) {
    console.error("SocketAPI admin callback is null", cb)
    return
  }

  socket.on("connect", function (){
    socket.emit("join", stream)
    cb({ type: "connect", socket })   
  })

  socket.on(`update`, response => cb({ response, type: "paidActions", socket }))

  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}

function paidActionTemplates(admin = "", stream, cb) {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token
  const socket = openSocket(`${host}/paidaction_template`, {
    secure: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
    query: {
      token,
      stream
    }
  })

  if (!cb) {
    console.error("SocketAPI admin callback is null", cb)
    return
  }
  socket.on("connect", function (){
    socket.emit("join", stream)
    cb({ type: "connect", socket })   
  })

  socket.on(`update`, response => cb({ response, type: "paidActionTemplates", socket }))

  socket.on("error", error => cb({ error, type: "error", socket }))

  socket.on("disconnect", () => cb({ type: "disconnect", socket }))
}

export { predictions, notifications, pdones, betty, challenges, votings, paidActions, paidActionTemplates }
