import React from "react"
import { Link } from "react-router-dom"
import FormValidator from "components/Common/FormValidator"
import { required, email, gte } from "utils/validator"
import user from "reducers/user"
import {trans} from "utils/translator"

export default trans(class SignUpForm extends FormValidator {
  constructor(props){
    super(props)
    this.state = {
      form: {
        referal: {
          value: props.referalCode,
          rules: [],
          isValid: true,
          isDirty: false,
        }
      }
    }
  }
  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.state.form.terms.value != true) {
      this.sendNotification({
        title: "Sign Up",
        message: "Please, confirm terms first",
        status: "warning",
        dismissible: true,
      })
      return
    }
    if (!this.isValid()) return
    const { email, password, referal } = this.state.form
    // this.props.onSubmit(email.value, password.value, referal.value)

    this.createUserAPI(email.value, password.value, referal.value)
  }

  sendNotification(data) {
    this.props.createNotification(data)
  }

  createUserAPI(email, password, promo) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    const body = { email, password, referralCode: this.state.form.referal.value }
    if (promo != null && promo != "") {
      body.promo = promo
    }
    console.log(body, 'sign up body')
    fetch("/api/user", {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        
        if (json.code == "WRONG_CREDENTIALS") {
          this.sendNotification({
            title: json.message,
            message: "Check credentionals or sign up first",
            status: "warning",
            dismissible: true,
          })
          return
        }
        if (json.code == "UNKNOWN_ERROR" && json.message == "Validation error: Validation isEmail on email failed") {
          this.sendNotification({
            title: "Wrong email",
            message: "Please, type correct email address",
            status: "warning",
            dismissible: true,
          })
          return
        }
        if (json.code == "UNKNOWN_ERROR" && json.message == "Validation error: Promocode not valid") {
          this.sendNotification({
            title: "Wrong promocode",
            message: "Please, use another promocode",
            status: "warning",
            dismissible: true,
          })
          return
        }
        if (json.code == "ALREADY_EXISTS") {
          this.sendNotification({
            title: json.message,
            message: "Please, use Sign In form",
            status: "warning",
            dismissible: true,
          })
          return
        }
        

        // валидация уровня бог
        if (json.id != null) {
          this.loginUserAPI(json.email, password, json)
        }
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("handleFormSubmit error:", error)
        this.sendNotification({
          title: "Server Error",
          message: "Server Error, try again or contact us via feedback form",
          status: "warning",
          dismissible: true,
        })
      })
  }

  loginUserAPI(email, password, user) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    fetch("/api/auth/signin", {
      method: "POST",
      headers,
      body: JSON.stringify({ email, password })
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {

        if (json.code == "UNKNOWN_ERROR" || json.code == "WRONG_CREDENTIALS") {
          this.sendNotification({
            title: "Server Error",
            message: "Server Error, try again or contact us via feedback form",
            status: "warning",
            dismissible: true,
          })
          return
        }
        console.log('in sign in', user, json);
        user.token = json.token

        const { email, password, referal } = this.state.form
        this.props.onSubmit(email.value, password.value, user)

        gtag("event", "sign_up", { method: "Predictoria" })

      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("loginUserAPI error:", error)
        this.sendNotification({
          title: "Server Error",
          message: "Server Error, try again or contact us via feedback form",
          status: "warning",
          dismissible: true,
        })
      })
  }

  render() {
    const {t} = this.props
    return (
      <form className="formContainerForm" noValidate onSubmit={this.handleFormSubmit}>
        <div className="inputBlock">
          <input
            {...this.bindInput("email", "email", [required, email])}
            className={`authInput ${this.isValid("email") || "authInput authInput_error"}`}
            placeholder={t('global_type_email')}
          />
          <span className="inputBlock-icon">
            <div className="inputBlock-img">
              <div className="icon svgIcon-email-icon svgIcon-email-icon-dims" />
            </div>
          </span>
        </div>
        <div className="inputBlock">
          <input
            {...this.bindInput("password", "password", [required, gte(6)])}
            className={`authInput ${this.isValid("password") || "authInput authInput_error"}`}
            placeholder={t('global_type_password')}
          />
          <span className="inputBlock-icon">
            <div className="inputBlock-img">
              <div className="icon svgIcon-password-icon svgIcon-password-icon-dims" />
            </div>
          </span>
        </div>
        <div className="inputBlock">
          <input
            {...this.bindInput("referal")}
            className={`authInput ${this.isValid("referal") || "authInput authInput_error"}`}
            placeholder={t('global_enter_referral')}
          />
          <span className="inputBlock-icon">
            <div className="inputBlock-img">
              <div className="icon svgIcon-referral svgIcon-referral-dims" />
            </div>
          </span>
        </div>
        <div className="inputBlock">
          <p className="checkBox">
            <input
              {...this.bindInput("terms", "checkbox")}
              id="test1"
            />
            <label htmlFor="test1" dangerouslySetInnerHTML={{__html: t('global_is_18_yo')}}>
              
            </label>
          </p>
        </div>
        <div className="inputBlock">
          <input disabled={!this.isValid()} className="authInput" type="submit" value={t('global_sign_up')} />
        </div>
      </form>
    )
  }
}
)