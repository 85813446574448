import collectionReducers from "utils/collectionReducers"

const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
const isAuthenticated = userL.token != null && userL.token != ""

const initialState = {
  isAuthenticated,
  model: {
    id: userL.id,
    email: userL.email,
    password: userL.password,
    promo: userL.promo,
    yourPromo: userL.yourPromo,
    name: userL.name || "Predictor",
    balance: userL.balance,
    avatar: userL.avatar || "/assets/images/avatar.svg",
    token: userL.token,
    permissions: userL.permissions,
    facebookId: userL.facebookId,
    twitch: userL.twitch,
    google: userL.google,
    steam: userL.steamId,
    orbs: userL.orbs,
    streams: userL.streams,
    referralCode: userL.referralCode,
    cardToken: userL.cardToken,
    passiveOrbs: userL.passiveOrbs,
    youtube: userL.youtube,
    goodgame: userL.goodgame,
  }
}

const NAMESPACE = "USER"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export const setName = (name) => {
  return {
    namespace: NAMESPACE,
    type: "USER_SET_NAME",
    handle: (state) => {
      return { ...state, name }
    }
  }
}
export function setUserBalance(balance) {
  return {
    type: "SET_USER_EMAIL",
    handle: (state) => { return { ...state, balance } }
  }
}

export const updateUserProfile = (name) => {
  return {
    namespace: NAMESPACE,
    type: "USER_SET_NAME",
    handle: (state) => {
      return { ...state, name }
    }
  }
}

export const updateUserBalance = (balance, orbs) => {
  return {
    namespace: NAMESPACE,
    type: "USER_SET_BALANCE",
    handle: (state) => {
      return { ...state, model: { ...state.model, balance, orbs } }
    }
  }
}

export const updateUserAvatar = (avatar) => {
  return {
    namespace: NAMESPACE,
    type: "USER_SET_AVATAR",
    handle: (state) => {
      return { ...state, model: { ...state.model, avatar } }
    }
  }
}
