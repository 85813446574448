import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'components/_Button';
import Input from 'components/Input';
import Form from 'components/_Form';
import Socials from '../../Socials.jsx';
import Checkbox from 'components/Checkbox';
import { appRoutes } from 'utils/config';
import { trans } from 'utils/translator';

const Reg = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [refCode, setRefCode] = useState('');
  const [isAgree, setAgree] = useState(false);
  const [isSendMemes, setSendMemes] = useState(false);
  const { UserActions, t } = props;

  return (
      <Form className='p2 bg-dark-magenta white' 
            onSubmit={(e) => {
            e.preventDefault();
            UserActions.register(email, password, refCode);
        }}>
          <Input
            name='email'
            placeholder={t('_reg_1')}
            value={email}
            onChange={setEmail}
            className='mb2'
          />
          <Input
            name='password'
            placeholder={t('_reg_2')}
            value={password}
            onChange={setPassword}
            className='mb2'
          />
          <Input
            name='refCode'
            placeholder={t('_reg_3')}
            value={refCode}
            onChange={setRefCode}
            className='mb2'
          />
          <Checkbox
            name='agree'
            label={t('_reg_4')}
            onChange={e => setAgree(!isAgree)}
            value={isAgree}
            className='mb2'
           />
        <Button type='submit' theme='white' className='block mx-auto mt4 mb2'>
        {t('_reg_5')}
        </Button>
        <Link to={appRoutes.LOGIN} className='center mb4 block'>{t('_reg_7')}</Link>
        <p className='center mb2'>{t('_reg_6')}</p>
        <Socials />
      </Form>
  )
}


export default trans(Reg);
