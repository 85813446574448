import * as Challenges from 'reducers/challenges'
import * as ChallengeAPI from 'api/ChallengeAPI'
import { getErrNotify, getSuccessNotify } from 'utils/helpers';
import { notify } from 'reapop';

export function patch(challengeID, challengeBody, callback) {
  return (dispatch) => {
      ChallengeAPI.patch((challenge) => {
      if (callback) callback(challenge)
      // dispatch(Challenges.update(challenge))
    }, challengeID, challengeBody)
  }
}

export function voteDoneUser(challengeID, callback) {
  return (dispatch) => {
      ChallengeAPI.voteDoneUser((challenge) => {
        if (callback) callback(challenge)
        // dispatch(Challenges.update(challenge))
    }, challengeID)
  }
}

export function voteFailUser(challengeID, callback) {
  return (dispatch) => {
      ChallengeAPI.voteFailUser((challenge) => {
      if (callback) callback(callback)
      // dispatch(Challenges.update(challenge))
    }, challengeID)
  }
}

export function donate(callback, challengeID, value) {
  return (dispatch) => {
      ChallengeAPI.donate((error, message) => {
      
        if(error){
          dispatch(notify(getErrNotify(error)));
        }
        else {
          dispatch(notify(getSuccessNotify('Success', 'You successfully donated to challenge')));
      }

      if (callback) {
        callback(error, message)
      }
   
    }, challengeID, value)
  }
}

export function get(admin) {
    return (dispatch) => {
        ChallengeAPI.load((list) => {
        dispatch(Challenges.set(list))
      }, admin)
    }
  }

  export function approve(challengeID, params, callback) {
    return (dispatch) => {
        ChallengeAPI.approve((challenge) => {
        if (callback) callback(challenge)
        // dispatch(Challenges.update(challenge))
      }, challengeID, params)
    }
  }
  export function refuse(challengeID, callback) {
    return (dispatch) => {
        ChallengeAPI.refuse((challenge) => {
        if (callback) callback(challenge)
        // dispatch(Challenges.update(challenge))
      }, challengeID)
    }
  }
  export function accept(challengeID, callback) {
    return (dispatch) => {
        ChallengeAPI.accept((challenge) => {
        if (callback) callback(chellenge)
        // dispatch(Challenges.update(challenge))
      }, challengeID)
    }
  }
  export function done(challengeID, callback) {
    return (dispatch) => {
        ChallengeAPI.done((challenge) => {
        if (callback) callback(challenge)
        // dispatch(Challenges.update(challenge))
      }, challengeID)
    }
  }
  export function failed(challengeID, callback) {
    return (dispatch) => {
        ChallengeAPI.failed((challenge) => {
        if (callback) callback(challenge)
        // dispatch(Challenges.update(challenge))
      }, challengeID)
    }
  }

  export function createAdmin(challengeBody) {
    return (dispatch) => {
        ChallengeAPI.createAdmin((challenge) => {
        // dispatch(Challenges.add(challenge))
      }, challengeBody)
    }
  }

  export function updated(callback, admin, stream) {
    return (dispatch) => {
      ChallengeAPI.updated((list) => {
        dispatch(Challenges.set(list))
        if (callback) callback(list)
      }, admin, stream)
    }
  }