import * as SocketAPI from "api/SocketAPI"
import { store } from "libs/redux"
import "whatwg-fetch" 

export const loadGameStats = async (callback) => {
  
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`/api/game/stats`, {
      method: "GET",
      headers,
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json.code == "PERMISSION_DENIED") {
          callback(json.message)
        } else {
          // success
          callback(null, json)
        }
      })
      .catch((error) => {
        // AHHHH! An Error!
        callback(error)
      })
  }