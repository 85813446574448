
import * as SocketAPI from "api/SocketAPI"
import { store } from "libs/redux"
import "whatwg-fetch"

const load = async (callback, streamID) => {
  const predictions = store.getState().predictions.predictions
  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/paidaction/stream/${streamID}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
}

const approve = async (callback, challengeID, params) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/admin/paidaction/approve/${challengeID}`, {
  method: "PATCH",
  headers,
  body: JSON.stringify(params)
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}
const accept = async (callback, challengeID) => {
const token = store.getState().user.model.token || ""

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/streamer/paidaction/accept/${challengeID}`, {
  method: "PATCH",
  headers
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}


const createAdmin = async (callback, templateId, paidActionBody) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/paidaction/${templateId}`, {
  method: "POST",
  headers, 
  body: JSON.stringify(paidActionBody),
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    if (json.code == "UNKNOWN_ERROR" || json.code != undefined) {
      callback(json.message)
    } else {
       callback(null, json)
    }
  })
}

const patchUser  = async (callback, paidActionID, paidActionBody) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/paidaction/${paidActionID}`, {
  method: "PATCH",
  headers,
  body: JSON.stringify(paidActionBody),
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}
const patch  = async (callback, paidActionID, paidActionBody) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : '/streamer'}/paidaction/${paidActionID}`, {
  method: "PATCH",
  headers,
  body: JSON.stringify(paidActionBody),
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}

const updated = async (callback, admin, stream) => {
  SocketAPI.paidActions(admin, stream, ({ error, response, type, socket }) => {

    if (error) {
      console.error("SocketAPI paidActions", error, socket)
      return
    }

    if (type == "connect" || type == "disconnect") {
      return
    }

    const paidAction = response.paidaction

    const paidActions = store.getState().paidActions.paidActions

    const index = paidActions.findIndex(item => item.id === paidAction.id)
   
    const oldPaidAction = index != -1 ? paidActions[index] : null
    const paid = update(paidAction, oldPaidAction)

    if (index != -1) {
      paidActions[index] = paid
    } else {
      paidActions.push(paid)
    }
    callback(paidActions)
  })
}

function update(paid, oldPaidAction, callback) {
  
  const paidAction = oldPaidAction || Object()
  paidAction.createdAt = paid.createdAt
  paidAction.currency = paid.currency
  paidAction.customer = paid.customer
  paidAction.description = paid.description
  paidAction.displayName = paid.displayName
  paidAction.id = paid.id
  paidAction.price = paid.price
  paidAction.status = paid.status 
  paidAction.stream = paid.stream
  paidAction.updatedAt = paid.updatedAt
 
  
  if (callback != null) {
    callback(paidAction)
  }
  return paidAction
}

export {
  load,
  approve, 
  accept,
  createAdmin,
  patch,
  updated, 
  patchUser
}
