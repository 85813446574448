
import React from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import get from 'lodash/get';


import * as ModalActions from "actions/ModalActions";
import './landing.scss';
import { DESKTOP_MIN_RESOLUTION } from 'utils/config';

import LandingDesktop from './Landing';
import LandingMobile from './Mobile/Landing';
 
const LandingContainer = (props) => {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: DESKTOP_MIN_RESOLUTION });
  return isDesktopOrLaptop ? <LandingDesktop {...props}/> : <LandingMobile {...props} />;
} 

function mapStateToProps(state) {
    return {
      authModal: get(state, 'modals.authModalNew'), 
    }
  }

function mapDispatchToProps(dispatch) {
    return {
        ModalActions: bindActionCreators(ModalActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LandingContainer);