import React, { Component } from "react"


import { t } from "i18n";

export default () => {
  return (
    <div className='referrals'>
    <div className="tabContent activeTab" id="tab3">
       <div className="tabContent-paragraph" dangerouslySetInnerHTML= {{__html: t('global_referrals')}}>
        </div> 
    </div>
    </div>
  )
}
