import React, { Component } from "react"

export default class PredictionTimerComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      elapsed: new Date() - new Date(this.props.from),
      props
    }
  }

  // counter code from example: https://codepen.io/smonn/pen/KzezEw
  getInitialState() {
    // This is called before our render function. The object that is 
    // returned is assigned to this.state, so we can use it later.

    return { elapsed: 0 }
  }

  componentDidMount() {
    // componentDidMount is called by react when the component 
    // has been rendered on the page. We can set the interval here:
    this.timer = setInterval(this.tick, 1000, this)
  }

  componentWillUnmount() {
    // This method is called immediately before the component is removed
    // from the page and destroyed. We can clear the interval here:
    clearInterval(this.timer)
  }

  tick(root) {
    // This function is called every 50 ms. It updates the 
    // elapsed counter. Calling setState causes the component to be re-rendered
    const to = root.props.to 
    const from = root.props.from
    if(to)  root.setState({ elapsed: Math.abs(new Date(to) - new Date()) })
    else if(from) root.setState({ elapsed: Math.abs( new Date() - new Date(from)) })
  }

  secondsToString(miliseconds) {
    const {show} = this.props
    function addZero(v) {
      return v < 10 ? `0${v}` : v
    }
    const date = new Date(miliseconds)
    const hh = addZero(Math.floor(date/(60*60*1000)) || 0)
    const mm = addZero(date.getUTCMinutes() || 0)
    const ss = addZero(date.getSeconds() || 0)

    let rr = show==='SECONDS' ? `${mm}:${ss}` : `${mm}`
    if (hh >= 0) {
      rr = `${hh}:${rr}`
    }
    return rr
  }

  render() {
    const { state: { elapsed }, props: { desc, icon } } = this
    const time = this.secondsToString(elapsed)

    return (
      <div className="timer">
        { icon }
        <div className="timer__time">{ time }</div>
        <div className="timer__desc">{ desc }
        </div>
      </div>
    )
  }
}
