import React from "react"

export default (props) => {
  return (
    <li className="profileMenuList-item">
      <div className="inputBlock-img">
        <div className="img icon svgIcon-yellow svgIcon-yellow-dims" />
      </div>
      <div className="notificationAllDesc">
        <h3 className="notification-header">You have no notifications yet.</h3>
        <p className="notification-condition">make your first prediction</p>
        <span className="notification-time">now</span>
      </div>
    </li>
  )
}
