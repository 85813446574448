
// def
export const defaultChallenge = (stream) => {
  return {
    stream,
    description: 'Win 3 match in a row',
    acceptDuration: 720*60*60,
    minBankToAccept: 1
  }
}
export const defaultPaidAction = (stream) => {
  return {
    stream,
    description: 'Streamer camera on|off',
    acceptDuration: 72*60*60,
    price: 1,
    currency: 'CUBES',
    type: 'ONETIME' 
  }
}

export const defaultVoting = (stream) => {
  return {
    stream,
    description: 'Choose the game for tomorrow',
    duration: 72*60*60,
    minBankToAccept: 0,
    options: ['Fortnite', 'PUBG'],
    currency: 'CUBES'
  }
}
export const defaultPrediction = (stream) => {
  return {
    title: "Streamer will win the match",
    game: "Custom",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Streamer win", desc: "", bank: 500, users: 1 }, { title: "Streamer lose", desc: "", bank: 500, users: 1 }]
  }
}

export const def = (stream) => {
  return {
    title: "Streamer will win the match",
    game: "Custom",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Streamer win", desc: "", bank: 500, users: 1 }, { title: "Streamer lose", desc: "", bank: 500, users: 1 }]
  }
}

// dota2

export const dota_prd_1 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Streamer's team will win the match",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Win", desc: "" }, { title: "Lose", desc: "" }]
  }
}

export const dota_prd_2 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Streamer's team will kill Roshan first",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const dota_prd_3 = (stream) => {
  return {
    game: 'Dota 2',
    title: "The amount of streamer's kills will be even / odd at the end of the match",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Even", desc: "" }, { title: "Odd", desc: "" }]
  }
}

export const dota_prd_4 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Enemy team will lose their three towers first",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const dota_prd_5 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Streamer's team will get first blood",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const dota_prd_6 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Enemy team will get 10 frags first",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const dota_prd_7 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Streamer will buy Divine Rapier",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const dota_prd_8 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Команда стримера выиграет матч",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Выиграет", desc: "" }, { title: "Проиграет", desc: "" }]
  }
}

export const dota_prd_9 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Команда стримера первой убьет Рошана",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const dota_prd_10 = (stream) => {
  return {
    game: 'Dota 2',
    title: "В конце матча сумма убийств, сделанных стримером, будет четной/нечетной",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Четная", desc: "" }, { title: "Нечетная", desc: "" }]
  }
}

export const dota_prd_11 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Вражеская команда первой потеряет три башни",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const dota_prd_12 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Команда стримера первой убьет вражеского героя",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const dota_prd_13 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Вражеская команда первой наберет 10 убийств",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const dota_prd_14 = (stream) => {
  return {
    game: 'Dota 2',
    title: "Стример приобретет Рапиру",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}
// #lol

export const lol_prd_1 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Streamer's team will win the match",
    mode: "LIVE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Win", desc: "" }, { title: "Lose", desc: "" }]
  }
}

export const lol_prd_2 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Streamer's team will kill Baron first",
    mode: "LIVE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const lol_prd_3 = (stream) => {
  return {
    game: 'League of Legends',
    title: "The amount of streamer's kills will be even / odd at the end of the match",
    mode: "LIVE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Even", desc: "" }, { title: "Odd", desc: "" }]
  }
}

export const lol_prd_4 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Enemy team will lose their three towers first",
    mode: "LIVE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const lol_prd_5 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Streamer's team will get first frag",
    mode: "LIVE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const lol_prd_6 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Enemy team will get 10 frags first",
    mode: "LIVE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const lol_prd_7 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Команда стримера выиграет матч",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Выиграет", desc: "" }, { title: "Проиграет", desc: "" }]
  }
}

export const lol_prd_8 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Команда стримера первой убьет Барона",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const lol_prd_9 = (stream) => {
  return {
    game: 'League of Legends',
    title: "В конце матча сумма убийств, сделанных стримером, будет четной/нечетной",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Четная", desc: "" }, { title: "Нечетная", desc: "" }]
  }
}

export const lol_prd_10 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Вражеская команда первой потеряет три башни",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const lol_prd_11 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Команда стримера первой убьет вражеского героя",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const lol_prd_12 = (stream) => {
  return {
    game: 'League of Legends',
    title: "Вражеская команда первой наберет 10 убийств",
    stream,
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}
// #csgo

export const csgo_prd_1 = (stream) => {
  return {
    game: "CS:GO",
    title: "Streamer`s team will win the match",
    mode: "LIVE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Win", desc: "" }, { title: "Lose", desc: "" }]
  }
}

export const csgo_prd_2 = (stream) => {
  return {
    game: "CS:GO",
    title: "Streamer`s team will win round",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Win", desc: "" }, { title: "Lose", desc: "" }]
  }
}

export const csgo_prd_3 = (stream) => {
  return {
    game: "CS:GO",
    title: "The amount of win rounds will be even / odd at the end of the match",
    mode: "FUTURE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Even", desc: "" }, { title: "Odd", desc: "" }]
  }
}

export const csgo_prd_4 = (stream) => {
  return {
    game: "CS:GO",
    title: "Streamer's team will be leading after 15 rounds",
    mode: "FUTURE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}
export const csgo_prd_5 = (stream) => {
  return {
    game: "CS:GO",
    title: "Команда стримера выиграет матч",
    mode: "LIVE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const csgo_prd_6 = (stream) => {
  return {
    game: "CS:GO",
    title: "Команда стримера выиграет раунд",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}
// #pubg

export const pubg_prd_1 = (stream) => {
  return {
    game: "PUBG",
    title: "Streamer will stay alive untill the end of the match",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Win", desc: "" }, { title: "Lose", desc: "" }]
  }
}

export const pubg_prd_2 = (stream) => {
  return {
    game: "PUBG",
    title: "Streamer will have 8 frags or more in the match",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "8 or more", desc: "" }, { title: "7 or less", desc: "" }]
  }
}

export const pubg_prd_3 = (stream) => {
  return {
    game: "PUBG",
    title: "Streamer will stay alive among last 60 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const pubg_prd_4 = (stream) => {
  return {
    game: "PUBG",
    title: "Streamer will stay alive among last 20 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const pubg_prd_5 = (stream) => {
  return {
    game: "PUBG",
    title: "Streamer will stay alive among last 10 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const pubg_prd_6 = (stream) => {
  return {
    game: "PUBG",
    title: "Streamer will stay alive among last 5 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const pubg_prd_7 = (stream) => {
  return {
    game: "PUBG",
    title: "Стример останется в живых до конца матча",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const pubg_prd_8 = (stream) => {
  return {
    game: "PUBG",
    title: "Стример сделает 8 или более убийств за матч",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "8 или больше", desc: "" }, { title: "7 или меньше", desc: "" }]
  }
}

export const pubg_prd_9 = (stream) => {
  return {
    game: "PUBG",
    title: "Стример останется в живых среди 50 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const pubg_prd_10 = (stream) => {
  return {
    game: "PUBG",
    title: "Стример останется в живых среди 20 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const pubg_prd_11 = (stream) => {
  return {
    game: "PUBG",
    title: "Стример останется в живых среди 10 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const pubg_prd_12 = (stream) => {
  return {
    game: "PUBG",
    title: "Стример останется в живых среди 5 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

// #fortnite

export const fortnite_prd_1 = (stream) => {
  return {
    game: "Fortnite",
    title: "Streamer will stay alive untill the end of the match",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Win", desc: "" }, { title: "Lose", desc: "" }]
  }
}

export const fortnite_prd_2 = (stream) => {
  return {
    game: "Fortnite",
    title: "Streamer will have 8 frags or more in the match",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "8 or more", desc: "" }, { title: "7 or less", desc: "" }]
  }
}

export const fortnite_prd_3 = (stream) => {
  return {
    game: "Fortnite",
    title: "Streamer will stay alive among last 40 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const fortnite_prd_4 = (stream) => {
  return {
    game: "Fortnite",
    title: "Streamer will stay alive among last 20 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const fortnite_prd_5 = (stream) => {
  return {
    game: "Fortnite",
    title: "Streamer will stay alive among last 10 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const fortnite_prd_6 = (stream) => {
  return {
    game: "Fortnite",
    title: "Streamer will stay alive among last 5 players",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const fortnite_prd_7 = (stream) => {
  return {
    game: "Fortnite",
    title: "Стример останется в живых до конца матча",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const fortnite_prd_8 = (stream) => {
  return {
    game: "Fortnite",
    title: "Стример сделает 8 или более убийств за матч",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "8 или больше", desc: "" }, { title: "7 или меньше", desc: "" }]
  }
}

export const fortnite_prd_9 = (stream) => {
  return {
    game: "Fortnite",
    title: "Стример останется в живых среди 40 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const fortnite_prd_10 = (stream) => {
  return {
    game: "Fortnite",
    title: "Стример останется в живых среди 20 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const fortnite_prd_11 = (stream) => {
  return {
    game: "Fortnite",
    title: "Стример останется в живых среди 10 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const fortnite_prd_12 = (stream) => {
  return {
    game: "Fortnite",
    title: "Стример останется в живых среди 5 игроков",
    stream, 
    type: "twitch",
    mode: "LIVE_MODE",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

// #hearthstone

export const hearthstone_prd_1 = (stream) => {
  return {
    game: 'Hearthstone',
    title: "Streamer will win the match",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Win", desc: "" }, { title: "Lose", desc: "" }]
  }
}

export const hearthstone_prd_2 = (stream) => {
  return {
    game: 'Hearthstone',
    title: "Streamer will have more than 10 HP at the end of the match",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Yes", desc: "" }, { title: "No", desc: "" }]
  }
}

export const hearthstone_prd_3 = (stream) => {
  return {
    game: 'Hearthstone',
    title: "Streamer will have an even / odd number of HP at the end of the match",
    mode: "FUTURE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Even", desc: "" }, { title: "Odd", desc: "" }]
  }
}

export const hearthstone_prd_4 = (stream) => {
  return {
    game: 'Hearthstone',
    title: "Стример выиграет матч",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Выиграет", desc: "" }, { title: "Проиграет", desc: "" }]
  }
}

export const hearthstone_prd_5 = (stream) => {
  return {
    game: 'Hearthstone',
    title: "У стримера останется более 10 очков жизни в конце матча",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}


// #World_of_Tanks

export const wot_prd_1 = (stream) => {
  return {
    game: 'World of Tanks',
    title: "Команда стримера выиграет бой",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const wot_prd_2 = (stream) => {
  return {
    game: 'World of Tanks',
    title: "Танк/САУ стримера будет уничтожен до конца боя",
    mode: "FUTURE_MODE",
    stream, 
    type: "twitch",
    outcomes: [{ title: "Да", desc: "" }, { title: "Нет", desc: "" }]
  }
}

export const wot_prd_3 = (stream) => {
  return {
    game: 'World of Tanks',
    title: "Стример уничтожит Х единиц техники противника в бою",
    mode: "FUTURE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Х или больше", desc: "" }, { title: "Меньше Х", desc: "" }]
  }
}

export const wot_prd_4 = (stream) => {
  return {
    game: 'World of Tanks',
    title: "Стример нанесет Х единиц урона технике противника в бою",
    mode: "FUTURE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "Х или больше", desc: "" }, { title: "Меньше Х", desc: "" }]
  }
}

export const wot_prd_5 = (stream) => {
  return {
    game: 'World of Tanks',
    title: "Стример сделает 0,четное/нечетное количество фрагов в бою",
    mode: "FUTURE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "0 или четное", desc: "0,2,4,6,8,10,12,14" }, { title: "Нечетное", desc: "1,3,5,7,9,11,13,15" }]
  }
}

export const wot_prd_6 = (stream) => {
  return {
    game: 'World of Tanks',
    title: "Стример получит очки защиты или захвата базы по итогам боя",
    mode: "FUTURE_MODE",
    stream,
    type: "twitch",
    outcomes: [{ title: "получит", desc: "" }, { title: "не получит", desc: "" }]
  }
}
