import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/_Button';
import Form from 'components/_Form';
import Textarea from 'components/Textarea';
import { trans } from 'utils/translator';

const SuggestChallenge = props => {

    const [displayName, setDisplayName] = useState('');
    const [description, setDescription] = useState('');
    const [donate, setDonate] = useState('');
    const { stream, t } = props;


    const onSubmit = (challenge) => {
        props.PredictionsActions.createChallenge(challenge);
    }

    return (
        <Form className='suggest-challenge feedback py3 px5 bg-white black border' action='javascript:void(0);' onSubmit={() => onSubmit({
            displayName,
            description, 
            donate,
            stream
        })}>
            <h1 className="h1 mb4">{t('_suggest_challenge_1')}</h1>
            <label htmlFor="" className='mb1 bold t3'>{t('_suggest_challenge_2')}</label>
            <Input
                className='mb3 t3'
                value={displayName}
                onChange={setDisplayName}
                placeholder={t('_suggest_challenge_3')}
                view='ghost'
                />
            <label htmlFor="" className='mb1 bold t3'>{t('_suggest_challenge_4')}</label>
            <Textarea
                value={description}
                onChange={setDescription}
                placeholder={t('_suggest_challenge_5')}
                className='mb3 t3'
            />
            <div className="flex mb5">
                <div className="">
                    <label htmlFor="" className='mb1 bold t3'>{t('_suggest_challenge_6')}</label>
                    <Input view='ghost' value={donate} onChange={setDonate} className='' placeholder={t('_suggest_challenge_7')} />
                </div>
            </div>
            <Button theme='purple' type='submit' className='mx-auto'>{t('_suggest_challenge_8')}</Button>
        </Form>
    )
}

SuggestChallenge.propTypes = {

}

export default trans(SuggestChallenge);
