import React from 'react'
import PropTypes from 'prop-types'
import Button  from 'components/ds/Button';
import { StackLayout } from 'components/ds/Layouts';

export const MessageCard = ({header, message, action1, action2}) => {
    return <div className="ds-message-card">
        <h5>{header}</h5>
        <div className="ds-message-card__body">
            {message}
        </div>
        <div className="ds-message-card__actions">
            <StackLayout gutter='small'>
                <Button onClick={action1}>Action 1</Button>
                <Button onClick={action2}>Action 2</Button>
            </StackLayout>
        </div>
    </div>
}



