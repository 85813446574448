import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Streams from './Streams';
import  './streams.scss';


function mapStateToProps(state) {
    return {
      streams: state.streamList.streamlist,
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      
    }
  }

export default connect(mapStateToProps, null)(Streams)
