import React, { Component } from "react"

export default (props) => {
  // console.log("finished state", props)
  if (props.isPlaced) {
    if (props.selected == 1) {
      return (
        <div className="predictionContainerBlock__bottom">
          <table cellSpacing="0">
            <thead>
              <tr>
                <th width="42%">Your Prediction</th>
                <th width="58%">Profit</th>
              </tr>
            </thead>
            <tbody>
              <tr className={`team_one ${props.isWin ? "win" : "lose"}`}>
                <td>
                  <button>{props.winBtn}</button>
                </td>
                <td>
                  <span>{props.isWin ? parseInt(props.winValue) : "Wrong Prediction"}</span>
                </td>
              </tr>
              
                <tr className="team_two disabled">
                  <td>
                    <button>{props.loseBtn}</button>
                  </td>
                  <td>
                    <span>{props.K2}</span>
                  </td>
                </tr>
                
            </tbody>
          </table>
        </div>
      )
    }
    return (
      <div className="predictionContainerBlock__bottom">
        <table cellSpacing="0">
          <thead>
            <tr>
              <th width="42%">Your Prediction</th>
              <th width="58%">Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr className="team_one disabled">
              <td>
                <button>{props.winBtn}</button>
              </td>
              <td>
                <span>{props.K1}</span>
              </td>
            </tr>
            
              <tr className={`team_two ${props.isWin ? "win" : "lose"}`}>
                <td>
                  <button>{props.loseBtn}</button>
                </td>
                <td>
                  <span>{props.isWin ? parseInt(props.winValue) : "Wrong Prediction"}</span>
                </td>
              </tr>
                
          </tbody>
        </table>
      </div>
    )
  } 

  //by default
  if (props.selected == 1) {
    return (
      <div className="predictionContainerBlock__bottom">
        <table cellSpacing="0">
          <thead>
            <tr>
              <th width="42%">Prediction</th>
              <th width="58%">Coefficient</th>
            </tr>
          </thead>
          <tbody>
            <tr className={`team_one ${props.isWin ? "win first" : "lose"}`}>
              <td>
                <button>{props.winBtn}</button>
              </td>
              <td>
                <span>{props.K1}</span>
              </td>
            </tr>
            
              <tr className="team_two disabled">
                <td>
                  <button>{props.loseBtn}</button>
                </td>
                <td>
                  <span>{props.K2}</span>
                </td>
              </tr>
              
          </tbody>
        </table>
      </div>
    )
  } 
  return (
    <div className="predictionContainerBlock__bottom">
      <table cellSpacing="0">
        <thead>
          <tr>
              <th width="42%">Prediction</th>
              <th width="58%">Coefficient</th>
          </tr>
        </thead>
        <tbody>
          <tr className="team_one disabled">
            <td>
              <button>{props.winBtn}</button>
            </td>
            <td>
              <span>{props.K1}</span>
            </td>
          </tr>
          
            <tr className={`team_two ${props.isWin ? "win first" : "lose"}`}>
              <td>
                <button>{props.loseBtn}</button>
              </td>
              <td>
                <span>{props.K2}</span>
              </td>
            </tr>
              
        </tbody>
      </table>
    </div>
  )
}
