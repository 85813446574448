import * as user from "../reducers/user"
import * as users from "reducers/users"
import * as UsersAPI from "api/UsersApi"

import * as PredictionsActions from "./PredictionsActions"
import * as ModalActions from './ModalActions';

import { predictions } from "api/SocketAPI"
import { store } from "libs/redux"
import "whatwg-fetch" 
import { userActionTypes } from './actionTypes';
import { history } from 'libs/router';
import { getErrNotify } from 'utils/helpers';
import { notify } from 'reapop';

export const signIn = (email, password, json, token) => {
 /**
 |--------------------------------------------------
 | @REFACTOR THIS PIECE OF SHIT
 |--------------------------------------------------
 */
  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const isAuthenticated = json.token != null && json.token != ""
  
  if(!json.avatar){
    json.avatar = "/assets/images/avatar.svg";
  }

  userL.id = json.id
  userL.email = json.email
  userL.password = json.password
  userL.promo = json.promo
  userL.name = json.name
  userL.balance = json.balance
  userL.permissions = json.permissions
  userL.token = json.token
  userL.facebook = json.facebook
  userL.twitch = json.twitch
  userL.google = json.google
  userL.steam = json.steam
  userL.yourPromo = json.yourPromo
  userL.avatar = json.avatar
  userL.orbs = json.orbs
  userL.streams = json.streams
  userL.push = json.push
  userL.referralCode = json.referralCode
  userL.cardToken = json.cardToken
  userL.passiveOrbs = json.passiveOrbs
  if (token) {
    userL.token = token
  }

 

  localStorage.setItem("predictoria_currentUser", JSON.stringify(userL))

  
    const referalCode = localStorage.getItem('referalCode')
    // const referalCode = "b828018bfe048924096d7c3738419d2e"
    if(referalCode){
    UsersAPI.useReferalCode(referalCode, (err, msg) => {
    this.updateUserBalance() 
    localStorage.setItem('referalCode', null)
    })
    }
  

  return user.collection.set({
    isAuthenticated,
    "model.permissions": json.permissions, 
    "model.id": json.id,
    "model.email": email,
    "model.password": password,
    "model.name": json.name,
    "model.balance": json.balance,
    "model.avatar": json.avatar,
    "model.token": token,
    "model.yourPromo": json.yourPromo,
    "model.promo": json.promo,
    "model.orbs": json.orbs,
    "model.streams": json.streams,
    "model.push": json.push,
    "model.referralCode": json.referralCode,
    "model.cardToken": json.cardToken,
    "model.passiveOrbs": json.passiveOrbs,
    "model.twitch": json.twitch,
    "model.youtube": json.youtube,
    "model.goodgame": json.goodgame
  });
}

export const signUp = (email, password, promo, json) => {

  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const isAuthenticated = json.token != null && json.token != ""

  userL.id = json.id
  userL.email = json.email
  userL.password = json.password
  userL.name = json.name
  userL.balance = json.balance
  userL.permissions = json.permissions
  userL.token = json.token
  userL.facebook = json.facebook
  userL.twitch = json.twitch
  userL.google = json.google
  userL.steam = json.steam
  userL.yourPromo = json.yourPromo
  userL.promo = json.promo
  userL.avatar = json.avatar
  userL.orbs = json.orbs
  userL.streams = json.streams
  userL.push = json.push
  userL.referralCode = json.referralCode
  userL.cardToken = json.cardToken
  userL.passiveOrbs = json.passiveOrbs
  userL.youtube = json.youtube
  userL.goodgame = json.goodgame
  localStorage.setItem("predictoria_currentUser", JSON.stringify(userL))


  return user.collection.set({
    isAuthenticated,
    "model.permissions": json.permissions, 
    "model.id": json.id,
    "model.email": email,
    "model.password": password,
    "model.promo": json.promo,
    "model.name": json.name,
    "model.balance": json.balance,
    "model.avatar": json.avatar,
    "model.token": json.token,
    "model.yourPromo": json.yourPromo,
    "model.orbs": json.orbs,
    "model.streams": json.streams,
    "model.push": json.push,
    "model.referralCode": json.referralCode,
    "model.cardToken": json.cardToken,
    "model.passiveOrbs": json.passiveOrbs,
    "model.twitch": json.twitch,
    "model.youtube": json.youtube,
    "model.goodgame": json.goodgame
  })
}

export const updateUser = (name, email, password, callback) => (dispatch) => {

  // да, знаю, так нельзя. нужно переделать потом
  const userLocal = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  userLocal.name = name
  userLocal.email = email

  localStorage.setItem("predictoria_currentUser", JSON.stringify(userLocal))
  const token = userLocal.token || ""

  const body = { name, email }
  if (password != "" && userLocal.password != password) {
    body.password = password
  }

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch("/api/user", {
    method: "PATCH",
    headers,
    body: JSON.stringify(body)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {

      if (json.status == 1002 || json.status == 1001) {
        console.error("updateUser:", json)
        if (callback != null) {
          callback(json.message, "error")
        }
        return
      }
      if (callback != null) {
        callback("Saved successfully", "success")
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.error("updateUser error:", error)
    })

  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const isAuthenticated = userL.token != null && userL.token != ""

  dispatch(user.collection.set({
    isAuthenticated,
    "model.email": email,
    "model.password": password,
    "model.name": name,
  }))
}


export const updateSocial = (provider, id) => (dispatch) => {

  // да, знаю, так нельзя. нужно переделать потом
  const userLocal = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()

  switch (provider) {
    case "facebook":
      userLocal.facebook = id
      dispatch(user.collection.set({
        facebook: id
      }))
      break
    case "steam":
      userLocal.steam = id
      dispatch(user.collection.set({
        steam: id
      }))
      break
    case "twitch":
      userLocal.twitch = id
      dispatch(user.collection.set({
        twitch: id
      }))
      break
    case "google":
      userLocal.google = id
      dispatch(user.collection.set({
        google: id
      }))
      break
    default: break
  }

  localStorage.setItem("predictoria_currentUser", JSON.stringify(userLocal))
}

export const updateUserBalance = () => (dispatch) => {
  const userLocal = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = userLocal.token

  const headers = new Headers()
  headers.append("Content-Type", "application/json")
  headers.append("Authorization", token)

  fetch("/api/user", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 1002 || json.status == 1001) {
        console.error("readUser: balance", json)
        return
      }

      if (json.message === "invalid signature") {
        localStorage.clear()
        location.href = "/"

        return
      }

      const userL = JSON.parse(localStorage.getItem("predictoria_currentUser"))
    
      userL.id = json.id
      userL.email = json.email
      userL.password = json.password
      userL.name = json.name
      userL.balance = json.balance
      userL.permissions = json.permissions
      userL.facebook = json.facebook
      userL.twitch = json.twitch
      userL.google = json.google
      userL.steam = json.steam
      userL.yourPromo = json.yourPromo
      userL.promo = json.promo
      userL.avatar = json.avatar
      userL.orbs = json.orbs
      userL.streams = json.streams
      userL.push = json.push
      userL.referralCode = json.referralCode
      userL.cardToken = json.cardToken
      userL.passiveOrbs = json.passiveOrbs
      userL.youtube = json.youtube
      userL.goodgame = json.goodgame
      localStorage.setItem("predictoria_currentUser", JSON.stringify(userL))
      dispatch(user.updateUserBalance(json.balance, json.orbs))
     
    })
    .catch((error) => {
      console.error("readUser error:", error)
      
    })
}
// "model.balance": store.getState().user.model.balance - balance

// predictions((err, prediction) => {
//   updateBalance(prediction.prediction.yourBet.value)
// })

export function editUserBalance(balance) {
  return (dispatch) => {
    dispatch(user.setUserBalance(balance))
  }
}

export const updateAvatar = (url, callback) => (dispatch) => {
  const userLocal = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", userLocal.token)

  userLocal.avatar = url
  localStorage.setItem("predictoria_currentUser", JSON.stringify(userLocal))

  const body = { avatar: url }

  fetch("/api/user", {
    method: "PATCH",
    headers,
    body: JSON.stringify(body)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {

      if (json.status == 1002 || json.status == 1001) {
        console.error("updateUser:", json)
        if (callback != null) {
          callback(json.message, "error")
        }
        return
      }

      dispatch(user.updateUserAvatar(url))

      if (callback != null) {
        callback("Saved successfully", "success")
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.error("updateUser error:", error)
    })

  dispatch(user.collection.set({
    "model.avatar": url
  }))
}

export const updatePassword = (token, password, callback) => {
  const userLocal = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body

  fetch("/api/user/password/reset", {
    method: "PATCH",
    headers,
    body: JSON.stringify({ token, password })
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {

      if (json.code == "UNKNOWN_ERROR" || json.code == "EMAIL_NOT_FOUND") {
        callback(null, {
          title: "Error",
          message: json.message,
          status: "warning"
        })
        return
      }

      callback({
        title: "Reset password",
        message: "Updated",
        status: "success"
      })
    })
    .catch((error) => {
      // AHHHH! An Error!
      const message = error ? `: ${error}` : ""
      callback(null, {
        title: "Server Error",
        message: `Try again or contact us via feedback form${message}`,
        status: "error"
      })
    })

  return user.collection.set({
    "model.password": password
  })
}

export const getBonusCubes = (bonuscode, callback) => {
  UsersAPI.getShareCubes(bonuscode, callback)
}

export const search = queue => (dispatch) => {
  UsersAPI.search(queue, (error, list) => {
    if (error != null) {
      console.error(error)
      return
    }
    dispatch(users.set(list))
  })
}

export const save = (queue, callback) => (dispatch) => {
  UsersAPI.save(queue, (error, list) => {
    if (error != null) {
      console.error(error)
    }
    if(callback) callback(error, list)
  })
}

export const remove = id => (dispatch) => {
  dispatch(console.error("UserActions.`remove` this action not implemented"))
}


// auth routine 
function login(email, password) {
  return dispatch => {
      dispatch(request({ email }));
      UsersAPI.login(email, password)
          .then(res => { 
                dispatch(readUser(res.token));
                dispatch(ModalActions.hideModal());
              }
          )
          .catch(error => {
              dispatch(failure(error.toString()));
          });
  };

  function request(user) { return { type: userActionTypes.LOGIN_REQUEST, user } }
  function success(user) { return { type: userActionTypes.LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userActionTypes.LOGIN_FAILURE, error } }
}

function readUser(token) {
  return dispatch => {
    dispatch(request({ token }));
    UsersAPI.readUser(token)
        .then(
            res => { 
                if(res.code){
                  dispatch(notify(getErrNotify(res)));
                }
                else {
                  dispatch(signIn(null, null, res, token));
                  // history.push('/main');
                }
            },
            error => {
              dispatch(notify(getErrNotify(res)));
            }
        );
};

  function request(user) { return { type: userActionTypes.READ_USER_REQUEST, user } }
  function success(user) { return { type: userActionTypes.READ_USER_SUCCESS, user } }
  function failure(error) { return { type: userActionTypes.READ_USER_FAILURE, error } }
}


function register(email, password, referralCode) {
  return dispatch => {
    dispatch(request({ email, password, referralCode }));
    UsersAPI.register(email, password, referralCode)
        .then(
            user => { 
                if(user.code){
                  alert(`Error ocurred. ${user.code}. \n ${user.message}.`);
                  return;
                }
                dispatch(login(email, password));
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
};

  function request(user) { return { type: userActionTypes.REGISTER_REQUEST, user } }
  function success(user) { return { type: userActionTypes.REGISTER_SUCCESS, user } }
  function failure(error) { return { type: userActionTypes.REGISTER_FAILURE, error } }
}


export {
  login,
  readUser,
  register
};