import React from "react"
import { Link } from "react-router-dom"
import "../../assets/style/partials/emptyPage.scss"

export default () => {
  return (
    <div className="travoltaPage">
      <div className="travolta">
        <h1>404</h1>
        <p>It seems you got lost...</p>
        <Link to="/">Go back</Link>
        {/* <img src="https://media.giphy.com/media/54rv8mQZtZgyY/giphy.gif" alt='404' /> */}
      </div>
    </div>

  )
}
