import * as TemplateVoting from "reducers/templateVoting"
import * as TemplateVotingAPI from "api/TemplateVotingAPI"


export function setTemplates(list) {
  return (dispatch) => {
    dispatch(TemplateVoting.setTemplates(list))
  }
}

export const create = (template, callback) => (dispatch) => {
  TemplateVotingAPI.create(template, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(TemplateVoting.add(template))
    if (callback != null) {
      callback(null, template)
    }
  })
}

export const remove = (id, callback) => (dispatch) => {
  TemplateVotingAPI.remove(id, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(TemplateVoting.remove(id))
    if (callback != null) {
      callback(null, template)
    }
  })
}

export const get = (title, stream) => (dispatch) => {
  TemplateVotingAPI.get(title, stream, (error, templates) => {
    if (error) {
      console.error(error)
      return
    }
    dispatch(TemplateVoting.setTemplates(templates))
  })
}

export const patch = (id, template, callback) => (dispatch) => {
  TemplateVotingAPI.patch(id, template, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(TemplateVoting.update(template))
    if (callback != null) {
      callback(null, template)
    }
  })
}