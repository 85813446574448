import AuthForm from "components/AuthForm"
import { modalsActionTypes } from 'actions/actionTypes';
/**
|--------------------------------------------------
| DEPRECATED
|--------------------------------------------------
*/

const NAMESPACE = "MODALS"

// const initialState = {
//   authModal: {
//     show: false,
//     activeTab: AuthForm.SIGNIN_TAB
//   },
//   quickDonationModal: {
//     isOpen: false, 
//   },
//   authModalNew: {
//     show: false,
//     activeTab: AuthForm.SIGNIN_TAB
//   },
// }

// @TODO review this tresh
// export default (state = initialState, action) => {
//   switch(action.type){
//     case 'TOGGLE_AUTH_MODAL_NEW': 
//       return { ...state, authModalNew: { ...state.authModalNew, ...action.data, show: !state.authModalNew.show  }}
//     case 'TOGGLE_AUTH_MODAL': 
//       return { ...state, authModal: { ...state.authModal, ...action.data, show: !state.authModal.show } };
//     case 'SWITCH_AUTH_TABS':
//       return  { ...state, authModal: { ...state.authModal, activeTab: action.tab } };
//     case 'TOGGLE_QUICK_DONATION_MODAL':
//       return { ...state, quickDonationModal: { isOpen: !state.quickDonationModal.isOpen, ...action.data } };
//     default: 
//       return state;

//   }

// }

/**
|--------------------------------------------------
| NEW MODALS REDUCER
|--------------------------------------------------
*/

const initialState = {
  modalType: null,
  modalProps: {},
    authModal: {
    show: false,
    activeTab: AuthForm.SIGNIN_TAB
  },
  quickDonationModal: {
    isOpen: false, 
  },
  authModalNew: {
    show: false,
    activeTab: AuthForm.SIGNIN_TAB
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case modalsActionTypes.SHOW_MODAL:
      return {
        ...state,
        modalProps: action.modalProps,
        modalType: action.modalType,
        type: action.type
      }
    case modalsActionTypes.HIDE_MODAL:
      return initialState
    default:
      return state
  }
}