import React from 'react'
import PropTypes from 'prop-types'
import ProfileSectionHead from '../ProfileSectionHead';

const ProfilePartnership = props => {
    return (
        <div className='profile-partnership bg-white p4'>
            <ProfileSectionHead
                title='Партнерское соглашение'
                description='Условия, обезательства и остальная “очень полезная” информация. А еще ссылки на ваши аккаунты. И все в одном месте.'
            />
            <p className='mb2'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing duis velit sed laoreet in nec tristique. Fusce donec blandit sit interdum leo at nisi mauris. Sed scelerisque augue pellentesque cursus aliquam odio enim malesuada dolor. Etiam elit varius aliquet sapien et malesuada. Ultrices euismod pharetra libero massa. Laoreet neque non et etiam risus ac interdum. Blandit eleifend ornare ut sit maecenas. Viverra feugiat quis enim leo lobortis lectus. Proin nisl lobortis urna, habitant dignissim curabitur bibendum. Nam sed felis, ultricies morbi aliquet viverra. Mauris bibendum morbi neque tortor mi morbi fermentum. Urna felis sollicitudin at nunc, neque. Fames vestibulum imperdiet gravida semper aenean. Lectus tellus dignissim nisl, id sagittis facilisis feugiat. Pulvinar quis proin a sagittis, consectetur. Sem pulvinar at consequat in magnis. Senectus in ligula convallis viverra interdum pretium a. Blandit accumsan sit fringilla convallis volutpat arcu risus. Quam habitasse eu eget gravida pharetra. Nibh amet, facilisis amet viverra adipiscing ornare nisl nullam gravida.
            </p>
            <h5 className="h5 bold ">
                Статус партнерки
            </h5>
        </div>
    )
}

ProfilePartnership.propTypes = {

}

export default ProfilePartnership




