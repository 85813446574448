import React from 'react'
import PropTypes from 'prop-types'

import fbIcon from 'assets/images/main/icons/normal-facebook-white.svg';
import discordIcon from 'assets/images/main/icons/normal-discord-white.svg';

const Socials = props => {

    return (
        <div className='flex justify-around'>
            <a href='https://www.facebook.com/predictoria' className='mr2'>
                <img src={fbIcon} />
            </a>
            <a href='https://discord.gg/mTbbf7g'>
                <img src={discordIcon} />
            </a>
        </div>
    )
}

Socials.propTypes = {

}

export default Socials
