import React, {Component} from "react"
import {get} from "api/TransactionAPI"
import {trans} from "utils/translator"
import {Link} from "react-router-dom"
import ReactPaginate from 'react-paginate';

export default trans(class CreateStream extends Component {

  
    constructor(props) {
        super(props)
        const {user} = props
        const isStreamer = user.model && (user.model.permissions || []).includes('STREAM')

        this.state = {
            transactions: [],
            showOnlyOrbs: false,
            page: 1,
            isStreamer,
            pages: 1,
        }

        this.renderTransactionRow = this.renderTransactionRow.bind(this)
    }
  
    componentDidMount(){
       get(this.state.page, (transactions) => {
            this.setState({
                transactions: transactions,
                pages: transactions.pages,
            }, () => {

            }) 
        })
        gtag("event", "profile", { 
            event_category: 'webpage_interraction',
            event_action: 'profile_payments_page_visited',
          })
    }
    onlyOrbsChange = () => {
        this.setState({
            showOnlyOrbs: !this.state.showOnlyOrbs
        })
    }
    handlePageClick = (data) => {
        let selected = data.selected + 1
        this.setState({
            page: selected
            }, () => {
                get(this.state.page, (transactions) => {
                    this.setState({
                        transactions: transactions,
                    }) 
                })
            })
    }
    renderActivityType = description => {
        if(description.toLowerCase().includes('paidaction')) return 'Paid action'
        if(description.toLowerCase().includes('prediction')) return 'Prediction'
        if(description.toLowerCase().includes('challenge')) return 'Challenge'
        if(description.toLowerCase().includes('voting')) return 'Voting'
        if(description.toLowerCase().includes('refill')) return 'Refill'
        if(description.toLowerCase().includes('withdraw')) return 'Withdraw'
        if(description.toLowerCase().includes('income')) return 'Income'
        if(description.toLowerCase().includes('outcome')) return 'Outcome'
    } 

    renderTransactionRow = transaction => {
        const {streamer, createdAt, description, operation, currency, value } = transaction
        const { isStreamer } = this.state
        const creationDate = new Date(transaction.createdAt)
        if(this.state.showOnlyOrbs && currency != 'ORBS') return 
        return <tr className='transactions__row'>
            <td className='transactions__status'> <div className={`transactions__circle ${currency=='ORBS' ? 'gold': ''}`}></div></td>
            <td className='transactions__activity'>{this.renderActivityType(description) || 'Not specified'}</td>
            <td className='transactions__operation'>{isStreamer && operation=='INCOME' ? '+': null}{value.toFixed(2).replace('.00', '')} {currency.toLowerCase()}</td>
            <td className='transactions__streamer'><Link to={`/stream/${streamer}`}>{streamer || 'Not specified'}</Link></td>
            <td className='transactions__date'>{`${creationDate.toLocaleTimeString()} ${creationDate.toLocaleDateString()}`}</td>
        </tr>
    }
    render(){
        const { t, user } =this.props
        const {transactions} = this.state
        const transactionList = transactions.rows
        const isStreamer = _.get(user, 'model.permissions', []).includes('STREAM')
        
        return (
            <div className='profilePage'>
            <div className='profile-page__transactions transactions'>
         
            <div className='transactions__table'>
            <label className="transactions__label" htmlFor="orbs">
        Orbs only
          <input
            className="transactions__checkbox"
            name="orbs"
            type="checkbox"
            onChange={this.onlyOrbsChange}
          />
        </label>
            <table cellSpacing="0">
            <thead className='transactions__header'>
            <tr>
            <th className='transaction__circle'></th>
            <th className='transactions__activity'>{t('profile_activity')}</th>
            <th className='transactions__operation'>{t('profile_operation')}</th>
            <th className='transactions__streamer'>{t('profile_streamer')}</th>
            <th className='transactions__date'>{t('profile_date')}</th>
            </tr>
            </thead>
            <tbody className='transactions__body'>
            {
                Array.isArray(transactionList) ? 
                transactionList.map(t => {
                    return this.renderTransactionRow(t)
                }) : null
            }
            </tbody>
            </table>
            <div className="transactions__paginator-wrapper"> 
            <div className="transactions__paginator-container"> 
            <ReactPaginate previousLabel={"previous"}
                       nextLabel={"next"}
                       breakLabel={"..."}
                       breakClassName={"break-me"}
                       pageCount={this.state.pages || 0}
                       marginPagesDisplayed={2}
                       pageRangeDisplayed={5}
                       onPageChange={this.handlePageClick}
                       containerClassName={"pagination"}
                       subContainerClassName={"pages pagination"}
                       activeClassName={"active"} />
            </div>
            </div>
            </div>
            </div>
            </div>
  )

}
}
)