const initialState = {
  showBlock: {
    show: false,
  }
}

const NAMESPACE = "DEPOSIT"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const toggleBlock = (show, data) => {
  return {
    namespace: NAMESPACE,
    type: "TOGGLE_BLOCK",
    handle: (state) => {
      show = show || !state.showBlock
      return { ...state, showBlock: { ...state.showBlock, ...data, show } }
    }
  }
}
