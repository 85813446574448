import React from "react"

function StatsControl({ resultMeta, query, setQuery, searchStats }) {
  const changePage = (page) => {
    setQuery("page", page, searchStats)
  }

  return (
    <div className="admin-stats__filter-wrap admin_stats__table-control-top">
      <div>
        Items: {resultMeta.predictionsCount} |
        Pages: {resultMeta.pagesCount}
      </div>
      <div className="admin-stats__bank-title">Bank: {resultMeta.fullBank || 0}</div>
      <div className="admin-stats__filter-item-wrap admin--stats__table-control-item">
        Items on page
        <select
          onChange={e => setQuery("limit", e.target.value)}
          value={query.limit}
        >
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div className="admin-stats__filter-item-wrap admin-stats__table-control-item">
        <div className="admin-stats__pagination-wrap">
          {resultMeta.page > 2
            ? <div
              role="navigation"
              onClick={() => changePage(1)}
              className="admin-stats__pagination-button"
            >1</div>
            : null}
          {resultMeta.page > 3
            ? <div className="admin-stats__pagination-button">...</div>
            : null}
          {resultMeta.page > 1
            ? <div
              role="navigation"
              onClick={() => changePage(resultMeta.page - 1)}
              className="admin-stats__pagination-button"
            >{resultMeta.page - 1}</div>
            : null}
          <div className="admin-stats__current-page">{resultMeta.page}</div>
          {resultMeta.page < resultMeta.pagesCount - 1
            ? <div
              role="navigation"
              onClick={() => changePage(resultMeta.page + 1)}
              className="admin-stats__pagination-button"
            >{resultMeta.page + 1}</div>
            : null}
          {resultMeta.page < resultMeta.pagesCount - 2
            ? <div className="admin-stats__pagination-button">...</div>
            : null}
          {resultMeta.page < resultMeta.pagesCount
            ? <div
              role="navigation"
              onClick={() => changePage(resultMeta.pagesCount)}
              className="admin-stats__pagination-button"
            >{resultMeta.pagesCount}</div>
            : null}
        </div>
        <div className="admin-stats__pagination-field-wrap">
          <input
            className="admin-stats__pagination-field"
            type="text" 
            defaultValue={resultMeta.page}
            onChange={e => setQuery("page", e.target.value)}
          />
        </div>
        <div
          role="navigation"
          onClick={searchStats}
          className="admin-stats__pagination-button"
        >
          Go!
        </div>
      </div>
    </div>
  )
}

export default StatsControl
