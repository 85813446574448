import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import {withRouter, Link} from 'react-router-dom';
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as ModalActions from "actions/ModalActions"
import * as UserActions from "actions/UserActions"
import AboutPredictoria from "components/About"
import FaqTab from "components/FaqTab"
import Legal from "components/Legal"
import Privacy from "components/Privacy"
import Referral from "components/Referral"
import { siteTitle } from "utils/constants"
import {trans} from "utils/translator"
import HowItWorks from "components/HowItWorks";
import HowItWorksArticle from "components/HowItWorksArticle";

class Faq extends Component {
  state = { title: "FAQ - Predictoria.com" }

  constructor(props) {
    super(props)

    document.title = this.state.title
  }

  componentDidMount() {
    setTimeout(() => jivo_api.open("Нужна помощь, консультация или совет? Обращайтесь ко мне."), 5000)
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    if (document.title == this.state.title) { document.title = siteTitle }
  }
  
  static mapDispatchToProps(dispatch) {
    return {
      modalActions: bindActionCreators(ModalActions, dispatch),
      userActions: bindActionCreators(UserActions, dispatch)
    }
  }

  static mapStateToProps(state) {
    return {
      modals: state.modals,
      theme: state.theme
    }
  }

  render() {
    const { modalActions, userActions, modals, t } = this.props
    const path = this.props.location.pathname

    return (
      <div className={`faqPage ${this.props.theme}`}>
        <div className="about">
          <div className="aboutContent aboutContent_faq">
            <div className="aboutContentHeader">
              <h1 className="aboutContent-header">How it works</h1>
            </div>
          </div>
        </div>
        <div className="about">
          <div className="container">
            <div className="faqContainer">
              <div className="faqMenuBlock">
                <ul className="faqMenu">
                  <li className="faqMenu-item">
                    <NavLink to="/info/about-predictoria" activeClassName="activeItem">{t('global_faqtabs_about')}</NavLink>
                  </li>
                  <li className="faqMenu-item">
                    <NavLink to="/info/faq" activeClassName="activeItem">{t('global_faqtabs_faq')}</NavLink>
                  </li>
                  <li className="faqMenu-item">
                    <NavLink to="/info/how-it-works" activeClassName="activeItem">{t('global_faqtabs_how_it_works')}</NavLink>
                  </li>
                  <li className="faqMenu-item">
                    <NavLink to="/info/terms" activeClassName="activeItem">{t('global_faqtabs_legal')}</NavLink>
                  </li>
                  <li className="faqMenu-item">
                    <NavLink to="/info/referral" activeClassName="activeItem">{t('global_faqtabs_referral')}</NavLink>
                  </li>
                  <li className="faqMenu-item">
                    <NavLink to="/info/privacy" activeClassName="activeItem">{t('global_faqtabs_privacy')}</NavLink>
                  </li>
                </ul>
                <div className="faqContent">
                  <div>
                    <Route path="/info/about-predictoria" component={AboutPredictoria} />
                    <Route exact path="/info/how-it-works" render={() => <HowItWorksArticle />} />
                    <Route exact path="/info/how-it-works/viewer" render={() => <HowItWorksArticle activeTab={1}/>} />
                    <Route path="/info/terms" component={Legal} />
                    <Route path="/info/referral" component={Referral} />
                    <Route path="/info/privacy" component={Privacy} />
                    <Route path="/info/faq" render={() => <FaqTab {...this.props} />} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(trans(connect(
  Faq.mapStateToProps, 
  Faq.mapDispatchToProps
)(Faq)))
