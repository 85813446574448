import React from 'react'
import PropTypes from 'prop-types'
import ProfileSectionHead from '../ProfileSectionHead';
import times from 'lodash/times';

const getRow = (row) => {
    return <tr className='flex justify-around p2 border-bottom'>
            <td className='block'>Gusenko</td>
            <td className='block'>+</td>
            <td className='block'>Подробнее</td>
         </tr>
}
const ProfileStreams = props => {
    return (
        <div className='profile-streams bg-white p4'>
            <ProfileSectionHead 
                title='Модерируемые стримы' 
                description='Тут будет твоя активность во всех возможных видах активностей на Predictoria: От челенджей, до бренд-квестов' 
            />
                <table className="block">
                <tr className='flex justify-around p2 bg-gray'>
                    <th className='block'>Стример</th>
                    <th className='block'>Права</th>
                    <th className='block'>Управление</th>
                </tr>
                {
                    times(100, () => getRow())
                }
                </table>
        </div>
    )
}

ProfileStreams.propTypes = {

}

export default ProfileStreams
