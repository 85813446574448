import React from "react"
import Gravatar from "react-gravatar"
import Dropzone from "react-dropzone"

export default (props) => {
  let name
  let email

  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  // const isAdmin = userL.permissions != null ? userL.permissions[0] == "PREDICTION_*" : false
  const token = userL.token || ""
  if (!token) {
    // hack
    location.href = "/"
  }

  const { saveAction, editPhoto, isUploading, user } = props
  const { model } = user
  const facebookAcc = model.facebookId || ""
  const avatarURL = model.avatar

  const avatar = avatarURL == "/assets/images/avatar.svg" || avatarURL == null ?
    <Gravatar email={model.email} size={160} default="mm" /> :
    <img src={avatarURL} alt="avatar" />


  return (
    <div className="modalView">
      <div className="profileContainer modalViewContainer">
        <div className="modalViewBlock">
          <h3 className="modalViewBlock-title">Complete Profile</h3>
          <div className="profilePageBlock profilePageBlock_wide">
            <div className="profilePageBlock-avatar">
              {/* <img src="./img/main/header/avatar2.jpg" alt="avatar" />
              <div className="overlayEditPhoto">
                <p>Edit Photo</p>
              </div> */}
              {avatar}
              <Dropzone
                accept="image/jpeg, image/png"
                multiple={false}
                maxSize={3145728}
                onDropAccepted={editPhoto}
              >
                <div className="overlayEditPhoto">
                  <p>Upload Photo</p>
                </div>

                {isUploading ? (
                  <div><img src="/assets/images/loading-spin.svg" alt="loading" /></div>
              ) : null}

              </Dropzone>
            </div>
            <div className="profilePageBlock-info">
              <form action="#" className="profilePageForm" method="post">
                <div className="profilePageInputBlock">
                  <label className="profilePageInput-label" htmlFor="fullname">
                                Full name
                    <input type="text" ref={(input) => { name = input }} className="profileInput profileInput_narrow" name="fullname" id="fullname" defaultValue={model.name} />
                  </label>
                </div>
                <div className="profilePageInputBlock">
                  <label className="profilePageInput-label" htmlFor="profileEmail">
                                Email
                    <input type="text" ref={(input) => { email = input }} className="profileInput profileInput_narrow" name="profileEmail" id="profileEmail" defaultValue={model.email} />
                  </label>
                </div>
              </form>
            </div>
          </div>
          <button onClick={() => props.userActions.updateUser(name.value, email.value, model.password, saveAction)} className="pre-btn pre-btn_blue pre-btn_modal">Complete</button>
        </div>
      </div>
    </div>
  )
}
