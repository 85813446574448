import Auth from 'components/Auth';
import Feedback from 'components/_Feedback';
import SuggestChallenge from 'components/SuggestChallenge';
import ReportStreamer from 'components/ReportStreamer';
import Notify from 'components/Notify';
/**
|--------------------------------------------------
| index for all modals
|--------------------------------------------------
*/

export {
    Auth,
    Feedback,
    SuggestChallenge,
    ReportStreamer,
    Notify
}