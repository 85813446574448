import React, { Component } from "react"
import { store } from "libs/redux"

import AdminStats from "components/AdminStats"

class AdminStatsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultMeta: {
        page: "",
        pagesCount: "",
        predictionsCount: "",
        limit: "",
      },
      predictions: [],
      query: {
        limit: 100,
        page: "",
        mode: "",
        user: "",
        winOutcome: "",
        before: "",
        after: "",
      }
    }

    this.searchStats = this.searchStats.bind(this)
    this.setDate = this.setDate.bind(this)
    this.setQuery = this.setQuery.bind(this)
  }

  setQuery(key, value, callback) {
    if (callback) {
      this.setState(
        ({ query }) => ({ query: { ...query, [key]: value } }),
        callback
      ) 
    } else {
      this.setState(({ query }) => ({ query: { ...query, [key]: value } }))
    }
  }

  setDate(key, date) {
    this.setState(({ query }) => ({ query: { ...query, [key]: date } }))
  }

  async searchStats() {
    const { query } = this.state
    const token = store.getState().user.model.token || ""

    const headers = new Headers()
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", token)

    const url = new URL(`${window.location.origin}/api/stats`)
    Object.keys(query).forEach((key) => {
      // if (key === "before" || key === "after") {
      //   let date
      //   if (key === "before") {
      //     date = query[key]
      //       ? query[key].hours(23).minutes(59).seconds(59).format("YYYY-MM-DD HH:mm:ss")
      //       : ""
      //   }
      //   if (key === "after") date = query[key] ? query[key].format("YYYY-MM-DD HH:mm:ss") : ""
      //   url.searchParams.append(key, date)
      // } else {
        url.searchParams.append(key, query[key])
      // }
    })

    const stats = await fetch(url, {
      method: "GET",
      headers,
    }).then(response => response.json())

    this.setState({
      resultMeta: {
        page: stats.page,
        pagesCount: stats.pagesCount,
        predictionsCount: stats.predictionsCount,
        limit: stats.limit,
        fullBank: stats.fullBank
      },
      predictions: stats.predictions,
    })
  }

  render() {
    const { resultMeta, predictions, query } = this.state
    return (
      <div>
        <AdminStats
          predictions={predictions}
          query={query}
          resultMeta={resultMeta}
          setQuery={this.setQuery}
          setDate={this.setDate}
          searchStats={this.searchStats}
        />
      </div>
      
    )
  }
}

export default AdminStatsContainer
