import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';

export const StackLayoutDistributionStyles = {
    center: 'ds-stack-layout-distribution--center', 
    start: 'ds-stack-layout-distribution--start', 
    end: 'ds-stack-layout-distribution--end',
    evenly: 'ds-stack-layout-distribution--evenly' 
}

export const StackLayoutGutterStyles = {
    default: 'ds-stack-layout-gutter--default', 
    none: 'ds-stack-layout-gutter--none',
    small: 'ds-stack-layout-gutter--small',
    medium: 'ds-stack-layout-gutter--medium', 
    large: 'ds-stack-layout-gutter--large'
}

export const StackLayoutAlignmentStyles = {
    center: 'ds-stack-layout-alignment--center',
    start: 'ds-stack-layout-alignment--start',
    end: 'ds-stack-layout-alignment--end'
} 

const RowLayoutGutterStyles = {
    default: 'row-layout-gutter--default', 
    none: 'row-layout-gutter--none',
    small: 'row-layout-gutter--small',
    medium: 'row-layout-gutter--medium', 
    large: 'row-layout-gutter--large'
}

const gridTemplateStyles = {
    '1/2 1/2': 'column-layout-distribution--2-cols',
    '1/3 1/3 1/3': 'column-layout-distribution--3-cols',
    '1/3 2/3': 'column-layout-distribution--1-3-2-3',
    '2/3 1/3': 'column-layout-distribution--2-3-1-3'
  }
  

export const RowLayout = ({ gutter, children }) => {
    return (
        <div className={cn('row-layout',
         RowLayoutGutterStyles[gutter])}>
            {children}
        </div>
    )
}

export const StackLayout = ({ distribution, children, gutter, alignment }) => {
    return  <div className={cn('stack-layout', 
    StackLayoutGutterStyles[gutter],
    StackLayoutAlignmentStyles[alignment],
    StackLayoutDistributionStyles[distribution]
    )}>
       { children }
   </div>
}


export const ColumnLayout = ({ distribution, children }) => {
    return <div className={cn('column-layout', gridTemplateStyles[distribution])}>
        { children }
    </div>
}


export const Page = (props) => {
    return <div className="page-layout">
        {props.children}
    </div>
} 


ColumnLayout.defaultProps = {
    distribution: '1/2 1/2',
}

RowLayout.defaultProps = {
    gutter: 'default'
}

RowLayout.propTypes = {

}

