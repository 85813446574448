import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import * as userActions from "actions/UserActions"
import * as StreamActions from "actions/StreamActions"

import AdminStreamsPage from "components/AdminStreamsPage"
import RenderWithPermission from "components/Common/RenderWithPermission"

class AdminStreams extends Component {
  constructor(props) {
    super(props)
    this.state = {
      streamFilter: ''
    }
  }

  componentDidMount() {
    this.getStreams()
  }

  render() {
    const { props, state: { streamFilter }, applyFilter, setFilter } = this
    const { streamlist, userStreams, StreamActions } = props
    return <AdminStreamsPage { ...{streamlist: userStreams || streamlist, StreamActions, setFilter, streamFilter }}/>
  }

  getStreams() {
    this.props.StreamActions.getAdmin()
  }

  setFilter = (e) => {
    this.setState({
      streamFilter: e.target.value
    })
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    streamlist: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminStreams)
