import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import { Switch, Route, Link, withRouter } from "react-router-dom"
import { notify } from "reapop"
import { groupBy } from 'lodash' 
import Main from './Main'
import StreamManagement from "./StreamingPlatformSelector"
import StreamManagementNew from "./StreamManagementNew"
import PaymentsManagement from "./PaymentsManagement"
import StreamModeration from "./StreamModeration"
import ReferalsManagement from "./ReferalManagement"
import Donationsmanagement from "./DonationsManagement"
import WidgetsExplorer from 'components/ds/Screens/WidgetsExplorer';

import cn from 'classnames';
import * as StreamActions from "actions/StreamActions"
import * as UserActions from "actions/UserActions"
import * as ThemesActions from "actions/ThemesActions"



class ProfilePage extends Component {
  constructor(props) {
    super(props)
       
    this.state = {
      activeTab: 'MAIN',
      stream: {},
      isUploading: false,
    }
  this.editPhoto = this.editPhoto.bind(this)
  this.saveAction = this.saveAction.bind(this)

  }
  scrollListener = null
  
  componentDidMount() {

    const user = this.props.user.model
    const stream = user.streams && user.streams[0]
    this.props.StreamActions.getById(stream, (err, msg) => {
      this.setState({
        stream: msg
      })
    }) 
    this.props.StreamActions.getAdmin()
    gtag("event", "profile", { 
      event_category: 'webpage_interraction',
      event_action: 'profile_visited',
    })
  }

  editPhoto(files) {
    const clientId = "90f6583c00142d1"
    const bridge = this.props.UserActions

    this.setState({
      isUploading: true
    })

    fetch("https://api.imgur.com/3/image", { // Your POST endpoint
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Client-ID ${clientId}`
      },
      body: files[0] // This is the content of your file
    }).then(response => response.json() // if the response is a JSON object
    ).then((success) => {
      if (success.success) {
        bridge.updateAvatar(success.data.link, (message, status) => {
          this.saveAction(message, status, "Image uploading")
        })
      }

      this.setState({
        isUploading: false
      })
    } // Handle the success response object
    ).catch((error) => {
      this.setState({
        isUploading: false
      })
      this.saveAction(error, "error", "Image uploading")
    } // Handle the error response object
    )
  }

  saveAction(message, status, title) {
    const { notify } = this.props
    notify({
      title: title || "Profile",
      message,
      status,
      dismissible: true,
      dismissAfter: 2000
    })
  }

  
  render() {
    const { props, state } = this
    const { t, streamList, user } = props
    if(!this.props.user.isAuthenticated) this.props.history.push('/')
    // const path = this.props.location.pathname
    const userId = user.model.id
    const userStreams = streamList.filter(stream => stream.owner == userId)
    const userStreamsByPlatform = groupBy(userStreams, 'type')
    // const userPermissions = _.get(user, 'model.permissions')
    return (    
      <div className='profile-page'>
      <Switch>
          <Route path="/profile/main" render={() =>  <Main {...this.props}  saveAction={this.saveAction} editPhoto={this.editPhoto} isUploading={this.state.isUploading}/>} />
          <Route exact path="/profile/stream" render={() => <StreamManagement {...this.props} stream={this.state.stream || {}} UserActions={this.props.UserActions} users={this.props.users}/>} />
          <Route path="/profile/stream/twitch" render={() => <StreamManagementNew {...this.props} streams={userStreamsByPlatform['twitch']}/>} />
          <Route path="/profile/stream/youtube" render={() => <StreamManagementNew  {...this.props} streams={userStreamsByPlatform['youtube']} />} />
          <Route path="/profile/stream/goodgame" render={() => <StreamManagementNew {...this.props} streams={userStreamsByPlatform['goodgame']} />}/>
          <Route path="/profile/payments" render={() => <PaymentsManagement {...this.props}/>} />
          <Route path='/profile/moderation' render={() =>  <StreamModeration {...this.props} />} /> 
          <Route path='/profile/referrals' render={() =>  <ReferalsManagement {...this.props} />} />
          <Route path='/profile/donations' render={() =>  <Donationsmanagement {...this.props} />} />
          <Route path='/profile/overlay-creator' render={() =>  <WidgetsExplorer {...this.props} />} />
      </Switch>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    ThemesActions: bindActionCreators(ThemesActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default withRouter(trans(connect(mapStateToProps, mapDispatchToProps)(ProfilePage)))