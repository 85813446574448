
export const changeWidgetsTab = (tab) => ({
    type: 'CHANGE_WIDGETS_TAB', 
    tab
})

export const installWidget = (widgetId) => ({
    type: 'INSTALL_WIDGET', 
    widgetId
})

export const setupWidget = (widgetId) => ({
    type: 'SETUP_WIDGET', 
    widgetId
})

export const closeSuccessModal = () => ({
    type: 'CLOSE_SUCCESS_MODAL'
})