import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'components/_Button';
import Socials from '../Socials.jsx';
import Input from 'components/Input';
import Form from 'components/_Form';
// @TODO extract api calls!
import { trans } from 'utils/translator';

const Login = props => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { ModalActions, UserActions, setSelected, t } = props;

    return (
        <Form className='p4' 
            onSubmit={(e) => {
            e.preventDefault();
            UserActions.login(email, password);
        }}>
                <Input
                    name='email'
                    label={t('_login_1')}
                    value={email}
                    onChange={setEmail}
                    className='mb2 t3'
                />
                <Input
                    name='password'
                    label={t('_login_2')}
                    value={password}
                    onChange={setPassword}
                    className='mb2 t3'
                />
            <Link to="/reset-password" className='center mb4 t3 block'>{t('_login_3')}</Link>
            <Button type='submit' theme='purple' appearance='accent' className='block mx-auto mb3 t3'>
            {t('_login_4')}
            </Button>
            <p className='center mb3 t3'>{t('_login_5')}</p>
            <Socials />
        </Form>
    )
}


export default trans(Login);
