import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import { Switch, Route, Link, withRouter } from "react-router-dom"
import { notify } from "reapop"
import { Editor } from '@tinymce/tinymce-react';

import * as StreamActions from "actions/StreamActions"
import * as UserActions from "actions/UserActions"
import * as ThemesActions from "actions/ThemesActions"

import ArticleManage from "./ArticleManage"

import * as ArticleAPI from "../../api/ArticleAPI"
const initialEditorContent = `<h1 class="heading"><span style="font-family: ubuntu-regular; font-size: 24pt; color: #f7f7f7;">Lorem Ipsum&nbsp;</span></h1>
<p><span style="font-family: ubuntu-regular; font-size: 14pt; color: #f7f7f7;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras rhoncus tincidunt nibh, ut venenatis leo. Praesent vestibulum turpis a felis tincidunt pretium. Integer mattis, sem eget pretium congue, purus sapien elementum arcu, ac luctus mauris nisl vel sapien. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent sed quam vestibulum, blandit enim ut, rutrum metus. Donec feugiat libero sed ultricies efficitur.&nbsp;</span></p>
<p>&nbsp;</p>`

class PostEditor extends Component {
  constructor(props) {
    super(props)
    const { article, isEdit }  = this.props.location.state || {}
    
    const { title, html, author, sandbox, tags, type, parent, id, language, position } = article || {}

    this.state = {
      article: {
        title: title || 'Add title',
        html: html,
        author: author || this.props.user.model.id,
        sandbox: sandbox || true,
        tags: tags || [],
        type: type || 'article',
        parent: parent || 0, 
        language: language || "en",
        position,
      },
      categories: [],
      siblings: [],
      isEdit,
      id
    }
  }
    componentDidMount(){
      const { props } = this
      const { match } = props
      const articleId = match.params.id
     
      // при переходе по прямой ссылке/открытии в новой вкладке подгружаем статью
      // иначе передаем статью через роутер
      if(articleId){
        ArticleAPI.getArticles((articles) => {
          this.setState({
            article: articles[0],
            id: articles[0].id,
            isEdit: true,
          })
        }, {id: articleId})
      }

      ArticleAPI.getCategories((categories) => {
        this.setState({
          categories,
        })
      })
    
    }
    delete = () => {
      ArticleAPI.deleteArticle(() => {
        alert('Article deleted')
        // установка стейта в дефолт
        this.refs.editor.editor.setContent(initialEditorContent)
        this.setState(prevState => ({
          article: {
            ...prevState.article,
            title: '',
            html: '',
            sandbox: true,
            type: 'article',
            author: this.props.user.model.id || 1,
            tags: [],
          },
          id: null,
          isEdit: false
        }))
       
      }, this.state.id)
    }
    publish = () => {
      ArticleAPI.editArticle((article) => {
        if(article.id) alert('Article published')
        else alert('Error occured')
      }, {...this.state.article, id: this.state.id, sandbox: false})
    }
    save = () => {
      // очень костыльно.туду: добавить в поле категории количество чилдренов, либо сетить позицию на бэке. 
      if(!this.state.isEdit)
      ArticleAPI.getArticles((siblings) => {
        ArticleAPI.addArticle((article) => {
          this.setState({
            id: article.id,
            isEdit: true,
          })
         }, {...this.state.article, position: siblings.length})
      }, {parent: this.state.article.parent})
      else
      ArticleAPI.editArticle(() => {
        alert('Changes saved')
      }, {...this.state.article, id: this.state.id})
    }
    addTags = tags => () => {
      this.setState({
        article: {
          ...this.state.article,
          tags: tags.split(','),
        } 
      })
    }
    selectCategory = category => () => {
      this.setState({
        article: {
          ...this.state.article,
          parent: category,
        } 
      })
    }
    handleTitleChange = (e) => {
        this.setState({
          article: {
           ...this.state.article,
            title: e.target.value,
          } 
        })
    }
    handleHTMLChange = (e) => {
      this.setState({
        article: {
          ...this.state.article,
          html: e.target.getContent(),
        } 
      })
    }
    handleAddCategory = (category) => {
      this.setState(prevState => ({
        categories: [
          ...prevState.categories,
          category 
        ]
      }))
    }
    handleEditCategory = (category) => {
      const { categories } = this.state
      let oldCategory = categories.find(c => c.id == category.id)
      oldCategory = {...category}
      this.setState(prevState => ({
        categories: [...categories]
      }))
    }
    handleDeleteCategory = (id) => {
      this.setState(prevState => ({
        categories: prevState.categories.filter(c => c.id!=id)
      }))
    }

    render() {
    const {t, user} = this.props
    const { article, categories, isEdit } = this.state
    const { title, html, parent } = article
    return (    
      <div className='post-editor'>
      <div className="post-editor__row">
      <div className="post-editor__editor-container">
      {
        isEdit ? 
      <Link to={{pathname: `/help/view/article/${article.title}`, state: {
              article,
              user,
            }}}>{t('pe_article_link')} {article.title}</Link> : null
      }
      <input type="text" onChange={this.handleTitleChange} value={title} className="post-editor__article-name" placeholder={t('pe_article_title')}/>
       
          <Editor
          ref='editor'
          apiKey = "h0vefgr3qhvvvy2n0qsn90rv89k3g2kaxl8batz4kni6dx18"
        initialValue={html || initialEditorContent}
        init={{
          plugins: 'link image code textcolor',
          toolbar: 'undo redo | bold italic | styleselect | fontselect | fontsizeselect | alignleft aligncenter alignjustify alignright | code | forecolor backcolor | Button_gold Button_blue | removeformat ',
          height: '350',
          font_formats: 'Ubuntu-Regular=ubuntu-regular; Ubuntu-Medium=ubuntumedium;Ubuntu-Bold=ubuntu-bold;',
          textcolor_map: [
            "f7f7f7", "White",
            "232323", "Grey",
            "1b1b1b", "Grey dark",
            "ff8008", "Gold dark",
            "ffc837", "Gold light",
            "2094fa", "Blue",
          ],
          setup: function (ed) {
            ed.on('init', function() {
              this.getDoc().body.style.backgroundColor = '#1b1b1b'
              });
            ed.addButton('Button_gold', {
                 title : 'Insert gold button',
                 onclick : function() {
                  var html = '<a class="" style="text-decoration: none; padding: 5px 10px; color: #f7f7f7; font-family: Ubuntu-Regular; border-radius: 20px; background-image: linear-gradient(#ffc400, #f5851c); text-align: center; cursor: pointer; outline: none; border: none; display: inline-block;" href="../buy">Button gold</a>';
                  ed.insertContent(html);
                 }
              });
              ed.addButton('Button_blue', {
                title : 'Insert blue button',
                onclick : function() {
                 var html = '<a class="" style="text-decoration: none; padding: 5px 10px; color: #f7f7f7; font-family: Ubuntu-Regular; border-radius: 20px; background-color: #4b7bec; text-align: center; cursor: pointer; outline: none; border: none; display: inline-block;">Button Blue</a>';
                 ed.insertContent(html);
                }
             });
            },
          custom_colors: false,
          relative_urls: false,
          remove_script_host: false,
          convert_urls: true,
          formats: {
            h1: { block: 'h1', classes: 'heading' }
          },
          style_formats: [
            { title: 'My heading', block: 'h1', classes: 'heading' }
          ]
         }}
        onChange={this.handleHTMLChange}
      />
      <button className="post-editor__save" onClick={this.save}>
      {t('pe_save')}
      </button>
      <button className="post-editor__publish" onClick={this.publish}>
      {t('pe_publish')}
      </button>
      <button className="post-editor__delete" onClick={this.delete}>
      {t('pe_delete')}
      </button>
      </div>
      <div className="post-editor__article-manage-container">
      <ArticleManage handleEditCategory = {this.handleEditCategory} handleAddCategory = {this.handleAddCategory} handleDeleteCategory = {this.handleDeleteCategory} categories={categories} selectedCategory={parent} selectCategory={this.selectCategory} addTags={this.addTags} article={article}/>
      </div>
      </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    ThemesActions: bindActionCreators(ThemesActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default withRouter(trans(connect(mapStateToProps, mapDispatchToProps)(PostEditor)))