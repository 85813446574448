import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import Dropdown from "components/Dropdown"
import MediaQuery from 'react-responsive'
import Gravatar from "react-gravatar"
import {trans} from "utils/translator"

export default trans(class Leaderboard extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectedOption: 'week',
      mobileSwitcherIndex: 0,
    }
  }
  avatar({ avatar }) {
     return <span>
      <img className='leaderboard__avatar' src={avatar || "/assets/images/avatar.svg"} alt="avatar" />
      </span> 
  }
  renderLineStreamers(users) {
    return users.map((u) => {
      const rank = (users.indexOf(u) + 1) || 1
      const { name, avatar, stream, challengeCount, bank } = u
      
      return (
        <tr key={rank}>
           <td>
            {rank}.
          </td>
          <td>
            {this.avatar({ avatar })}
            <span className="userName">
            {name || 'Predictor'}
            </span>
          </td>
          <td>
            <span className="cubesCount">{(bank || 0).toFixed(2) || "~~~"}</span>
          </td>
          <td>
            <span className="streamerName">
              <NavLink to={`/stream/${stream || "~~~~"}`}>{stream || "~~~~"}</NavLink>
            </span>
          </td>
          {/* <td>
            <span className="game">
              {game || 'Dota 2'}
            </span>
          </td> */}
          <td>
            <span className="predictionsCount">
              {challengeCount || '0'}
            </span>
          </td>
        </tr>
      )
    })
  }

  renderLine(users) {
    return users.map((u) => {
      const rank = (users.indexOf(u) + 1) || 1
      const { user, value, streamer, totalPred, name, avatar } = u
      
      return (
        <tr key={rank}>
           <td>
            {rank}.
          </td>
          <td>
            {this.avatar({ avatar })}
            <span className="userName">
            {name || 'Predictor'}
            </span>
          </td>
          <td>
            <span className="cubesCount">{value.toFixed(2) || "~~~"}</span>
          </td>
          <td>
            <span className="streamerName">
              <NavLink to={`/stream/${streamer || "~~~~"}`}>{streamer || "~~~~"}</NavLink>
            </span>
          </td>
          {/* <td>
            <span className="game">
              {game || 'Dota 2'}
            </span>
          </td> */}
          <td>
            <span className="predictionsCount">
              {totalPred || '0'}
            </span>
          </td>
        </tr>
      )
    })
  }

  renderCurrentUser({ user, users }) {
    if (!user || Object.keys(user).length == 0) return null
    if (users.indexOf(user) > 10) return null
    const rank = users.indexOf(user) 
    const { value, streamer, totalPred, name, avatar } = user
    return (
      <thead className="currentUser">
        <tr key="you">
          <td>
            {rank}.
          </td>
          <td>
            {this.avatar({ avatar })}
            <span className="userName">
              <NavLink to="/profile">You</NavLink>
            </span>
          </td>
          <td>
            <span className="cubesCount">{value || "~~~"}</span>
          </td>
          <td>
            <span className="streamerName">
              <NavLink to={`/stream/${streamer || "~~~~"}`}>{streamer || "~~~~"}</NavLink>
            </span>
          </td>
          {/* <td>
            <span className="game">
              {game || 'Dota 2'}
            </span>
          </td> */}
          <td>
            <span className="predictionsCount">
              {totalPred || '0'}
            </span>
          </td>
        </tr>
      </thead>
    )
  }
  componentDidMount(){
    this.props.LeaderboardActions.get(this.state.selectedOption)
  }
  handleOptionChange = option => () => {
    this.setState({
      selectedOption: option,
    }, () => {
      let period = option
      this.props.streamers ? this.props.LeaderboardActions.getStreamers(period) : this.props.LeaderboardActions.get(period)
      this.refs.dropdown.hide() 
  })
  }
  handleSwitcherChange = (period) => {
    this.props.streamers ? this.props.LeaderboardActions.getStreamers(period) : this.props.LeaderboardActions.get(period)
  }
  handleMobileSwitcherClick = (option) => () => {
    this.setState(prevState => ({
      mobileSwitcherIndex: (prevState.mobileSwitcherIndex + 1)%3, 
    }), () => {
      const optionsMobile = ['day', 'week', 'month', 'year']
      let period = optionsMobile[this.state.mobileSwitcherIndex]
      this.props.streamers ? this.props.LeaderboardActions.getStreamers(period) : this.props.LeaderboardActions.get(period)
    })
  }
  render() {
    const { props } = this
    let { users, user, t } = props
    if (users.length == 0) {
      users = [{ rank: "~", name: t('leaderboard_ladder_empty'), streamer: "~~~~", value: 0 }]
    }
    const options = ['day', 'week', 'month', 'year'].map( option => <div className='leaderboard__option' onClick={this.handleOptionChange(option)}>{t(`leaderboard_${option}`)}</div>)
    const optionsMobile = ['day', 'week', 'month', 'year'].map( option => <div className='leaderboard__option' onClick={this.handleMobileSwitcherClick(option)}>{t(`leaderboard_${option}`)}</div>)
    const dropdown = <div className='leaderboard__trigger'>{t(`leaderboard_${this.state.selectedOption}`)}</div>
    return (
      <div className='leaderboard'>
      <div className='leaderboard__section'>
      <div className='leaderboard__header'>
      <h1 className='leaderboard__page-title'>{t('leaderboard_leaderboard')}</h1>
      <h5 className='leaderboard__page-desc'>{t('leaderboard_scoreboard')}</h5>
      </div>
      <div className='leaderboard__filter'>
      <MediaQuery query="(min-device-width: 480px)">
      <Dropdown ref="dropdown" component={dropdown}>
        {options}
      </Dropdown>
      </MediaQuery>
      <MediaQuery query="(max-device-width: 480px)">
      {optionsMobile[this.state.mobileSwitcherIndex]}
      </MediaQuery>
      </div>
      </div>
      <div className='leaderboard__table'>
      <table cellSpacing="0">
        {/* {this.renderCurrentUser({ user, users })} */}
        {
          this.props.streamers ? 
          <thead className='leaderboard__head'>
          <tr key="th">
            <th></th>
            <th>{t('leaderboard_player')}</th>
            <th>{t('leaderboard_streamer_win')}</th>
            <th>{t('leaderboard_streamer_stream')}</th>
            {/* <th>Favourite game</th> */}
            <th>{t('leaderboard_streamer_total')}</th>
          </tr>
        </thead>
          : 
          <thead className='leaderboard__head'>
          <tr key="th">
            <th></th>
            <th>{t('leaderboard_player')}</th>
            <th>{t('leaderboard_win')}</th>
            <th>{t('leaderboard_favourite')}</th>
            {/* <th>Favourite game</th> */}
            <th>{t('leaderboard_total')}</th>
          </tr>
        </thead>
        }
       
        <tbody className='leaderboard__body'>
          {
            this.props.streamers ? this.renderLineStreamers(users) : this.renderLine(users)
          }
        </tbody>
      </table>
      </div>
      </div>
    )
  }
}
)