import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"
import FormValidator from "components/Common/FormValidator"
import * as ModalActions from "actions/ModalActions"
import * as userActions from "actions/UserActions"
import { required, gte } from "utils/validator"
import { trans } from "utils/translator"
import Role from 'components/Common/Role'
class Bonuscode extends FormValidator {
  constructor(props) {
    super(props)

    this.callback = this.callback.bind(this)
    this.createNotification = this.createNotification.bind(this)
  }

  componentDidMount() {
    const queryString = require("query-string")
    const parsed = queryString.parse(this.props.location.search)
    const bonus = parsed.code
    this.setState({ bonus })
  }

  createNotification(data) {
    const { notify } = this.props
    this.setState({ notification: data })
    if (data) {
      notify({
        title: data.title,
        message: data.message,
        status: data.status,
        dismissible: data.dismissible,
        dismissAfter: 5000,
        dismissible: true
      })
    }
  }

  callback(response, error) {
    if (error != null) {
      this.createNotification({
        title: error.title,
        message: error.message,
        status: "error"
      })
      return
    }

    this.createNotification({
      title: response.title,
      message: response.message,
      status: "success"
    })

    setTimeout(() => {
      window.location.href = "/"
    }, 1000)
  }

  handleFormSubmit = (e) => {
    const { t } = this.props;
    e.preventDefault()
    if (!this.isValid()) {
      this.createNotification({
        title: "Validation error",
        message: "Please, fill correct bonuscode",
        status: "warning"
      })
      return
    }
    const { bonuscode } = this.state.form

    userActions.getBonusCubes(bonuscode.value, (user, error) => {
      if (_.isObject(error)) {
        this.createNotification({
          title: "Free cubes",
          message: t(error.code == 'WRONG_PROMO_CODE' ? 'errors_wrong_bonus_code' : 'errors_general' ),
          status: "error"
        })
        gtag("event", "deposit", { getShareCubes: "error" })
        return
      }
      gtag("event", "deposit", { getShareCubes: "success" })
      userActions.updateUserBalance()
      this.createNotification({
        title: "Free cubes",
        message: "Congrats! You receive free cubes!",
        status: "success"
      })
    })
  }

  render() {
    const { bonus } = this.state
    const { t } = this.props
    return (
      <div className="modalView">
        <div className="profileContainer modalViewContainer">
          <div className="modalViewBlock bonuscode">
            <h3 onClick={this.executeCaptcha} className="modalViewBlock-title">{t('bonuscodes_type')}</h3>
            <form className="formContainerForm" noValidate onSubmit={this.handleFormSubmit}>
              <div className="inputBlock">

                <input
                  {...this.bindInput("bonuscode", "text", [required], bonus)}
                  className={`authInput ${this.isValid("bonuscode") || "authInput authInput_error"}`}
                  placeholder={t('bonuscodes_type_here')}
                  value={bonus}
                />
                <span className="inputBlock-icon">
                  <div className="inputBlock-img">
                    <div className="icon svgIcon-password-icon svgIcon-password-icon-dims" />
                  </div>
                </span>
              </div>
              <div className="inputBlock">
                <input
                  id="authInput-signIn"
                  className="authInput authSendInput_modal"
                  type="submit"
                  value={t('bonuscodes_receive')}
                  disabled={!this.isValid()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    modals: state.modals
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(Bonuscode))
