import * as streamList from "../reducers/streamList"
import * as StreamAPI from "api/StreamAPI"
import * as challenges from "../reducers/challenges"
import * as prediction from "../reducers/predictions"
import * as votings from "../reducers/votings"
import * as paidActions from "../reducers/paidActions"

import * as templateChallenge from "../reducers/templateChallenge"
import * as templatePrediction from "../reducers/templatePrediction"
import * as templateVoting from "../reducers/templateVoting"
import * as templatePaidAction from "../reducers/templatePaidAction"
import * as user from "../reducers/user"

export const search = (searchQuery, callback) => (dispatch) => {
  StreamAPI.search(searchQuery, (error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    // dispatch(streamList.setSearchResultsList(list))
    const activeStreams = list.filter(s => !s.disable)
    if (callback) { callback(null, activeStreams) }
  })
}
export const getStream = (callback) => (dispatch) =>  {
  
  StreamAPI.getStream((error, res) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    
    if (callback) { callback(null, res) }
  })
}

export const getById = (streamId, callback) => (dispatch) =>  {
  
  StreamAPI.getById((error, res) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }

    dispatch(paidActions.set(res.PaidActions))
    dispatch(templatePaidAction.setTemplates(res.PaidActionTemplates))

    dispatch(votings.set(res.Votings))
    dispatch(templateVoting.setTemplates(res.VotingTemplates))

    dispatch(challenges.set(res.Challenges))
    dispatch(templateChallenge.setTemplates(res.ChallengeTemplates))

    dispatch(prediction.set(res.Predictions))
    dispatch(templatePrediction.setTemplates(res.PredictionTemplates))
    
    if (!error && res && callback) { callback(null, res) }

  }, streamId)
}

export function getStreamList() {
  return (dispatch) => {
    dispatch(streamList.getStreamItem())
  }
}

export function update(prediction) {
  return (dispatch) => {
    dispatch(streamList.update(prediction))
  }
}

export function setNewList(streamlist) {
  return (dispatch) => {
    dispatch(streamList.setNewList(streamlist))
  }
}


export const create = (stream, callback) => (dispatch) => {
  StreamAPI.create(stream, (error, stream) => {
    if (error != null) {
      callback(error)
      return
    }
    callback(null, stream)

    const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    userL.streams = [stream.id]
    userL.permissions.push('STREAM')
    userL.permissions.push('ACTIVITY')
    localStorage.setItem("predictoria_currentUser", JSON.stringify(userL))


    dispatch(user.collection.set({
      "model.streams": [stream.id],
      "model.permissions": userL.permissions,
    }))

    
  })
}

export const createAdmin = (stream, callback) => (dispatch) => {
  StreamAPI.createAdmin(stream, (error, stream) => {
    if (error != null) {
      callback(error)
      return
    }
    callback(null, stream)

    // const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    // userL.streams = [stream.id]
    // userL.permissions.push('STREAM')
    // userL.permissions.push('ACTIVITY')
    // localStorage.setItem("predictoria_currentUser", JSON.stringify(userL))


    // dispatch(user.collection.set({
    //   "model.streams": [stream.id],
    //   "model.permissions": userL.permissions,
    // }))

    
  })
}

export const remove = (id, callback) => (dispatch) => {
  StreamAPI.remove(id, (error, stream) => {
    if (error != null) {
      callback(error)
      return
    }
    callback(null, stream)
  })
}

export const activate = (id, callback) => (dispatch) => {
  StreamAPI.activate(id, (error, stream) => {
    if (error != null) {
      callback(error)
      return
    }
    callback(null, stream)
  })
}

export const deactivate = (id, callback) => (dispatch) => {
  StreamAPI.deactivate(id, (error, stream) => {
    if (error != null) {
      callback(error)
      return
    }
    callback(null, stream)
  })
}

export const updateStream = (stream, id, callback) => (dispatch) => {
  StreamAPI.update(stream, id, (error, stream) => {
    if (error != null) {
      callback(error)
      return
    }
    callback(null, stream)
  })
}

export const updateStreamAdmin = (id, callback) => (dispatch) => {
  StreamAPI.updateAdmin(id, (error, stream) => {
    if (error != null) {
      callback(error)
      return
    }
    callback(null, stream)
  })
}

export const get = callback => (dispatch) => {
  StreamAPI.get((error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    const activeStreams = list.filter( s => !s.disable)
    dispatch(streamList.setNewList(activeStreams))
    if (callback) { callback(null, activeStreams) }
  })
}

export const getAdmin = callback => (dispatch) => {
  StreamAPI.getAdmin((error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(streamList.setAdminList(list))
    if (callback) { callback(null, list) }
  })
}

export const streamerID = (type, stream) => (dispatch) => {
  switch (type) {
    case "youtube":
      // return stream.stream.trim()
      return stream.trim()
    default:
      return stream.trim()
  }
}

export const streamID = (type, stream) => (dispatch) => {
  switch (type) {
    case "youtube":
      // return stream.video.trim()
      return stream.trim()
    default:
      return stream.trim()
  }
}

export const streamUrl = (type, id) => (dispatch) => {
  switch (type) {
    case "twitch":
      return `https://player.twitch.tv/?channel=/${id}`// `https://twitch.tv/${id}`
      // return `https://player.twitch.tv/?channel=/${id}`
    case "goodgame":
      return `https://goodgame.ru/player?${id}`
    case "youtube":
      return `https://gaming.youtube.com/embed/live_stream?channel=${id}&autoplay=1`
    default:
      return `https://player.twitch.tv/?channel=/${id}`
  }
}

export const streamChat = (type, id) => (dispatch) => {
  switch (type) {
    case "twitch":
      return `https://www.twitch.tv/embed/${id}/chat`
    case "goodgame":
      return `https://goodgame.ru/chat/${id}/`
    case "youtube":
      return `https://www.youtube.com/live_chat?v=${id}&embed_domain=${window.location.hostname}`
    default:
      return `https://www.twitch.tv/embed/${id}/chat`
  }
}

export const defaultStream = () => (dispatch) => {
  return {
    title: "Loading...",
    id: "Loading",
    stream: "",
    type: "twitch",
    img: "/assets/images/main/slider/7.jpg",
    spectators: "loading",
    featured: false,
    predictions: null,
    predictions: []
  }
}
