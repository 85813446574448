import * as SocketAPI from "api/SocketAPI"
import { store } from "libs/redux"
import "whatwg-fetch" 

const generateSnippet = async (body, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/messages/generateSnippet`, {
    method: "POST",
    body: JSON.stringify(body),
    headers
  })
  .then(response => response.blob())
  .then(blob => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = "overlay.html";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove();  //afterwards we remove the element again         
  });
}

const updateModerators = async (streamId, moderators, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/${streamId}/moderators`, {
    method: "PATCH",
    body: JSON.stringify(moderators),
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const getModerators = async (streamId, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/${streamId}/moderators`, {
    method: "get",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}
const givePermissions = async (streamId, user, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/${streamId}/permission`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(user)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code) {
        callback(json.message)
      } else {
        // success
        callback(null, json.message)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const getStream = async (callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/streamer/stream`, {
    method: "GET",
    headers,
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code) {
        callback(json.message)
      } else {
        // success
        callback(null, json.message)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}


const deactivate = async (id, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []
  const isAdmin = permissions.includes("SUPER")

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/${isAdmin? 'admin': ''}/stream/${id}/deactivate`, {
    method: "PATCH",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED") {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const activate = async (id, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""
  const permissions = user.permissions || []
  const isAdmin = permissions.includes("SUPER")

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/${isAdmin? 'admin': ''}/stream/${id}/activate`, {
    method: "PATCH",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED") {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const getById= async (callback, streamId) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") 
  headers.append("Content-Type", "application/json") 
  headers.append("Authorization", token)

  fetch(`/api/stream/${streamId}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const createAdmin = async (stream, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch("/api/admin/stream/", {
    method: "POST",
    headers,
    body: JSON.stringify(stream)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}


const create = async (stream, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch("/api/stream/", {
    method: "POST",
    headers,
    body: JSON.stringify(stream)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const search = async (searchQuery, callback) => {
  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/search?q=${searchQuery}`, {
    method: "GET",
    headers
  })
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
        json.code != undefined) {
      callback(json.message)
    } else {
      callback(null, json)
    }
  })
  .catch((error) => {
    // AHHHH! An Error!
    callback(error)
  })
}

const get = async (callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch("/api/stream/", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const getAdmin = async (callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch("/api/stream/", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED" || json.code == "NOT_FOUND" || json.code == "UNKNOWN_ERROR" ||
          json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}


const update = async (stream, id, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/${id}`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(stream)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED") {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const updateAdmin = async (stream, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/admin/stream/${stream.id}`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(stream)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED") {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const remove = async (id, callback) => {

  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/stream/${id}`, {
    method: "DELETE",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "PERMISSION_DENIED") {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}



const updateSpecs = async (streamlist, StreamActions) => {
  streamlist.forEach((element) => {
    if (element.id == "Loading") {
      return
    }
    const url = `https://api.twitch.tv/kraken/streams/${element.stream.trim()}/?client_id=wup7qhjpngkdjr8r90oy43bxcelx8l`
    fetch(url)
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json.stream != null) {
          // нужно засунуть дату с сервера в эти проперти
          const viewers = json.stream ? json.stream.viewers : "online"
          element.spectators = viewers
          StreamActions.setNewList(streamlist)
        }
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("StreamAPI updateSpecs error", error)
      })
  })
}

export { create, get, getAdmin, getById, update, remove, search, givePermissions, createAdmin, updateAdmin, activate, deactivate, getModerators, updateModerators, generateSnippet, getStream }
