import * as PaidActions from 'reducers/paidActions'
import * as PaidActionAPI from 'api/PaidActionAPI'

export function patch(paidActionId, paidActionBody, callback) {
  return (dispatch) => {
      PaidActionAPI.patch((paidAction) => {
      if(callback) callback(paidAction)
      dispatch(PaidActions.update(paidAction))
    }, paidActionId, paidActionBody)
  }
}

export function patchUser(paidActionId, paidActionBody, callback) {
  return (dispatch) => {
      PaidActionAPI.patchUser((paidAction) => {
      if(callback) callback(paidAction)
      dispatch(PaidActions.update(paidAction))
    }, paidActionId, paidActionBody)
  }
}

export function get(admin) {
    return (dispatch) => {
       PaidActionAPI.load((list) => {
        dispatch(PaidActions.set(list))
      }, admin)
    }
  }

  export function createAdmin(templateId, paidActionBody) {
    return (dispatch) => {
        PaidActionAPI.createAdmin((paidAction) => {
        dispatch(PaidActions.update(paidAction))
      }, templateId, paidActionBody)
    }
  }

  export function create(templateId, paidActionBody, cb) {
    return (dispatch) => {
        PaidActionAPI.createAdmin((error, paidAction) => {
          cb(error, paidAction)
        dispatch(PaidActions.update(paidAction))
      }, templateId, paidActionBody)
    }
  }

  export function updated(callback, admin, stream) {
    return (dispatch) => {
      PaidActionAPI.updated((list) => {
        dispatch(PaidActions.set(list))
        if (callback) callback(list)
      }, admin, stream)
    }
  }