import collectionReducers from "utils/collectionReducers"

const initialState = {
  paidActions: []
}

const NAMESPACE = "PAID_ACTIONS"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function set(paidActions) {
  const copyPaidActions = paidActions.map(item => Object.assign({}, item))
  return {
    namespace: NAMESPACE,
    type: "SET_PAID_ACTION_LIST",
    handle: (state) => { return { ...state, paidActions: copyPaidActions } }
  }
}


export function update(prd) {
  function _update(paidActions) {
  
    const index = paidActions.findIndex(item => item.id === prd.id)
    // заменяем/добавляем
    if (index != -1) {
        paidActions[index] = prd
    } else {
        paidActions.push(prd)
    }
    return paidActions
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_PAID_ACTION",
    handle: (state) => {
      const newlist = _update(state.paidActions)
      const copyNewlist = newlist.map(item => Object.assign({}, item))
      return { ...state, paidActions: copyNewlist }
    }
  }
}
