import collectionReducers from "utils/collectionReducers"

const initialState = {
  votings: []
}

const NAMESPACE = "VOTINGS"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function set(votings) {
  const copyVotings = votings.map(item => Object.assign({}, item))

  return {
    namespace: NAMESPACE,
    type: "SET_VOTINGS_LIST",
    handle: (state) => { return { ...state, votings: copyVotings } }
  }
}


export function update(prd) {
  function _update(votings) {
  
    const index = votings.findIndex(item => item.id === prd.id)

    // заменяем/добавляем
    if (index != -1) {
      votings[index] = prd
    } else {
      votings.push(prd)
    }

    return votings
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_VOTING",
    handle: (state) => {
      const newlist = _update(state.votings)
      const copyNewlist = newlist.map(item => Object.assign({}, item))
      return { ...state, votings: copyNewlist }
    }
  }
}
