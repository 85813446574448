import React, { Component } from "react"
import * as BettyAPI from "api/BettyAPI"

export default class extends Component {
  constructor(props){
    super(props)
    this.state ={
        fifaTeamlist: [],
        dotaHeroes: [],
    }
    }
  componentDidMount(){
   BettyAPI.getFifaTeams((err, msg) => {
    this.setState({
        fifaTeamlist: msg.teams,
    })
    
})
BettyAPI.getDotaHeroes((err, msg) => {
  this.setState({
    dotaHeroes: msg.heroes,
})
})

}
  render() {
    
    const { props, state } = this
    const { teamRating1, teamRating2, pair, game } = props
    const { fifaTeamlist, dotaHeroes } = state
    console.table('DOTA HER', dotaHeroes)
    const def = "https://d1u5p3l4wpay3k.cloudfront.net/dota2_gamepedia/3/33/Unknown_Hero_icon.png?version=551ac1fa463f9299465ba7cfd14331ee"
    const cs = "https://res.cloudinary.com/teepublic/image/private/s--L-imVlF8--/t_Preview/b_rgb:191919,c_limit,f_jpg,h_630,q_90,w_630/v1479252809/production/designs/821539_1.jpg"
    const tt = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlY9BMGUHAQTjE3S0FJ8Lj5MDP8oQVj_ExMJ24Z6Auj2-bEha8"
    const defaultPlayerImage = ''

    const heroes = pair.split(",")
    const index = pair.split(",")
    const teams = pair.split(",")

   
    const heroImage1 = (dotaHeroes.find(h =>  h.id == +heroes[0]) || { image: game == 1 ? def : cs }).image
    const heroImage2 = (dotaHeroes.find(h => h.id == +heroes[1]) || { image: game == 1 ? def : cs }).image
    const heroImage3 = (dotaHeroes.find(h => h.id == +heroes[2]) || { image: game == 1 ? def : tt }).image
    const heroImage4 = (dotaHeroes.find(h => h.id == +heroes[3]) || { image: game == 1 ? def : tt }).image


    // const heroImage1 = (this.heroes()[heroes[0]] || { image: game == 1 ? def : cs }).image
    // const heroImage2 = (this.heroes()[heroes[1]] || { image: game == 1 ? def : cs }).image
    // const heroImage3 = (this.heroes()[heroes[2]] || { image: game == 1 ? def : tt }).image
    // const heroImage4 = (this.heroes()[heroes[3]] || { image: game == 1 ? def : tt }).image

    const playerImage1 = (fifaTeamlist.find(t => t.id == +teams[0]) || {}).image
    const playerImage2 = (fifaTeamlist.find(t => t.id == +teams[1]) || {}).image
    

    const teams1 = [
        '',
        'Radiant',
        'Counter-T',
        (fifaTeamlist.find(t => t.id == +teams[0]) || {}).name
        || 'Team 2',
    ]
    const teams2 = [
        '',
        'Dire',
        'Terrorist',
        (fifaTeamlist.find(t => t.id == +teams[1]) || {}).name
        || 'Team 2',
    ]
    

    return (
      <div className="bettyTeamVersus">
        <div className="team radiant">
          <div>
              {
                  game === 3 ? 
                  <div className="img" style={{ backgroundImage: `url(${playerImage1})` }} />
                  :
                  <div>     
                  <div className="img" style={{ backgroundImage: game === 3 ? `url(${playerImage1})` :`url(${heroImage1})` }} />
                  <div className="img" style={{ backgroundImage: game === 3 ? `url(${playerImage3})` : `url(${heroImage2})` }} />
                  </div>
              }
            </div>
          <div style={{padding: "3px 0" }}>
            <label className="title regular">Team {teams1[game]}</label><br/>
            {
                game == 3 ? null : <label className="rating regular">Rating: {teamRating1}</label>
            }
            
          </div>
        </div>
        <div className="vs medium">
          VS
        </div>
        <div className="team dire">
          <div>
             {
                 game == 3 ? 
                 <div className="img" style={{ backgroundImage: game === 3 ? `url(${playerImage2})` : `url(${heroImage3})` }} />
                :
                <div>   <div className="img" style={{ backgroundImage: game === 3 ? `url(${playerImage2})` : `url(${heroImage3})` }} />
                <div className="img" style={{ backgroundImage: game === 3 ? `url(${playerImage4})` : `url(${heroImage4})` }} /></div>      
             }
         
          </div>
          <div style={{padding: "3px 0" }}>
            <label className="title regular">Team:  {teams2[game]}</label><br/>
            {
                game == 3 ? null : <label className="rating regular">Rating: {teamRating2}</label>
            }
          </div>
        </div>
      </div>
    )
  }
  players(id){
      return [][id]
  }
  heroes(id) {
    return [
        {
          "localized_name": "Anti-Mage",
          "name": "antimage",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/antimage_full.png",
          "id": "1"
        },
        {
          "localized_name": "Axe",
          "name": "axe",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/axe_full.png",
          "id": "2"
        },
        {
          "localized_name": "Bane",
          "name": "bane",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/bane_full.png",
          "id": "3"
        },
        {
          "localized_name": "Bloodseeker",
          "name": "bloodseeker",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/bloodseeker_full.png",
          "id": "4"
        },
        {
          "localized_name": "Crystal Maiden",
          "name": "crystal_maiden",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/crystal_maiden_full.png",
          "id": "5"
        },
        {
          "localized_name": "Drow Ranger",
          "name": "drow_ranger",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/drow_ranger_full.png",
          "id": "6"
        },
        {
          "localized_name": "Earthshaker",
          "name": "earthshaker",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/earthshaker_full.png",
          "id": "7"
        },
        {
          "localized_name": "Juggernaut",
          "name": "juggernaut",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/juggernaut_full.png",
          "id": "8"
        },
        {
          "localized_name": "Mirana",
          "name": "mirana",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/mirana_full.png",
          "id": "9"
        },
        {
          "localized_name": "Morphling",
          "name": "morphling",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/morphling_full.png",
          "id": "10"
        },
        {
          "localized_name": "Shadow Fiend",
          "name": "nevermore",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/nevermore_full.png",
          "id": "11"
        },
        {
          "localized_name": "Phantom Lancer",
          "name": "phantom_lancer",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/phantom_lancer_full.png",
          "id": "12"
        },
        {
          "localized_name": "Puck",
          "name": "puck",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/puck_full.png",
          "id": "13"
        },
        {
          "localized_name": "Pudge",
          "name": "pudge",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/pudge_full.png",
          "id": "14"
        },
        {
          "localized_name": "Razor",
          "name": "razor",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/razor_full.png",
          "id": "15"
        },
        {
          "localized_name": "Sand King",
          "name": "sand_king",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/sand_king_full.png",
          "id": "16"
        },
        {
          "localized_name": "Storm Spirit",
          "name": "storm_spirit",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/storm_spirit_full.png",
          "id": "17"
        },
        {
          "localized_name": "Sven",
          "name": "sven",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/sven_full.png",
          "id": "18"
        },
        {
          "localized_name": "Tiny",
          "name": "tiny",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/tiny_full.png",
          "id": "19"
        },
        {
          "localized_name": "Vengeful Spirit",
          "name": "vengefulspirit",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/vengefulspirit_full.png",
          "id": "20"
        },
        {
          "localized_name": "Windranger",
          "name": "windrunner",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/windrunner_full.png",
          "id": "21"
        },
        {
          "localized_name": "Zeus",
          "name": "zuus",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/zuus_full.png",
          "id": "22"
        },
        {
          "localized_name": "Kunkka",
          "name": "kunkka",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/kunkka_full.png",
          "id": "23"
        },
        {
          "localized_name": "Lina",
          "name": "lina",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/lina_full.png",
          "id": "25"
        },
        {
          "localized_name": "Lion",
          "name": "lion",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/lion_full.png",
          "id": "26"
        },
        {
          "localized_name": "Shadow Shaman",
          "name": "shadow_shaman",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/shadow_shaman_full.png",
          "id": "27"
        },
        {
          "localized_name": "Slardar",
          "name": "slardar",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/slardar_full.png",
          "id": "28"
        },
        {
          "localized_name": "Tidehunter",
          "name": "tidehunter",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/tidehunter_full.png",
          "id": "29"
        },
        {
          "localized_name": "Witch Doctor",
          "name": "witch_doctor",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/witch_doctor_full.png",
          "id": "30"
        },
        {
          "localized_name": "Lich",
          "name": "lich",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/lich_full.png",
          "id": "31"
        },
        {
          "localized_name": "Riki",
          "name": "riki",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/riki_full.png",
          "id": "32"
        },
        {
          "localized_name": "Enigma",
          "name": "enigma",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/enigma_full.png",
          "id": "33"
        },
        {
          "localized_name": "Tinker",
          "name": "tinker",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/tinker_full.png",
          "id": "34"
        },
        {
          "localized_name": "Sniper",
          "name": "sniper",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/sniper_full.png",
          "id": "35"
        },
        {
          "localized_name": "Necrophos",
          "name": "necrolyte",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/necrolyte_full.png",
          "id": "36"
        },
        {
          "localized_name": "Warlock",
          "name": "warlock",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/warlock_full.png",
          "id": "37"
        },
        {
          "localized_name": "Beastmaster",
          "name": "beastmaster",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/beastmaster_full.png",
          "id": "38"
        },
        {
          "localized_name": "Queen of Pain",
          "name": "queenofpain",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/queenofpain_full.png",
          "id": "39"
        },
        {
          "localized_name": "Venomancer",
          "name": "venomancer",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/venomancer_full.png",
          "id": "40"
        },
        {
          "localized_name": "Faceless Void",
          "name": "faceless_void",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/faceless_void_full.png",
          "id": "41"
        },
        {
          "localized_name": "Wraith King",
          "name": "skeleton_king",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/skeleton_king_full.png",
          "id": "42"
        },
        {
          "localized_name": "Death Prophet",
          "name": "death_prophet",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/death_prophet_full.png",
          "id": "43"
        },
        {
          "localized_name": "Phantom Assassin",
          "name": "phantom_assassin",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/phantom_assassin_full.png",
          "id": "44"
        },
        {
          "localized_name": "Pugna",
          "name": "pugna",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/pugna_full.png",
          "id": "45"
        },
        {
          "localized_name": "Templar Assassin",
          "name": "templar_assassin",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/templar_assassin_full.png",
          "id": "46"
        },
        {
          "localized_name": "Viper",
          "name": "viper",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/viper_full.png",
          "id": "47"
        },
        {
          "localized_name": "Luna",
          "name": "luna",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/luna_full.png",
          "id": "48"
        },
        {
          "localized_name": "Dragon Knight",
          "name": "dragon_knight",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/dragon_knight_full.png",
          "id": "49"
        },
        {
          "localized_name": "Dazzle",
          "name": "dazzle",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/dazzle_full.png",
          "id": "50"
        },
        {
          "localized_name": "Clockwerk",
          "name": "rattletrap",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/rattletrap_full.png",
          "id": "51"
        },
        {
          "localized_name": "Leshrac",
          "name": "leshrac",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/leshrac_full.png",
          "id": "52"
        },
        {
          "localized_name": "Nature's Prophet",
          "name": "furion",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/furion_full.png",
          "id": "53"
        },
        {
          "localized_name": "Lifestealer",
          "name": "life_stealer",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/life_stealer_full.png",
          "id": "54"
        },
        {
          "localized_name": "Dark Seer",
          "name": "dark_seer",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/dark_seer_full.png",
          "id": "55"
        },
        {
          "localized_name": "Clinkz",
          "name": "clinkz",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/clinkz_full.png",
          "id": "56"
        },
        {
          "localized_name": "Omniknight",
          "name": "omniknight",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/omniknight_full.png",
          "id": "57"
        },
        {
          "localized_name": "Enchantress",
          "name": "enchantress",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/enchantress_full.png",
          "id": "58"
        },
        {
          "localized_name": "Huskar",
          "name": "huskar",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/huskar_full.png",
          "id": "59"
        },
        {
          "localized_name": "Night Stalker",
          "name": "night_stalker",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/night_stalker_full.png",
          "id": "60"
        },
        {
          "localized_name": "Broodmother",
          "name": "broodmother",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/broodmother_full.png",
          "id": "61"
        },
        {
          "localized_name": "Bounty Hunter",
          "name": "bounty_hunter",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/bounty_hunter_full.png",
          "id": "62"
        },
        {
          "localized_name": "Weaver",
          "name": "weaver",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/weaver_full.png",
          "id": "63"
        },
        {
          "localized_name": "Jakiro",
          "name": "jakiro",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/jakiro_full.png",
          "id": "64"
        },
        {
          "localized_name": "Batrider",
          "name": "batrider",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/batrider_full.png",
          "id": "65"
        },
        {
          "localized_name": "Chen",
          "name": "chen",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/chen_full.png",
          "id": "66"
        },
        {
          "localized_name": "Spectre",
          "name": "spectre",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/spectre_full.png",
          "id": "67"
        },
        {
          "localized_name": "Ancient Apparition",
          "name": "ancient_apparition",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/ancient_apparition_full.png",
          "id": "68"
        },
        {
          "localized_name": "Doom",
          "name": "doom_bringer",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/doom_bringer_full.png",
          "id": "69"
        },
        {
          "localized_name": "Ursa",
          "name": "ursa",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/ursa_full.png",
          "id": "70"
        },
        {
          "localized_name": "Spirit Breaker",
          "name": "spirit_breaker",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/spirit_breaker_full.png",
          "id": "71"
        },
        {
          "localized_name": "Gyrocopter",
          "name": "gyrocopter",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/gyrocopter_full.png",
          "id": "72"
        },
        {
          "localized_name": "Alchemist",
          "name": "alchemist",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/alchemist_full.png",
          "id": "73"
        },
        {
          "localized_name": "Invoker",
          "name": "invoker",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/invoker_full.png",
          "id": "74"
        },
        {
          "localized_name": "Silencer",
          "name": "silencer",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/silencer_full.png",
          "id": "75"
        },
        {
          "localized_name": "Outworld Devourer",
          "name": "obsidian_destroyer",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/obsidian_destroyer_full.png",
          "id": "76"
        },
        {
          "localized_name": "Lycan",
          "name": "lycan",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/lycan_full.png",
          "id": "77"
        },
        {
          "localized_name": "Brewmaster",
          "name": "brewmaster",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/brewmaster_full.png",
          "id": "78"
        },
        {
          "localized_name": "Shadow Demon",
          "name": "shadow_demon",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/shadow_demon_full.png",
          "id": "79"
        },
        {
          "localized_name": "Lone Druid",
          "name": "lone_druid",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/lone_druid_full.png",
          "id": "80"
        },
        {
          "localized_name": "Chaos Knight",
          "name": "chaos_knight",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/chaos_knight_full.png",
          "id": "81"
        },
        {
          "localized_name": "Meepo",
          "name": "meepo",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/meepo_full.png",
          "id": "82"
        },
        {
          "localized_name": "Treant Protector",
          "name": "treant",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/treant_full.png",
          "id": "83"
        },
        {
          "localized_name": "Ogre Magi",
          "name": "ogre_magi",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/ogre_magi_full.png",
          "id": "84"
        },
        {
          "localized_name": "Undying",
          "name": "undying",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/undying_full.png",
          "id": "85"
        },
        {
          "localized_name": "Rubick",
          "name": "rubick",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/rubick_full.png",
          "id": "86"
        },
        {
          "localized_name": "Disruptor",
          "name": "disruptor",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/disruptor_full.png",
          "id": "87"
        },
        {
          "localized_name": "Nyx Assassin",
          "name": "nyx_assassin",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/nyx_assassin_full.png",
          "id": "88"
        },
        {
          "localized_name": "Naga Siren",
          "name": "naga_siren",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/naga_siren_full.png",
          "id": "89"
        },
        {
          "localized_name": "Keeper of the Light",
          "name": "keeper_of_the_light",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/keeper_of_the_light_full.png",
          "id": "90"
        },
        {
          "localized_name": "Io",
          "name": "wisp",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/wisp_full.png",
          "id": "91"
        },
        {
          "localized_name": "Visage",
          "name": "visage",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/visage_full.png",
          "id": "92"
        },
        {
          "localized_name": "Slark",
          "name": "slark",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/slark_full.png",
          "id": "93"
        },
        {
          "localized_name": "Medusa",
          "name": "medusa",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/medusa_full.png",
          "id": "94"
        },
        {
          "localized_name": "Troll Warlord",
          "name": "troll_warlord",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/troll_warlord_full.png",
          "id": "95"
        },
        {
          "localized_name": "Centaur Warrunner",
          "name": "centaur",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/centaur_full.png",
          "id": "96"
        },
        {
          "localized_name": "Magnus",
          "name": "magnataur",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/magnataur_full.png",
          "id": "97"
        },
        {
          "localized_name": "Timbersaw",
          "name": "shredder",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/shredder_full.png",
          "id": "98"
        },
        {
          "localized_name": "Bristleback",
          "name": "bristleback",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/bristleback_full.png",
          "id": "99"
        },
        {
          "localized_name": "Tusk",
          "name": "tusk",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/tusk_full.png",
          "id": "100"
        },
        {
          "localized_name": "Skywrath Mage",
          "name": "skywrath_mage",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/skywrath_mage_full.png",
          "id": "101"
        },
        {
          "localized_name": "Abaddon",
          "name": "abaddon",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/abaddon_full.png",
          "id": "102"
        },
        {
          "localized_name": "Elder Titan",
          "name": "elder_titan",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/elder_titan_full.png",
          "id": "103"
        },
        {
          "localized_name": "Legion Commander",
          "name": "legion_commander",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/legion_commander_full.png",
          "id": "104"
        },
        {
          "localized_name": "Techies",
          "name": "techies",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/techies_full.png",
          "id": "105"
        },
        {
          "localized_name": "Ember Spirit",
          "name": "ember_spirit",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/ember_spirit_full.png",
          "id": "106"
        },
        {
          "localized_name": "Earth Spirit",
          "name": "earth_spirit",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/earth_spirit_full.png",
          "id": "107"
        },
        {
          "localized_name": "Underlord",
          "name": "abyssal_underlord",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/abyssal_underlord_full.png",
          "id": "108"
        },
        {
          "localized_name": "Terrorblade",
          "name": "terrorblade",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/terrorblade_full.png",
          "id": "109"
        },
        {
          "localized_name": "Phoenix",
          "name": "phoenix",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/phoenix_full.png",
          "id": "110"
        },
        {
          "localized_name": "Oracle",
          "name": "oracle",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/oracle_full.png",
          "id": "111"
        },
        {
          "localized_name": "Winter Wyvern",
          "name": "winter_wyvern",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/winter_wyvern_full.png",
          "id": "112"
        },
        {
          "localized_name": "Arc Warden",
          "name": "arc_warden",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/arc_warden_full.png",
          "id": "113"
        },
        {
          "localized_name": "Monkey King",
          "name": "monkey_king",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/monkey_king_full.png",
          "id": "114"
        },
        {
          "localized_name": "Dark Willow",
          "name": "dark_willow",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/dark_willow_full.png",
          "id": "119"
        },
        {
          "localized_name": "Pangolier",
          "name": "pangolier",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/pangolier_full.png",
          "id": "120"
        },
        {
          "localized_name": "Grimstroke",
          "name": "grimstroke",
          "image": "http://cdn.dota2.com/apps/dota2/images/heroes/grimstroke_full.png",
          "id": "121"
        }
      ]
  }
}
