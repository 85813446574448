import React, { Component } from "react"
import Container from "utils/Container"
import BetAuth from "./BetAuth"
import AnimatedNumber from "react-animated-number"
import Transition from "react-transition-group/Transition"

export default class PredictionDefaultState extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isHoverBtn: false,
      isHoverLocked: false,
      bank1: 0,
      users1: 0,
      bank2: 0,
      users2: 0,
      users1P: 0,
      users2P: 0
    }
    // console.log('PredictionDefaultState constructor', this.state.bets)

    this.handleHover = this.handleHover.bind(this)
    this.handleHoverLeave = this.handleHoverLeave.bind(this)
    this.handleHoverLocked = this.handleHoverLocked.bind(this)
  }

  componentWillReceiveProps(props) {
    const { bets } = props
    // console.log('PredictionDefaultState componentWillReceiveProps', bets)

    setTimeout(() => {
      this.setState({
        bank1: bets[0].bank,
        users1: bets[0].users,
        bank2: bets[1].bank,
        users2: bets[1].users
      })
    }, 300)
  }

  handleHover = (e) => {
    this.setState(prevState => ({ isHoverBtn: !prevState.isHoverBtn }))
  }

  handleHoverLocked = (e) => {
    // console.log('PredictionDefaultState handleHoverLocked')
    this.setState(prevState => ({ isHoverLocked: !prevState.isHoverLocked }))
  }

  handleHoverLeave = () => {
    this.setState({ isHoverBtn: false })
  }

  render() {
    // это просто звезда, я уже с ума схожу с этими анимациями для предикшинов, 
    // потратил кучу времени, а результата - ноль

    const { props, state } = this
    const { users1, users2} = state
    const { winBtn, loseBtn, isLocked0, isLocked1, handlePrediction, bets, isChallenge, K1, K2  } = props
    const winDesc = bets[0].desc || winBtn
    const loseDesc = bets[1].desc || loseBtn

    // console.log('PredictionDefaultState render', { bank1, bank2, users1, users2 })

    const animationDuration = 500
    const defaultStyleCounter = {
      transition: `top ${animationDuration}ms, opacity ${4 * animationDuration}ms`,
      opacity: 0,
      position: "absolute",
      right: 0,
      top: "-100%",
      height: "14px",
    }
    const transitionStylesCounter = {
      entering: {
        opacity: 1,
        top: "-100%",
      },
      entered: {
        opacity: 0,
        top: "-180%",
      }
    }
    const Plus1Component = ({ newValue, prev }) => (

      <span style={{ position: "relative" }}>

        <Transition
          in
          timeout={0}
          appear={newValue != prev}
        >
          {state => (
            <span style={{
              ...defaultStyleCounter,
              ...transitionStylesCounter[state]
            }}
            >
              {"+1"}
            </span>
          )}
        </Transition>

        {newValue}

      </span>
    )

    const r = (
      <div className="predictionContainerBlock__bottom">
        {(isLocked0 || isLocked1) && !isChallenge ? (
          <table cellSpacing="0">
            <thead>
              <tr>
                <th width="42%">Prediction</th>
                <th width="58%">Coefficient</th>
              </tr>
            </thead>
            <tbody>
              {isLocked0 ?
                (this.state.isHoverLocked ? (
                  <tr className="team_one tip" onMouseLeave={this.handleHoverLocked} >
                    <td colSpan="2" >
                      <span>this prediction is temporary unavaliable </span>
                    </td>
                  </tr>
                ) : (
                  <tr className="team_one disabled" onMouseEnter={this.handleHoverLocked} >
                    <td>
                      <button>{winBtn}</button>
                    </td>
                    <td>
                      <span>
                        {K1}
                      </span>
                    </td>
                  </tr>
                  )
                ) : (
                  <tr className="team_one" >
                    <td className="hoverTipWrapper">

                      <BetAuth isClosed={false} title={winBtn} id={0} handlePrediction={handlePrediction} />
                      <div className="hoverButtonOverlay"><p>{`${winDesc}`}</p></div>
                    </td>
                    <td>
                      <span>
                        {K1}
                      </span>
                    </td>
                  </tr>
                )}
              {!isChallenge ?
              isLocked1 ?
                (this.state.isHoverLocked ? (
                  <tr className="team_two tip" onMouseLeave={this.handleHoverLocked}>
                    <td colSpan="2">
                      <span>this prediction is temporary unavaliable </span>
                    </td>
                  </tr>
                ) : (
                  <tr className="team_two disabled" onMouseEnter={this.handleHoverLocked} >
                    <td>
                      <button>{loseBtn}</button>
                    </td>
                    <td>
                      <span>
                      {K2}
                      </span>
                    </td>
                  </tr>
                  )
                ) : (
                  <tr className="team_two">
                    <td className="hoverTipWrapper" onMouseEnter={this.handleHover} onMouseLeave={this.handleHoverLeave} >

                      <BetAuth isClosed={false} title={loseBtn} id={1} handlePrediction={handlePrediction} />
                      <div className="hoverButtonOverlay"><p>{loseDesc}</p></div>
                    </td>
                    <td>
                      <span>
                      {K2}
                      </span>
                    </td>
                  </tr>
                ) : null }
            </tbody>
          </table>
        ) : (
          <table cellSpacing="0">
            <thead>
              <tr>
                <th width="42%">Prediction</th>
                <th width="58%">Coefficient</th>
              </tr>
            </thead>
            <tbody>
              <tr className="team_one">
                <td className="hoverTipWrapper" onMouseEnter={this.handleHover} onMouseLeave={this.handleHoverLeave}>
                  <BetAuth isClosed={false} title={winBtn} id={0} handlePrediction={handlePrediction} />
                  <div className="hoverButtonOverlay"><p>{winDesc}</p></div>
                </td>
                <td>
                  <span>
                  {K1}
                  </span>
                </td>
              </tr>
              {!isChallenge ? 
                <tr className="team_two">
                  <td className="hoverTipWrapper" onMouseEnter={this.handleHover} onMouseLeave={this.handleHoverLeave}>
                    <BetAuth isClosed={false} title={loseBtn} id={1} handlePrediction={handlePrediction} />
                    <div className="hoverButtonOverlay"><p>{loseDesc}</p></div>
                  </td>
                  <td>
                    <span>  
                    {K2}
                    </span>
                  </td>
                </tr>
                : null }
            </tbody>
          </table>
          )
        }
      </div>
    )
    state.users1P = users1
    state.users2P = users2
    return r
  }
}
