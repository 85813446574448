
import * as SocketAPI from "api/SocketAPI"
import { updateUserBalance } from 'actions/UserActions'
import { store } from "libs/redux"
import "whatwg-fetch"

const load = async (callback, admin = "") => {
  // я не знаю - правильно так делать или нет, скорее всего нет
  // но что поделать, когда мы не успеваем вообще никуда
  // в апи нужно перенести
  const predictions = store.getState().predictions.predictions
  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/${admin}prediction/`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      json.forEach((prediction) => {
        const index = predictions.findIndex(item => item.id === prediction.id)

        // берём старый предикшн
        const oldPrediction = index != -1 ? predictions[index] : null
        const prd = update(prediction, oldPrediction)

        // заменяем/добавляем
        if (index != -1) {
          predictions[index] = prd
        } else {
          predictions.push(prd)
        }
      })

      const filtered = predictions.filter((prd) => {
        if (prd.finishedAt == null) return true

        const time = new Date() - new Date(prd.finishedAt)
        return time / 1000 < 30
      })

      const sorted = filtered.sort((a, b) => {
        return a.id - b.id
      })

      const rev = sorted.reverse()

      callback(rev)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
}

function mapBets(bets) {
  const outcome0 = bets.filter((item) => {
    return item.outcome == 0
  }).map((item) => {
    return item.value
  })

  const outcome1 = bets.filter((item) => {
    return item.outcome == 1
  }).map((item) => {
    return item.value
  })

  const bank0 = outcome0.reduce((total, val) => {
    return total + parseInt(val)
  }, 0)
  const bank1 = outcome1.reduce((total, val) => {
    return total + parseInt(val)
  }, 0)

  return [
    {
      bank: bank0,
      users: outcome0.length
    }, {
      bank: bank1,
      users: outcome1.length
    }
  ]
}

function mapUserBet(bets) {
  const bet = bets.filter((item) => {
    return item.user == 1 // user.model.id
  }).map((item) => {
    return { value: item.value, outcome: item.outcome }
  })
  const value = bet.reduce((total, val) => {
    return total + val.value
  }, 0)
  return {
    value,
    outcome: bet.length > 0 ? bet[0].outcome : 0
  }
}

const updated = async (callback, admin, stream) => {
  // sockets logic
  SocketAPI.predictions(admin, stream, ({ error, response, type, socket }) => {
    // check for the errors
    if (error) {
      console.error("SocketAPI predictions", error, socket)
      return
    }

    // check for the connects
    if (type == "connect" || type == "disconnect") {
      return
    }

    const prediction = response.prediction

    const predictions = store.getState().predictions.predictions

    const index = predictions.findIndex(item => item.id === prediction.id)

    // берём старый предикшн
    const oldPrediction = index != -1 ? predictions[index] : null
    const prd = update(prediction, oldPrediction)

    // заменяем/добавляем
    if (index != -1) {
      predictions[index] = prd
    } else {
      predictions.push(prd)
    }

    const filtered = predictions.filter((prd) => {
      if (prd.finishedAt == null) return true

      const time = new Date() - new Date(prd.finishedAt)
      return time / 1000 < 30
    })

    const sorted = filtered.sort((a, b) => {
      return a.id - b.id
    })

    const rev = sorted.reverse()

    callback(rev)
  })
}

function modeToType(prd, origin) {
  let mode = prd.mode

  // мне нужно знать оригинальный стейт, что бы правильно посчитать профит
  if (origin != "origin") {
    // если ставка закрыта, значит статус "waiting_for_result"
    mode = prd.closedAt != null ? "WAITING_MODE" : prd.mode
    // если ставка закрыта и разыграна, значит статус "finishedAt"
    mode = prd.finishedAt != null ? "CLOSED_MODE" : mode
  }

  switch (mode) {
    case "WAITING_MODE": return "waiting_for_result"
    case "CLOSED_MODE": return "done"
    case "LIVE_MODE": return "live_event"
    case "FUTURE_MODE": return "next_event"
    case "CHALLENGE_MODE": return "challenge_event"
    default: return "waiting_for_result"
  }
}


const create = async (prd, callback) => {

  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  // remove id
  const _prd = { ...prd }
  delete _prd.id

  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/prediction/`, {
    method: "POST",
    headers,
    body: JSON.stringify(_prd)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "UNKNOWN_ERROR" || json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const createChallenge = async (prd, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch("/api/challenge/", {
    method: "POST",
    headers,
    body: JSON.stringify(prd)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "UNKNOWN_ERROR" || json.code != undefined) {
        callback(json)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const add = async (prd, callback) => {

  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/prediction/`, {
    method: "POST",
    headers,
    body: JSON.stringify(prd)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code != undefined) {
        callback(json.message)
      } else {
        // success
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}


const placeBet = async (id, bet, callback, _token) => {

  const token = _token || store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  if (bet.value < 1) {
    callback("Prediction value can't be less then 1")
  }

  fetch(`/api/prediction/${id}/bet`, {
    method: "POST",
    headers,
    body: JSON.stringify(bet)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "BET_TOO_SMALL" || json.code == "FEW_BONUSES" || json.code == "DOUBLE_BETS_DENIED" || json.code == "PREDICTION_ENDED"
          || json.code != undefined) {
        callback(json.message)
      } else if (json.id != null) {
        // success
        const prd = update(json)
        // update prd at props
        callback(null, prd)
      } else {
        callback(json.message)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const placeBetAdmin = async (id, bet, callback, _token) => {

  const token = _token || store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  if (bet.value < 1) {
    callback("Prediction value can't be less then 1")
  }

  fetch(`/api/admin/prediction/${id}/bet`, {
    method: "POST",
    headers,
    body: JSON.stringify(bet)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "BET_TOO_SMALL" || json.code == "FEW_BONUSES" || json.code == "DOUBLE_BETS_DENIED" || json.code == "PREDICTION_ENDED" || json.code == "UNKNOWN_ERROR"
          || json.code != undefined) {
        callback(json.message)
      } else if (json.id != null) {
        // success
        const prd = update(json)
        // update prd at props
        callback(null, prd)
      } else {
        callback(json.message)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const closePrediction = async (id, callback) => {

  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/prediction/${id}/close`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "INSTANCE_NOT_FOUND" || json.code != undefined) {
        alert("looks like you don`t have permissions for that")
        callback(json.message)
      } else {
        // success
        const prd = update(json)
        // update prd at props
        callback(null, prd)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const updatePrediction = async (request, callback) => {

  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/prediction/`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(request)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "INSTANCE_NOT_FOUND" || json.code != undefined) {
        alert("looks like you don`t have permissions for that")
        callback(json.message)
      } else {
        // success
        const prd = update(json)
        // update prd at props
        callback(null, prd)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const cancelPrediction = async (id, callback) => {

  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions
 
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/prediction/${id}/cancel`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "INSTANCE_NOT_FOUND" || json.code != undefined) {
        alert("looks like you don`t have permissions for that")
        callback(json.message)
      } else {
        // success
        const prd = update(json)
        // update prd at props
        callback(null, prd)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}


const calculatePrediction = async (id, outcome, callback) => {

  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : ''}/prediction/${id}/calculate?outcome=${outcome}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "INSTANCE_NOT_FOUND" || json.code != undefined) {
        alert("looks like you don`t have permissions for that")
        callback(json.message)
      } else {
        // success
        const prd = update(json)
        // update prd at props
        callback(null, prd)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}

const predictionOver = async (prediction, outcome, stream, callback) => {

  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/prediction/${prediction}/over`, {
    method: "POST",
    headers,
    body: JSON.stringify({ outcome, stream })
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "INSTANCE_NOT_FOUND" || json.code != undefined) {
        alert("looks like you don`t have permissions for that")
        if (callback) { callback(json.message) }
        return
      }
      if (callback) {
        const prd = update(json)
        callback(null, prd)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(error)
    })
}


const getPdone = async (callback) => {
  const pdones = store.getState().predictions.pdone
  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch("/api/admin/prediction/pdone", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code != undefined) {
        if (callback) { callback(json.message) }
        return
      }
      if (callback) {
        callback(null, json)
      }
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
}

const updatePdone = async (token, stream, callback) => {
  SocketAPI.pdones(stream, ({ error, response, type, socket }) => {
    if (error) {
      console.error("SocketAPI pdones", type, error)
      return
    }

    if (callback && type == "pdone") {
      callback(response)
    }
  })
}

function update(prd, oldPrediction, callback) {
  store.dispatch(updateUserBalance())
  const prediction = oldPrediction || Object()

  prediction.id = prd.id
  prediction.stream = prd.stream
  prediction.title = prd.title
  prediction.description = prd.description
  prediction.game = prd.game

  prediction.type = modeToType(prd)
  prediction.typeOrigin = modeToType(prd, "origin")

  prediction.winOutcome = parseInt(prd.winOutcome)
  prediction.userBets = prd.yourBet || prediction.userBets || []
  prediction.outcomes = prd.outcomes
  prediction.mode = prd.mode
  prediction.createdAt = prd.createdAt
  prediction.closedAt = prd.closedAt
  prediction.updatedAt = prd.updatedAt
  prediction.finishedAt = prd.finishedAt

  prediction.moderated = prd.moderated
  prediction.author = prd.user

  if (callback != null) {
    callback(prediction)
  }
  return prediction
}


export {
  load, create, createChallenge, updated, update, placeBet, placeBetAdmin, cancelPrediction, closePrediction, calculatePrediction,
  predictionOver, getPdone, updatePdone, updatePrediction
}
