import React, {Component} from "react"
import { Link } from "react-router-dom"
import { trans } from "utils/translator"

import * as paymentAPI from "api/paymentAPI"

export default trans(class CashboxModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardNumber: 'XXXXXXXXXXXXXXXX',
            amount: 1,
            account: '',
            currency: 'UAH'
        }
        this.handleCardNumberInputChange = this.handleCardNumberInputChange.bind(this)
        this.handleCardNumberInputClick = this.handleCardNumberInputClick.bind(this)
        this.setCaretPosition = this.setCaretPosition.bind(this)
        this.handleAmountInputChange = this.handleAmountInputChange.bind(this)
        this.handleAddCard = this.handleAddCard.bind(this)
    }

    withdraw = (method) => () => {
        let { state } = this
        let { amount, cardNumber, currency, account} = state
        const { notify } = this.props
        
        paymentAPI.withdraw(amount, currency, method, account, (err, msg) => {
            if(!err)
           notify({
               title: 'Success',
               message: msg.message,
               status: 'success'
            })
            else
            notify({
                title: 'Something went wrong',
                message: err.message,
                status: 'error'
             })
        })
    }
    setCaretPosition = (ctrl, pos) => {
        // Modern browsers
        if (ctrl.setSelectionRange) {
          ctrl.focus();
          ctrl.setSelectionRange(pos, pos);
        
        // IE8 and below
        } else if (ctrl.createTextRange) {
          var range = ctrl.createTextRange();
          range.collapse(true);
          range.moveEnd('character', pos);
          range.moveStart('character', pos);
          range.select();
        }
      }
      handleAmountInputChange = e => {
        const value = e.target.value

        if(isNaN(+value)) return

        this.setState({
            account: +value
        })
    }
    handleAmountInputChange = e => {
        const value = e.target.value

        if(isNaN(+value)) return

        this.setState({
            amount: Math.floor(+value)
        })
    }
    handleCardNumberInputClick = (e) => {
        if(value.replace(/X/g, '').length > 16)
        this.setCaretPosition(e.target, 0)
    }
    handleCardNumberInputChange = (e) => {
        const value = e.target.value.replace(/-/g, '').replace(/X/g, '')
        const oldValue = this.state.cardNumber.replace(/-/g, '').replace(/X/g, '')
        // const diff = value.split(oldValue).join('');
        const diff = value.split('')[0]
        const target = e.target
        const placeholder = 'XXXXXXXXXXXXXXXX'

        if(isNaN(+value.replace(/X/g, '')) || value.replace(/X/g, '').length > 16) return
      
        this.setState( {
            cardNumber: `${oldValue + diff}${placeholder}`.slice(0, 16)
        }, () => {
            
            this.setCaretPosition(target, 0) 
        }) 
    } 
    handleAddCard = (e) => {
        e.preventDefault()
        paymentAPI.addCard('10.00', 'UAH', () => {})
    }
    handleCurrencyChange = (e) => {
        this.setState({
            currency: e.target.value,
        })
    } 
    handleAccountInputChange = (e) => {
        this.setState({
            account: e.target.value,
        })
    }
    render(){
        const {t, method, user} =  this.props
        return (
            <div className='cashbox-modal'  onClick={(e)=> e.stopPropagation()}>
            <div className='cashbox-modal__block cashbox-modal__head'>
            <div className='cashbox-modal__label'>
            You have <i>{this.props.user.model.orbs - this.props.user.model.passiveOrbs}</i> Orbs available for cashout 
             </div>
            </div>
            {
            method == "CARD" ?
            <div>
             <form className="profilePageForm cashbox-modal__form">
              {/* <div className="profilePageInputBlock">
                <label className="profilePageInput-label" htmlFor="cardnumber">Card number
                  <input type="text" 
                    onChange={this.handleCardNumberInputChange}
                    onClick={this.handleCardNumberInputClick} 
                    className="profileInput cashbox-modal__card-number"
                    name="cardnumber"
                    id="cardnumber"
                    value={this.state.cardNumber.match(/.{4}/g).join('-')}/>
                </label>
              </div> */}
              <div className="profilePageInputBlock">
              <button className="pre-btn pre-btn_blue pre-btn_modal cashbox-modal__button"
              onClick={this.handleAddCard}>
              {user.model.cardToken ? t('profile_change_card') : t('profile_add_card')}</button>
              </div>
              <div className='cashbox-modal__row'>
            
              <div className="profilePageInputBlock cashbox-modal__inline">
                <label className="profilePageInput-label cashbox-modal__label" htmlFor="amount">Amount
                  <input 
                  className="profileInput cashbox-modal__inline" 
                  name="amount" 
                  id="amount"
                  value={this.state.amount}
                  onChange={this.handleAmountInputChange}  />
                </label>
              </div>
              <div className="profilePageInputBlock cashbox-modal__inline">
                <label className="profilePageInput-label cashbox-modal__label" htmlFor="currency">Select a currency
                  <select className="profileInput cashbox-modal__currency-select" name="currency" id="currency" onChange={this.handleCurrencyChange}>
                  <option value="UAH" >UAH</option>
                  <option value="RUB" >RUB</option>
                  <option value="USD" >USD</option>
                  <option value="EUR" >EUR</option>
                  </select>
                </label>
              </div>
              </div>
              </form>
              <div className="inputBlock cashbox-modal__confirm">
                  <button onClick={this.withdraw('CARD')} className="pre-btn pre-btn_blue pre-btn_modal cashbox-modal__button">
                      Cashout
                  </button>
              </div>
              </div> :
              method == 'YANDEX' ? 
              <div>
             <form className="profilePageForm cashbox-modal__form">
              <div className="profilePageInputBlock">
              <label className="profilePageInput-label cashbox-modal__label" htmlFor="account">Your Yandex account
                  <input 
                  className="profileInput cashbox-modal__inline" 
                  name="account" 
                  id="account"
                  value={this.state.account}
                  onChange={this.handleAccountInputChange}  />
                </label>
              </div>
              <div className='cashbox-modal__row'>
            
            <div className="profilePageInputBlock cashbox-modal__inline">
              <label className="profilePageInput-label cashbox-modal__label" htmlFor="amount">Amount
                <input 
                className="profileInput cashbox-modal__inline" 
                name="amount" 
                id="amount"
                value={this.state.amount}
                onChange={this.handleAmountInputChange}  />
              </label>
            </div>
            <div className="profilePageInputBlock cashbox-modal__inline">
              <label className="profilePageInput-label cashbox-modal__label" htmlFor="currency">Select a currency
                <select className="profileInput cashbox-modal__currency-select" name="currency" id="currency" onChange={this.handleCurrencyChange}>
                <option value="UAH" >UAH</option>
                <option value="RUR" >RUR</option>
                <option value="USD" >USD</option>
                <option value="EUR" >EUR</option>
                </select>
              </label>
            </div>
            </div>
              </form>
              <div className="inputBlock cashbox-modal__confirm">
                  <button onClick={this.withdraw('YANDEX')} className="pre-btn pre-btn_blue pre-btn_modal cashbox-modal__button">
                      Cashout
                  </button>
              </div>
              </div> : method == 'QIWI' ? <div>
             <form className="profilePageForm cashbox-modal__form">
              <div className="profilePageInputBlock">
              <label className="profilePageInput-label cashbox-modal__label" htmlFor="account">Your QIWI account
                  <input 
                  className="profileInput cashbox-modal__inline" 
                  name="account" 
                  id="account"
                  value={this.state.account}
                  onChange={this.handleAccountInputChange}  />
                </label>
              </div>
              <div className='cashbox-modal__row'>
            
            <div className="profilePageInputBlock cashbox-modal__inline">
              <label className="profilePageInput-label cashbox-modal__label" htmlFor="amount">Amount
                <input 
                className="profileInput cashbox-modal__inline" 
                name="amount" 
                id="amount"
                value={this.state.amount}
                onChange={this.handleAmountInputChange}  />
              </label>
            </div>
            <div className="profilePageInputBlock cashbox-modal__inline">
              <label className="profilePageInput-label cashbox-modal__label" htmlFor="currency">Select a currency
                <select className="profileInput cashbox-modal__currency-select" name="currency" id="currency" onChange={this.handleCurrencyChange}>
                <option value="UAH" >UAH</option>
                <option value="RUR" >RUR</option>
                <option value="USD" >USD</option>
                <option value="EUR" >EUR</option>
                </select>
              </label>
            </div>
            </div>
              </form>
              <div className="inputBlock cashbox-modal__confirm">
                  <button onClick={this.withdraw('QIWI')} className="pre-btn pre-btn_blue pre-btn_modal cashbox-modal__button">
                      Cashout
                  </button>
              </div>
              </div> : null
            }
               <div className='cashbox-modal__block'>
              <div className='cashbox-modal__label'>
              1 Orb = 0.1 USD
            </div>
            <div className='cashbox-modal__label'>
              Transactions may take a while. 
              If your have not recieved your money within 24 hours, contact us <a href="#" onClick={() => jivo_api.open("Нужна помощь, консультация или совет? Обращайтесь ко мне.")}> here </a>
              Check out our <Link to={t('cashbox_modal_faq')}>F.A.Q</Link> for more info.
            </div>
            </div>
            </div>
  )

}
}
)