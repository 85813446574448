import React, {Component} from "react"
import {get} from "api/TransactionAPI"
import { Link } from "react-router-dom"
import { trans } from "utils/translator"
import * as UsersAPI from "api/UsersApi"

export default trans(class ReferalManagement extends Component {
    constructor(props) {
        const referalCode = props.user.model.referralCode
        super(props)

        this.state = {
            activeTab: 'INVITE',
            referalLink: `${window.location.origin}/ref/${referalCode || ''}`,
            referralList: [],
        }

    }
    componentDidMount(){
        gtag("event", "profile", { 
            event_category: 'webpage_interraction',
            event_action: 'profile_stream_referals_page_visited',
            user: this.props.user
          })
          UsersAPI.getReferrals((err, msg) => {
            
              if(!err){
                  this.setState({
                      referralList: msg,
                  })
              }
          })
    }

    copyReferralLink = () => {
        const inputElement = document.getElementById("referral-link")
        inputElement.select()
        document.execCommand('copy')
    }

    showTab = newTab => () => {
        this.setState({
            activeTab: newTab,
        })
    }
    renderReferalsRow = referal => {
        const { name, earned, createdAt } = referal
        const creationDate = new Date(createdAt)
        return <tr className='referals__row'>
            <td className='referals__username'>{ name || 'Predictor' }</td>
            {/* <td className='referals__earned'>{ earned }</td> */}
            <td className='referals__date'>{ creationDate.toLocaleDateString() || new Date().toLocaleDateString()}</td>
        </tr>
    }
    render(){
        const { props, state } = this
        const { user, streamList, t } = this.props
        const { activeTab, referalLink, referralList } = this.state
        return (
        <div className='profilePage'>
        <div className='referals'>
        <div className="referals__switch">
        <div className={`referals__button ${this.state.activeTab=='LIST' ? 'referals__button_active' : ''}`} onClick={this.showTab('LIST')}>
        {t('profile_referral_list')}
        </div>
        <div className={`referals__button ${this.state.activeTab=='INVITE' ? 'referals__button_active' : ''}`} onClick={this.showTab('INVITE')}>
        { t('profile_referral_link') }
        </div>
        <a target='_blank' href={t('profile_referral_info_link')} className={`referals__button ${this.state.activeTab=='INVITE' ? 'referals__button_active' : ''}`} onClick={this.showTab('INVITE')}>
        { t('profile_referral_info') }
        </a>
        </div>
           {
             activeTab === 'INVITE' ? 
                   
             <div className='referals__invite-wrapper'>
             <div className="referals__invite">
             <div className="referals__invite-container">
             <p className="referals__label">{t('profile_referral_link')}</p>
                <div className="referals__link">
                    <input id="referral-link" type="text" className="referals__link-input" value={this.state.referalLink}/>
                    <div className="referals__copy">
                        <button className="referals__copy-button pre-btn" onClick={this.copyReferralLink}>
                        {t('profile_copy')}
                        </button>
                    </div>
                </div>
             </div>
             <div className="referals__share-container">
             {/* <div className="fb-share-button" 
             data-href="https://predictoria.com/ref/dn198jd1jds81" 
             data-layout="button_count">
            </div> */}
            <a href={`http://www.facebook.com/share.php?u=${referalLink}&title=Join me on Predictoria`}>
            <div className="referals__fb" />
            </a>
             </div>
             </div>
             </div>
             :
             <div className='referals__list-wrapper'>
             <div className="referals__list">
             <div className="referals__list-container">
             <table cellSpacing="0">
            <thead className='referals__header'>
            <tr>
            <th className='referals__username'>{t('profile_referals_username')}</th>
            {/* <th className='referals__earned'>{t('profile_referals_earned')}</th> */}
            <th className='referals__date'>{t('profile_referals_date')}</th>
            </tr>
            </thead>
            <tbody className='referals__body'>
            {
                Array.isArray(referralList) ? 
                referralList.map(r => {
                    return this.renderReferalsRow(r)
                }) : null
            }
            </tbody>
            </table>
             </div>
             </div>
             </div>
           }
      
        </div>
        </div>
  )

}
})
