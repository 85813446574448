import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Route } from "react-router-dom"
import * as StreamActions from "actions/StreamActions"
import PredictionStreamItem from "./Betty-PredictionStreamItem.js"

class PredictionsStreamContainer extends Component {
  renderSliderItems(data) {
    const { StreamActions } = this.props

    const game = (this.props.location.pathname.split("/")[3] || "" ).replace(/ /g, "_")

    return data.map(el => (
      <Route
        path={`/betty/game/${game}/stream/${el.id}`}
        key={el.id}
        render={() => <PredictionStreamItem stream={el} bets={this.props.bets} StreamActions={this.props.StreamActions} />}
      />
    ))
  }

  render() {
    const streamList = this.props.events || []
    return (
      <div>{this.renderSliderItems(streamList)}</div>
    )
  }
}

function mapStateToProps(state) {
  return {
    events: state.bettyEvents.events
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PredictionsStreamContainer)
