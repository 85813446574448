import React, { Component } from "react"

export default class PredictionTimerComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      elapsed: new Date(this.props.createdAt) - new Date(),
      props
    }
    this.tick = this.tick.bind(this)
  }

  // counter code from example: https://codepen.io/smonn/pen/KzezEw
  getInitialState() {
    // This is called before our render function. The object that is 
    // returned is assigned to this.state, so we can use it later.

    return { elapsed: 0 }
  }

  componentDidMount() {

    // componentDidMount is called by react when the component 
    // has been rendered on the page. We can set the interval here:

    let timer = setInterval(this.tick, 1000, this)
    this.setState({timer: timer});
  }

  componentWillUnmount() {
    // This method is called immediately before the component is removed
    // from the page and destroyed. We can clear the interval here:
    clearInterval(this.state.timer)
  }

  tick(root) {
    // This function is called every 50 ms. It updates the 
    // elapsed counter. Calling setState causes the component to be re-rendered

    const createdAt = root.props.createdAt
    root.setState({ elapsed: new Date(createdAt) - new Date() })
  }

  secondsToString(miliseconds) {
    function addZero(v) {
      return v < 10 ? `0${v}` : v
    }
    const date = new Date(miliseconds)
    const hh = addZero(date.getUTCHours() || 0)
    const mm = addZero(date.getUTCMinutes() || 0)
    const ss = addZero(date.getSeconds() || 0)
    let rr = `${mm}:${ss}`
    if (parseInt(hh) > 0) {
      rr = `${hh}:${rr}`
    }
    return rr
  } 

  render() {

    let time = this.secondsToString(this.state.elapsed)

    if (new Date(this.props.createdAt) - new Date() < 0) {
      time = "Soon"
      clearInterval(this.state.timer)
    }

    return (
      <div className="timer">
        <div className="block">
          <div className="timer_desc medium">Next Event In</div>
          <div className="timer_time medium">{time}</div>
        </div>
      </div>
    )
  }
}
