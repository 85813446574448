import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/_Button';
import Toggler from 'components/Toggler';

const BrandQuestCard = props => {
    return (
        <div className='bq-card p2 border rounded'>
            <div className="flex mb3">
                <p className="bq-card__badge white h6">
                    BrandQuest
                </p>
                <p className="bq-card__company ml2 h6">
                    by CompName
                </p>
            </div>
            <p className="mb3">
                Wear a T-shirt with the company logo on the stream
            </p>
            <p className='mb3'>
                In progress
            </p>
            <div className="bq-card__bottom flex justify-between mb3">
                <div className="flex flex-column">
                    <p>Prize pool</p>
                    <p className='dark-magenta bold'>$1000</p>
                </div>
                {/* <p>1 day  left</p> */}
                <Toggler>More</Toggler>
            </div>
            {
                false && <div className="bq-card__info ">
                <pre>
                    1. You have to wear a branded t-shirt
                    2. Stream should go at least 2 hours
                    3. Streamer should not show products of other brands
                </pre>
                <div className="flex">
                    <Button>Apply</Button>
                    <Button>Dismiss</Button>
                </div>
            </div>
            }
          
        </div>
    )
}

BrandQuestCard.propTypes = {

}

export default BrandQuestCard
