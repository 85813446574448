import React, { Fragment } from "react"
import { trans } from "utils/translator"
import { val_gte, val_lte, isNum } from "utils/validator"

import FormValidator from "components/Common/FormValidator"
import ExpandableInput from 'components/Common/ExpandableInput'

export default trans(class ChallengeTemplate extends FormValidator {
  constructor(props){
    super(props)
    const { challenge: {id, game = "Custom", description, minBankToAccept, acceptDuration, stream }, create } = props
    this.state = {
          form: { 
            id: { value: id },
            game: { value: game },
            description: { value: description },
            minBankToAccept: { value: minBankToAccept, rules: [val_lte(10000), val_gte(0)], isValid: true },
            acceptDurationHours: { value: Math.floor(acceptDuration/(60*60)), rules: [val_lte(720), val_gte(0), isNum], isValid: true, checkImmediately: true },
            acceptDurationMinutes: { value: acceptDuration%(60*60)/(60), rules: [val_gte(0), val_lte(60), isNum], isValid: true, checkImmediately: true },
            stream: { value: stream }
          },
          isCreatingMode: create
    }
  }
  defaultCallback = (result) => {
    if(result.code){
      alert(result.code)
    }
    else alert('Success')
  }
  use = (e) => {
    const { props: { ChallengeActions: { createAdmin: use} }, makeTimeSec, defaultCallback } = this
    const { acceptDurationHours, acceptDurationMinutes, ...challenge } = this.getFormData()
    e.preventDefault()
    const duration = makeTimeSec(acceptDurationHours, acceptDurationMinutes)
    // refactor this shit
    if(duration < (60 * 60) || challenge.minBankToAccept < 1 || challenge.description.length < 10) {
      alert('Some form values are invalid!')
      return
    }
    use({...challenge, acceptDuration: duration }, defaultCallback)
  }

  remove = () => {
    const { props: { ChallengeTemplateActions: { remove }, challenge: { id } } } = this
    remove(id)
  }

  makeTimeSec = (hours, minutes) => (hours * 60 * 60) + minutes * 60

  edit = (e) => {
    const { props: { ChallengeTemplateActions: { patch }, challenge: { id } }, makeTimeSec } = this
    const { acceptDurationHours, acceptDurationMinutes, ...template } = this.getFormData()
    e.preventDefault()
    patch(id, { ...template, acceptDuration: makeTimeSec(acceptDurationHours, acceptDurationMinutes) })
  }

  create =  e => {
    const { props: { ChallengeTemplateActions: { create } }, state: { form }, makeTimeSec } = this
    const { acceptDurationHours, acceptDurationMinutes, ...challenge } = this.getFormData()
    e.preventDefault()
    if(!this.isValid()) {
      alert('Some form values are invalid!')
      return
    }
    create({ ...challenge, acceptDuration: makeTimeSec(acceptDurationHours, acceptDurationMinutes) },
    (challenge) => console.log(challenge))
  }

  render(){
  const { props: { t }, state: { isCreatingMode }, use, create, remove, edit } = this
  return (
    <form className="adm-new-control-card adm-new-challenge adm-new-challenge-state4">
    <div className="adm-new-control-card__header">
      <div className="adm-new-control-card__option adm-new-challenge__option-group">
        <div style={{ flexBasis: '50%'}} className="adm-new-control-card__input-group adm-new-challenge__input-group">
          <i className="icon icon_clock"></i>
          <div className="adm-new-challenge__input-time-container">
            <input 
            style={{width: '55%'}}
            className="adm-new-control-card__input time-input time-input_h"
            {...this.bindInput('acceptDurationHours', 'text')}
            />
            :
            <input 
            style={{width: '35%'}}
            className="adm-new-control-card__input time-input time-input_m"
            {...this.bindInput('acceptDurationMinutes', 'text')}
            />
          </div>
        </div>
        <div className="adm-new-control-card__input-group adm-new-challenge__input-group">
          <i className="adm-new-challenge__input-orb"></i>
          <input 
          className="adm-new-control-card__input"
          min={1}
          {...this.bindInput('minBankToAccept', 'number')}
           />
        </div>
      </div>
      {
        isCreatingMode ? null : <div 
        className="adm-new-control-card__close purecss-close"
        onClick={remove} 
        />
      }
    </div>
    <div className="adm-new-control-card__content">
      <ExpandableInput 
      className="adm-new-control-card__description" 
      rows="3"
      {...this.bindInput('description', 'text')} />
      
    </div>
   
    <div className="adm-new-control-card__actions">
    {
    isCreatingMode ?
      <button 
      className="adm-new-control-card__btn adm-new-control-card__btn--accent"
      onClick={create}
      >{t('admin_create')}</button>
      :
      <Fragment>
      <button
      className="adm-new-control-card__btn adm-new-control-card__btn--accent"
      onClick={use}
      >{t('admin_use')}</button>
      <button
      className="adm-new-control-card__btn adm-new-control-card__btn--accent"
      onClick={edit}
      >{t('admin_update')}</button>
      </Fragment>
    }
    </div>
  </form>
  )
      }
}
)