import React, { Component, Fragment } from "react"
import { Route, Link, NavLink } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import PredictionsContainer from "containers/PredictionsContainer"
import PredictionStreamContainer from "containers/PredictionStreamContainer"
import PredictionStreamItem from "components/PredictionStreamItem"

import StreamList from "components/StreamListSlider/StreamList"
import GamesListSlider from "components/StreamListSlider/GamesListSlider"
import AuthForm from "components/AuthForm"

import * as StreamActions from "actions/StreamActions"
import * as UserActions from "actions/UserActions"
import * as ModalActions from "actions/ModalActions"

import HowItWorks from "components/HowItWorks"

import { notify } from "reapop"
import { trans } from "utils/translator"
import "../../components/AuthForm/styles/index.scss"


class MainContainer extends Component {

  constructor(props){
    super(props)
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || {}
    this.state = {
      shouldDisplayModal: !user.token
    }
  }
  componentDidMount() {
    const { props } = this
    const { StreamActions, match, ModalActions, showPaymentNotification, notify } = props
    const referalCode = match && match.params.ref
    const paymentStatus = match && match.params && match.params.status

    if(paymentStatus == 'success'){
    notify({
      title: "Payment successfull",
      message: "Your payment was successfull, checkout your profile",
      status: "success",
      dismissible: true,
      dismissAfter: 5000,
    })
  }
    else if (paymentStatus == 'fail'){
      notify({
        title: "Payment failed",
        message: "Your payment failed, try again later",
        status: "error",
        dismissible: true,
        dismissAfter: 5000,
      })
    }
    
    if(referalCode){
      ModalActions.toggleAuthModal(true, { activeTab: AuthForm.STREAMER_TAB, referalCode })
      localStorage.setItem('referalCode', referalCode)
    }

    // PredictionsActions.get()
   
  }
  hideModal = () => {
    this.setState({
      shouldDisplayModal: false
    })
  }

  componentWillReceiveProps(nextProps){
    if(_.get(nextProps, 'user.model.token')){
      this.hideModal()
    }
  }

  render() {
    const { props } = this
    const { streams, StreamActions, t, match } = props
   
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || {}
    const lastStreamWatched = user.lastStreamWatched

    let fst_stream = streams[0] || {}

    console.log(this.state.shouldDisplayModal, 'TEST555')

    if(!isNaN(+lastStreamWatched) && streams.length > 1){
      fst_stream = streams.find(s => s.id == +lastStreamWatched)
    }
    

    if (!fst_stream && streams.length > 1) {
      fst_stream = streams.reduce((prev, current) => {
        return (prev.spectators > current.spectators) ? prev : current
    })
    }
  
   
    
    return (
      <div style={{ background: "#1b1b1b" }}>
        <main className="twitchSlider">
        { this.state.shouldDisplayModal && <HowItWorks hide={this.hideModal} ModalActions={this.props.ModalActions}/> }
        <div className='main__container_wide live-stream-slider'>
        <PredictionsContainer props={props} streamlist={streams} />
        </div>
          <div className="container">
            <div className="liveStream" id='watch'>
       
              <PredictionStreamContainer {...props} />
           
              <Route exact path="/main" render={() => <PredictionStreamItem stream={fst_stream} StreamActions={StreamActions} streamlist={streams}/>} />
              <Route exact path="/ref/" render={() => <PredictionStreamItem stream={fst_stream} StreamActions={StreamActions} />} />
              <Route exact path="/payments/success" render={() => <PredictionStreamItem stream={fst_stream} StreamActions={StreamActions} />} />
             
            </div>
          
          </div>
          <div className='main__container_wide'>
          <div className="liveStream" >
              <GamesListSlider {...props} streamlist={streams} StreamActions={StreamActions} key="gameList" slice={4}/>
            </div>
            <div className="liveStream">
              <StreamList {...props} predictions={props.predictions} featured limit={9} streamlist={streams} StreamActions={StreamActions} key="streamList" slice={2}/>
            </div>
            </div>
        </main>
        <div className="interaction">
          <img className="interactionBackground" src="/assets/images/main/overwatch.jpg" alt="overwatch" />
          <div className="gradient" />
          <div className="containerWrapper">
            <div className="container">
              <div className="interactionContent">
                <p className="predictionParagraph predictionParagraph_small">{t('main_predictoria_slogan_small')}</p>
                <p className="predictionParagraph predictionParagraph_big">{t('main_predictoria_slogan_big')}</p>
                <p className="predictionParagraph predictionParagraph_small predictionParagraph_smaller">{t('main_predictoria_slogan_smaller')}</p>
                <div className="linkWrapper">
                  {/* <a className="btn-blue" href="#">Watch Video</a> */}
                  <a className="btn-blue btn-blue_dark" href="/#advantages">{t('main_see_features_button')}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info" id="features">
          <div className="infoBlock">
            <div className="infoBlockImg">
              <img className="infoBlockImg-img" src="/assets/images/main/Mask1.jpg" alt="" />
            </div>
            <div className="infoBlockContent">
              <img className="infoblockContent-icon" src="/assets/images/alpha.svg" />
              <h3 className="infoBlockContent-header">
              	{ t('main_infoblock_alpha_header') }
              </h3>
              <p className="infoBlockContent-paragraph" dangerouslySetInnerHTML={{ __html: t('main_infoblock_alpha_paragraph') }}>
              </p>
            </div>
          </div>
          <div className="infoBlock">
            <div className="infoBlockImg">
              <img className="infoBlockImg-img" src="/assets/images/main/Mask2.jpg" alt="" />
            </div>
            <div className="infoBlockContent">            
              <img className="infoblockContent-icon" src="/assets/images/team.svg" />
              <h3 className="infoBlockContent-header">
                { t('main_infoblock_join_header') }
              </h3>
              <p className="infoBlockContent-paragraph" dangerouslySetInnerHTML={{ __html: t('main_infoblock_join_paragraph') }}>
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="Advantages" id="advantages">
            <div className="Advantages-header">
              <h3 className="predictoria-header">{t('main_advantages_header')}</h3>
            </div>
            <div className="AdvantagesContent">
              <div className="AdvantagesContent-item">
                <div className="AdvantagesContentIcon">
                  <div className="AdvantagesContentIcon-img">
                    <div className="icon svgIcon-icon1 svgIcon-icon1-dims" />
                  </div>
                </div>
                <div className="AdvantagesContentTitle">
                  <h5 className="AdvantagesContentTitle-header">{ t('main_advantages_unique_header') }</h5>
                </div>
                <div className="AdvantagesContentContent">
                  <p className="AdvantagesContentContent-paragraph">{ t('main_advantages_unique_paragraph') }</p>
                </div>
              </div>
              <div className="AdvantagesContent-item">
                <div className="AdvantagesContentIcon">
                  <div className="AdvantagesContentIcon-img">
                    <div className="icon svgIcon-icon2 svgIcon-icon2-dims" />
                  </div>
                </div>
                <div className="AdvantagesContentTitle">
                  <h5 className="AdvantagesContentTitle-header">{ t('main_advantages_interact_header') }</h5>
                </div>
                <div className="AdvantagesContentContent">
                  <p className="AdvantagesContentContent-paragraph">{ t('main_advantages_interact_paragraph') }</p>
                </div>
              </div>
              <div className="AdvantagesContent-item">
                <div className="AdvantagesContentIcon">
                  <div className="AdvantagesContentIcon-img">
                    <div className="icon svgIcon-icon3 svgIcon-icon3-dims" />
                  </div>
                </div>
                <div className="AdvantagesContentTitle">
                  <h5 className="AdvantagesContentTitle-header">{ t('main_advantages_favorite_header') }</h5>
                </div>
                <div className="AdvantagesContentContent">
                  <p className="AdvantagesContentContent-paragraph">{ t('main_advantages_favorite_paragraph') }</p>
                </div>
              </div>
              <div className="AdvantagesContent-item">
                <div className="AdvantagesContentIcon">
                  <div className="AdvantagesContentIcon-img">
                    <div className="icon svgIcon-icon4 svgIcon-icon4-dims" />
                  </div>
                </div>
                <div className="AdvantagesContentTitle">
                  <h5 className="AdvantagesContentTitle-header">{ t('main_advantages_social_header') }</h5>
                </div>
                <div className="AdvantagesContentContent">
                  <p className="AdvantagesContentContent-paragraph">{ t('main_advantages_social_paragraph') }</p>
                </div>
              </div>
              <div className="AdvantagesContent-item">
                <div className="AdvantagesContentIcon">
                  <div className="AdvantagesContentIcon-img">
                    <div className="icon svgIcon-icon5 svgIcon-icon5-dims" />
                  </div>
                </div>
                <div className="AdvantagesContentTitle">
                  <h5 className="AdvantagesContentTitle-header">{ t('main_advantages_entertain_header') }</h5>
                </div>
                <div className="AdvantagesContentContent">
                  <p className="AdvantagesContentContent-paragraph" dangerouslySetInnerHTML={{ __html: t('main_advantages_entertain_paragraph') }}></p>
                </div>
              </div>
              <div className="AdvantagesContent-item">
                <div className="AdvantagesContentIcon">
                  <div className="AdvantagesContentIcon-img">
                    <div className="icon svgIcon-icon6 svgIcon-icon6-dims" />
                  </div>
                </div>
                <div className="AdvantagesContentTitle">
                  <h5 className="AdvantagesContentTitle-header">{ t('main_advantages_fair_header') }</h5>
                </div>
                <div className="AdvantagesContentContent">
                  <p className="AdvantagesContentContent-paragraph">{ t('main_advantages_fair_paragraph') }</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    streams: state.streamList.streamlist,
    user: state.user,
    predictions: state.predictions.predictions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    ModalActions: bindActionCreators(ModalActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(MainContainer))
