import React, { Component } from "react"
import { Route } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import CustomScroll from "react-custom-scroll"
import "../../assets/style/partials/customScroll.css"

import * as PredictionsActions from "actions/PredictionsActions"

import PredictionContainer from "./Betty-PredictionContainer"
import ChallengeAuth from "components/Feedback/ChallengeAuth"


class PredictionsList extends Component {
  componentDidMount() {
    this.props.PredictionsActions.get()
  }

  renderList(predictions) {
    const PredictionsActions = this.props.PredictionsActions
    return predictions.map((item) => {

      let userBet = (this.props.bets || []).filter(bet => {
        return bet.event_id == this.props.stream.id && bet.market_id == item.id
      })

      return <PredictionContainer
        prediction={item}
        key={item.id}
        userBet={userBet}
        PredictionsActions={PredictionsActions}
        eventId={this.props.stream.id}
        winnerMarkets={this.props.stream.winner_markets}
        showResults={this.props.showResults}
      />
    })
  }

  render() {
    const { props } = this
    const { stream, handleChallenge } = props

    const _predictions = stream.markets || []

    return _predictions.length > 0 ? (
      <CustomScroll heightRelativeToParent="calc(100%)">
        {/* <CreateStream handleChallenge={handleChallenge} /> */}
        {this.renderList(_predictions)}
      </CustomScroll>
    ) : (
      <div className="predictionMainWindowEmpty">
        <span>
            Markets will be here soon, please wait, until pause gone.
        </span>
      </div>
    )
  }
}

function CreateStream(props) {
  return (
    <ChallengeAuth handleChallenge={props.handleChallenge} />
  )
}

function mapStateToProps(state) {
  return {
    predictions: state.predictions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PredictionsList)
