import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';
import Button from 'components/_Button';
import { trans } from 'utils/translator';


const TaskCard = props => {
    const { description, className, t } = props;
    return (
        <div className={cn(className, 'task-card white rounded p3')}>
            <div className="flex items-end mb4 t3">
                <p className="bold">BrandQuest</p>
                 &nbsp;
                <p>{t('_landing_task-card_1')} CompName </p>
            </div>
            <p className='mb4 t1'>{description}</p>
            <div className="flex t3">
                <Button view='link' className='task-card__btn'>
                    <span className='color--accent'>
                        {t('_landing_task-card_4')}
                    </span>
                </Button>
                <Button view='link' className='task-card__btn'>
                    <span className='color--disabled'>
                        {t('_landing_task-card_5')}
                    </span>
                </Button>
            </div>
        </div>
    )
}

TaskCard.propTypes = {

}

export default trans(TaskCard);
