import React, { Component, PropTypes } from 'react'
import { trans } from "utils/translator"

import * as ArticleAPI from "../../api/ArticleAPI"

export default trans(class CategoryNode extends Component {
    constructor(props) {
        super(props)
      }
  changeCategoryPosition = (category, direction) => () => {
    const { children } = this.props
    let replaceCategory
    if(direction == 'up' && category.position == 0) {
      alert('Unable to move category')
      return ''
    }  
    if(direction == 'down' && category.position == children.length) {
      alert('Unable to move category')
      return ''
    }  
    
    if(direction == 'up'){
      replaceCategory = children.find(c => c.position == category.position - 1)
      replaceCategory.position +=1
      category.position -=1
    }
    if(direction == 'down'){
      replaceCategory = children.find(c => c.position == category.position + 1)
      replaceCategory.position -=1
      category.position +=1
    }
    ArticleAPI.editArticle((editedCategory) => {
      this.props.handleEditCategory(editedCategory)
      ArticleAPI.editArticle((editedCategory) => {
        this.props.handleEditCategory(editedCategory)
      }, replaceCategory)
    }, category)
    
  }
  render() {
    const { children, level, isEditCategory, t, categories, newCategoryName, newCategoryParent } = this.props
    return (
      <div className="CategoryNode">
        {Array.isArray(children) && children.sort((a,b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0)).map((category, i) =>
          <div style={{
              paddingLeft: `${level*10}px`,
          }} key={category.id} className="category">
             <div><div style={{
          display: "flex",
        }}><label>
          <input
            type="checkbox"
            style={{ position: "relative", left: 0, outline: "initial" }}
            onChange={this.props.selectCategory(category.id)}
            checked={this.props.selectedCategory == category.id}
          /> {isEditCategory == category.id ? <div style={{display: "inline"}}><input value={newCategoryName} onChange={this.props.handleCategoryRename}/></div> : category.title}
</label>
{
isEditCategory == category.id ? 
<div className="post-editor__save-button" onClick={this.props.saveCategory(category)}/> :
<div className="post-editor__edit-button" onClick={this.props.editCategory(category.id, category.title, category.parent)}/>
}
{/* <div className="post-editor__close-button" onClick={this.deleteCategory(category.id)}/> */}
{<div style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={this.changeCategoryPosition(category, 'up')}>&#8593;</div> }
{<div style={{ marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={this.changeCategoryPosition(category, 'down')}>&#8595;</div> }
</div>
{ 
  isEditCategory == category.id ? 
<select onChange={this.props.handleParentCategoryChange} name="" id="" className="post-editor__select-parent" >
       <option value="" disabled selected>{t('pe_parent_category')}</option>
       {
       categories.map((category, i) =>  
          <option
            value={category.id}
          > {category.title} </option>
)
       }
       </select> : null
}
</div>
            {category.children && <CategoryNode {...this.props} level={level+1} children={category.children}/>}
          </div>
        )}
      </div>
    )

  }

})