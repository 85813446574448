import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Toggler from 'components/Toggler';
import Button from 'components/_Button';
import Input from 'components/Input';

const ChallengeCard = props => {
    const { displayName, description, status, id, t, ChallengeActions } = props;
    const [amount, setAmount] = useState(0);
    const [selected, setSelected] = useState(0);

    const handleAddOrbs = (id, amount) => {
        ChallengeActions.donate(null, id, amount);
    }


    return (
        <div className='ch-card p2 border rounded'>
            <div className="flex mb3 t3">
                <p className="black bold">
                    Challenge
                </p>
                <p className="ch-card__company ml2">
                    { displayName }
                </p>
            </div>
            <p className="mb3 t1">
                { description }
            </p>
            {/* <p className='mb3'>
                { status } 
            </p> */}
            <div className="ch-card__bottom flex justify-between mb3">
                <div className="flex flex-column t3">
                    <p>Prize pool</p>
                    <p className='dark-magenta bold'>$1000</p>
                </div>
                {/* <p>1 day  left</p> */}
                <Toggler><p className='t4'>More</p></Toggler>
            </div>
            {
                true && <div className="black t3">
                    <p className="bold mb2">Adding orbs...</p>
                    <p className="mb2">You can add any amount of orbs</p>
                    <div className="flex items-center mb4">
                        <Button 
                            size='small'
                            color={selected === 0 ? 'dark-magenta' : 'grey'}
                            onClick={() => {
                                setAmount(1);
                                setSelected(0);
                            }
                            }
                            ><span className={selected === 0 ? 'white' : 'dark-magenta'}>1 orb</span></Button>
                        <Button 
                            className='ml2' 
                            size='small' 
                            color={selected === 1 ? 'dark-magenta' : 'grey'}
                            onClick={() => {
                                setAmount(2)
                                setSelected(1);
                            }}
                            >
                            <span className={selected === 1 ? 'white' : 'dark-magenta'}>2 orb</span></Button>
                        <Button 
                            className='ml2' 
                            size='small' 
                            color={selected === 2 ? 'dark-magenta' : 'grey'}
                            onClick={() => {
                                setAmount(5)
                                setSelected(2);
                            }}
                            ><span className={selected === 2 ? 'white' : 'dark-magenta'}>5 orb</span></Button>
                        <Input 
                            view='grey' 
                            className='ml2 t4 ch-card__input' 
                            placeholder='any amount'
                            onChange={setAmount}
                            />
                    </div>
                    <div className="flex ">
                        <Button
                            className='mx-auto'
                            theme='purple'
                            onClick={() => handleAddOrbs(id, amount)}

                        >ADD ORBS</Button>
                    </div>
                </div>
            }
        </div>
    )
}

ChallengeCard.propTypes = {

}

export default ChallengeCard
