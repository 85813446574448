import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as UserActions from "actions/UserActions"
import FormValidator from "components/Common/FormValidator"
import { required, email, } from "utils/validator"
import { notify } from "reapop"
import {Link} from "react-router-dom"
import { trans } from 'utils/translator';

class Feedback extends FormValidator {
  constructor(props) {
    super(props)
    this.state = {
      isOpenFeedback: false
    }

    this.notificClick = this.notificClick.bind(this)
    this.authConfirm = this.authConfirm.bind(this)
  }
  authConfirm = () =>  {
    if (confirm('Auth with twitch?')) {
      window.location.href = `/api/auth/twitch`
    }
  }
  handleChat() {
    this.setState(prevState => ({
      isOpenFeedback: !prevState.isOpenFeedback,
    }))
  }
  
  handleMsg(e, props) {
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    e.preventDefault()

    const fstText = this.refs.feedbackModalText.value || ""
    const sndText = this.refs.feedbackSubModalText.value || ""

    if (fstText == "" && sndText == "") {
      this.notificClick("Feedback", "Please, fill fields first", "warning")
      return
    }

    if (this.props.user.isAuthenticated) {
      fetch("https://concepter.co/wp-content/themes/iblazr/solutions/predictoria-support.php", {
        mode: "no-cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },  
        body: JSON.stringify({
          email: this.props.user.model.email || user.email,
          name: props.user.model.name || user.name || "User",        
          predictionDescription: fstText,
          additionalSuggestions: sndText
        })
      }).then((response) => {
        this.handleChat()
      }).catch((err) => {
        console.log(err)
      })
    } else if (!this.props.user.isAuthenticated && this.refs.feedbackModalEmail.value.length > 0) {
      fetch("https://concepter.co/wp-content/themes/iblazr/solutions/predictoria-support.php", {
        mode: "no-cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        },  
        body: JSON.stringify({
          email: this.refs.feedbackModalEmail.value || this.props.user.model.email || user.email,
          name: props.user.model.name || user.name || "User",        
          predictionDescription: fstText,
          additionalSuggestions: sndText
        })
      }).then((response) => {
        this.handleChat()
      }).catch((err) => {
        console.log(err)
      })
    }
  }

  render() {
  const { t } = this.props;
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const hasTwitch = !!user.twitch
    return (
      <div onClick={(e)=> e.stopPropagation()} style={{
        overflow: 'hidden'
      }} className="feedbackModal become-streamer__modal">
        <div className="feedbackModalTitle">
          <p className="feedbackModal-title">{t('global_i_wish_to_use')}</p>
        </div>
        {
          !hasTwitch ?
        <div className='become-streamer__row'>
        <div className="feedbackModalDesc become-streamer__desc">
            <p style={!this.props.user.isAuthenticated ? { paddingBottom: "20px" } : null} className="feedbackModal-desc">
              {t('global_authorize_twitch')}
            </p>
          </div>
          <div className='become-streamer__twitch' onClick={this.authConfirm}>
            <img className="connectedServicesSocial-img" src="/assets/images/main/main-social/twitch.png" alt="twitch" />
          </div>
          </div>
          : 
          <Link to='/profile/stream' onClick={this.props.hide} className="become-streamer__button pre-btn become-streamer__set-up">
          Set up your stream!
        </Link>
        }
        {this.state.isOpenFeedback ? 
          <div style={{ overflow: "hidden" }} className="feedbackModal feedbackModal_result">
            <div className="feedbackModalTitle">
              <p className="feedbackModal-title">Feedback</p>
            </div>
            <div className="feedbackModal-img">
              <img src="/assets/images/feedback.svg" alt="feedback" />
              <p className="feedbackModal-result">Thank You for Your Feedback!</p>
            </div>
          </div> : null}
      </div>
    )
  }

  notificClick(title, message, status) {
    const { notify } = this.props
    notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000
    })
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(Feedback))
