import React from 'react';
import PropTypes from 'prop-types'
import Socials from './Socials';
import { trans } from 'utils/translator';


const Profile = props => {
    const { streamName, createdAt, t } = props;
    return (
        <div className='mt4 profile-header white flex items-end p2'>
            <div className='profile-header__avatar circle ml4' />
            <div className="flex flex column ml4">
            <h1 className="h1">{streamName}</h1>
            <p>{`${t('_stream_page_header_1')} ${createdAt} ${t('_stream_page_header_2')}`}</p>
            <div className="">
            <Socials /> 
            </div>
            </div>
        </div>
    )
}

Profile.propTypes = {

}

export default trans(Profile);
