import * as helper from "utils/helpers"
import { apiRoutes } from 'utils/config';

const defaultUser = {
  name: "",
  email: "",
  password: "",
  balance: 1000
}

export const createUser = async (user) => {
  return { ...defaultUser, ...user }
}

export const updateUser = async (user) => {
  return { ...defaultUser, ...user }
}

export const getFreeCubes = async (callback) => {
  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = userL.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch("/api/user/silver", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == 500 || json.code == 1001 || json.code == "ALREADY_RECIVED") {
        callback(null, json)
        return
      }

      // success
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(null, error)
    })

  return { ...defaultUser, ...userL }
}

export const getShareCubes = async (bonuscode, callback) => {
  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = userL.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/user/promo?code=${bonuscode}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == 500 || json.code == 1001 || json.code == "UNKNOWN_ERROR" || json.code == "ALREADY_RECIVED" || json.code == "USED_PROMO_CODE" || json.code == "WRONG_PROMO_CODE") {
        callback(null, json)
        return
      }

      // success
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(null, error)
    })

  return { ...defaultUser, ...userL }
}


const search = async (queue, callback) => {
  const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = user.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)


  fetch(`/api/user/?${helper.params(queue)}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}

const save = async (user, callback) => {
  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = userL.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/user/${user.id}`, {
    method: "PATCH",
    headers,
    body: JSON.stringify(user)
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}
const getReferrals = async (callback) => {
  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = userL.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/user/referrals`, {
    method: "GET",
    headers,
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}
const useReferalCode = async (useReferalCode, callback) => {
  const userL = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  const token = userL.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/user/add-referer`, {
    method: "POST",
    headers,
    body: JSON.stringify({ referralCode: useReferalCode })
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status == 500 || json.status == 1001) {
        callback(json.message)
      } else {
        callback(null, json)
      }
    })
    .catch((error) => {
      callback(error)
    })
}

function login(email, password) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  return fetch(apiRoutes.USER_SIGNIN, requestOptions)
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      // AHHHH! An Error!
      // console.log("loginUserAPI error:", error)
      // this.sendNotification({
      //   title: "Server Error",
      //   message: "Try again or contact us via feedback form",
      //   status: "error",
      //   dismissible: true,
      // })
    })
}

function readUser(token) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
  };

 return  fetch(apiRoutes.USER_READ, requestOptions)
    .then((response) => {
      return response.json()
    });
}

function register(email, password, referralCode) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password, referralCode }),
  };

 return  fetch(apiRoutes.USER_REGISTER, requestOptions)
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == "WRONG_CREDENTIALS" || json.code == "UNKNOWN_ERROR") {
        return;
      }
      return json;
    })
    .catch((error) => {
    })
}

export { save, search, useReferalCode, getReferrals, login, readUser, register }
