import collectionReducers from "utils/collectionReducers"

const initialState = {
  "events": [
      {
          "id": 1,
          "game_id": 1,
          "event_type_id": 1,
          "started_at": "2018-08-29 12:00:00",
          "bets_end_at": "2018-08-29 12:30:00",
          "markets": [ ]
      }
  ],
  "bets": [],
  "signature": ""
}

const NAMESPACE = "BETTY_EVENTS"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function getGameItem() {
  return {
    namespace: NAMESPACE,
    type: "GET_BETTY_EVENTS_ITEM",
    handle: (state) => { 
      return { ...state, games: state.games 
      }
    }
  }
}

export function setNewList(streamlist) {
  return {
    namespace: NAMESPACE,
    type: "SET_BETTY_EVENTS_LIST",
    handle: (state) => {
      return { ...state, events: streamlist }
    }
  }
}

export function setBets(streamlist) {
  return {
    namespace: NAMESPACE,
    type: "SET_BETTY_BETS_LIST",
    handle: (state) => {
      return { ...state, bets: streamlist }
    }
  }
}

export function add(item) {
  function _add(item, all) {
    
    let _item = all.find(obj => {
      return obj.id === item.id
    })

    let index = all.indexOf(_item)

    all[index] = item

    return all
  }

  return {
    namespace: NAMESPACE,
    type: "ADD_BETTY_EVENTS_ITEM",
    handle: (state) => {
      const newList = _add(item, state.events)
      return { ...state, events: newList }
    }
  }
}

