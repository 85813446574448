
import * as SocketAPI from "api/SocketAPI"
import { updateUserBalance } from 'actions/UserActions'
import { store } from "libs/redux"
import "whatwg-fetch"

const load = async (callback, streamID) => {
  const predictions = store.getState().predictions.predictions
  const token = store.getState().user.model.token || ""

  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/challenge/stream/${streamID}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
}
const refuse  = async (callback, challengeID) => {
const user = store.getState().user.model
const token = user.token || ""
const permissions = user.permissions
const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/${permissions.includes('SUPER_ACTIVITY') ? 'admin' : ''}/challenge/refuse/${challengeID}`, {
  method: "PATCH",
  headers
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}
const approve = async (callback, challengeID, params) => {
  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/${permissions.includes('SUPER_ACTIVITY') ? 'admin' : 'streamer'}/challenge/approve/${challengeID}`, {
  method: "PATCH",
  headers,
  body: JSON.stringify(params)
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}
const accept = async (callback, challengeID) => {
  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/${permissions.includes('SUPER_ACTIVITY') ? 'admin' : 'streamer'}/streamer/challenge/accept/${challengeID}`, {
  method: "PATCH",
  headers
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}

const loadDonators = async (callback, donatorsIds) => {
  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions
  
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)
  
  fetch(`/api/user/multiple`, {
    method: "post",
    headers,
    body: JSON.stringify({ids: donatorsIds})
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
  }

const done = async (callback, challengeID) => {
const user = store.getState().user.model
const token = user.token || ""
const permissions = user.permissions

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/challenge/done/${challengeID}`, {
  method: "PATCH",
  headers
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}
const failed = async (callback, challengeID) => {
const user = store.getState().user.model
const token = user.token || ""
const permissions = user.permissions

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/challenge/fail/${challengeID}`, {
  method: "PATCH",
  headers
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}

const createAdmin = async (callback, challengeBody) => {
const user = store.getState().user.model
const token = user.token || ""
const permissions = user.permissions

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api${permissions.includes('SUPER_ACTIVITY') ? '/admin' : '/streamer'}/challenge/`, {
  method: "POST",
  headers, 
  body: JSON.stringify(challengeBody),
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}

const donate = async (callback, challengeID, value) => {
const token = store.getState().user.model.token || ""

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/challenge/${challengeID}/donate/`, {
  method: "POST",
  headers, 
  body: JSON.stringify({value, }),
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    if (json.code == "UNKNOWN_ERROR" || json.code != undefined) {
      callback(json.message)
    } else {
      // success
      callback(null, json)
    }
  })
  
}
const patch  = async (callback, challengeID, challengeBody) => {
const user = store.getState().user.model
const token = user.token || ""
const permissions = user.permissions

const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/${permissions.includes('SUPER_ACTIVITY') ? 'admin' : 'streamer'}/challenge/${challengeID}`, {
  method: "PATCH",
  headers,
  body: JSON.stringify(challengeBody),
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}

const voteDoneUser  = async (callback, challengeID) => {
  const user = store.getState().user.model
  const token = user.token || ""
  const permissions = user.permissions
  
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)
  
  fetch(`/api/challenge/done/${challengeID}`, {
    method: "PATCH",
    headers,
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
  }
  const voteFailUser  = async (callback, challengeID) => {
    const user = store.getState().user.model
    const token = user.token || ""
    const permissions = user.permissions
    
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
    
    fetch(`/api/challenge/fail/${challengeID}`, {
      method: "PATCH",
      headers,
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
    }

const updated = async (callback, admin, stream) => {
  SocketAPI.challenges(admin, stream, ({ error, response, type, socket }) => {
    if (error) {
      return
    }

    if (type == "connect" || type == "disconnect") {
      return
    }
   
    const challenge = response.challenge

    const challenges = store.getState().challenges.challenges

    const index = challenges.findIndex(item => item.id === challenge.id)
    const oldChallenge= index != -1 ? challenges[index] : null
    const chal = update(challenge, oldChallenge)

    if (index != -1) {
      challenges[index] = chal
    } else {
      challenges.push(chal)
    }
    callback(challenges)
  })
}

function update(chal, oldchallenge, callback) {
  store.dispatch(updateUserBalance())
  const challenge = oldchallenge || Object()
// ?????? wtf
  challenge.ChallengeDonates = chal.ChallengeDonates
  challenge.acceptDuration = chal.acceptDuration
  challenge.acceptedAt = chal.acceptedAt
  challenge.createdAt = chal.createdAt
  challenge.createdBy = chal.createdBy
  challenge.description = chal.description
  challenge.displayName = chal.displayName
  challenge.done = chal.done
  challenge.doneAt = chal.doneAt
  challenge.fail =  chal.fail
  challenge.failedAcceptionAt = chal.failedAcceptionAt
  challenge.failedAt = chal.failedAt
  challenge.failedModerationAt = chal.failedModerationAt
  challenge.forAcceptionAt = chal.forAcceptionAt
  challenge.forModerationAt = chal.forModerationAt
  challenge.id = chal.id
  challenge.minBankToAccept = chal.minBankToAccept
  challenge.participants = chal.participants
  challenge.status = chal.status
  challenge.stream = chal.stream
  challenge.updatedAt = chal.updatedAt

  if (callback != null) {
    callback(challenge)
  }
  return challenge
}
export {
  load,
  approve, 
  refuse,
  accept,
  done,
  failed,
  createAdmin,
  donate,
  patch,
  voteDoneUser,
  voteFailUser,
  updated,
  loadDonators
}
