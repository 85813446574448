import React, { Component } from "react"
import { Switch, Route, Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"

import * as userActions from "actions/UserActions"
import * as StreamAPI from "api/StreamAPI"
import * as StreamActions from "actions/StreamActions"
import * as PredictionsActions from "actions/PredictionsActions"
import * as ChallengeActions from "actions/ChallengeActions"
import * as VotingActions from "actions/VotingActions"
import * as PaidActionActions from "actions/PaidActionActions"
import * as PaidActionTemplateActions from "actions/PaidActionTemplateActions"

import * as PredictionTemplateActions from "actions/PredictionTemplateActions"
import * as ChallengeTemplateActions from "actions/ChallengeTemplateActions"
import * as VotingTemplateActions from "actions/VotingTemplateActions"

import AdminStreams from "containers/AdminContainer/AdminStreams"
import AdminStreamPage from "components/AdminStreamsPage/AdminStreamPage"
import CreateStream from "components/AdminStreamsPage/CreateStream"
import AdminTexts from "components/AdminTexts"
import AdminLogs from "components/AdminLogs"

import AdminStats from "containers/AdminContainer/AdminStatsContainer"
import AdminUsersContainer from "containers/AdminContainer/AdminUsersContainer"
import GenerateBonus from "components/Deposit/GenerateBonus"
import { siteTitle } from "utils/constants"

import { trans } from 'utils/translator';
class AdminContainer extends Component {
  state = { title: "Admin Portal" }

  constructor(props) {
    super(props)

    const permissions = props.user.model.permissions || []
    const isAdmin = permissions.length > 0 
    // const isAdmin = permissions.includes('SUPER') || permissions.includes('SUPER_ACTIVITY')
    if (!isAdmin) {
      props.history.goBack()
    }

    document.title = this.state.title
  }

  componentWillUnmount() {
    if (document.title == this.state.title) { document.title = siteTitle }
  }

  componentDidMount() {
    this.props.StreamActions.getAdmin()
  }

  render() {
    const { props } = this
    const { StreamActions, PredictionsActions, ChallengeActions, PredictionTemplateActions, ChallengeTemplateActions, streamlist, challenges, t } = props
    const permissions = props.user.model.permissions
    return (
      <main className="adminPage">
        <div className="container">
          <div>
                {
                  permissions.includes('SUPER') || permissions.includes('SUPER_ACTIVITY') ? 
                  <div className="adminPage__navigation">
                  <p className="predictionParagraph">{t('admin_manage')}</p>
                  <div className='adminPage__navigation-buttons'>
                  <Link to="/admin/streams" className="admin__nav pre-btn pre-btn_blue">{t('admin_streams')}</Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/admin/generate-bonus" className="admin__nav pre-btn pre-btn_blue">{t('admin_bonuscodes')}</Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/admin/users" className="admin__nav pre-btn pre-btn_blue">{t('admin_users')}</Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/admin/texts/en" className="admin__nav pre-btn pre-btn_blue">{t('admin_texts')}</Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/admin/stats" className="admin__nav pre-btn pre-btn_blue">{t('admin_stats')}</Link>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link to="/admin/logs" className="admin__nav pre-btn pre-btn_blue">{t('admin_logs')}</Link>
                  </div>
                 </div>
                 : 
                 null
                }
            

            <Switch>
              <Route path="/admin/generate-bonus" component={GenerateBonus} />
              <Route path="/admin/streams" component={AdminStreams} />
              <Route path="/admin/users" component={AdminUsersContainer} />
              <Route path="/admin/texts/:lang" component={AdminTexts} />
              <Route path="/admin/stats" component={AdminStats} />
              <Route path="/admin/logs" render={() => <AdminLogs streamlist={this.props.streamlist} />} />
            </Switch>

            <Switch>
              {streamlist.adminStreamlist.map((item) => {
                const streamID = StreamActions.streamerID(item.type, item.stream)
                return (<Route 
                  key={streamID}
                  path={`/admin/stream/${streamID}`}
                  render={() => (<AdminStreamPage
                    {...props}
                    challenges={challenges} 
                    stream={item}
                    StreamActions={StreamActions}
                    PredictionsActions={PredictionsActions}
                    ChallengeActions={ChallengeActions}
                    PredictionTemplateActions={PredictionTemplateActions}
                    ChallengeTemplateActions={ChallengeTemplateActions}
                  />)}
                />)
              })}

              <Route
                path="/admin/stream/add"
                key="add"
                render={() => (<div className="tabContent activeTab">
                  <CreateStream {...props} justCreate StreamActions={StreamActions} />
                </div>)}
              />
            </Switch>
          </div>
        </div>
      </main>
    )
  }

  getStreams() {
    if (location.pathname.substr(0, 14) == "/admin/stream/") { this.props.StreamActions.getAdmin() }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    streamlist: state.streamList,
    templates: state.templatePrediction,
    challengeTemplates: state.templateChallenge,
    votingTemplates: state.templateVoting,
    predictionTemplates: state.templatePrediction,
    paidActionTemplates: state.templatePaidAction,
    predictions: state.predictions.predictions,
    challenges: state.challenges.challenges,
    votings: state.votings.votings,
    paidActions: state.paidActions.paidActions,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    notify: bindActionCreators(notify, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch),
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    ChallengeActions: bindActionCreators(ChallengeActions, dispatch),
    VotingActions: bindActionCreators(VotingActions, dispatch),
    PredictionTemplateActions: bindActionCreators(PredictionTemplateActions, dispatch),
    ChallengeTemplateActions: bindActionCreators(ChallengeTemplateActions, dispatch),
    VotingTemplateActions: bindActionCreators(VotingTemplateActions, dispatch),
    PaidActionActions: bindActionCreators(PaidActionActions, dispatch),
    PaidActionTemplateActions: bindActionCreators(PaidActionTemplateActions, dispatch),
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(AdminContainer))
