import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';
import Button from 'components/_Button';
import Form from 'components/_Form';


const Feedback = props => {

    const [yp, setYp] = useState('');
    const [p, setP] = useState('');
    const [message, setMessage] = useState('');

    return (
        <Form className='feedback p2 bg-white black' onSubmit={() => { alert('') }}>
            <h1 className="h1">Мы всегда на связи</h1>
            <Input value={yp}  onChange={setYp} placeholder='Ваша проблева' />
            <Input value={p}  onChange={setP} className='mb4' placeholder='Вы проблема' />
            <Input value={message}  onChange={setMessage} className='mb2' placeholder='Текст сообщений' />
            <Button type='submit' className='mx-auto'>ОТПРАВИТЬ</Button>
        </Form>
    )
}

Feedback.propTypes = {

}

export default Feedback
