import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { loadLocalizationData, updateLocalizationData } from "api/i18n"
import i18next from "i18n"
import { trans } from "utils/translator"

import  {Editor, convertFromRaw, convertToRaw, EditorState, RichUtils, Entity, CompositeDecorator} from "draft-js";
import { getBlockStyle, styleMap, BlockStyleControls, InlineStyleControls } from "utils/draftUtils"
import createLinkifyPlugin from 'draft-js-linkify-plugin';

const linkifyPlugin = createLinkifyPlugin();
const editorStateAsJSONString = '{"blocks":[{"key":"1qh1g","text":"Header","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"fthtl","text":"text text text","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
const editablePages = ['faqtab', 'about', 'legal']; 
const styles = {
  root: {
    fontFamily: '\'Georgia\', serif',
    padding: 20,
    width: 600,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: '\'Georgia\', serif',
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};
class TextEditor extends Component {
 constructor(props) {
          super(props);
          const decorator = new CompositeDecorator([
            {
              strategy: findLinkEntities,
              component: Link,
            },
          ]);
          this.state = {
              editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(editorStateAsJSONString))),
              trans: [],
              locale: {},
              showURLInput: false,
              urlValue: '',
            };
          this.focus = () => this.refs.editor.focus();
          this.logState = () => {
            const content = this.state.editorState.getCurrentContent();
          };
          this.onChange = (e) => {
            this.setState({editorState: e.target.value})
          }
          this.handleKeyCommand = (command) => this._handleKeyCommand(command);
          this.onTab = (e) => this._onTab(e);
          this.toggleBlockType = (type) => this._toggleBlockType(type);
          this.toggleInlineStyle = (style) => this._toggleInlineStyle(style);
          this.handleButtonClick = () => this._handleButtonClick();
          this.handleSelectorClick = (option, trans) => e => this._handleSelectorClick(e, option, trans);
          this.promptForLink = this._promptForLink.bind(this);
          this.onURLChange = (e) => this.setState({urlValue: e.target.value});
          this.confirmLink = this._confirmLink.bind(this);
          this.onLinkInputKeyDown = this._onLinkInputKeyDown.bind(this);
          this.removeLink = this._removeLink.bind(this);
        }
        _promptForLink(e) {
          e.preventDefault();
          const {editorState} = this.state;
          const selection = editorState.getSelection();
          if (!selection.isCollapsed()) {
            this.setState({
              showURLInput: true,
              urlValue: '',
            }, () => {
              setTimeout(() => this.refs.url.focus(), 0);
            });
          }
        }
        _confirmLink(e) {
          e.preventDefault();
          const {editorState, urlValue} = this.state;
          const contentState = editorState.getCurrentContent();
          const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {url: urlValue}
          );
          const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
          const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
         this.logState()
          this.setState({
            editorState: RichUtils.toggleLink(
              newEditorState,
              newEditorState.getSelection(),
              entityKey
            ),
            showURLInput: false,
            urlValue: '',
          }, () => {
            setTimeout(() => this.refs.editor.focus(), 0);
          });
        }
        _onLinkInputKeyDown(e) {
          if (e.which === 13) {
            this._confirmLink(e);
          }
        }
        _removeLink(e) {
          e.preventDefault();
          const {editorState} = this.state;
          const selection = editorState.getSelection();
          if (!selection.isCollapsed()) {
            this.setState({
              editorState: RichUtils.toggleLink(editorState, selection, null),
            });
          }
        }
 _handleKeyCommand(command) {
          const {editorState} = this.state;
          const newState = RichUtils.handleKeyCommand(editorState, command);
          if (newState) {
            this.onChange(newState);
            return true;
          }
          return false;
        }

 _onTab(e) {
   const maxDepth = 4;
   this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
 }

_toggleBlockType(blockType) {
  this.onChange(
    RichUtils.toggleBlockType(
      this.state.editorState,
      blockType
    )
  );
}
_toggleInlineStyle(inlineStyle) {
          this.onChange(
            RichUtils.toggleInlineStyle(
              this.state.editorState,
              inlineStyle
        )
      );
    }

   componentWillReceiveProps(props){
    const {t} = this.props
    const newLocale =  this.state.trans.find(locale => locale.lang == props.lang)
    this.setState({
      locale: this.state.trans.find(locale => locale.lang == props.lang),
    });
    if(editablePages.find( page => page === props.scope)){
      if(newLocale.translation[`${props.scope}-global`].length>10)
      this.setState({
        // editorState:  EditorState.createWithContent(convertFromRaw(JSON.parse(newLocale.translation[`${props.scope}-global`])))
        editorState: newLocale.translation[`${props.scope}-global`]
      })
      else
      this.setState({
        // editorState:  EditorState.createWithContent(convertFromRaw(JSON.parse(
        //   '{"blocks":[{"key":"1qh1g","text":"Default header","type":"header-three","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"fthtl","text":"default content","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
        // )))
        editorState: newLocale.translation[`${props.scope}-global`]
      })
    }
   }
componentDidMount(){

const {
    lang,
    scope, 
    t
  } = this.props;
 
loadLocalizationData((json) => {
  this.setState({
  trans: json,
  locale: json.find(locale => locale.lang == lang),
});
})

}

 _handleClick(options){
   const {
     lang,
     scope
   } = this.props;

 const update = Object.assign({}, this.state.locale)
 update.translation[`${scope}-global`] = this.state.editorState
 updateLocalizationData(update, (res) => {
  loadLocalizationData((json) => {
    this.setState({
    trans: json,
    locale: json.find(locale => locale.lang == lang),
  });
  })

  alert(`everything seems to be ok, check ${scope} now`)
  i18next.addResourceBundle(lang,'translation', json.find(locale => locale.lang == lang), true, true)
  } );  

 }
// знаю что тут костыль на костыле, рефакторну когда закроем основные таски 
 render() {
  const {
     lang,
     scope, 
     t
   } = this.props;

   let urlInput;
   if (this.state.showURLInput) {
     urlInput =
       <div style={styles.urlInputContainer}>
         <input
           onChange={this.onURLChange}
           ref="url"
           style={styles.urlInput}
           type="text"
           value={this.state.urlValue}
           onKeyDown={this.onLinkInputKeyDown}
         />
         <button onMouseDown={this.confirmLink}>
           Confirm
         </button>
       </div>;
   }

   const {editorState, editorStates} = this.state;
   let options = [];

   if(!this.state.locale || !this.state.locale.translation)
   return 'something went wrong '
   
   options = Object.keys(this.state.locale.translation).filter(key => key.split('_')[0] === scope)
   if(scope == 'global') 
   options = Object.keys(this.state.locale.translation).filter(key => key.split('_')[0] === 'header' || key.split('_')[0] === 'prediction' || key.split('_')[0] === 'footer' || key.split('_')[0] === 'global')
   options = options.sort();


  return (
  	<div className="editor">
  
    { editablePages.find( page => page === scope)  ? (
        <div>
          <div>
            {/* <Editor
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              onTab={this.onTab}
              placeholder="Edit me :) "
              ref="editor"
              spellCheck={true}
              plugins={[linkifyPlugin]}
            /> */}
            <textarea className="editor__window" type="text" contentEditable={true} ref="editor" value = {this.state.editorState} onChange={this.onChange}/>
            
          
          </div>
        </div>
                ) : (  options.length ?  options.map((option)=> {
                  return (
                    <div className='row'>
                      <div className='editor__key'>{option.replace(/_/g, ' ')}: </div>
                      <textarea className='text-area' 
                      value={this.state.trans.find((locale) => locale.lang == lang ).translation[option]
                      || this.state.trans[0].translation[option]} 
                      onChange={(e) => {
                        let newState = Object.assign({}, this.state) 
                        newState.trans.find((locale) => locale.lang == lang).translation[option] = e.target.value
                        this.setState(prevState => ({
                          newState
                        }));
                        }
                      }
                         /> 
                    </div>
                  )
                }) : <span>loading...</span>)
    }
  
      <div className='btn pre-btn editor__save' onClick={() => this._handleClick(options)}>Save</div>
      </div>
    )
 }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(TextEditor))

function findLinkEntities(contentBlock, callback) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        Entity.get(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}
const Link = (props) => {
  const {url} = Entity.get(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};
      