import React from 'react'
import PropTypes from 'prop-types'

const Tag = ({ name }) => {
    return (
        <span className='ds-tag'>
            { name }
        </span>
    )
}

Tag.propTypes = {

}

export default Tag
