import { store } from "libs/redux"
import "whatwg-fetch" 

export const pay = async (amount, currency, method, user, action, params, callback) => {
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
    
    let url = `/api/payments?orbsAmount=${Math.trunc(amount)}&paymentCurrency=${currency}&paymentMethod=${method}`

    if(user) url += `&userid=${user}`;
    if(action) url += `&action=${action}`;
    if(params) url += `&params=${params}`;

    fetch(url, {
      method: "GET",
      redirect: 'follow',
      headers,
    })
    .then((response) => {
      // alert(response.json())
      return response.json()

    })
    .then((json) => {
      window.location.assign(json.paymentPageUrl, '_blank');
        // window.open(json.paymentPageUrl, '_blank');
      })
      .catch((error) => {
        // AHHHH! An Error!
        // window.open('https://www.google.com', '_blank');
        callback(error)
      })
  }
  export const addCard = async (amount, currency, callback) => {
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
    
    fetch(`/api/payments?orbsAmount=${Math.trunc(amount)}&paymentCurrency=${currency}&tokenize=true&paymentMethod=CARD`, {
      method: "GET",
      redirect: 'follow',
      headers,
    })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      
      // window.location.replace(json.paymentPageUrl);
      window.open(json.paymentPageUrl, '_blank');
    })
    .catch((error) => {
      // AHHHH! An Error!
      window.open('google.com', '_blank');
      callback(error)
    })
  }

  export const withdraw = async (amount, currency, method, account, callback) => {
  
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`/api/payments/payout?orbsAmount=${amount}&wantedCurrency=${currency}&paymentMethod=${method}&accountNumber=${account}`, {
      method: "GET",
      headers,
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if(!json.code)
        callback(null, json)
        else
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        callback(error)
      })
  }