import React, { Component } from "react"
import Dropdown from "components/Dropdown.js"
import { trans } from "utils/translator"
import i18next, { languages } from "i18n"

class LangSwitcher extends Component {

  constructor(props){
    super(props)
    let storedLang = localStorage.getItem('language').substr(0, 2);
    if(!languages.find(lang => lang.code == storedLang)) storedLang = 'en';
    this.state = { language: storedLang };
  }
  handleLinkClick = lang => e => {
    e.preventDefault();

	i18next.changeLanguage(lang, (err, t) => {
      if (err) {
        return console.log('error', err);
      }
      this.setState({ language: i18next.language });
    })
  
    this.refs.dropdown.hide();
  };

  render() {
    const {t} = this.props
     const nodes = languages.map((lang, i) => (
      <div key={i}>
        <button className="b-dropdown__link"
                onClick={this.handleLinkClick(lang.code)}>
          <i className={`b-flag b-flag_${lang.code}`}/> <span>{lang.fullname}</span>
        </button>
      </div>
    ));

    const component = (
     
      <div className='mainMenu__lang-switcher'>
        <div className={`mainMenu__lang-trigger`}/>
        <div className="mainManu__lang-label">{t('profile_language')}</div>
      </div>
    );

    return (
      <Dropdown
        ref="dropdown"
        component={component}>
        {nodes}
      </Dropdown>
    );
  }
}

export default trans(LangSwitcher);
