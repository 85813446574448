
import React from 'react'; 
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';

import * as UserActions from "actions/UserActions";
import * as ModalActions from "actions/ModalActions";
import { DESKTOP_MIN_RESOLUTION } from 'utils/config';

import RegisterDesktop from './Register';
import RegisterMobile from './Mobile/Register';

const RegisterContainer = (props) => {
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: DESKTOP_MIN_RESOLUTION });
    return isDesktopOrLaptop ? <RegisterDesktop {...props}/> : <RegisterMobile {...props} />;
} 

function mapDispatchToProps(dispatch) {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
        ModalActions: bindActionCreators(ModalActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(RegisterContainer);