import * as Votings from 'reducers/votings'
import * as VotingAPI from 'api/VotingAPI'

export function cancel(votingId, callback) {
  return (dispatch) => {
     VotingAPI.cancel((voting) => {
       if(callback) callback(voting)
      dispatch(Votings.update(voting))
    }, votingId)
  }
}

export function close(votingId, callback) {
  return (dispatch) => {
     VotingAPI.close((voting) => {
       if(callback) callback(voting)
      // dispatch(Votings.update(voting))
    }, votingId)
  }
}

export function get(admin) {
    return (dispatch) => {
       VotingAPI.load((list) => {
        dispatch(Votings.set(list))
      }, admin)
    }
  }

  export function createAdmin(votingBody) {
    return (dispatch) => {
        VotingAPI.createAdmin((voting) => {
        dispatch(Votings.update(voting))
      }, votingBody)
    }
  }

  export function placeBet(callback, votingId, selectedOption, amount) {
    return (dispatch) => {
        VotingAPI.placeBet((error, voting) => {
        callback(error, voting)
        // dispatch(Votings.update(voting))
      }, votingId, selectedOption, amount)
    }
  }
  
  export function updated(callback, admin, stream) {
    return (dispatch) => {
      VotingAPI.updated((list) => {
        dispatch(Votings.set(list))
        if (callback) callback(list)
      }, admin, stream)
    }
  }