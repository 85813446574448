import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import Slider from "react-slick"
import { groupBy, chain, toPairs, value } from "lodash"

export default class GamesListSlider extends Component {
 
  parseImagesBetty(gameList) {
    return gameList.map((value) => { 
      value.image = this.pickImg(value.title) 
      return value
    })
  }

  parseImages(gameList) {
    return gameList.map((value) => { 
      value.image = this.pickImg(value.game)
      return value
    })
  }

  groupByGame(streamList) {
    const r = chain(streamList)
      .groupBy("title")
      .map((value, key) => 
        ({ game: key,
          streams: value }))
      // .toPairs()
      .value()

    return r
  }

  failIdea() { return "/assets/images/main/games/Unknown-game-285x380.jpg" }

  pickImg(game) {
    switch (game) {
      case "Dota 2": return "/assets/images/main/games/Dota 2-285x380.jpg"
      case "CS:GO": return "/assets/images/main/games/Counter-Strike_ Global Offensive-285x380.jpg"
      case "Counter-Strike: Global Offensive": return "/assets/images/main/games/Counter-Strike_ Global Offensive-285x380.jpg"
      case "League of Legends": return "/assets/images/main/games/League of Legends-285x380.jpg"
      case "FIFA": return "/assets/images/main/games/FIFA 18-285x380.jpg"
      case "Fortnite": return "/assets/images/main/games/Fortnite-285x380.jpg"
      case "PUBG": return "/assets/images/main/games/PLAYERUNKNOWN'S BATTLEGROUNDS-285x380.jpg"
      case "Overwatch": return "/assets/images/main/games/Overwatch-285x380.jpg"
      case "Hearthstone": return "/assets/images/main/games/Hearthstone-285x380.jpg"
      case "World of Tanks": return "/assets/images/main/games/World of Tanks-285x380.jpg"
      default: return this.failIdea()// "https://i.imgur.com/NyfDfeh.gif"
    }
  }

  renderLinks(_data) {
    let result = Array()

    let data = this.parseImages(this.groupByGame(_data))

    if (this.props.betty) {
      data = this.parseImagesBetty(_data)
    }

    result = data.map(item => {
      if(item.image!=this.failIdea())
      return <NavLink className="streamItem"
        to={`${this.props.betty || ''}/game/${(item.title || item.game).trim().replace(/ /g, "_")}`}
        key={(item.title || item.game).trim()} activeClassName="activestreamItemList">
        
        <span className={item.image != this.failIdea() ? "hidden" : null}>{item.title || item.game}</span>  
        <img className="streamItem-img" src={item.image} alt={item.title || item.game} />
        <div className="streamItem-overlay" />
      </NavLink>
    }
    )
   
      // lol kek cheburek
    const additional = 9 - data.length > 0 ? 9 - data.length : 0
    for (let i = 0; i < additional; i++) {
      result.push(<div className="streamItem" key={`stream_empty_${i}`} />)
    }

    return result
  }

  render() {
    const streamList = this.props.streamlist || []

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        }
      ]
    }

    const links = this.renderLinks(streamList)

    return (
      <div className="gamesSlider" id="gamesSlider">
          {links.length <= 5 ? <PrevArrow /> : null}
          <Slider {...settings}>
            {links}
          </Slider>
          {links.length <= 5 ? <NextArrow /> : null}
      </div>
    )
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className || "slick-arrow slick-next slick-disabled"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="streamItem-img">
      <div className="gamesSlider__arrow-right" />
      </div>
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={`${className || "slick-arrow slick-prev slick-disabled"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="streamItem-img">
      <div className="gamesSlider__arrow-left" />
      </div>
    </div>
  )
}