import React from 'react'
import PropTypes from 'prop-types';
import times from 'lodash/times';
import NewsFeed from './NewsFeed';
import TasksFeed from './TasksFeed';

const ProfileMain = props => {
    return (
        <div className='profile-main flex'>
           <div className="profile-main__news-feed">
                <NewsFeed />
           </div>
           <div className="profile-main__tasks-feed">
                <TasksFeed />
           </div>
        </div>
    )
}

ProfileMain.propTypes = {

}

export default ProfileMain
