import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"
import { groupBy, chain, toPairs, value } from "lodash"

import * as userActions from "actions/UserActions"
import * as StreamActions from "actions/StreamActions"
import * as StreamAPI from "../../api/StreamAPI"

import GameListSlider from "components/StreamListSlider/GamesListSlider"
import StreamList from "components/StreamListSlider/StreamList"
import AnimatedNumber from "react-animated-number"

const getRandomInt = (min, max) => (Math.floor(Math.random() * (max - min + 1)) + min)

class StreamsContainer extends Component {

  render() {
    let game = this.props.location.pathname.split("/")[2] 
    game = game ? game.replace(/_/g, " ") : null

    const streamlist = this.props.streamList.streamlist.filter(s => s.game === game)
    

    return (
      <div>
        <main className="twitchSlider">
          <div className="container">
            <div className="liveStream">
              <p className="predictionParagraph">Games</p>
              {
                game ?
                <StreamList {...this.props} streamlist={streamlist} StreamActions={this.props.StreamActions} key="streamList" view='ALL' />
                  :
                <GameListSlider {...this.props} streamlist={this.props.streamList.streamlist} StreamActions={this.props.StreamActions} key="streamList"  />
              }
            </div>
          </div>
        </main>
      </div>
    )
  }


  groupByGame(streamList) {
    const r = chain(streamList)
      .groupBy("title")
      .map((value, key) =>
        ({
          game: key,
          streams: value
        }))
      // .toPairs()
      .value()

    return r
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    streamList: state.streamList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    notify: bindActionCreators(notify, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamsContainer)
