import React from 'react'
import PropTypes from 'prop-types'

const ProfileSectionHead = props => {
    const { title, description } = props;
    return (
        <div className="flex items-center mb3">
            <img src='' className='profile__icon circle mr2' />
            <div className="">
                <h2 className="h2 mb2">{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    )
}

ProfileSectionHead.propTypes = {

}

export default ProfileSectionHead
