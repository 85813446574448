import React, { Component } from "react"

export default class GenerateBonusForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bonus: {
        code: "",
        bonuses: 500,
        count: 1
        // amount: 1
      }
    }

    this.inputDataChange = this.inputDataChange.bind(this)
  }

  render() {
    const { code, bonuses, count } = this.state.bonus
    const { handleCreate } = this.props

    return (
      <div className="modalViewBlock" style={{ display: "block", width: "initial", marginBottom: "25px", padding: "20px" }}>
        <div>
          Если поле Code оставить пустым, то система сгенерирует столько промокодов, сколько указано в Count
          <br /><br />
          Если в поле Code написать какое-то значение, то система сгенерирует промокод, который можно будет использовать столько раз, сколько указано в Count
        </div>
        <form className="profilePageForm" noValidate onSubmit={this.handleFormSubmit}>
          {/* <div className="inputBlock">
            <label className="profilePageInput-label" htmlFor="title">
              Amount
              <input
                className="profileInput profileInput_narrow"
                name="amount"
                placeholder="1"
                value={amount} onChange={this.inputDataChange.bind(this, 'amount')}
              />
            </label>
          </div> */}
          <div className="inputBlock">
            <label className="profilePageInput-label" htmlFor="title">
              Code
              <input
                className="profileInput profileInput_narrow"
                name="code"
                placeholder="code name"
                defaultValue={code}
                onChange={this.inputDataChange.bind(this, "code")}
              />
            </label>
          </div>
          <div className="inputBlock">
            <label className="profilePageInput-label" htmlFor="stream">
              Cubes
              <input
                className="profileInput profileInput_narrow"
                placeholder="500"
                name="bonuses"
                defaultValue={bonuses}
                onChange={this.inputDataChange.bind(this, "bonuses")}
              />
            </label>
          </div>

          <div className="inputBlock">
            <label className="profilePageInput-label" htmlFor="type">
              Count
              <input
                className="profileInput profileInput_narrow"
                placeholder="1"
                name="count"
                defaultValue={count}
                onChange={this.inputDataChange.bind(this, "count")}
              />
            </label>
          </div>
        </form>
        <div className="inputBlock">
          <label className="profilePageInput-label" htmlFor="save">
            Generate bonus
            <button onClick={() => handleCreate(this.state.bonus)} className="pre-btn pre-btn_blue pre-btn_modal">
              GEN
            </button>
          </label>
        </div>
      </div>
    )
  }

  inputDataChange(type, event) {

    switch (type) {
      case "code":
        this.state.bonus.code = event.target.value.trim()
        break
      case "bonuses":
        if (event.target.value.trim() != "") { this.state.bonus.bonuses = parseInt(event.target.value.trim()) }
        break
      case "count":
        if (event.target.value.trim() != "") { this.state.bonus.count = parseInt(event.target.value.trim()) }
        break
      // case 'amount':
      //   this.state.bonus.amount = parseInt(event.target.value.trim())
      //   break
      default: break
    }
    this.setState({
      bonus: this.state.bonus
    })
  }
}
