export default (value, rules = []) => {
  return rules.every(check => check(value) === true)
}

export const required = value => !!value
export const email = value => /.+@.+\..+/.test(value)
export const gt = num => value => value.length > num
export const lt = num => value => value.length < num
export const gte = num => value => value.length >= num
export const lte = num => value => value.length <= num
export const val_gte = num => value => value >= num
export const val_lte = num => value => value <= num
// this will not allow -+ sign it allow empty strings though
export const isNum = value => { return !isNaN(+value) || value=="" } 
export const isInt = value => { return Math.floor(value) == value}