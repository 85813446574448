import React, {Component} from "react"
import {get, getTopDonators} from "api/DonationAPI"
import {trans} from "utils/translator"
import {Link} from "react-router-dom"
import ReactPaginate from 'react-paginate'
import { history } from 'libs/router'

export default trans(class CreateStream extends Component {

  
    constructor(props) {
        super(props)
        const {user} = props
        const isStreamer = user.model && (user.model.permissions || []).includes('STREAM')

        this.state = {
            donations: [],
            showOnlyOrbs: false,
            page: 1,
            isStreamer,
            pages: 1,
        }

    }
  
    componentDidMount(){
       history.listen(() => {
        const { donations } = this.state
        if(donations.count)
        localStorage.setItem('donationsCount', donations.count)
       })
       get(this.state.page, (donations) => {
            this.setState({
                donations: donations,
                pages: donations.pages,
                count: donations.count
            }, () => {
              
            }) 
        })
        getTopDonators(10, (donators) => {
            this.setState({
                topDonators: donators,
            })
        })
    }
 
    handlePageClick = (data) => {
        let selected = data.selected + 1
        this.setState({
            page: selected
            }, () => {
                get(this.state.page, (donations) => {
                    this.setState({
                        donations: donations,
                    }, () => {

                    }) 
                })
            })
    }
  
    renderDonation = (donation, i, offset, unreadCount) => {
        const { username, message, donationValue, createdAt, currency } = donation
        const date = new Date(createdAt).toLocaleDateString() + ' ' + new Date(createdAt).toLocaleTimeString()
        return  <tr className='transactions__row'>
        <td className='transactions__currency'><div className={`transactions__circle ${currency=='ORBS' ? 'gold': ''}`} /></td>
        {/* <td className='transactions__status'> {(i + offset) < unreadCount ? 'New': ''}</td> */}
        <td className='transactions__activity'>{donationValue}</td>
        <td className='transactions__operation'>{username}</td>
        <td className='transactions__streamer'>{message}</td>
        <td className='transactions__date'>{date}</td>
    </tr>
    }
    render(){
        const {t} =this.props
        const { donations, topDonators, page, pages, count } = this.state
        const donationsList = donations.rows
        const readCount = localStorage.getItem('donationsCount')
        const unreadCount = count - readCount
        const offset = (page - 1) * 50 
   
        return (
            <div className='profilePage'>
            <div className='profile-page__donations donations'>
            <div style = {{
                color: "white"
            }} className="donations__top-donators">
            <h3 className="donation__subheader">{t('profile_top_donators')}</h3>
            <div className="donations__top-donators-list">
            {
                Array.isArray(topDonators) ? 
                topDonators.map((d, i) => {
                    return <div><span>{d.user}</span><span>{d.sumDonation}</span></div>
                }) : null
            }
            </div>
            </div>
                <div className="profile-page__table-cnt">               
                    <table style={{
                        width: "100%",
                    }} cellSpacing="0">
                    <thead className='transactions__header'>
                        <tr>
                            <th className='transaction__circle'></th>
                            {/* <th className='transaction__status'>{t('profile_donations_status')}</th> */}
                            <th className='transactions__activity'>{t('profile_donation_sum')}</th>  
                            <th className='transactions__operation'>{t('profile_donation_nickname')}</th>
                            <th className='transactions__streamer'>{t('profile_donations_text')}</th>
                            <th className='transactions__date'>{t('profile_date')}</th> 
                        </tr>
                    </thead>
                    <tbody className='transactions__body'>
                    {
                        Array.isArray(donationsList) ? 
                        donationsList.map((d, i) => {
                            return this.renderDonation(d, i, offset, unreadCount)
                        }) : null
                    }
                    </tbody>
                    </table>
                </div>
            <div className="transactions__paginator-wrapper"> 
                <div className="transactions__paginator-container"> 
                    <ReactPaginate previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={this.state.pages || 0}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div>
            </div>
            </div>
            </div>
          
  )

}
}
)