
import React, { Component } from "react"
import { NavLink, Link } from "react-router-dom"
import { groupBy } from "lodash"
import { trans } from 'utils/translator';
import _ from 'lodash';

export default trans((props) => {
  const { streamlist=[], StreamActions, setFilter, streamFilter, t }  = props
  const streamsByStatus = groupBy(streamlist, stream => stream.spectators ? 'online' : 'offline')
  const streams = [
    ..._.sortBy(streamsByStatus['online'] || [], (stream) => stream.stream.toLowerCase()),
   ..._.sortBy(streamsByStatus['offline'] || [], (stream) => stream.stream.toLowerCase())
  ]
  .filter((stream, i) => {
    return (stream.stream || []).includes(streamFilter)
  })
  
  return (
      <div className='stream-list'>
        <div className="col-container col-container_center">
            <div className="stream-list__header">
              <div className="stream-list__search row-container row-container_v-center">
                <input 
                placeholder={t('admin_quick_search')}
                className="form__input stream-list__filter"
                onChange={setFilter}
                value={streamFilter}/>
                <i className="icon stream-list__search-icon"/>
              </div>
            </div>
        <div className="stream-list__streams">
          <ul className="stream-list__list">
            {
               Array.isArray(streams) && streams.map((item) => {
                return (
                  <li className="stream-list__item" key={StreamActions.streamerID(item.type, item.stream)}>
                    <Link to={`/admin/stream/${StreamActions.streamerID(item.type, item.stream)}`} activeClassName="activeItem">{StreamActions.streamerID(item.type, item.stream)} {item.spectators ? <small style={{ color: "red" }}> live</small> : ""}</Link>
                  </li>
                )
              })
            }
                  <li className="stream-list__item">
                    <NavLink to="/admin/stream/add" activeClassName="activeItem" key="add">{t('admin_add_new')}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
  )
})
