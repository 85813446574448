import * as TemplatePrediction from "reducers/templatePrediction"
import * as TemplatePredictionAPI from "api/TemplatePredictionAPI"


export function setTemplates(list) {
  return (dispatch) => {
    dispatch(TemplatePrediction.setTemplates(list))
  }
}

export const create = (template, callback) => (dispatch) => {
  TemplatePredictionAPI.create(template, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(TemplatePrediction.add(template))
    if (callback != null) {
      callback(null, template)
    }
  })
}

export const remove = (id, callback) => (dispatch) => {
  TemplatePredictionAPI.remove(id, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(TemplatePrediction.remove(id))
    if (callback != null) {
      callback(null, template)
    }
  })
}

export const get = (title, stream) => (dispatch) => {
  TemplatePredictionAPI.get(title, stream, (error, templates) => {
    if (error) {
      console.error(error)
      return
    }
    dispatch(TemplatePrediction.setTemplates(templates))
  })
}

export const patch = (id, template, callback) => (dispatch) => {
  TemplatePredictionAPI.patch(id, template, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(TemplatePrediction.update(template))
    if (callback != null) {
      callback(null, template)
    }
  })
}