import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"
import * as userActions from "actions/UserActions"
import Recaptcha from "react-recaptcha"
import FormValidator from "components/Common/FormValidator"
import { required, email } from "utils/validator"


class ResetPassword extends FormValidator {
  constructor(props) {
    super(props)

    this.state.recaptchaValid = false

    this.callback = this.callback.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
    this.createNotification = this.createNotification.bind(this)
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    if (!this.isValid()) {
      this.createNotification({
        title: "Error",
        message: "Please, fill correct email",
        status: "warning"
      })
      return
    }
    if (!this.state.recaptchaValid) {
      this.createNotification({
        title: "Error",
        message: "Check captcha first",
        status: "warning"
      })
      return
    }
    const { email } = this.state.form
    this.resetPass(email.value)
  }

  createNotification(data) {
    const { notify } = this.props
    this.setState({ notification: data })
    if (this.state.notification) {
      notify({
        title: this.state.notification.title,
        message: this.state.notification.message,
        status: this.state.notification.status,
        dismissible: true,
        dismissAfter: 5000
      })
    }
  }

  callback() {
    console.log("Done!!!!")
  }

  // // create a reset function
  // resetRecaptcha = () => {
  //   this.state.recaptchaInstance.reset()
  // };

  // specifying verify callback function
  verifyCallback(response) {
    this.state.recaptchaValid = true
  }

  // // manually trigger reCAPTCHA execution
  // executeCaptcha = function () {
  //   this.state.recaptchaInstance.execute()
  // };

  resetPass(email) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    fetch("api/user/reset", {
      method: "POST",
      headers,
      body: JSON.stringify({ email })
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {

        if (json.code == "UNKNOWN_ERROR" || json.code == "EMAIL_NOT_FOUND") {
          this.createNotification({
            title: "Error",
            message: json.message,
            status: "warning"
          })
          return
        }
        // как-то это вообще не так, видимо, делается)
        // user.model.token = json.token;

        // this.readNewUser(json.token, email, password)
        this.createNotification({
          title: "Reset password",
          message: "Check your email",
          status: "success"
        })
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("loginUserAPI error:", error)
        const message = error ? `: ${error}` : ""
        this.createNotification({
          title: "Server Error",
          message: `Try again or contact us via feedback form${message}`,
          status: "error"
        })
      })
  }

  render() {
    return (
      <div className="modalView">
        <script src="//www.google.com/recaptcha/api.js?onload=vcRecaptchaApiLoaded()&render=explicit" async defer />
        <div className="profileContainer modalViewContainer">
          <div className="modalViewBlock  reset-password">
            <h3 onClick={this.executeCaptcha} className="modalViewBlock-title">Reset password</h3>
            <p className="modalViewBlock-desc">Enter your e-mail address below to begin password reset process.</p>
            <form className="formContainerForm" noValidate onSubmit={this.handleFormSubmit}>
              <div className="inputBlock">

                <input
                  {...this.bindInput("email", "email", [required, email])}
                  className={`authInput ${this.isValid("email") || "authInput authInput_error"}`}
                  placeholder="Type your email here"
                />
                <span className="inputBlock-icon">
                  <div className="inputBlock-img">
                    <div className="icon svgIcon-email-icon svgIcon-email-icon-dims" />
                  </div>
                </span>
              </div>
              <Recaptcha
                sitekey="6Ld3p0gUAAAAAJyI9EFfvDJjHCIeOcP3JQ7egV-J"
                render="onload"
                theme="dark"
                verifyCallback={this.verifyCallback}
                onloadCallback={this.callback}
              />
              <div className="inputBlock">
                <input
                  id="authInput-signIn"
                  className="authInput authSendInput_modal"
                  type="submit"
                  value="Send Reset Email"
                  disabled={!this.isValid()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

