import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import FormValidator from "components/Common/FormValidator"
import { required, email, } from "utils/validator"
import { notify } from "reapop"
import { trans } from "utils/translator"

import * as UserActions from "actions/UserActions"
import * as PredictionsActions from "actions/PredictionsActions"

const CHALLENGE_DESC_MAX_LENGTH = 200;

class ChallengeCreate extends FormValidator {
  constructor(props) {
    super(props)
    this.state = {
      isOpenMessage: false,
      cubesAmount: this.props.stream.minimumDonation,
      message: '',
      username: '',
      currency: 'cubes', 
    }

    this.notificClick = this.notificClick.bind(this)
    this.handleOrbsAmountInputChange =  this.handleCubesAmountInputChange.bind(this)
    this.handleDescInputChange = this.handleMessageInputChange.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
  }
  handleUsernameChange = (e) => {
    const value = e.target.value
    if(value.length > 30) return 
    this.setState({
      username: value,
    })
  }
  handleMessageInputChange = (e) =>{
    const value = e.target.value
    if(value.length > CHALLENGE_DESC_MAX_LENGTH) return 
    this.setState({
      message: value,
    })
  }
  handleCubesAmountInputChange = (e) => {
    const value = e.target.value
    const valueInt = (parseInt(value))

    if(!value.length) 
    this.setState({
      cubesAmount: ''
    })

    if(value.length > 5) return 
    
    if(isNaN(valueInt)) return 
    this.setState({
        cubesAmount: valueInt 
    })
  }
  handleChat() {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
    }))
  }

  handleMsg(e, props) {
    e.preventDefault()
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    const { username, message, cubesAmount } = this.state 
    const minimumDonation = this.props.stream.minimumDonation
    if (cubesAmount < minimumDonation) {
      this.notificClick("Error", `Minimum donation is ${minimumDonation} cubes (set by streamer)!`, "error")
      return
    }

    
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", user.token)
    e.preventDefault()
    fetch(`/api/messages`, {
      method: "POST",
      body: JSON.stringify({
        username,
        message,
        donationValue: cubesAmount,
        streamId: this.props.streamID
      }),
      headers
    }).then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code != undefined) {
        //error
        this.notificClick("Error", error, "error")
      } else {
        // process
        this.notificClick("Succsess", "Your message have been sent")
        this.props.UserActions.updateUserBalance();
      }
    })
  }

  render() {
    const {stream, t}  = this.props
    return (
      <div className="feedbackModal message-modal" onClick={(e) => e.stopPropagation()}>
        <div className="feedbackModalTitle">
        <div className="message-modal__right">
          <p className="feedbackModal-title">{t('message_message_for')}</p>
          <p className="feedbackModal-title message-modal__streamer">{stream.stream.toUpperCase()}</p>
          <p className="feedbackModal-title message-modal__playing">{!!stream.game ? `${t('message_playing_in')} ${stream.game}` : ''}</p>
        </div>
        <div className="message-modal__left">
            <div className="message-modal__avatar">
            <img src={stream.image} alt=""/>
            </div>
        </div>
      </div>
        <form action="" className="feedbackModal-form">
        <div className="feedbackModalDesc message-modal__row">
            <p className="feedbackModal-desc">{t('message_your_nickname')}</p>
            <input
            ref="challengeUsername"
            onChange={this.handleUsernameChange}
            onFocus={(e) => e.target.placeholder = ''}
            onBlur={e => e.target.placeholder='Anonymous'}
            value={this.state.username}
            placeholder='Anonymous'
            className="feedbackModal-text message-modal__username"
          />
          </div>
          <div className="feedbackModalDesc" style={{ marginTop: "25px" }}>
            <p className="feedbackModal-desc">{t('message_tell_feelings')}</p>
          </div>
          <textarea
            ref="challengeTitle"
            name="challengeTitle"
            className="feedbackModal-text message-modal__description"
            placeholder="Hello world"
            onFocus={(e) => e.target.placeholder = ''}
            onBlur={e => e.target.placeholder='Hello world'}
            onChange={this.handleMessageInputChange}
            value={this.state.message}
          />
           <div className="feedbackModalDesc message-modal__row" style={{ paddingTop: "10px" }}>
            <div className="message-modal__rigth">
            <div className="message-modal__donation-value">
            <p className="message-modal__label">{t('message_price')}</p>
            <input
            ref="challengeValue"
            style={{ height: "auto" }}
            onChange={this.handleCubesAmountInputChange}
            value={this.state.cubesAmount}
            className="feedbackModal-text message-modal__cubes-amount"
            placeholder="100"
            onFocus={(e) => e.target.placeholder = ''}
            onBlur={e => e.target.placeholder='100'}
          />
          {
          this.state.currency == 'cubes' ?
          <div className={"message-modal__cubes"} onClick={() => this.setState({
            currency: 'orbs'
          })}/> :
          <div className={"message-modal__orbs"} onClick={() => this.setState({
            currency: 'cubes'
          })}/>
          }
            </div>
           </div>
           <div className="message-modal__left">
           <input type='submit' className="pre-btn pre-btn_gold message-modal__send"  onClick={e => this.handleMsg(e, this.props)} value={t('message_send')}/>
           </div>
          </div>
        </form>
        <p className="message-modal__desc">
        {t('message_streamer_will_see')}
        </p>
        { this.state.isOpenMessage ?
          <div className="feedbackModal feedbackModal_result">
            <div className="feedbackModalTitle">
              <p className="feedbackModal-title">{t('message_message')}</p>
            </div>
            <div className="feedbackModal-img">
              <img src="/assets/images/feedback.svg" alt="feedback" />
              <p className="feedbackModal-result">{t('message_thank_you')}<br /></p>
              <button
                onClick={this.props.handleCloseMessage}
                className="btn-blue"
                style={{ margin: "0 auto" }}
              >{t('message_close')}
              </button>
            </div>
          </div> : null}
      </div>
    )
  }

  notificClick(title, message, status) {
    const { notify } = this.props
    notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000
    })
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(ChallengeCreate))
