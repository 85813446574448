import React from 'react'
import PropTypes from 'prop-types'

const Avatar = props => {
    const { image, onClick } = props;
    return (
            <img 
                className='avatar avatar__img circle block'
                onClick={onClick}
                src={image} 
                alt="user avatar"
                
                />
    )
}

Avatar.propTypes = {

}

export default Avatar
