import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import Slider from "react-slick"
import MediaQuery from 'react-responsive'
import {withRouter, Link} from 'react-router-dom'
import {trans} from "utils/translator"

import BecomeStreamerModal from "components/Feedback"

 class StreamListSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isNeedUpdate: false,
      activeIndex: 0,
      showModal: false,
      searchInput: 'search for streamer',
      suggestions: [],
    }
    this.handleArrowClick = this.handleArrowClick.bind(this)

  }
  
  handleArrowClick = (direction, live) => () => {
  
    const length = live.length
    let ind = this.state.activeIndex + direction
    if(ind < 0) ind = length - 1
    if(ind > length-1) ind = 0
    
    this.setState({
      activeIndex: ind,
    }, () => {
    })
  }
  renderStreamLinks(data) {
    let result = Array()
    const { StreamActions } = this.props
    // data = data.filter(s => s.spectators > 0)
    
    result = data.map(item => (
      <NavLink className="streamItem" to={`/stream/${StreamActions.streamerID(item.type, item.stream).trim()}`} key={StreamActions.streamerID(item.type, item.stream).trim()} activeClassName="activestreamItemList">
        <img className="streamItem-img" src={item.image} alt={item.title} />
       
        <div className="streamItem-overlay" >
        <div className='streamSlider__info'>
        <div className='streamSlider__stream-name'>
          {item.stream}
          </div>
        <div className="streamSlider__stream-spectators">{item.spectators || "online"}</div>
        </div >
        </div>
        
      </NavLink>
    ))

    // Slider "не умеет" в пустые элементы. Если задавать ему определённую ширину, то он не будет заполнять пробелами это место.
    // Поэтому пришлось добавлять вручную пустые эллементы. Хотя можно было просто на фоне что-то сверстать с черными блоками, 
    // а слайдер добавить поверх этого пустого места
    const additional = 7 - data.length > 0 ? 7 - data.length : 0
    for (let i = 0; i < additional; i++) {
      result.push(<div className="streamItem streamItem_empty" key={`stream_empty_${i}`} />)
    }

    return result
  }
  handleSearchChange = (e) => {
    const q = e.target.value;
    this.setState({
      searchInput: q
    })
    this.props.StreamActions.search(q, (error, message) => {
      this.setState({
        suggestions: message
      })
    })
  }

  render() {
    const path = this.props.location.pathname
    const stream = path.split('/').pop()
    const streamList = this.props.streamlist || []
    const settings = {
      dots: false,
      infinite: false,
      speed: 1000,
      // useTransform: true,
      slidesToShow: 5,
      slidesToScroll: 3,
      draggable: false,
      initialSlide: 0,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    console.log(streamList, 'streamlist123123');
    const links = this.renderStreamLinks(streamList)
    // const links = this.renderStreamLinks([])
    const {activeIndex} = this.state
    // const live = streamList.filter(s => s.spectators > 0)
    const live = streamList;
    let activeStream = live.find(s => s.stream == stream) || {
      stream: stream,
      spectators: 0
    }
    const {t} = this.props
    if(!live.length) return ('')
    return (
      <div className='live-stream-slider'>
       {/* <ReactTwitchEmbedVideo channel="talk2megooseman" layout="video" height="200" width="300" /> */}
       
      <MediaQuery query="(min-device-width: 480px)">
      <div className="twitchStream">
        <div className="streamSlider" id="streamSlider">
          {links.length <= 5 ? <PrevArrow /> : null}
          <Slider {...settings}>
            {links}
          </Slider>
          {links.length <= 5 ? <NextArrow /> : null}
        </div>
      </div>
      </MediaQuery>
      </div>
    )
  }
}


function NextArrow({ className, style, onClick }) {
  return (
    <div
      className={`${className || "slick-arrow slick-next slick-disabled slider-mobile-hidder"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="streamItem-img">
        <div className="streamSlider__arrow-right" />
      </div>
    </div>
  )
}

function PrevArrow({ className, style, onClick }) {
  return (
    <div
      className={`${className || "slick-arrow slick-prev slick-disabled slider-mobile-hidder"} streamItem-Btn`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
       <div className="streamItem-img">
        <div className="streamSlider__arrow-left" />
      </div>
    </div>
  )
}

export default withRouter(trans(StreamListSlider))
// export default scriptLoader(['https://player.twitch.tv/js/embed/v1.js'])(StreamListSlider)