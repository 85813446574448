import React, { Component } from "react"
import AuthForm from "components/AuthForm"
import { trans } from "utils/translator";
import  {Editor, convertFromRaw, EditorState} from "draft-js";

export default trans(class FaqTab extends Component{

render() {
  const { t } = this.props
  return (
    <div className="tabContent activeTab" id="tab2" dangerouslySetInnerHTML={{
      __html: t('faqtab-global')
    }}>
    
    </div>
  )
}
}
)    

