import React, { Fragment, Component } from "react"
import FormValidator from "components/Common/FormValidator"
import { trans } from 'utils/translator';
class PaidActionNew extends FormValidator{

  static get waitingForModeration() { return "WAITING_FOR_MODERATION" }
  static get waitingForAcception() { return "WAITING_FOR_ACCEPTION" }
  static get accepted() { return "ACCEPTED" }
  static get failed() { return "FAILED" }
  static get done() { return "DONE" }
  static get closed() { return "CLOSED" }

  constructor(props){
    super(props)
  }

  done = () => {
    const { paidAction } = this.props
    gtag("event", "paidaction", { 
      event_category: 'activity_managing',
      event_action: 'done_clicked',
      paidAction,
    })
    this.props.PaidActionActions.patch(this.props.paidAction.id, {
      status: 'DONE',
    }, (paidAction) => {
      if(paidAction.status === 'DONE'){
        gtag("event", "paidaction", { 
          event_category: 'activity_managing',
          event_action: 'activity_done',
          paidAction,
        })
      }
      else{
        gtag("event", "paidaction", { 
          event_category: 'activity_managing',
          event_action: 'error',
          paidAction,
        })
      }
    })
  }

  render(){
    const { done, props: { paidAction: { price, currency, description } } } = this
    const { t } = this.props
    return (
    <div className="adm-new-control-card adm-new-control-card--active adm-new-paid-action adm-new-paid-action-state2">
    <div className="adm-new-control-card__header">
      <div className="adm-new-control-card__option">
        <span className={`adm-new-paid-action__${currency == 'CUBES' ? 'cubes' : 'orbs'}`}>{ price }</span>
      </div>
      <div className="adm-new-control-card__close purecss-close"></div>
    </div>
    <div className="adm-new-control-card__content">
      { description }
    </div>
    <div className="adm-new-control-card__actions">
      <button 
      className="adm-new-control-card__btn adm-new-control-card__btn--accent"
      onClick={done}
      >{t('admin_done')}</button>
    </div>
  </div>
  );
  }
}
export default trans(PaidActionNew)

