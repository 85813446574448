import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as NotificationActions from "actions/NotificationActions"
import * as notificationsList from "reducers/notificationsList"
import NotificationRow from "components/Notification/NotificationRow"
import NotificationEmptyRow from "components/Notification/NotificationEmptyRow"

export default class NotificationBlockContainer extends Component {
  renderNotifications(notifications) {
    if (notifications.length == 0) {
      return <NotificationEmptyRow key="empty" />
    }
    
    return notifications.map((notification, index) => {
      return <NotificationRow notification={notification} key={`notification_${index}`} />
    })
  }

  render() {
    const notifications = this.props.notificationsList.notificationsList.filter((value) => {
      return value != null
    })

    return (
      <div>
        <div className="profileMenu" id="notificationMenu">
          <div className="nameBlock"><span>Notification</span></div>
          <ul className="profileMenuList">
            {this.renderNotifications(notifications)}
          </ul>
        </div>
      </div>
    )
  }
}
