import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"

import Prediction from "./Prediction"

import * as PredictionsActions from "actions/PredictionsActions"

class PredictionContainer extends Component {
  render() {
    return (
      <Prediction {...this.props} />
    )
  }
}

function mapStateToProps(state) {
  return {
    // predictions: state.predictions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    notify: bindActionCreators(notify, dispatch),
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PredictionContainer)
