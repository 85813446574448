import React from "react"
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as ModalActions from "actions/ModalActions"
import FormValidator from "components/Common/FormValidator"
import { required, email, gte } from "utils/validator"
import {trans} from "utils/translator"

class SignInForm extends FormValidator {
  handleFormSubmit = (e) => {
    e.preventDefault()
    if (!this.isValid()) return
    const { email, password } = this.state.form
    this.loginUserAPI(email.value, password.value)
  }

  sendNotification(data) {
    this.props.createNotification(data)
  }

  render() {
    const {t} = this.props
    return (
      <form className="formContainerForm" noValidate onSubmit={this.handleFormSubmit}>
        <div className="inputBlock">
          <input
            {...this.bindInput("email", "email", [required, email])}
            className={`authInput ${this.isValid("email") || "authInput authInput_error"}`}
            placeholder={t('global_type_email')}
          />
          <span className="inputBlock-icon">
            <div className="inputBlock-img">
              <div className="svgIcon-email-icon svgIcon-email-icon-dims" />
            </div>
          </span>
        </div>
        <div className="inputBlock">
          <input
            {...this.bindInput("password", "password", [required, gte(6)])}
            className={`authInput ${this.isValid("password") || "authInput authInput_error"}`}
            placeholder={t('global_type_password')}
          />
          <span className="inputBlock-icon">
            <div className="inputBlock-img">
              <div className="svgIcon-password-icon svgIcon-password-icon-dims" />
            </div>
          </span>
        </div>
        <div className="inputBlock submit">
          <input
            disabled={!this.isValid()}
            id="authInput-signIn"
            className="authInput"
            type="submit"
            value={t('global_form_sign_in')}
          />
        </div>
        {/* <a className="forgotPass" href="">Forgot Password?</a> */}
        <Link onClick={() => this.props.modalActions.toggleAuthModal(false, null)} to="/reset-password" className="forgotPass" >{t('global_forgot_password')}</Link>
      </form>
    )
  }

  loginUserAPI(email, password) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    fetch("/api/auth/signin", {
      method: "POST",
      headers,
      body: JSON.stringify({ email, password })
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {

        if (json.code == "WRONG_CREDENTIALS" || json.code == "UNKNOWN_ERROR") {
          this.sendNotification({
            title: json.message,
            message: "Check credentionals or sign up first",
            status: "warning",
            dismissible: true,
          })
          return
        }
        
        // как-то это вообще не так, видимо, делается)
        // user.model.token = json.token;
        this.readNewUser(json.token, email, password)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("loginUserAPI error:", error)
        this.sendNotification({
          title: "Server Error",
          message: "Try again or contact us via feedback form",
          status: "error",
          dismissible: true,
        })
      })
  }

  readNewUser(token, email, password) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)

    fetch("/api/user/", {
      method: "GET",
      headers
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {

        if (json.code == "WRONG_CREDENTIALS" || json.code == "UNKNOWN_ERROR") {
          this.sendNotification({
            title: json.message,
            message: "Check credentionals or sign up first",
            status: "warning",
            dismissible: true,
          })
          return
        }
        json.token = token
        this.props.onSubmit(email, password, json)
        
        gtag("event", "login", { method: "Predictoria" })

        // setTimeout(() => {
        //   window.location.href = "/"
        // }, 500)
      })
      .catch((error) => {
        // AHHHH! An Error!
        this.sendNotification({
          title: "Server Error",
          message: "Try again or contact us via feedback form",
          status: "error",
          dismissible: true,
        })
      })
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modals
  }
}

function mapDispatchToProps(dispatch) {
  return {
    modalActions: bindActionCreators(ModalActions, dispatch)
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(SignInForm))