import "babel-polyfill"
import "react-hot-loader/patch"
import { AppContainer } from "react-hot-loader"
import { render } from "react-dom"
import React from "react"
import RootContainer from "./containers/RootContainer"

const run = (App) => {
  render(
    <AppContainer>
      <App />
    </AppContainer>, 
    document.getElementById("root")
  )
}

run(RootContainer)

if (module.hot) {
  module.hot.accept("./containers/RootContainer", () => {
    const NextRootContainer = require("./containers/RootContainer").default
    run(NextRootContainer)
  })
}


