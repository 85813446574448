
import { store } from "libs/redux"
import "whatwg-fetch"

const search = async (query, callback) => {
  const token = store.getState().user.model.token || ""
  const headers = new Headers()
  const esc = encodeURIComponent;
  const params = Object.keys(query)
    .filter(k => query[k])
    .map(k => esc(k) + '=' + esc(query[k]))
    .join('&');
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch(`/api/admin/logs?${params}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
}

export {
  search
}
