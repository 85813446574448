import React, { Fragment, Component } from "react"

class ExpandableInput extends Component{
  constructor(props){
    super(props)
    const { rows } = props
    this.state = {
        rows
    }
  }

  change = callback => (e) => {
    // const { state: { rows } } = this
    // const oldRows = rows;
    // e.target.rows = 1
    // const newRows = ~~(e.target.scrollHeight/15);
    // if (newRows === oldRows) {
    // 	e.target.rows = newRows;
    // }
    // this.setState({ rows: newRows })
    const { refs: { textarea } } = this
    textarea.style.height = textarea.scrollHeight + 'px' 
    callback(e)
  }
  componentDidMount(){
    const { refs: { textarea } } = this
    textarea.style.height = textarea.scrollHeight + 'px' 
  }

  render(){
    const { state: { rows }, props, change } = this
    return (
       <textarea ref='textarea' { ...props } onChange={change(props.onChange)} />
  );
  }
}
export default ExpandableInput