import React, { Component } from "react"
import { Route } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { store } from "libs/redux"

import EventsSliderContainer from "./Betty-EventsSliderContainer"
import PredictionStreamContainer from "./Betty-PredictionStreamContainer"
import PredictionStreamItem from "./Betty-PredictionStreamItem"
import BettyGamesSlider from "./Betty-GamesSlider"

import GamesListSlider from "components/StreamListSlider/GamesListSlider"

import * as PredictionsActions from "actions/PredictionsActions"
import * as BettyActions from "actions/BettyActions"
import * as UserActions from "actions/UserActions"
import * as StreamActions from "actions/StreamActions"

import "../../components/AuthForm/styles/index.scss"


class MainContainer extends Component {
  componentDidMount() {
    const { props } = this
    const { BettyActions } = props

    BettyActions.get()
    BettyActions.events()
    if (store.getState().user.model.token) {
      BettyActions.bets()
    }

    var intervalId = setInterval(_ => {

      BettyActions.events()
      
      if (store.getState().user.model.token) {
        BettyActions.bets()
      }
    }, 5000)
    // store intervalId in the state so it can be accessed later:
    this.setState({intervalId: intervalId});
  }

  render() {
    const { props } = this
    const { streams, events, bets, BettyActions, StreamActions } = props

    let fst_stream = events[0]
    
    return (
      <div style={{ background: "white" }}>
        <main className="twitchSlider">
          <div className="container betty">
            <div className="liveStream">
              <p className="predictionParagraph">Games</p>

              {/* <GamesListSlider {...props} betty={`/betty`} streamlist={streams} StreamActions={StreamActions} key="gameList" /> */}
              <BettyGamesSlider  {...props} betty={`/betty`} streamlist={streams} StreamActions={StreamActions} key="gameList" />
              <p className="predictionParagraph">Events</p>
              
              <EventsSliderContainer {...props} streamlist={events} />

              <PredictionStreamContainer {...props} />

              <Route exact path="/betty/" render={() => <PredictionStreamItem bets={bets} stream={fst_stream} StreamActions={StreamActions} />} />
              {
                streams.map(item => {
                  let stream = events.filter(e => e.game_id == item.id)[0]
                  return <Route exact path={`/betty/game/${item.title.replace(/ /g, "_")}`} key={item.id}
                    render={() => <PredictionStreamItem stream={stream} bets={bets} StreamActions={StreamActions} />} />
                })
              }

            </div>
          </div>
        </main>        
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    streams: state.bettyGames.games,
    events: state.bettyEvents.events,
    bets: state.bettyEvents.bets,
    user: state.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    BettyActions: bindActionCreators(BettyActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
