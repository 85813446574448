import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'components/_Button';
import Socials from '../../Socials.jsx';
import Input from 'components/Input';
import Form from 'components/_Form';
import { appRoutes } from 'utils/config.js';
import { trans } from 'utils/translator';

const Login = props => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { UserActions, setSelected, t } = props;

    return (
        <Form
            className='p2 bg-dark-magenta white'
            onSubmit={(e) => {
                e.preventDefault();
                UserActions.login(email, password);
            }}>
            <Input
                name='email'
                placeholder={t('_login_1')}
                value={email}
                onChange={setEmail}
                className='mb2'
            />
            <Input
                name='password'
                placeholder={t('_login_2')}
                value={password}
                onChange={setPassword}
                className='mb2'
            />
            <Link to="/reset-password" className='center mb4 block'>{t('_login_3')}</Link>
            <Button type='submit' theme='white' appearance='accent' className='block mx-auto mb2'>
            {t('_login_4')}
            </Button>
            <Link to={appRoutes.REGISTRATION} className='center mb4 block'>{t('_login_6')}</Link>
            <p className='center mb2'>{t('_login_5')}</p>
            <Socials />
        </Form>
    )
}


export default trans(Login);
