import { store } from "libs/redux"
import "whatwg-fetch"

const get = async (page, callback) => {
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`/api/messages?page=${page}`, {
      method: "GET",
      headers
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
  }
  const getTopDonators = async (number, callback) => {
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`/api/messages/top?number=${number}`, {
      method: "GET",
      headers
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
  }
  export {
    get,
    getTopDonators
  }