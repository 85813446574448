import React from 'react'
import { Switch, Route } from 'react-router-dom';

import ProfileMenu from './ProfileMenu';

import ProfileMain from './ProfileMain';
import ProfilePayments from './ProfilePayments';
import ProfileSettings from './ProfileSettings';
import ProfilePersonalization from './ProfilePersonalization';
import ProfileWidget from './ProfileWidget';
import ProfilePartnership from './ProfilePartnership';
import ProfileStreams from './ProfileStreams';
import StreamPage from 'components/StreamPage';

import { appRoutes } from 'utils/config';

const Profile = props => {
    return (
        <div className='profile'>
            <div className="profile__sidebar">
                <ProfileMenu />
            </div>
            <div className="profile__content">
                <Switch>
                    <Route path={appRoutes.PROFILE_MAIN} component={ProfileMain} />
                    <Route path={appRoutes.PROFILE_PAYMENTS} component={ProfilePayments} />
                    <Route path={appRoutes.PROFILE_SETTINGS} component={ProfileSettings} />
                    <Route path={appRoutes.PROFILE_PERSONALIZATION} component={ProfilePersonalization} />
                    <Route path={appRoutes.PROFILE_WIDGET} component={ProfileWidget} />
                    <Route path={appRoutes.PROFILE_PARTNERSHIP} component={ProfilePartnership} />
                    <Route path={appRoutes.PROFILE_STREAM} component={StreamPage} />
                    <Route path={appRoutes.PROFILE_STREAMS} component={ProfileStreams} />
                </Switch>
            </div>
        </div>
    )
}

Profile.propTypes = {

}

export default Profile
