import React from 'react'
import PropTypes from 'prop-types'
import { trans } from 'utils/translator';
import { Link } from 'react-router-dom';

const Navigation = props => {
    const { t } = props;
    return (
        <nav className='navigation-desktop flex items-center bold t3'>
            <Link to="/streams/">{t('header_nav_streams')}</Link>
            <Link to="/help">{t('header_nav_helpcenter')}</Link>
            <Link to={`/admin/stream/`}>{t('admin_panel')}</Link>
        </nav>
    )
}

Navigation.propTypes = {

}

export default trans(Navigation);
