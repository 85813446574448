import { notify } from "reapop"
import { store } from "libs/redux"

export function notificClick(title, message, status, buttons=[]) {
    
    store.dispatch(notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000,
      buttons
    }))
  }