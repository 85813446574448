import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ModalWindow from 'components/ModalWindow';
import Button from 'components/ds/Button';
import { Link } from 'react-router-dom';
import { trans }  from 'utils/translator';
import _ from 'lodash';

export const QuickDonationModal = trans(({ 
    isOpen,
    onRequestClose,
    onMakeDonation,
    donation,
    onLogIn,
    user,
    t
    }) => {
    const userOrbs = _.get(user, 'model.orbs');
    const { donationValue, action, params } = donation; 
    return (
        <ModalWindow show={isOpen} hideModal={onRequestClose}>
            <div className='ds-quick-donation'>
                <h5 className="ds-quick-donation__header">{t('quick_donation_header')}</h5>
                <p className="ds-quick-donation__message">{t('quick_donation_message')}
                </p>
                <div className="ds-quick-donation__action-area">
                   <Button appearance="secondary" onClick={() => onMakeDonation(donationValue, action, params)}>Make Donation</Button>
                   <Button appearance="primary" onClick={onLogIn}>Log In</Button>
                </div>
            </div>
        </ModalWindow>
    )
})

QuickDonationModal.propTypes = {
    isOpen: PropTypes.func,
    onRequestClose: PropTypes.func,
    onMakeDonation: PropTypes.func.isRequired,
    onLogIn: PropTypes.func,
}



