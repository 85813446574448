import React from "react"
import { trans } from "utils/translator"
import { Link, withRouter } from "react-router-dom"
import i18next, { languages } from "i18n"
import CategoryItem from './CategoryItem';

const MainPage = props => {
  const { categories, popular, t } = props;
    return (
      <div className='p2 bg-white black'>
        <ul className='flex justify-around py4 px6'>
          {
            Array.isArray(categories) && categories.map(c => <CategoryItem 
              name={c.title}
              image={c.title}
              link={c.title}
            />)
          }
        </ul>
        <h1 className="h1 bold mb4">
          {t('hc_popular_articles')}
        </h1>
        <ul className="flex flex-wrap">
          {
           Array.isArray(popular) && popular.map(a => {
              return <Link className='col-4 mb2' to={`/help/view/article/${a.id}`}>{a.name}</Link>
            })}
        </ul>
      </div>
    )
  }

export default withRouter(trans(MainPage));