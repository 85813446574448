import React, { Fragment, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo';

import Button from 'components/_Button';
import Navigation from './Navigation';

import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import HeaderMenu from './HeaderMenu';

import { trans } from 'utils/translator';


const Header = props => {
    const { ModalActions, isAuthenticated, user, t } = props;
    const [ showMenu, setShowMenu ] = useState(false);
    const ref = React.createRef();
   
    return (
        <div className='header-desktop flex px5 py4 justify-between white'>
            <div className="header-desktop__left flex items-center">
                <Logo className='mr3' />
                <Navigation />
            </div>
            <div className="header-desktop__right flex items-center">
            {
                isAuthenticated ? <Avatar onClick={() => setShowMenu(!showMenu)} image={user.avatar}/> :  
                <Fragment><Button 
                view='ghost' 
                theme='purple' 
                className='mr3'
                onClick={() => ModalActions.showModal({
                    modalType: 'auth',
                })}
                >{t('_header_1')}</Button>
            <Link to='#' className='bold' onClick={() => ModalActions.showModal({
                modalType: 'auth',
            })}>{t('_header_2')}</Link></Fragment>
            }
            {
                showMenu ? <HeaderMenu ref={ref} user={user} setShowMenu={setShowMenu}/> : ''
            }
            </div>
        </div>
    )
}

Header.propTypes = {

}

export default trans(Header);
