import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import { Switch, Route, Link, withRouter } from "react-router-dom"
import * as ArticleAPI from "../../api/ArticleAPI"

class Article extends Component {
  constructor(props) {
    super(props)
    const { user, article } = this.props
    this.state = {
    
      hasEditPermission: false,
      article,
    }
  }
  componentDidMount() {
    const { props, state } = this
    const { match } = props
    const articleId = match.params.article

    let isAdmin = false
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()

    if (user && user.permissions)
      isAdmin = user.permissions.includes("SUPER")

    const hasEditPermission = isAdmin

    this.setState({
      hasEditPermission
    })

    if (articleId) {
      ArticleAPI.getArticles((articles) => {
        this.setState({
          article: articles[0],
        })
      }, { id: articleId })
    }
  }
  render() {
    const { state, props } = this
    const { hasEditPermission } = state
    const { t, user } = props
    const article = (props.location.state && props.location.state.article)
      || props.article
      || state.article
    return (
      <div>
        {
          article ?
            <React.Fragment>
              <a id='article' style={{
                position: 'relative',
                top: '-100px',
                display: 'block',
              }}></a>
              <article className='help-center__article'>

                <div style={{
                  display: "flex",
                }}>
                  <h1 className="help-center__subheader">{article.title}</h1>
                  {
                    hasEditPermission ? <Link to={{
                      pathname: `/test-editor/${article.id}`, state: {
                        article,
                        isEdit: true
                      }
                    }}>{t('hc_edit')}</Link> : null
                  }
                </div>
                <div className="help-center__article-content" dangerouslySetInnerHTML={{
                  __html: article.html
                }}
                />
              </article></ React.Fragment> : null
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(trans(connect(mapStateToProps, mapDispatchToProps)(Article)))