import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import logger from "redux-logger"
import rootReducer from "../reducers"
import { middleware } from "./router"

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, middleware, logger)
)

if (module.hot) {
  module.hot.accept("../reducers", () => {
    const nextRootReducer = require("../reducers")
    store.replaceReducer(nextRootReducer)
  })
}
