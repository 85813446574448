import * as gameslist from "reducers/bettyGames"
import * as eventslist from "reducers/bettyEvents"
import * as BettyAPI from "api/BettyAPI"
// import * as teamList from "reducers/bettyTeamList"

export function getGamesList() {
  return (dispatch) => {
    dispatch(gameslist.getGameItem())
  }
}


export function setNewList(streamlist) {
  return (dispatch) => {
    dispatch(gameslist.setNewList(streamlist))
  }
}

export const get = callback => (dispatch) => {
  BettyAPI.get((error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(gameslist.setNewList(list))
    if (callback) { callback(null, list) }
  })
}

export const events = callback => (dispatch) => {
  BettyAPI.events((error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(eventslist.setNewList(list.reverse()))
    if (callback) { callback(null, list.reverse()) }
  })
}

// export const getFifaTeams = callback => (dispatch) => {
//   BettyAPI.getFifaTeams((error, list) => {
//     if (error) {
//       if (callback) { callback(error) }
//       return
//     }
//     dispatch(teamList.setNewList(list))
//     if (callback) { callback(null, list) }
//   })
// }

export const bets = callback => (dispatch) => {
  BettyAPI.bets((error, list) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(eventslist.setBets(list))
    if (callback) { callback(null, list) }
  })
}

export function updates(callback) {
  return (dispatch) => {
    BettyAPI.updates((item) => {
      dispatch(eventslist.setNewList(item))
      if (callback) callback(item)
    })
  }
}


export const defaultGames = () => (dispatch) => {
  return {
    title: "Loading...",
    id: 0
  }
}
