import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/_Button';
import TaskCard from './TaskCard';

import { history } from 'libs/router';
import { appRoutes } from 'utils/config';
import { trans } from 'utils/translator';


import landingDesctop1 from 'assets/images/main/Landing/landing-desktop-1.png';
import landingDesctop2 from 'assets/images/main/Landing/landing-desktop-2.png';
import landingDesctop3 from 'assets/images/main/Landing/landing-desktop-3.svg';

// TODO optimize 
const Landing = props => {
    const { ModalActions, t } = props;
    return (
        <Fragment>
            <div className='landing-desktop flex flex-column'>
                <section className="landing-desktop__section-1 white flex p4">
                    <div className="col-6">
                        <h1 className="h1 mb4 bold">{t('_landing_main_header')}</h1>
                        <p className='mb4 t1'>
                            {t('_landing_main_header_1')}
                        </p>
                        <a href="#advertisments" className='dashed t4' >
                            {t('_landing_main_header_2')}
                        </a>
                    </div>
                    <div className="col-6 flex items-start justify-end">
                        <img className='landing-desktop__image-1 mr6' src={landingDesctop1} alt="" />
                    </div>
                </section>
                <section className="landing-desktop__section-2 black flex p4">
                    <div className="col-6">
                        <img className='landing-desktop__image-2' src={landingDesctop2} alt="" />
                    </div>
                    <div className="col-6">
                        <h2 className="h1 mb4 bold left-align">
                            {t('_landing_how_it_works')}
                        </h2>
                        <p className='mb2 t1'>
                            {t('_landing_how_it_works_1')}
                            &nbsp;
                            <span className='italic gray'>
                                {t('_landing_how_it_works_2')}
                            </span>
                        </p>
                        <p className='mb2 t1'>
                            {t('_landing_how_it_works_3')}
                            &nbsp;
                            <Link className='black dashed' to='/help'>
                                {t('_landing_how_it_works_4')}
                            </Link>
                        </p>
                        <p className='mb4 t1'>
                            {t('_landing_how_it_works_5')}
                        </p>
                        <Button theme='purple' onClick={() => ModalActions.showModal({ modalType: 'auth' })} className='mb4'>
                            {t('_landing_how_it_works_6')}
                        </Button>
                    </div>
                </section>
                <section id='advertisments' className="landing-desktop__section-3 black flex p4">
                    <div className="col-6">
                        <h2 className="h1 mb4 ">{t('_landing_advertiser_info')}</h2>
                        <p className='mb2 t1'>{t('_landing_advertiser_info_1')}</p>
                        <p className='mb2 t1'>{t('_landing_advertiser_info_2')}</p>
                        <p className='mb2 t1'>{t('_landing_advertiser_info_3')}</p>
                        <p className='mb2 t1'>{t('_landing_advertiser_info_4')}</p>
                        <p className="mb2 t1">{t('_landing_advertiser_info_5')}
                            <span className='italic'>{t('_landing_advertiser_info_6')}</span>
                            {t('_landing_advertiser_info_7')}
                        </p>
                    </div>
                    <div className="relative col-6">
                        <TaskCard
                            className='landing-desktop__task-card-2'
                            description={t('_landing_task-card_2')}
                        />
                        <TaskCard
                            className='landing-desktop__task-card-3'
                            description={t('_landing_task-card_3')}
                        />
                    </div>
                </section>
                <section className="landing-desktop__section-4 white flex p4">
                    <div className="col-6">
                        <h2 className="h1 mb4">{t('_landing_under_development')}</h2>
                        <p className='mb2 t1'>
                            {t('_landing_under_development_1')}
                            <Link className='white' to='/main'> {t('_landing_under_development_2')}</Link>
                        </p>
                        <p className='mb4 t1'> {t('_landing_under_development_3')}
                            &nbsp;
                            <span className='italic' onClick={() => ModalActions.showModal({
                                modalType: 'feedback'
                            })}>{t('_landing_under_development_4')}
                            </span>
                            &nbsp;
                            {t('_landing_under_development_5')}
                        </p>
                        <Button onClick={() => history.push(appRoutes.STREAMS)} view='ghost' theme='purple'>
                            {t('_landing_under_development_6')}
                        </Button>
                    </div>
                    <div className="flex items-center justify-center col-6">
                        <img src={landingDesctop3} alt="img" className='landing-desktop__image-3' />
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

Landing.propTypes = {

}

export default trans(Landing);
