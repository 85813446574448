import * as TemplatePaidAction from "reducers/templatePaidAction"
import * as TemplatePaidActionAPI from "api/TemplatePaidActionAPI"


export function patch(paidActionId, paidActionBody) {
  return (dispatch) => {
      TemplatePaidActionAPI.patch(paidActionId, paidActionBody, (err, paidAction) => {
      dispatch(TemplatePaidAction.update(paidAction))
    })
  }
}

export function setTemplates(list) {
  return (dispatch) => {
    dispatch(TemplatePaidAction.setTemplates(list))
  }
}

export const create = (template, callback) => (dispatch) => {
  TemplatePaidActionAPI.create(template, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    // dispatch(TemplatePaidAction.add(template))
    if (callback != null) {
      callback(null, template)
    }
  })
}

export const remove = (id, callback) => (dispatch) => {
  TemplatePaidActionAPI.remove(id, (error, template) => {
    if (error) {
      if (callback) { callback(error) }
      return
    }
    dispatch(TemplatePaidAction.remove(id))
    if (callback != null) {
      callback(null, template)
    }
  })
}

export const get = (title, stream) => (dispatch) => {
  TemplatePaidActionAPI.get(title, stream, (error, templates) => {
    if (error) {
      console.error(error)
      return
    }
    dispatch(TemplatePaidAction.setTemplates(templates))
  })
}

export function updated(callback, admin, stream) {
  return (dispatch) => {
    TemplatePaidActionAPI.updated((list) => {
      dispatch(TemplatePaidAction.setTemplates(list))
      if (callback) callback(list)
    }, admin, stream)
  }
}