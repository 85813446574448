import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';
export default class Tabs extends Component {
  static propTypes = {
    prop: PropTypes
  }
  constructor(props) {
    super(props)

    this.tabs = this.getTabsFromProps(props)
    this.state = {
      selectedIndex: this.getSelectedTabFromChildProps(this.tabs)
    }
  }
  static Tab = (props) => <div className="">{props.children}</div>
  getTabsFromProps(props) {
    return React.Children.toArray(props.children)
  }

  componentWillReceiveProps(newProps) {
    this.tabs = this.getTabsFromProps(newProps)
    this.setState({
      selectedIndex: this.getSelectedTabFromChildProps(this.tabs)
    })
  }

  getSelectedTabFromChildProps(tabs) {
    const { selected } = this.props
    if (selected) return selected

    for (let index = 0; index < tabs.length; index++) {
      if (tabs[index].props.selected) return index
    }
    return 0
  }

  changeTab = (nextIndex) => {
    const currentIndex = this.props.selected

    if (currentIndex !== nextIndex) {
      this.props.onSelect(nextIndex)
    }
  }

  handleTabLinkKeypress(e, index, tabsId, tabsLength) {
    if (e.key === 'Enter' || e.key === ' ') {
      this.changeTab(index)
      return
    }

    if (!(e.key === 'ArrowLeft' || e.key === 'ArrowRight')) return

    const firstTabIndex = 0
    const lastTabIndex = tabsLength - 1

    let nextPosition = e.key === 'ArrowLeft' ? index - 1 : index + 1
    if (nextPosition > lastTabIndex) nextPosition = firstTabIndex
    if (nextPosition < firstTabIndex) nextPosition = lastTabIndex

    this.changeFocusedTab(tabsId, nextPosition)
  }

  changeFocusedTab(tabsId, index) {
    const tab = document.querySelector(`#${tabsId}-${index}`)
    tab.focus()
  }
  render() {
    const uniqueTabPrefix = `tabs${this.props.id || 'erter'}`
    const { selected: selectedIndex, onSelect, rightAddon } = this.props
    return (
      <Fragment>
        <ul className='ds-tabs__tab-list flex items-center' 
          role="tablist" >
          {
            this.tabs.map((tab, index) => {
              const id = `${uniqueTabPrefix}-${index}`
              const tabIsSelected = selectedIndex === index
          
              return (
                <li className={`ds-tabs__list-item ${tab.props.isDisabled ? 'disabled' : ''}`} classNamekey={id} >
                  <button
                    className={cn(
                      'ds-tabs__button',
                      'px4', 'py2', 'center',
                      {
                        'bold': tabIsSelected,
                        'ds-tabs__button--selected': tabIsSelected
                      })}
                    type="button"
                    id={id}
                    tabIndex={tabIsSelected ? '0' : '-1'}
                    onClick={() => this.changeTab(index)}
                    onKeyDown={e =>
                      this.handleTabLinkKeypress(e, index, uniqueTabPrefix, this.tabs.length)
                    }
                  >
                    {tab.props.label}
                  </button>
                </li>
              )
            })}
          <div className="ds-tabs__left-addon">
            { rightAddon }
          </div>
        </ul>
        {this.tabs[selectedIndex] &&
          React.cloneElement(this.tabs[selectedIndex], {
            role: 'tabpanel',
            id: `${uniqueTabPrefix}-${selectedIndex}-tab`,
            'aria-labelledby': `${uniqueTabPrefix}-${selectedIndex}`,
          })}
      </Fragment>
    )
  }

}
