import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"

import StubPage from "./StubPage"

class Role extends Component {

  static permissions(role) { 
      const permissionByRole = {
          ADMIN: ['SUPER'], 
          SUPER_MODERATOR: ['SUPER_ACTIVITY'],
          MODERATOR: ['ACTIVITY'],
          STREAMER: ['ACTIVITY', 'STREAM'],
          USER: []
      }
      return permissionByRole[role]
   }


  constructor(props) {
    super(props)
    // const { props: { roles, user: { model: { permissions } } }, checkPermissions } = this
    const { roles, user: { model: { permissions } } } = this.props;
    const hasValidPermissions = roles.some(role => this.checkPermissions(permissions, Role.permissions(role)));
    this.state = { hasValidPermissions };
  }

checkPermissions = (userPermissions=[], requiredPermissions=[]) => {
    return requiredPermissions.every(permission => userPermissions.includes(permission))
}
 render() {
 const { children, stub='' }  = this.props;
 const { hasValidPermissions } = this.state;
 return (<Fragment>
    {
        hasValidPermissions ?  
        <Fragment>{ children } </Fragment>
         : stub
    }
    </Fragment>)
 }
}

function mapStateToProps(state) {
    return {
      user: state.user
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {}
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Role)
  