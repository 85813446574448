import React, { useState, useRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

const Select = props => {
    const { items, onChange } = props;
    console.log('slected', props.selected);
    let selected = props.selected >=0 ? props.selected : 0;
    const [isOpen, setOpen] = useState(false);
    const wrapperRef = useRef(null);
    const handleClick = (e) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            setOpen(false);
        }
    } 
    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
        document.removeEventListener('mousedown', handleClick);
        }
      }, []);
  
    return (
        <div ref={wrapperRef}>
            <button className='select__button' onClick={() => setOpen(true)}>
                <span className=''>
                    {items[selected].label}
                </span>
            </button>
            {
                isOpen ? <ul className="select__content">
                {
                    items.map((item, index) => <li className='select__item' onClick={() => {
                        onChange(index);
                        setOpen(false);
                    }}>{item.label}</li>)
                }
                </ul> : null
            }
           
        </div>
    )
}

Select.propTypes = {

}

export default Select
