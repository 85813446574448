import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';

const Checkbox = props => {
    const { name, className, value, onChange, label } = props;
    return (
        <div className={className}>
            <input
              className={cn('checkbox__input')}
              name='is18'
              type='checkbox'
              checked={value}
            />
            <label onClick={e => onChange(e)} className='block checkbox__label' htmlFor={name} >
                {label}
            </label>
        </div>
    )
}

Checkbox.propTypes = {

}

export default Checkbox
