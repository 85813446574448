import React, { Component } from "react"

import { groupBy } from "lodash"

import PaidActionNew from "./ActivitiesNew/PaidAction"
import PaidActionTemplate from "./ActivitiesNew/PaidActionTemplate"
import { defaultPaidAction } from "./DefaultPredictions"
import { trans } from 'utils/translator';
class PaidActionsBlock extends Component {
  constructor(props) {
    super(props)
  }

  renderList = (paidActions = []) => {
    const { props: { PaidActionActions, PaidActionTemplateActions } } = this
    return paidActions.map((item, i) => {
      const isTemplate = !item.customer
      return isTemplate ? <PaidActionTemplate key={`paidaction_${item.id}+${item.createdAt}`} {...{ paidAction: item, PaidActionActions, PaidActionTemplateActions }} />
        : <PaidActionNew key={`paidaction_${item.id}+${item.createdAt}`} paidAction={item} PaidActionActions={PaidActionActions} />
    })
  }


  render() {
    const { props: { stream: { id }, paidActions = [], paidActionTemplates, PaidActionTemplateActions: { create } }, renderList } = this
    const filteredPaidActions = paidActions.filter(c => c.stream == id);
    const paidActionsByStatus = groupBy(filteredPaidActions, "status");
    const filteredTmls = paidActionTemplates.filter(t => t.stream == id);
    const tableData = { ORDERED: [...paidActionsByStatus.null || [], ...paidActionsByStatus.undefined || []], ADD_NEW: filteredTmls }
    const columnOrder = ["ORDERED", "ADD_NEW"]
    const { t } = this.props
    const cols = [{
      value: 'ORDERED', 
      label: t('admin_ordered')
    }, 
    {
      value: 'ADD_NEW', 
      label: t('admin_add_new')
    }
  ]
    return (
      <div className="row-container">
        {
          cols
          .map((column, i) => {
            return (<div className="activity-admin__activity-block"> 
              <h2 className="text head head_center activity-admin__header">{column.label}</h2>
              <div key={i} className='col-container col-container_center'>
              {
                renderList(tableData[column.value])
              }
               {
                column.value == 'ADD_NEW' ? <div onClick={() => create(defaultPaidAction(id))} class="plus__icon" /> : null
              }
              </div>
            </div>)
          })
        }
      </div>
    )
  }
}

export default trans(PaidActionsBlock)
