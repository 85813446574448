import React, { Component } from "react"

export default class AdminUserSearchForm extends Component {
  constructor(props) {
    super(props)

    let name, 
      email, 
      permissions,
      id = ""

    this.state = {
      queue: {
        name: '',
        email: '',
        permissions: '',
        id: '',
      }
    }

    this.inputDataChange = this.inputDataChange.bind(this)
  }

  render() {
    const { props, state } = this
    const { search } = props
    const { queue } = state

    const { name, email, permissions, id } = queue

    return (
      <table className="predictionTemplate">
        <thead>
          <tr>
            <th width="27%">param:</th>
            <th width="73%">field:</th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>id:</td>
            <td><input type="text" id="id" defaultValue={id} onChange={this.inputDataChange.bind(this)} /></td>
          </tr>
          <tr>
            <td>name:</td>
            <td><input type="text" id="name" defaultValue={name} onChange={this.inputDataChange.bind(this)} /></td>
          </tr>

          <tr>
            <td>email:</td>
            <td><input type="text" id="email" defaultValue={email} onChange={this.inputDataChange.bind(this)} /></td>
          </tr>

          <tr>
            <td>permissions:</td>
            <td><input type="text" id="permissions" defaultValue={permissions} onChange={this.inputDataChange.bind(this)} /></td>
          </tr>
       

          <tr>
            <td>actions:</td>
            <td>
              <button onClick={() => search(this.state.queue)}>search</button>
            </td>
          </tr>

        </tbody>
      </table>
    )
  }

  inputDataChange(e) {
    const { props, state } = this
    const { search } = props
    const { queue } = state

    queue[e.target.id] = e.target.value

    // realtime search
    if (e.target.value.length > 3) { search(queue) }
  }
}
