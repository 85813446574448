import collectionReducers from "utils/collectionReducers"

const NAMESPACE = "CHALLENGES"
export default (state = [], action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function set(challenges) {
  const copyChallenges = challenges.map(item => Object.assign({}, item))
  return {
    namespace: NAMESPACE,
    type: "SET_CHALLENGE_LIST",
    handle: (state) => { return { ...state, challenges: copyChallenges } }
  }
}

export function update(chal) {
  
  function _update(challenges) {
    // получаем индекс ставки в этом стриме
    const index = challenges.findIndex(item => item.id === chal.id)

    // заменяем/добавляем
    if (index != -1) {
      challenges[index] = chal
    } else {
      challenges.push(chal)
    }

    return challenges
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_CHALLENGES",
    handle: (state) => {
      const newlist = _update(state.challenges)
      const copyNewlist = newlist.map(item => Object.assign({}, item))
      return { ...state, challenges: copyNewlist }
    }
  }
}
  
  export function add(challenge) {
    return {
      namespace: NAMESPACE,
      type: "ADD_CHALLENGE",
      handle: (state) => {
        const c = state.challenges;
        c.push(challenge)
        return Object.assign({},
           state,
           {
           challenges: 
             c
          }
             )
    }
  }
  }
  