import React, { Fragment } from "react"
import DatePicker from  "react-datepicker"
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css"

import StatsControl from "./StatsControl"
import Role from 'components/Common/Role'

function AdminStats({ predictions, query, setQuery, setDate, resultMeta, searchStats }) {
  return (
    <Role roles={['ADMIN']}>
    <div>
      <div className="admin-stats__filter-wrap">
        <div className="admin-stats__filter-item-wrap">
          Win Outcome
          <select
            onChange={e => setQuery("winOutcome", e.target.value)}
            value={query.winOutcome}
          >
            <option value="" />
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="null">null</option>
          </select>
        </div>

        <div className="admin-stats__filter-item-wrap">
          Mode
          <select
            onChange={e => setQuery("mode", e.target.value)}
            value={query.mode}
          >
            <option value="" />
            <option value="LIVE_MODE">LIVE_MODE</option>
            <option value="FUTURE_MODE">FUTURE_MODE</option>
            <option value="CHALLENGE_MODE">CHALLENGE_MODE</option>
          </select>
        </div>
        <div className="admin-stats__filter-item-wrap">
          User
          <input type="text" value={query.user} onChange={e => setQuery("user", e.target.value)} />
        </div>
        <div className="admin-stats__filter-item-wrap">
          After
          <input
            type="date"
            value={query.after || null}
            onChange={e => setDate("after", e.currentTarget.value)}
          />
        </div>
        <div className="admin-stats__filter-item-wrap">
          Before
          <input 
            type="date"
            value={query.before || null}
            onChange={e => setDate("before", e.target.value)}
          />
        </div>
        <div
          className="admin-stats__search-button"
          role="navigation"
          onClick={() => setQuery("page", 0, searchStats)}
        >
          Search
        </div>
      </div>
      <div className="admin-stats__table-wrap">
        {!predictions || predictions.length === 0
          ? "There is no data to display"
          : (
            <Fragment>
              {/* TABLE CONTROL */}
              <StatsControl
                resultMeta={resultMeta}
                query={query}
                setQuery={setQuery}
                searchStats={searchStats}
              />
              {/* TABLE */}
              <table className="admin-stats__table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>stream</th>
                    <th>finishedAt</th>
                    <th>createdAt</th>
                    <th>title</th>
                    <th>winOutcome</th>
                    <th>bank</th>
                  </tr>
                </thead>
                <tbody>
                  {predictions.map(({ stream, finishedAt, createdAt, title, winOutcome, bank }, index) => (
                    <tr key={`${stream}${createdAt}${finishedAt}`}>
                      <td className="admin-stats__table-item">
                        {(resultMeta.limit * (resultMeta.page - 1)) + index + 1}
                      </td>
                      <td className="admin-stats__table-item">{stream}</td>
                      <td style={{ whiteSpace: "nowrap" }} className="admin-stats__table-item">
                        {createdAt && createdAt.slice(0, -14)}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }} className="admin-stats__table-item">
                        {finishedAt && finishedAt.slice(0, -14)}
                      </td>
                      <td className="admin-stats__table-item">{title}</td>
                      <td className="admin-stats__table-item">{winOutcome === null ? "null" : winOutcome}</td>
                      <td className="admin-stats__table-item">{bank}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* TABLE CONTROL */}
              <StatsControl
                resultMeta={resultMeta}
                query={query}
                setQuery={setQuery}
                searchStats={searchStats}
              />
            </Fragment>
          )}
      </div>
    </div>
  </Role>)
}

export default AdminStats
