import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import { Switch, Route, Link, withRouter } from "react-router-dom"
import { notify } from "reapop"
import { Editor } from '@tinymce/tinymce-react';

import * as _ from "lodash"
import CategoryNode from "./CategoryNode"

import * as StreamActions from "actions/StreamActions"
import * as UserActions from "actions/UserActions"
import * as ThemesActions from "actions/ThemesActions"

import * as ArticleAPI from "../../api/ArticleAPI"

class ArticleManage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: '',
      parent: 1,
      title: 'New Category',
      selectedLang: this.props.article.language || "ru",
      isCategoriesBlockOpen: false,
      editCategory: '',
      newCategoryName: '',
      newCategoryParent: '',
      locales: {
        ru: 2,
        en: 3, 
        kr: 3,
        es: 4, 
        ch: 5
      }
    }
  }
  componentDidMount(){
    const {selectedLang, locales} =  this.state
    this.setState({
      parent: locales[selectedLang]
    })
  
  }
  componentWillReceiveProps(){
    const tree = this.transformFlatToTree(this.props.categories)
  }
 
  handleCategoryRename = (e) => {
    this.setState({
      newCategoryName: e.target.value
    })
  }
  handleParentCategoryChange = (e) => {
    this.setState({
      newCategoryParent: e.target.value
    })
  }
  selectLang = lang => () => {
    const {locales} =  this.state
    this.setState({
     selectedLang: lang,
     parent: locales[lang],
   })
  }
  handleCategoryParentChange = (e) => {
    this.setState({
      parent: +e.target.value,
    })
  }
  handleCategoryNameChange = (e) => {
    this.setState({
      title: e.target.value
    })
  } 
  showCategoriesBlock = (e) => {
    e.preventDefault()
    this.setState(prevState => ({
      isCategoriesBlockOpen: !prevState.isCategoriesBlockOpen
    }))
  }
  showTagsBlock = (e) => {
    e.preventDefault()
    this.setState(prevState => ({
      isTagsBlockOpen: !prevState.isTagsBlockOpen
    }))
  }
  showAllTags = (e) => {
    e.preventDefault()
    alert('Dont implemented yet!');
  }

    deleteCategory = id => () => {
      const { handleDeleteCategory } = this.props

      ArticleAPI.deleteArticle(() => {
        alert('Category deleted')
        handleDeleteCategory(id)
      }, id)
    }
    editCategory = (id, name, parent) => () => {
      this.setState( prevState => ({
        editCategory: prevState.editCategory == id ? '' : id,
        newCategoryName: name,
        newCategoryParent: parent
      }))
    }
    saveCategory = (category) => () => {
      const { handleEditCategory } =  this.props
      const { locales, selectLang } = this.state
      this.setState({
        editCategory: ''
      })
      category.title = this.state.newCategoryName
      category.parent = this.state.newCategoryParent || locales[selectLang]

      ArticleAPI.editArticle((editedCategory) => {
        alert('saved')
        handleEditCategory(editedCategory)
      }, category)
    }

  
    transformFlatToTree = (flatArray) => {
      if(!flatArray.length) return []
    let groupedByParents = _.groupBy(flatArray, 'parent');
    let catsById = _.keyBy(flatArray, 'id');
    
    _.each(_.omit(groupedByParents, ['', null]), function(children, parentId) {
        catsById[parentId].children = children; 
    });
    _.each(catsById, function(cat) {
      cat.isParent = !_.isEmpty(cat.children); 
      cat.children = _.compact(_.union(cat.children, cat.posts));
  });
    return groupedByParents[null];
    }

    addCategory = (e) => {
      const { title, parent } = this.state
      const { categories, handleAddCategory } = this.props

      const language = categories.find(c => c.id == parent).language
      const siblingLength = categories.filter(c => c.parent == parent).length

      ArticleAPI.addArticle((category) => {
        alert('Category added')
        handleAddCategory(category)
      }, {
        title,
        type: 'category',
        parent,
        language,
        position: siblingLength
      })
    }
    onToggle = (node, toggled) => {
      if(this.state.cursor){this.state.cursor.active = false;}
      node.active = true;
      if(node.children){ node.toggled = toggled; }
      this.setState({ cursor: node });
  }
    render() {
      const { props, state } = this
      const { isCategoriesBlockOpen, isTagsBlockOpen, editCategory, newCategoryName, parent } = state
    const {t, categories, article} = props
    const groups = _.groupBy(categories, 'language')
    const tree = this.transformFlatToTree(categories || []) || []
    return (    
      <div className='post-editor__article-manage'>
      <div className="post-editor__category-manage">
      <h3 className="post-editor__subheader">{t('pe_category-manage')}</h3>
      <div className="post-editor__langs">
      <span style={this.state.selectedLang == 'ru' ? {
        backgroundColor: "#1b1b1b"
      }: null} onClick={this.selectLang('ru')}>RU</span>
      <span style={this.state.selectedLang == 'en' ? {
        backgroundColor: "#1b1b1b"
      }: null} onClick={this.selectLang('en')}>EN</span>
        <span style={this.state.selectedLang == 'kr' ? {
        backgroundColor: "#1b1b1b"
      }: null} onClick={this.selectLang('kr')}>KR</span>
      <span style={this.state.selectedLang == 'es' ? {
        backgroundColor: "#1b1b1b"
      }: null} onClick={this.selectLang('es')}>ES</span>
      <span style={this.state.selectedLang == 'ch' ? {
        backgroundColor: "#1b1b1b"
      }: null} onClick={this.selectLang('ch')}>CH</span>
    
      </div>
      <CategoryNode {...this.props} handleParentCategoryChange={this.handleParentCategoryChange} newCategoryParent={this.state.newCategoryParent} newCategoryName={newCategoryName} handleCategoryRename={this.handleCategoryRename} isEditCategory={editCategory} deleteCategory = {this.deleteCategory} saveCategory = {this.saveCategory} editCategory = {this.editCategory} selectCategory={this.props.selectCategory} level={0} children={tree[0] && tree[0].children || []} />
      {/* <div className="post-editor__categories">
      {
        Array.isArray(groups[this.state.selectedLang]) && groups[this.state.selectedLang].map((category, i) =>  <div><div style={{
          display: "flex",
        }}><label>
          <input
            type="checkbox"
            style={{ position: "relative", left: 0, outline: "initial" }}
            onChange={this.props.selectCategory(category.id)}
            checked={this.props.selectedCategory == category.id}
          /> {editCategory == category.id ? <div style={{display: "inline"}}><input value={newCategoryName} onChange={(e) => this.setState({
            newCategoryName: e.target.value
          })}/></div> : category.title}
</label>
{
editCategory == category.id ? 
<div className="post-editor__save-button" onClick={this.saveCategory(category)}/> :
<div className="post-editor__edit-button" onClick={this.editCategory(category.id, category.title)}/>
}
<div className="post-editor__close-button" onClick={this.deleteCategory(category.id)}/>
</div>
{ 
  editCategory == category.id ? 
<select onChange={(e) => this.setState({newCategoryParent: e.target.value}) } name="" id="" className="post-editor__select-parent" >
       <option value="" disabled selected>{t('pe_parent_category')}</option>
       {
       categories.map((category, i) =>  
          <option
            value={category.id}
          > {category.title} </option>
)
       }
       </select> : null
}
</div>)
      }
     </div> */}
     <div className="post-editor__add-category">
     <a href="" onClick = {this.showCategoriesBlock} className="post-editor__add-category-link">{t('pe_add_category')} 	&#9662;</a>
    
     {
       isCategoriesBlockOpen ?  <div style={{ display: "flex", flexDirection: "column"}}>
      <input onChange={this.handleCategoryNameChange} type="text" className="post-editor__category-name" placeholder={t('pe_add_category_name')}/>  
       <select onChange={this.handleCategoryParentChange} value={parent} name="" id="" className="post-editor__select-parent" >
       <option value="" disabled selected>{t('pe_parent_category')}</option>
       {
       categories.map((category, i) =>  
          <option
            value={category.id}
          > {category.title} </option>
)
       }
       </select>
       <button onClick={this.addCategory} className="post-editor__add-category-button">{t('pe_add_category')}</button>     
       </div> : null
     
     }
     </div>
   
      </div>
      <div className="post-editor__tags-manage">
      <a href="" onClick = {this.showTagsBlock} className="post-editor__add-category-link">{t('pe_add_tags')} &#9662;</a>
      { isTagsBlockOpen ?
      <div>
      <div>
      <input type="text" className="post-editor__tag-name" onChange={(e) => this.setState({
        tags: e.target.value,
      })} placeholder={t('type_tags_here')}/>
      <button onClick={this.props.addTags(this.state.tags)} className="post-editor__add-tags">{t('pe_add_tags')}</button>
      </div>
      <p className="post-editor__tip">{t('pe_tags-tip')}</p>
      <div className="post-editor__tags">
      {
        article.tags.map(tag => {
          return <span>{tag}</span>
        })
      }
      </div>
      
      <a href="" onClick={this.showAllTags} className="post-editor__show-all-tags">{t('pe_show_all_tags')}</a>
      </div> : null
      }
      </div> 
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    ThemesActions: bindActionCreators(ThemesActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default withRouter(trans(connect(mapStateToProps, mapDispatchToProps)(ArticleManage)))