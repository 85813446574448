import React, { Fragment, Component } from "react"
import FormValidator from "components/Common/FormValidator"
import Timer from "../AdminPrediction/AdminPredictionTimerComponent"
import { trans } from 'utils/translator';
import {val_gte} from 'utils/validator';

class ChallengeNew extends FormValidator{

  static get waitingForModeration() { return "WAITING_FOR_MODERATION" }
  static get waitingForAcception() { return "WAITING_FOR_ACCEPTION" }
  static get accepted() { return "ACCEPTED" }
  static get failed() { return "FAILED" }
  static get done() { return "DONE" }
  static get closed() { return "CLOSED" }

  constructor(props){
    super(props)
    const { challenge: {id, description, acceptDuration, status, ChallengeDonates=[] }} = props
    const stream = props.challenge.stream || props.stream

    const totalBank = ChallengeDonates.reduce((totalBank, donate) => totalBank + donate.value, 0)
    const { t } = this.props
    if(status ==  ChallengeNew.waitingForModeration) {
      console.log(this.props.challenge, "CHALLENGE123")
    } 

   
    let minBankToAccept = this.props.challenge.minBankToAccept;
    if(status === ChallengeNew.waitingForModeration) {
      minBankToAccept = totalBank + 1;
    }


    this.state = {
       form: { 
         description: { value: description },
         minBankToAccept: { value: minBankToAccept || 1, rules: [val_gte(totalBank + 1)] },
         acceptDuration: { value: acceptDuration },
      },
      isCloseActionsVisible: false,
    }
  }
  componentWillReceiveProps(nextProps){
    const totalBank = (nextProps.challenge.ChallengeDonates || []).reduce((totalBank, donate) => totalBank + donate.value, 0)
    const status = nextProps.challenge.status
   
    let minBankToAccept = nextProps.challenge.minBankToAccept;
    if(status === ChallengeNew.waitingForModeration) {
      minBankToAccept = totalBank + 1;
      console.log('totalBank123', totalBank)
      this.setState( prevState => ({
       form: {
         ...prevState.form, 
        minBankToAccept: {value: minBankToAccept, rules: [val_gte(totalBank)]}
       }
      }))
    }
  }
  close = (e) => {
    e.preventDefault()
    this.setState( prevState => ({
      isCloseActionsVisible: !prevState.isCloseActionsVisible
    }))
  }
  fail = (e) => {
    const { props: { challenge: { id }, ChallengeActions }, defaultCallback } = this
    e.preventDefault()
    ChallengeActions.patch( id, { status: 'FAILED' }, defaultCallback)
  }

  done = (e) => {
    const { props: { challenge: { id }, ChallengeActions }, defaultCallback } = this
    e.preventDefault()
    ChallengeActions.patch( id, { status: 'DONE' }, defaultCallback)
  }

  cancel = (e) => {
    const { props: { challenge: { id }, ChallengeActions }, defaultCallback } = this
    e.preventDefault()
    ChallengeActions.patch(id, { status: 'FAILED_ACCEPTION'}, defaultCallback)
  }

  accept = (e) => {
    const { props: { challenge: { id }, ChallengeActions}, defaultCallback }  = this
    e.preventDefault()
    ChallengeActions.patch(id, { status: 'ACCEPTED'}, defaultCallback)
  }

  approve = (e) => {
    const { props: { challenge: { id }, ChallengeActions }, defaultCallback } = this
    const { acceptDuration, minBankToAccept } = this.getFormData()
    e.preventDefault()
    ChallengeActions.patch(id, {
      status: 'WAITING_FOR_ACCEPTION',
      acceptDuration: 720 * 60 * 60,
      minBankToAccept, 
    }, defaultCallback)
  }

  refuse = (e) => {
    const { props: { challenge: { id }, ChallengeActions }, defaultCallback } = this
    e.preventDefault()
    ChallengeActions.patch(id, { status: 'FAILED_MODERATION'}, defaultCallback)
  }

  defaultCallback = (result) => {
    if(result.code){
      alert('Error')
    }
  }

  renderActionBlock = () => {
    const { props: { challenge: { status }}, state: { isCloseActionsVisible }} = this
    const { t } = this.props
    if(status === ChallengeNew.waitingForModeration){
      return (
        <Fragment>
          <button onClick={this.approve} className="adm-new-control-card__btn adm-new-control-card__btn--accent">{t('admin_approve')}</button>
          <button onClick={this.refuse} className="adm-new-control-card__btn adm-new-control-card__btn--blue">{t('admin_refuse')}</button>
        </Fragment>
      )
    }
    else if(status === ChallengeNew.waitingForAcception){
      return (
        <Fragment>
          <button onClick={this.accept} className="adm-new-control-card__btn adm-new-control-card__btn--accent">{t('admin_accept')}</button>
          <button onClick={this.cancel} className="adm-new-control-card__btn adm-new-control-card__btn--blue">{t('admin_cancel')}</button>
        </Fragment>
      )
    }
    else if(status === ChallengeNew.accepted && !isCloseActionsVisible){
      return (
        <Fragment>
          <button onClick={this.close} className="adm-new-control-card__btn adm-new-control-card__btn--accent">{t('admin_close')}</button>
        </Fragment>
      )
    }
    else if(status === ChallengeNew.accepted && isCloseActionsVisible){
      return (
        <Fragment>
          <button onClick={this.done} className="adm-new-control-card__btn adm-new-control-card__btn--accent">{t('admin_done')}</button>
          <button onClick={this.fail} className="adm-new-control-card__btn adm-new-control-card__btn--accent">{t('admin_fail')}</button>
        </Fragment>
      )
    }
  }
  getTimerParams = () => {
    const { props: { challenge: { status, createdAt, forAcceptionAt, acceptDuration, acceptedAt } } } = this
    const { t } = this.props
    if(status === ChallengeNew.waitingForModeration)
      return { desc: t('admin_for_approve'), to: new Date(createdAt).getTime() + 24*60*60*1000, show: 'SECONDS' }
    else if(status === ChallengeNew.waitingForAcception)
      return { 
        desc: t('admin_for_acception'),
        from: new Date(forAcceptionAt).getTime(),
        to: new Date(forAcceptionAt).getTime() + new Date(acceptDuration*1000).getTime(),
        show: 'MINUTES'
      }
    else if(status === ChallengeNew.accepted)
    return {
      from: new Date(acceptedAt).getTime(),
      show: 'SECONDS',
      desc: t('admin_from_start'),
    }
  }
  
  render(){ 
    const { props: { challenge: { description, ChallengeDonates=[], status, displayName } }, getTimerParams, cancel } = this
    const totalBank = ChallengeDonates.reduce((totalBank, donate) => totalBank + donate.value, 0)
    const { minBankToAccept } = this.getFormData()
    console.log('minBank', minBankToAccept)
    return (
        <form className="adm-new-control-card adm-new-control-card--active adm-new-challenge adm-new-challenge-state3">
          <div className="adm-new-control-card__header">
              <span className="adm-new-challenge__time">
              <Timer { ...getTimerParams()  } icon={<i className="icon icon_clock adm-new-challenge__icon"></i>}/>
            </span>
          </div>
          <div className="activity__subheader">
            <p>By {displayName}</p>
          </div>
          <div className="adm-new-control-card__content">
            <div className="adm-new-control-card__desc">
              <p>{description}</p>
            </div>
            <div className='adm-new-challenge__current-balance'>
              <span> {`Balance ${totalBank || 0} of` } </span>
                {
                  status == ChallengeNew.waitingForModeration ? 
                  <input className="adm-new-control-card__input bank-input" {...this.bindInput('minBankToAccept','number', [val_gte(totalBank + 1)])}/>
                  : <span>{minBankToAccept}</span>
                }
              </div>
          </div>
          <div className="adm-new-control-card__actions">
          {
            this.renderActionBlock()
          }
          </div>
        </form>
  );
  }
}
export default trans(ChallengeNew)