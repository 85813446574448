import { connect } from "react-redux"
import { bindActionCreators } from "redux"

export default (Component, mapStateToProps, mapDispatchToProps) => {
  mapStateToProps = Component.mapStateToProps || mapStateToProps
  mapDispatchToProps = Component.mapDispatchToProps || mapDispatchToProps
  const actions = Object.entries(mapDispatchToProps())
  return connect(mapStateToProps, (dispatch) => {
    return actions.reduce((temp, el) => {
      temp[el[0]] = bindActionCreators(el[1], dispatch)
      return temp
    }, {})
  })(Component)
}
