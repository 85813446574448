import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"

import * as UserActions from "actions/UserActions"
import * as StreamActions from "actions/StreamActions"

import UsersList from "components/AdminStreamsPage/AdminUser/AdminUserList"
import AdminUserSearchForm from "components/AdminStreamsPage/AdminUser/AdminUserSearchForm"
import RenderWithPermission from "components/Common/RenderWithPermission"

class AdminStreamsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
    isHelpOpened: false,
    }
  }

  render() {
    const { props } = this
    const { user, users, UserActions } = props
    return (
    <RenderWithPermission requiredPermissions={['SUPER']}>
      <main className="admin-user">
        <div className="container">
          <div>
            <p className="predictionParagraph">Admin Users Container</p>

            <div className="admin-user__search">
              {/* general user block with founded users */}

              <div style={{ width: "100%", overflow: "hidden" }}>
                <AdminUserSearchForm search={UserActions.search} />
               
                <div style={{ margin: "0 5px", width: "418px", display: this.state.isHelpOpened ? 'block' : 'none'  }}>
                  <br />
                  кратенькая инструкция<br /><br />
                  гуглим имя и/или эмейл нужного юзера<br /><br />
                  в результатах - назначаем нужные права доступа<br /><br />
                  p.s.: realtime поиск включается, если в поисковом запросе больше 3х символов<br />
                  P.P.S: ведены роли. <br /> 
                  User - Основний функціонал <br /> 
                  Дозволи: none <br /> 
                  Moderator - Менеджинг активностей дозволених стрімів, <br />
                  Дозволи: ACTIVITY <br />
                  Streamer - Менеджинг своїх стрімів та активностей, <br />
                  Дозволи: ACTIVITY, STREAM <br />
                  Supermoderator - Менеджинг будьяких активностей, <br />
                  Дозволи: SUPER_ACTIVITY <br />
                  Admin - локалізація, юзери, промокоди та все вищевказане. <br />
                  Дозволи: SUPER, SUPER_ACTIVITY <br />
                </div>
                <div className='admin-user__help'
                 style={{
                  cursor: 'pointer',
                }}
                 onClick={
                  () => this.setState(prevState => ({
                    isHelpOpened: !prevState.isHelpOpened
                  }))
                }>{this.state.isHelpOpened ? 'Hide': 'Show'} help</div>
              </div>

              <UsersList
                user={user}
                users={users}
                streams={this.streamlist()}
                UserAction={UserActions}
              />
            </div>
          </div>
        </div>
      </main>
    </RenderWithPermission>
    )
  }

  streamlist() {
    return this.props.streamlist.streamlist.map(s => { 
      return { stream: s.stream, id: s.id } 
      })
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamlist: state.streamList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminStreamsContainer)
