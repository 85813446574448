
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/ds/Button/';
import Tag from 'components/ds/Tag'
import { trans } from 'utils/translator';
import { StackLayout } from 'components/ds/Layouts';

const WidgetCard = ({ title, image, author, tags, description, onInstall, onReadMore, t}) => {
    return (
        <a className='ds-widget-card'>
            <div className="ds-widget-card__img-wrapper">
                <img className='ds-widget-card__img' src={image} alt={title} />
                <div className="ds-widget-card__hover-overlay">
                    <p className="ds-widget-card__desc">{description}</p>
                    <div className="ds-widget-card__action-area">
                        <StackLayout gutter='small'>
                            <Button isDisabled={typeof onReadMore !== 'function'} onClick={() => {
                                if(typeof onReadMore !== 'function') return 
                                onReadMore()
                            }}>{t('widget_card_read_more_btn')}</Button>
                            <Button appearance='accent' isDisabled={typeof onInstall !== 'function'} onClick={ () => {
                                   if(typeof onInstall !== 'function') return 
                                   onInstall()
                                }}>{t('widget_card_install_btn')}</Button>
                        </StackLayout>
                    </div>
                </div>
            </div>
            <div className="ds-widget-card__info-box">
                <p className="ds-widget-card__info-box-title">{title}</p>
                <p className="ds-widget-card__info-box-author">{author}</p>
                <div className="ds-widget-card__info-box-tags">
                    {
                        _.map(tags, tag => <Tag name={tag} />)
                    }
                </div>
            </div>
           
        </a>
    )
}



WidgetCard.defaultProps = {
    title: 'ComingSoon'
}


WidgetCard.propTypes = {
    title: PropTypes.string,
    onInstall: PropTypes.func,
    onReadMore: PropTypes.func,
}

export default trans(WidgetCard)





