import { store } from "libs/redux"
import "whatwg-fetch"

const deleteArticle  = async (callback, id) => {
  const user = store.getState().user.model
  const token = user.token || ""
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)
  
  fetch(`/api/articles/${id}`, {
    method: "DELETE",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
  }

const addArticle  = async (callback, article) => {
const user = store.getState().user.model
const token = user.token || ""
const headers = new Headers()
headers.append("Accept", "application/json") // This one is enough for GET requests
headers.append("Content-Type", "application/json") // This one sends body
headers.append("Authorization", token)

fetch(`/api/articles/`, {
  method: "POST",
  body: JSON.stringify(article),
  headers
})
  .then((response) => {
    return response.json()
  })
  .then((json) => {
    callback(json)
  })
  .catch((error) => {
    // AHHHH! An Error!
    console.log("getPredictionItem error:", error)
  })
}
const editArticle = async (callback, article) => {
  const user = store.getState().user.model
  const token = user.token || ""
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)
  
  fetch(`/api/articles/${article.id}`, {
    method: "PATCH",
    body: JSON.stringify(article),
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
  }
const getArticles  = async (callback, query) => {
  const user = store.getState().user.model
  const token = user.token || ""
  const headers = new Headers()
  const keys = Object.keys(query)


  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)
  
  fetch(`/api/articles?${keys.map(key => `${key}=${query[key]}`)}`, {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      callback(json)
    })
    .catch((error) => {
      // AHHHH! An Error!
      console.log("getPredictionItem error:", error)
    })
  }
const getCategories  = async (callback) => {
    const user = store.getState().user.model
    const token = user.token || ""
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
    
    fetch(`/api/articles/categories`, {
      method: "GET",
      headers
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
    }
    const search  = async (callback, query) => {
      const user = store.getState().user.model
      const token = user.token || ""
      const headers = new Headers()
      headers.append("Accept", "application/json") // This one is enough for GET requests
      headers.append("Content-Type", "application/json") // This one sends body
      headers.append("Authorization", token)
      
      fetch(`/api/articles/search?q=${query}`, {
        method: "GET",
        headers
      })
        .then((response) => {
          return response.json()
        })
        .then((json) => {
          callback(json)
        })
        .catch((error) => {
          // AHHHH! An Error!
          console.log("getPredictionItem error:", error)
        })
      }

     

export {
  addArticle,
  editArticle,
  deleteArticle,
  getCategories,
  getArticles,
  search,
}
