import React, { Component } from "react"

import { Switch, Route, withRouter } from "react-router-dom"

import MainPage from "./MainPage"
import CategoryView from "./CategoryView"
import Article from "./Article"

import * as ArticleAPI from "../../api/ArticleAPI"
import i18next, { languages } from "i18n"
import HelpSubheader from './HelpSubheader';

class HelpCenter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            showResults: false,
        }
    }
    componentDidMount() {
        let storedLang = localStorage.getItem('language').substr(0, 2);
        if (!languages.find(lang => lang.code == storedLang)) storedLang = 'en';

        let category = 2 //MAGIC NUMBERS HEH )
        switch (storedLang) {
            case "ru":
                category = 85
                break
            case "en":
                category = 86
                break
            default:
                category = 86
        }
        ArticleAPI.getArticles((categories) => {
            this.setState({
                categories: categories.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))
            })
        }, { parent: category })
    }

    render() {
        const { t } = this.props;
        return (
            <div className='help-center'>
                <HelpSubheader t={t} />
                <div className="help-center__main-content">
                    <Switch>
                        <Route exact path="/help/view/:category" render={() => <CategoryView {...this.props} categories={this.state.categories} />} />
                        <Route exact path={`/help/view/article/:article`} render={() => <Article {...this.props} />} />
                        <Route render={() => <MainPage categories={this.state.categories} />} />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default withRouter(HelpCenter);