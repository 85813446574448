import collectionReducers from "utils/collectionReducers"

const initialState = []

const NAMESPACE = "CHALLENGETEMPLATELIST"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function setTemplates(list) {
  return {
    namespace: NAMESPACE,
    type: "SET_TEMPLATE_LIST",
    handle: (state) => {
      return [...list]
    }
  }
}

export function add(item) {
  return {
    namespace: NAMESPACE,
    type: "ADD_TEMPLATE_ITEM",
    handle: (state) => {
      state.push(item)
      return [...state]
    }
  }
}


export function remove(id) {
  return {
    namespace: NAMESPACE,
    type: "REMOVE_TEMPLATE_ITEM",
    handle: (state) => {
      const list = state.filter((o) => { return o.id !== id })
      return [...list]
    }
  }
}

export function update(challengeTemplate) {
  function _update(challengeTemplates) {
  
    const index = challengeTemplates.findIndex(item => item.id === challengeTemplate.id)

    // заменяем/добавляем
    if (index != -1) {
      challengeTemplates[index] = challengeTemplate
    } else {
      challengeTemplates.push(challengeTemplate)
    }
    return challengeTemplate
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_PAIDACTIONS_TEMPLATES",
    handle: (state) => {
      const newlist = _update(state.templateChallenge)
      const copyNewlist = newlist.map(item => Object.assign({}, item))
      return { ...state, challengeTemplates: copyNewlist }
    }
  }
}
