import React from 'react'
import PropTypes from 'prop-types'
import ProfileSectionHead from '../ProfileSectionHead';

const ProfilePersonalization = props => {
    return (
        <div className='profile-personalization p4 bg-white'>
             <ProfileSectionHead 
                title='Персонализация' 
                description=' Тут вы сможете что-то поменять, чтоб было красиво. А также поставить новую тему бесплано и без смс.' 
            />
            <h5 className="h5 bold mb2">Ваш аватар</h5>
            <div className="flex items-end mb2">
                <div className="profile-personalization__avatar-big circle bg-gray"/>
                <div className="profile-personalization__avatar-small circle bg-gray"/>
            </div>
            <h5 className="h5 bold mb2">Ваша обложка</h5>
            <div className="profile-personalization__page-cover bg-gray">
              
            </div>
        </div>
    )
}

ProfilePersonalization.propTypes = {

}

export default ProfilePersonalization
