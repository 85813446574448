import React, { Component } from "react"
import { trans } from "utils/translator"
import DatePicker from 'react-date-picker'
import Select from "react-select"
import moment from 'moment'
import * as AdminLogsAPI from "api/AdminLogsAPI"
import * as UsersApi from "api/UsersApi"

import Role from 'components/Common/Role'

export default trans(class AdminLogs extends Component {
    constructor(props) {
      super(props)
  
      this.state = {
        query: {
          name: '',
          email: '',
          permissions: '',
          id: '',
        },
        filters: { 
          stream: '',
          actionType: '',
          activityType: '',
          createdAt: ''
        },
        logs: [],
        userActions: [],
        isCustomSearchOpened: false,
        startDate: moment(),
        users: [],
        showModal: false,
        details: 'Thee are no details',
      }
  
      this.inputDataChange = this.inputDataChange.bind(this)
      this.handleChange = this.handleChange.bind(this)
    }
    renderLogRow = log => {
      const {user, createdAt, actionDetails, activityDetails, activityType, actionType, activity} = log
      const { isStreamer, users } = this.state
      const { streamlist } = this.props

      const userDetails = users[0] || {}
      const activityDetailsObj = JSON.parse(activityDetails)
      let actionDetailsObj = {}
      try{
        actionDetailsObj = JSON.parse(actionDetails)
        
      }
      catch(e){

      }
      const {Comment, Changes} = actionDetailsObj
      
      
      const stream = Array.isArray(streamlist.adminStreamlist) 
      && streamlist.adminStreamlist.find(s => s.id == activityDetailsObj.stream) || {}
      
      const {name, id, permissions, streams, email} = userDetails
      const creationDate = new Date(createdAt)
      if(this.state.showOnlyOrbs && currency != 'ORBS') return 
      return <tr className='logs__row'>
          <td 
          onClick={this.showModal(`User details: 
          Id: ${id} 
          Name: ${name}
          Streams: ${streams}
          Email: ${email}
          Permissions: ${permissions} 
          `)}>
          {user} 
          </td>
          <td
          onClick={this.showModal(`
          Stream details: 
          Owner: ${stream.owner}
          Moderators: not implemented yet, 
          Type: ${stream.type}
          Created at: ${new Date(stream.createdAt).toLocaleDateString()}
          `)}
          >{stream.stream || 'Not specified'}</td>
          <td
          onClick={this.showModal(`
          Action details: 
          Changes: ${JSON.stringify(Changes)}
          Comment: ${Comment}`)}
          >
          {actionType || 'Not specified'}
          </td>
          <td
            onClick={this.showModal(`${activityDetails}`)}          
          >{`${activityType} ${activity} ${activityDetailsObj.description}`}</td>
          <td>{`${creationDate.toLocaleTimeString()} ${creationDate.toLocaleDateString()}`}</td>
      </tr>
    }
    toggleCustomSeacrhForm = () => {
        this.setState(prevState => ({
            isCustomSearchOpened: !prevState.isCustomSearchOpened
        }), () => {
          !this.state.isCustomSearchOpened ? this.setState({
            filters: {

            }
          }) : null
        } )
    }
    handleChange(date) {
        this.setState({
          startDate: date
        });
      }
     showModal = details => () => {
       this.setState({
         showModal: true,
         details, 
       })
     } 
     hideModal = () => {
      this.setState({
        showModal: false,
      })
    } 
    search = () => {
      UsersApi.search(this.state.query, (err, msg) => {
        this.setState({
          users: msg,
        }, () => {
         
          AdminLogsAPI.search({...this.state.filters, user: (this.state.users[0] || {}).id}, (msg) => {
              this.setState({
                logs: msg, 
              })
            })
        })
      })
  
    }
    render() {
      const { props, state } = this
      const { search, t } = props
      const { query, filters, logs, isCustomSearchOpened } = state
  
      const { name, email, permissions, id } = query
      const { stream, actionType, activityType, createdAt} = filters
      return (
        // todo сделать все модалки через редакс
        <Role roles={['ADMIN']}>
        <div className="admin-logs">
        {
          this.state.showModal ?
       
        <div className="admin-logs__modal-overlay" onClick={this.hideModal}> 
        <div className="admin-logs__modal">
        {
          `${this.state.details}`
        }
        </div>
        </div>
        : 
        null
        }
        <div className="admin-logs__search-form">
        <h3 className="admin-logs__header"> Search for a streamer or moderator:</h3>
        <table className="predictionTemplate">
          <thead>
            <tr>
              <th width="27%">param:</th>
              <th width="73%">field:</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>id:</td>
              <td><input type="text" id="id" defaultValue={id} onChange={this.inputDataChange.bind(this)} /></td>
            </tr>
            <tr>
              <td>name:</td>
              <td><input type="text" id="name" defaultValue={name} onChange={this.inputDataChange.bind(this)} /></td>
            </tr>
            <tr>
              <td>email:</td>
              <td><input type="text" id="email" defaultValue={email} onChange={this.inputDataChange.bind(this)} /></td>
            </tr>
            <tr>
              <td>permissions:</td>
              <td><input type="text" id="permissions" defaultValue={permissions} onChange={this.inputDataChange.bind(this)} /></td>
            </tr>
            <tr>
              <td>actions:</td>
              <td>
                <button onClick={this.search}>search</button>
              </td>
            </tr>
  
          </tbody>
        </table>
        </div>
        <div className="admin-logs__additional-params">
        
        <button className="admin-logs__trigger" onClick={this.toggleCustomSeacrhForm}>
        Custom search
        </button>
        {
        isCustomSearchOpened ?
        <div>
        <h3 className="admin-logs__header">Custom search:</h3>
        <table className="predictionTemplate">
          <thead>
            <tr>
              <th width="27%">param:</th>
              <th width="73%">field:</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>createdAt:</td>
              <td><input type="date" id="createdAt" defaultValue={createdAt} onChange={this.filtersDataChange.bind(this)} /></td>
            </tr>
            <tr>
              <td>Activity:</td>
              <td>
                {/* <input type="text" id="activityType" defaultValue={activityType} onChange={this.filtersDataChange.bind(this)} /> */}
                <Select
                id="activityType"
                name="form-field-activity-type"
                value={this.state.filters.activityType}
                onChange={this.filtersDataChange('activityType')}
                placeholder="Select activity type"
                closeOnSelect={true}
                options={[{label: 'Challenge', value: 'CHALLENGE'}, 
                {label: 'Voting', value: 'VOTING'}, 
                {label: 'Paid action', value: 'PAIDACTION'}  
              ]}
              />
                
                </td>
            </tr>
            <tr>
              <td>Action:</td>
              <td>
              <Select
                id="actionType"
                name="form-field-action-type"
                value={this.state.filters.actionType}
                onChange={this.filtersDataChange('actionType')}
                placeholder="Select action type"
                closeOnSelect={true}
                options={[{label: 'Create', value: 'CREATE'}, 
                {label: 'Update', value: 'UPDATE'}, 
                {label: 'Delete', value: 'DELETE'}  
              ]}
              />
              </td>
            </tr>
            <tr>
              <td>Streamer:</td>
              <td><input type="text" id="stream" defaultValue={stream} onChange={this.handleStreamerChange} /></td>
            </tr>
          </tbody>
        </table> 
        </div>
        : 
        null
        }

        </div>
        <div className="admin-logs__user-actions">
        <table className="table" cellSpacing="0">
            <thead className='table__header'>
            <tr>
            <th>User</th>
            <th>stream</th>
            <th>Action</th>
            <th>Activity</th>
            <th>Date</th>
            </tr>
            </thead>
            <tbody className='transactions__body'>
            {
                Array.isArray(logs) ? 
                logs.map(a => {
                    return this.renderLogRow(a)
                }) : null
            }
            </tbody>
            </table>
        </div>
        </div>
        </Role>
      )
    }

    inputDataChange(e) {
      const { props, state } = this
      const { search } = props
      const { query } = state
      
      query[e.target.id] = e.target.value
      
      // realtime search
      if (e.target.value.length > 3) { search(query) }
    }

    filtersDataChange = type => (selectedPrm) => {
      const { state, props } = this
      const { filters } = state
      const { streamlist } = props
      filters[type] = selectedPrm.value
      this.setState(filters)
    }

    handleStreamerChange = (e) => {
      const { state, props } = this
      const { filters } = state
      const { streamlist } = props
      const stream = streamlist.adminStreamlist.find(s => s.stream == e.target.value)
      filters['stream'] = stream.id
      this.setState(filters)
    }
  })
  
  