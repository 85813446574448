import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"
import FormValidator from "components/Common/FormValidator"
import * as userActions from "actions/UserActions"
import { required, gte } from "utils/validator"
import GenerateBonusForm from "./GenerateBonusForm"
import RenderWithPermission from "components/Common/RenderWithPermission"

class GenerateBonus extends FormValidator {
  constructor(props) {
    super(props)
   
    this.handleCreate = this.handleCreate.bind(this)
    this.apiCall = this.apiCall.bind(this)
    this.exportToCSV = this.exportToCSV.bind(this)
    this.downloadableCSV = this.downloadableCSV.bind(this)
  }
  render() {
    return (
      <RenderWithPermission requiredPermissions={['SUPER']}>
        <div className="about">
          <div className="aboutContent aboutContent_faq">
            <div className="aboutContentHeader">
              <h1 className="aboutContent-header">Generate Bonus</h1>
            </div>
          </div>
        </div>
        <div className="about">
          <div className="container">
            <div className="faqContainer">
              <div className="faqMenuBlock">
                <ul className="faqMenu">
                  <li className="faqMenu-item">
                    <NavLink to="/admin/generate-bonus/add" activeClassName="activeItem" key="gen">gen new</NavLink>
                  </li>
                </ul>
                <div className="faqContent createPredictionBlock">
                  <div>
                    <Route path="/admin/generate-bonus/add" key="gen" render={() => <div className="tabContent activeTab"><GenerateBonusForm handleCreate={this.handleCreate} /></div>} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </RenderWithPermission>
    )
  }


  handleCreate(bonus) {

    if (bonus.code == "") {
      const bonuses = Array()
      for (let i = 0; i < bonus.count; i++) {
        const code = Math.random().toString(36).substring(2)
        const body = {
          code,
          count: 1,
          bonuses: bonus.bonuses
        }
        this.apiCall(body)
        const row = [i, code, 1, bonus.bonuses]
        bonuses.push(row)
      }

      this.exportToCSV(bonuses)
      alert("Success")
    } else {
      this.apiCall(bonus, () => {
        alert("Success")
      })
    }
  }
  
  exportToCSV(bonuses) {
    window.open(this.downloadableCSV(bonuses))
  }

  downloadableCSV(rows) {
    let content = "data:text/csv;charset=utf-8,"
  
    rows.forEach((row, index) => {
      content += `${row.join(",")}\n`
    })
  
    return encodeURI(content)
  }

  apiCall(body, callback) {
    const userLocal = JSON.parse(localStorage.getItem("predictoria_currentUser"))

    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", userLocal.token)

    fetch("/api/promo", {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json.code == "UNKNOWN_ERROR") {
          alert(json.message)
          return
        }

        if (callback != null) { callback() }
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.error("GenerateBonus error:", error)
      })
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modals,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    notify: bindActionCreators(notify, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateBonus)
