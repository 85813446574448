import React, { Fragment, Component } from "react"
import FormValidator from "components/Common/FormValidator"
import { trans } from 'utils/translator';
class VotingNew extends FormValidator{

  // move => enums 
  static get waitingForModeration() { return "WAITING_FOR_MODERATION" }
  static get waitingForAcception() { return "WAITING_FOR_ACCEPTION" }
  static get accepted() { return "ACCEPTED" }
  static get failed() { return "FAILED" }
  static get done() { return "DONE" }
  static get closed() { return "CLOSED" }

  constructor(props){
    super(props)
    const { props: {voting: { closedAt, optionsBank = [] } } } = this
    
    this.state = {
       isClosed: !!closedAt,
       shouldCreatePrd: false,
    }
    this.setState = this.setState.bind(this)
  }

  componentDidMount(){
    const { refs: { textarea } } = this
    textarea.style.height = textarea.scrollHeight + 'px'
    // проверить, могу ли я авторасширяющуюся форму сделать таким же образом, повесив на keyup такой колбек
  }
  close = (e) => {
   const { props: { voting: { id }, VotingActions: { close } } } = this
   e.preventDefault() 
   close(id, (voting) => {})
  }

  cancel = (e) => {
    const { props: { voting: { id }, VotingActions: { cancel } } } = this
    e.preventDefault()
    cancel(id, (voting) => {})
  }

 
  render(){ 
    const { props: { voting: { currency, options, description, optionsBank=[], winnerBank, winner } } }  = this
    const totalBank = optionsBank.reduce((total, option) => total += option, 0)
    const { t } = this.props
    return (
      <form className="adm-new-control-card adm-new-control-card--active adm-new-voting adm-new-voting-state2">
      <div className="adm-new-control-card__header">
        <div className="adm-new-control-card__option">
          <span className={`adm-new-voting__${currency == 'ORBS' ? 'orbs' : 'cubes'}`}>{ totalBank }</span>
        </div>
      </div>
      <div className="adm-new-control-card__content">
      <textarea 
      ref='textarea'
      onChange={e => e.preventDefault()}
      className="adm-new-control-card__description"
      value={description}
      />
      {
        options.map((option, i) => {
          const isWinner = winner == i 
          return <div className={`row-container row-container_v-center adm-new-voting__option ${isWinner ? 'adm-new-voting__winner': ''}`}>
            <input
            key={i} 
            className="adm-new-control-card__input"
            value={option}
            onChange={(e) => e.preventDefault()}
            />
            <div className="adm-new-voting__option-bank">
              <span className="adm-new-voting__option-value">{ isWinner? winnerBank || 0: optionsBank[i] || 0 }</span>
              <span className={`adm-new-voting__${currency == 'ORBS' ? 'orbs' : 'cubes'}`}/>
            </div>
          </div>
        }) 
      }
      </div>
      <div className="adm-new-control-card__actions">
        {
          this.props.voting.status !== 'WAITING' ? <button
          className="adm-new-control-card__btn adm-new-control-card__btn--accent"
          onClick={this.close}
          >{t('admin_finish')}</button> : null
        }
         <button
        className="adm-new-control-card__btn adm-new-control-card__btn--accent"
        onClick={this.cancel}
        >{t('admin_cancel')}</button>
      </div>
    </form>
  );
  }
}
export default trans(VotingNew)

