import React from 'react'
import PropTypes from 'prop-types'

const Notify = props => {
    const { icon, title, message } = props;
    return (
        <div className='notify bg-white black flex flex-column items-center justify-center'>
            <img src={icon} alt="" className='mb2'/>
            <h2 className="h2 mb2">{title}</h2>
            <p className="mb2">{message}</p>
        </div>
    )
}

Notify.propTypes = {

}

export default Notify
