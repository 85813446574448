import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames';

const Textarea = props => {
    const { value, onChange, limit, placeholder, className } = props;
    return (
        <div className='textarea__wrap'>
            <textarea placeholder={placeholder} value={value} onChange={(e) => {
                if(e.target.value.length <= limit){
                    onChange(e.target.value);
                }
            }} className={cn(className, 'textarea')}/>
            <span className="textarea__left-addon">
                {`${value.length} / ${limit}`}
            </span>
        </div>
    )
}

Textarea.defaultProps = {
    limit: 400
}

Textarea.propTypes = {

}

export default Textarea 
