import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

const TabSwitcher = ({ tabs, onSelectTab, activeTab, className }) => {
  return (
    <div className='ds-tab-switcher'>
      <ul className={cn('ds-tab-switcher-inner', className)}>
        {_.isArray(tabs) &&
          _.map(tabs, (tab, i) => (
            <li
              disabled={tab.isDisabled}
              key={i}
              className={cn('ds-tab-switcher__tab', {
                'ds-tab-switcher__tab--active': activeTab === tab.value,
              })}
              onClick={() => {
                if (tab.isDisabled) return;
                onSelectTab(tab.value);
              }}
            >
              {tab.label}
            </li>
          ))}
      </ul>
    </div>
  );
};

TabSwitcher.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onSelectTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string,
};

export default TabSwitcher;