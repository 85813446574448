import React, { Component } from "react"
import { Provider } from "react-redux"
import { store } from "libs/redux"
import { history } from "libs/router"
import { ConnectedRouter } from "react-router-redux"
import RouteComponent from "components/Route"
import { Route } from "react-router-dom"
import ScrollRestorator from './ScrollRestorator';


import { I18nextProvider } from 'react-i18next'
import i18n from '../../i18n' 

import "babel-polyfill"
import "../../assets/style/main.scss"
import "../../components/AuthForm/styles/index.scss"

const jivo_onLoadCallback = function() {
  jivo_api.setRules([
{
  "name": "Активное приглашение в диалог",
  "enabled": true,
  "type": "all",
  "conditions": [
    {
      "condition": "time_after_close",
      "comparator": "greater",
      "value": 1
    },
    {
      "condition": "time_after_invitation",
      "comparator": "greater",
      "value": 1
    }
  ]
}
  ])}


export default class RootContainer extends Component {
  componentDidMount() {
    // jivo_onLoadCallback();
    setTimeout(
      () => {
        const spinner = document.getElementById("initial-load-animation")
        if (spinner) { // && !spinner.hasClass('active')) {
          // spinner.classList.add('active');
          spinner.className += " active"
          setTimeout(
            () => {
              spinner.parentNode.removeChild(spinner)
            }
            , 300
          )
        }
      }
      , 0
    ) // hides the spinner
    
  }

  render() {
    return (
      <Provider store={store}>
      	<I18nextProvider i18n={i18n}>
          <ConnectedRouter history={history}>
           <ScrollRestorator>
             <Route path="/" component={RouteComponent} />
            </ScrollRestorator>
          </ConnectedRouter>
        </I18nextProvider>
      </Provider>
    )
  }
}
