import * as SocketAPI from "api/SocketAPI"
import { store } from "libs/redux"
import "whatwg-fetch"

const get = async (page, callback) => {
    const predictions = store.getState().predictions.predictions
    const token = store.getState().user.model.token || ""
  
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)
  
    fetch(`/api/transaction?page=${page}`, {
      method: "GET",
      headers
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        callback(json)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("getPredictionItem error:", error)
      })
  }

  export {
    get
  }