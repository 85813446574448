import collectionReducers from "utils/collectionReducers"

const initialState = []

const NAMESPACE = "PAIDACTIONTEMPLATELIST"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function setTemplates(list) {
  return {
    namespace: NAMESPACE,
    type: "SET_PAID_ACTION_TEMPLATE_LIST",
    handle: (state) => {
      return [...list]
    }
  }
}

export function add(item) {
  return {
    namespace: NAMESPACE,
    type: "ADD_PAID_ACTION_TEMPLATE_ITEM",
    handle: (state) => {
      state.push(item)
      return [...state]
    }
  }
}


export function remove(id) {
  return {
    namespace: NAMESPACE,
    type: "REMOVE_PAID_ACTION_TEMPLATE_ITEM",
    handle: (state) => {
      const list = state.filter((o) => { return o.id !== id })
      return [...list]
    }
  }
}

export function update(paidAction) {
  function _update(paidActions) {
    const index = paidActions.findIndex(item => item.id === paidAction.id)

    // заменяем/добавляем
    if (index != -1) {
      paidActions[index] = paidAction
    } else {
      paidActions.push(paidAction)
    }
    return paidActions
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_PAIDACTIONS_TEMPLATES",
    handle: (state) => {
      const newlist = _update(state)
      const copyNewlist = newlist.map(item => Object.assign({}, item))
      return  [...copyNewlist] 
    }
  }
}
