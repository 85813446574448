import { combineReducers } from "redux"
import { truncate, trimStart } from "lodash"
import { routerReducer } from "react-router-redux"
import { reducer as notificationsReducer } from "reapop"

const context = require.context(".", true, /^(?!\.\/index\.js).+\.js$/)
const reducers = context.keys().reduce((temp, key) => {
  temp[trimStart(truncate(key, { length: key.length - 3, omission: "" }), "./")] = context(key).default
  return temp
}, {})

reducers.router = routerReducer
reducers.notifications = notificationsReducer()

export default combineReducers(reducers)
