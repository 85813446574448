import React, { Component, Fragment } from "react"
import { Route } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import CustomScroll from "react-custom-scroll"
import "../../assets/style/partials/customScroll.css"

import * as PredictionsActions from "actions/PredictionsActions"
import * as ChallengeActions from "actions/ChallengeActions"
import * as UserActions from "actions/UserActions"
import * as StreamActions from "actions/StreamActions"
import * as VotingActions from "actions/VotingActions"
import * as PaidActionActions from "actions/PaidActionActions"
import * as ModalActions from 'actions/ModalActions';


import { QuickDonationModal } from 'components/ds/Molecules/Modals';
import Challenge from "components/Activities/Challenge"
import Prediction from "components/Activities/Prediction"
import PaidAction from "components/Activities/PaidAction"
import Voting from "components/Activities/Voting"

import ChallengeAuth from "components/Feedback/ChallengeAuth"
import {trans} from "utils/translator"
import { notify } from "reapop"
import { ETIME } from "constants";
import AuthForm from 'components/AuthForm';
import * as PaymentAPI from 'api/paymentAPI';
class PredictionsList extends Component {
  constructor(props){
    super(props)
    this.handleScrollClick = this.handleScrollClick.bind(this)
  }
  handleScrollClick = (e) => {

    if(!e.target.classList.contains('activity__button_inline') && !e.target.parentNode.classList.contains('activity__button_inline')) return

    document.querySelector('.inner-container').scrollTo(0, e.target.closest('.activity').offsetTop - 120)
  }

  renderPaidActionList(paidActions){
    const {PaidActionActions, UserActions, notify, user, ModalActions} = this.props
    if(!paidActions.length) return
    return paidActions.map((item) => {
      return <PaidAction
      user={user}
      key={item.id}
      paidAction={item}
      PaidActionActions={PaidActionActions}
      UserActions={UserActions}
      notify={notify}
      toggleQuickDonationModal={ModalActions.toggleQuickDonationModal}
      />
    })
  }
  renderVotingList(votings){
    const { VotingActions, UserActions, notify, user, ModalActions } = this.props
    
    if(!votings.length) return
    return votings.map((item) => {
      return <Voting
      onClick={this.handleScrollClick}
      user={user}
      key={item.id}
      voting={item}
      VotingActions={VotingActions}
      UserActions={UserActions}
      notify={notify}
      toggleQuickDonationModal={ModalActions.toggleQuickDonationModal}
      />
    })
  }

  renderChallengeList(challenges) {
    const {ChallengeActions, UserActions, ModalActions, notify, user, t} = this.props
    if(!challenges.length) 
    return [<p className='activities-list__no-challenges'>{t('global_streamer_has_no_challenges')} <br />
         <button 
         className="pre-btn pre-btn_gold activities-list__be-first"
         onClick={this.props.handleChallenge}>{t('global_be_first')}</button></p>]
    return challenges.map((item) => {
      return <Challenge 
      user={user}
      key={item.id}
      challenge={item}
      ChallengeActions={ChallengeActions}
      toggleQuickDonationModal={ModalActions.toggleQuickDonationModal}
      UserActions={UserActions}
      notify={notify}
      />
    })
  }
  renderPredictionList(predictions) {
    const UserActions = this.props.UserActions
    const PredictionsActions = this.props.PredictionsActions
    const notify = this.props.notify
    if(!predictions.length) return
  
    return predictions.map((item) => {
      return <Prediction
      prediction={item}
      key={item.id}
      PredictionsActions={PredictionsActions}
      UserActions={UserActions}
      notify={notify}/>
    })
  }

  onLogIn = () => {
    this.props.ModalActions.toggleAuthModal({ activeTab: AuthForm.SIGNIN_TAB })
  }

  onMakeDonation = (amount, action, params) => {
    PaymentAPI.pay(amount, 'USD', 'ALTERNATIVE', null, action, params) 
  }
  render() {
    const { props } = this
    let { user, predictions, challenges, votings, stream, paidActions, paidActionTemplates, userPaidActions, streamInfo, handleChallenge, filter, t, quickDonationModal, ModalActions, ChallengeActions } = props
    if(!challenges) challenges=[]
    const id = stream.stream.stream || stream.stream
   
    let  acceptedChallenges = challenges.filter(c => c.status === 'ACCEPTED' && +c.stream == stream.id)
    let  waitingForAcceptionChallenges = challenges.filter(c => c.status === 'WAITING_FOR_ACCEPTION' && +c.stream == stream.id)
    let content = 2
    switch(filter){
      case 'CHALLENGES': content = this.renderChallengeList([...waitingForAcceptionChallenges, ...acceptedChallenges ])
      break;
      case 'VOTINGS': content = this.renderVotingList(votings.filter(v => (v.status =='IN_PROGRESS' || v.status == 'WAITING') && +v.stream == stream.id))
      break;
      case 'PAID_ACTIONS': content = this.renderPaidActionList([...paidActionTemplates.filter(p => p.status!=='DISABLE' && +p.stream == stream.id), ...paidActions.filter(p => p.customer === user.model.id && p.status === 'DONE' && p.stream == stream.id)])
      break;
      case 'PREDICTIONS': content = this.renderPredictionList(predictions.filter(p => !p.finishedAt && (p.stream == stream.stream || +p.stream == stream.id)))
      break;
      default: content = [
      ...this.renderChallengeList([...waitingForAcceptionChallenges, ...acceptedChallenges ]) || [], 
      ...this.renderVotingList(votings.filter(v => (v.status =='IN_PROGRESS' || v.status == 'WAITING') && +v.stream == stream.id))  || [], 
      ...this.renderPaidActionList(paidActionTemplates.filter(p => p.status!=='DISABLE' && +p.stream == stream.id))  || [],
      ...this.renderPaidActionList(paidActions.filter(p => p.customer === user.model.id && p.status === 'DONE' && +p.stream == stream.id))  || [],
      ...this.renderPredictionList(predictions.filter(p => !p.finishedAt && (+p.stream == stream.id || p.stream==stream.stream)))  || [],
      ]
     }
    
  
    return <Fragment>
        <CustomScroll ref='scroll' heightRelativeToParent="calc(90%)">
        {content}
        </CustomScroll>
        <QuickDonationModal 
        isOpen={quickDonationModal.isOpen} 
        onRequestClose={ModalActions.toggleQuickDonationModal}
        donation={{
          donationValue: quickDonationModal.donationValue,
          action: quickDonationModal.action,
          params: quickDonationModal.params,
        }}
        onMakeDonation={this.onMakeDonation}
        onLogIn={this.onLogIn}
        ChallengeActions={ChallengeActions}
        user={user}
        />
      </Fragment>
  }
}

function CreateStream(props) {
  return (
    <ChallengeAuth handleChallenge={props.handleChallenge} />
  )
}

function mapStateToProps(state) {
  return {
    predictions: state.predictions.predictions,
    challenges: state.challenges.challenges,
    votings: state.votings.votings,
    paidActions: state.paidActions.paidActions,
    paidActionTemplates: state.templatePaidAction,
    quickDonationModal: _.get(state, 'modals.quickDonationModal'),
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    ChallengeActions: bindActionCreators(ChallengeActions, dispatch),
    VotingActions: bindActionCreators(VotingActions, dispatch),
    PaidActionActions: bindActionCreators(PaidActionActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    notify: bindActionCreators(notify, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch),
    ModalActions: bindActionCreators(ModalActions, dispatch)
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(PredictionsList))
