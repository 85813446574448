import React, {Component} from "react"
import { trans } from "utils/translator";
import  {Editor, convertFromRaw, EditorState} from "draft-js";
import {Link} from "react-router-dom";
import BecomeStreamerModal from "components/Feedback"
import AuthForm from "components/AuthForm"

import * as PaymentAPI from "api/paymentAPI"
const BecomeStreamer = trans(class BecomeStreamer extends Component{
    constructor(props){
      super(props)
      this.state = {
        showModal: false
      }
      this.handleClick = this.handleClick.bind(this)
      this.hide = this.hide.bind(this)
    }
    hide = () => {
      this.setState({
        showModal: false
      })
      this.props.hideHelloModal()
    }
    handleClick = () => {
      this.setState({
        showModal: !this.state.showModal
      })
    }
    render(){
    const { t } = this.props
    const { showModal } = this.state
    
    return (
      <div className="become-streamer">
      
      <div onClick={this.handleClick} className="become-streamer__button how-it-works__become-streamer pre-btn">
          {t('global_become_streamer')}
      </div>
     
      {
        showModal ? <div onClick={this.handleClick} className='become-streamer__wrapper'><BecomeStreamerModal hide={this.hide}/></div> : null
        // showModal ? <div onClick={this.handleClick} className='how-it-works__wrapper'><HowItWorks hide={this.hide}/></div> : null
      }
      </div>
    )
  }
  }
  )
export default trans(class HowItWorks extends Component{

  constructor(props){
    super(props)
    this.state = {
        activeContainer: -1,
        activeTab: this.props.activeTab || 0
    }
    this.handleMouseOnContainer = this.handleMouseOnContainer.bind(this)
    this.handleContainerClick = this.handleContainerClick.bind(this)

  }
  handleContainerClick = container => () => {
        this.setState({
            activeTab: container,
        }) 
  }
  handleMouseOnContainer = container => () => {
      this.setState({
          activeContainer: container
     })
  }
  render(){
  const { t } = this.props
  
  return (
  <div onClick={(e) =>  e.stopPropagation()} className='how-it-works'>
  <div className="how-it-works__head">
    <h3  className="how-it-works__heading">{t('hello_modal_who_are_you')}</h3>
    <br />
    <div className='how-it-works__split-panel-wrapper'>
    <div className={`how-it-works__split-panel ${this.state.activeContainer === 0 ? 'one-is-active' : ''} ${this.state.activeContainer === 1 ? 'two-is-active' : ''} `}>
    <a href='#body'>
    <div class="container">
  <div class="section-one" 
  onMouseOver={this.handleMouseOnContainer(0)}
  onClick={this.handleContainerClick(0)}
  >
    <div class="content content_left">
    {t('hello_modal_im_streamer')}
    </div>
  </div>
  <div class="section-two" 
  onMouseOver={this.handleMouseOnContainer(1)}
  onClick={this.handleContainerClick(1)}
  >
    <div class="content content_right">
    {t('hello_modal_im_a_viewer')}
    </div>
  </div>
</div>
</a>
    </div>
    </div>
  </div>
  {
      this.state.activeTab === 0 ?  <div className="how-it-works__body" id="body">
      <div className="how-it-works__section how-it-works__section_column">
      <h1 className="how-it-works__heading_left">{t('hello_modal_getting_started')}</h1>
      <br />
      {/* ОЧЕНЬ КОСТЫЛЬНО СДЕЛАЛ, ТАК КАК АПДЕЙТ ПОДГОРАЕТ. БУДЕТ ВРЕМЯ, ВСЕ МОДАЛКИ ПО ЧЕЛОВЕЧЕСКИ ЧЕРЕЗ РЕДАКС БУДУ МЕНЕДЖИТЬ */}
      <p className="how-it-works__description_body" >{t('hello_modal_use_the_button')}<div style={{display: 'inline-block'}}><BecomeStreamer hideHelloModal={this.props.hide}/></div> {t('hello_modal_follow_instructions')} 
    </p>
    <br />
      <p className="how-it-works__description_body">{t('hello_modal_your_stream')} </p>
      <br />
      <p className="how-it-works__description_body">{t('hello_modal_manage_activities')} </p>
      <br />
      <p className="how-it-works__description_body">{t('hello_modal_get_rewarded')} </p>
      <br />
      <p className="how-it-works__description_gold">{t('hello_modal_attention')} </p>
      </div>
      <div className="how-it-works__section how-it-works__section_column">
       <h1 className="how-it-works__heading_left">{t('hello_modal_predictoria_uses')}</h1>
       <p className="how-it-works__description_body">{t('hello_modal_cubes')} <div className='how-it-works__cubes' />{t('hello_modal_free_currency')}   </p>

       <p className="how-it-works__description_body">{t('hello_modal_orbs')}  <div className='how-it-works__orbs' /> {t('hello_modal_paid_currency')}  </p>
     <br />
     <p className="how-it-works__description_body">{t('hello_modal_please_read')} <Link className='how-it-works__link' to='/info/terms'>{t('hello_modal_rules_of_activities')}</Link></p>
     <br />
     <p className="how-it-works__description_body">{t('hello_modal_streamer_can')}</p>
     <br />
     <p className="how-it-works__description_body">{t('hello_modal_please_read')} <Link className='how-it-works__link' to='/info/terms'>{t('hello_modal_terms_of_use')}</Link></p>
     <br />
     <p className="how-it-works__description_body">{t('hello_modal_we_guarantee')}</p>
      </div>
      <div className="how-it-works__section how-it-works__section_column">
      <h1 className="how-it-works__heading_left">{t('hello_modal_transparent_fees')}</h1>
      <div className="how-it-works__line"><div className="how-it-works__blue"></div> <div className="how-it-works__gold"></div></div>
      <div className="how-it-works__line how-it-works__line_between"><div className="how-it-works__left">{t('hello_modal_you_keep')}<span className="how-it-works__percent-blue">95%</span></div><div className="how-it-works__right">{t('hello_modal_we_keep')} <span className="how-it-works__percent-gold">5%*</span></div></div>
      <br />
      <p className="how-it-works__description_body how-it-works__description">{t('hello_modal_please_note')}</p>
      </div>
      <div className="how-it-works__section how-it-works__section_column">
      <div className="how-it-works__clear pre-btn pre-btn_gold"
      onClick={this.props.hide}
      >{t('hello_modal_i_see')}</div>
      </div>
      </div> :
       <div className="how-it-works__body" id="body">
       <div className="how-it-works__section">
       <h1 className="how-it-works__description_gold">{t('hello_modal_streamer_will_accept')}
     </h1>
       </div>
       <div className="how-it-works__section how-it-works__section_column">
       <h1 className="how-it-works__heading_left">{t('hello_modal_predictoria_uses')}</h1>
       <p className="how-it-works__description_body">{t('hello_modal_cubes')} <div className='how-it-works__cubes' /> {t('hello_modal_free_currency_viewer')} 
     
     </p>
     <br />
       <p className="how-it-works__description_body">{t('hello_modal_orbs')}  <div className='how-it-works__orbs' /> {t('hello_modal_orbs_are_used')} 
    </p>
       </div>
       <div className="how-it-works__section how-it-works__section_column">
       <h1 className="how-it-works__heading_left">{t('hello_modal_be_active')}</h1>
       <p className="how-it-works__description_body">{t('hello_modal_create_challenges')}</p>
       <br />
       <p className="how-it-works__description_body">{t('hello_modal_influence')}</p>
       <br />
       <p className="how-it-works__description_body">{t('hello_modal_create_paid')}</p>
       <br />
       <p className="how-it-works__description_bodyhow-it-works__description">{t('hello_modal_please_read')} <Link className='how-it-works__link' to='/info/terms'>{t('hello_modal_rules_of_activities')}</Link></p>
       </div>
       <div className="how-it-works__section how-it-works__section_column">
       <div className='how-it-works__bottom'>
       <p className="how-it-works__description_body">{t('hello_modal_is_streamer_still')}
     </p>
     <Link onClick={this.props.hide} className='how-it-works__link' to='/info/referral'>{t('hello_modal_basics_of_referal')}</Link>
       </div>
       </div>
       </div>
  }
 
  </div>
  )
}
})
