import React from 'react';
import PropTypes from 'prop-types'

const ProfileHello = props => {
    return (
        <div className='mt4 profile-header white flex items-end'>
            <div className="flex flex column">
            <h5 className="h5">Привет,</h5>
            <h1 className="h1">%Username%</h1>
            </div>
        </div>
    )
}

ProfileHello.propTypes = {

}

export default ProfileHello
