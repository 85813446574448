import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/_Button';

import landing1 from 'assets/images/main/Landing/landing-1.svg';
import landing2 from 'assets/images/main/Landing/landing-2.svg';
import landing3 from 'assets/images/main/Landing/landing-3.svg';

import { history } from 'libs/router';
import { trans } from 'utils/translator';
// TODO optimize 
const Landing = props => {
    const { t } = props;
    return (
        <Fragment>
            <div className='landing flex flex-column bg-white black'>
                <section className="landing__section bg-dark-magenta white">
                    <h1 className="h1 my4 bold center">{t('_landing_main_header')}</h1>
                    <p className='t1 mb4 mx2 center'> {t('_landing_main_header_1')}</p>
                    <a className='t4 no-under' href="#advertisments">
                        <Button className='mb6 mx-auto' view='ghost' theme='purple'>{t('_landing_main_header_2')}</Button>
                    </a>
                </section>
                <section className="landing__section">
                    <div className="landing__block black px3 pt4">
                        <h2 className="h1 mb2 bold left-align"> {t('_landing_how_it_works')}</h2>
                            <p className="h1 bold">1</p>
                        <br />
                        <p className='mb4 t1'>  {t('_landing_how_it_works_1')}
                        <span className='italic gray t1'>  {t('_landing_how_it_works_2')}</span>
                        </p>
                        <br />
                        <p className="h1 bold">2</p>
                        <br />
                        <p className='mb4 t1'> {t('_landing_how_it_works_3')}
                    <Link className='black dashed t1' to='/help'>{t('_landing_how_it_works_4')} </Link></p>
                        <p className="h1 bold">3</p>
                        <br />
                        <p className='mb4 t1'>{t('_landing_how_it_works_5')} </p>
                        <Button 
                            color='dark-magenta'
                            onClick={() => {
                                history.push('/auth/registration');
                            }} 
                            className='mx-auto mb4 t1 white'
                        >
                        {t('_landing_how_it_works_6')}</Button>
                    </div>
                </section>
                <section id='advertisments' className="landing__section">
                    <div className="landing__block bg-dark-magenta white px3 py4">
                        <img src={landing1} alt="img" className='landing__circle circle my2' />
                        <h2 className="h1 mb2 bold">{t('_landing_advertiser_info')}</h2>
                        <p className='mb2 t1'>{t('_landing_advertiser_info_1')}</p>
                    </div>
                    <div className="landing__block px3 py4">
                        <p className='mb2 t1'>{t('_landing_advertiser_info_2')}</p>
                        <p className='mb2 t1'>{t('_landing_advertiser_info_3')}</p>
                    </div>
                    <img src={landing2} alt="img" className="fit landing-mobile__img" />
                    <div className="landing__block px3 py4">
                        <p className='mb2 t1'>{t('_landing_advertiser_info_4')}</p>
                        <p className="mb2 t1">{t('_landing_advertiser_info_5')}<span className='italic'>{t('_landing_advertiser_info_6')}</span>    {t('_landing_advertiser_info_7')}</p>
                    </div>
                </section>
                <section className="landing__section bg-dark-magenta white">
                    <div className="landing__block white px3 py4">
                        <img src={landing3} alt="img" className='circle my2 landing__circle' />
                        <h2 className="h1  mb2">  {t('_landing_under_development')}</h2>
                        <p className='mb2 t1'>  {t('_landing_under_development_1')}
                        <Link className='white dashed t1' to='/main'> {t('_landing_under_development_2')}</Link>.</p>
                        <p className='mb2 t1'> {t('_landing_under_development_3')}<span className='italic'>{t('_landing_under_development_4')}</span> {t('_landing_under_development_5')}</p>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

Landing.propTypes = {

}

export default trans(Landing);
