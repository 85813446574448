import React, { Component } from "react"
import { Route, NavLink } from "react-router-dom"
import Slider from "react-slick"
import MediaQuery from 'react-responsive'
import {withRouter, Link} from 'react-router-dom'
import {trans} from "utils/translator"
import {history} from "libs/router"

import BecomeStreamerModal from "components/Feedback"

 class MobileStreamSlider extends Component {
  constructor(props) {
    super(props)
    const stream = this.props.stream.stream
    const streamList = this.props.streamlist || []
    const live = streamList.filter(s => s.spectators > 0)
    let activeStreamIndex = live.findIndex(s => s.stream == stream) 
    if(activeStreamIndex < 0) activeStreamIndex = 0
    this.state = {
      isNeedUpdate: false,
      activeIndex: activeStreamIndex,
      showModal: false,
      searchInput: 'search for streamer',
      suggestions: [],
      selectedIndex: Math.floor(live.length / 2),
      live,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0
    }
    this.handleArrowClick = this.handleArrowClick.bind(this)

  }
  
  componentDidMount(){
    const swipeSurface = this.refs.touchSurface || document.body
    this.swipe(swipeSurface, this.handleSwipe)
  }
  handleSwipe = (direction) => {
    const { live } = this.state
    let dir 
    if(direction == 'right') dir = -1
    else if (direction == 'left') dir = 1
    else if (direction == 'none') return 
    this.setState(prevState => ({
      selectedIndex: (prevState.selectedIndex + dir) < 0 ? live.length :(prevState.selectedIndex + dir)%live.length 
    }))
  }
  handleArrowClick = (direction, live) => () => {
    const streamList = this.props.streamlist || []
    const live = streamList.filter(s => s.spectators > 0)
    const length = live.length
    let ind = this.state.activeIndex + direction
    if(ind < 0) ind = length - 1
    if(ind > length-1) ind = 0
    
    this.setState({
      activeIndex: ind,
    }, () => {
      history.push(`/stream/${live[ind].stream}`)
    })
  }
  renderStreamLinks(data) {
    let result = Array()
    const { StreamActions } = this.props
    data = data.filter(s => s.spectators > 0)
    
    result = data.map(item => (
      <NavLink className="streamItem" to={`/stream/${StreamActions.streamerID(item.type, item.stream).trim()}`} key={StreamActions.streamerID(item.type, item.stream).trim()} activeClassName="activestreamItemList">
        <img className="streamItem-img" src={item.image} alt={item.title} />
       
        <div className="streamItem-overlay" >
        <div className='streamSlider__info'>
        <div className='streamSlider__stream-name'>
          {item.stream}
          </div>
        <div className="streamSlider__stream-spectators">{item.spectators || "online"}</div>
        </div >
        </div>
        
      </NavLink>
    ))

    // Slider "не умеет" в пустые элементы. Если задавать ему определённую ширину, то он не будет заполнять пробелами это место.
    // Поэтому пришлось добавлять вручную пустые эллементы. Хотя можно было просто на фоне что-то сверстать с черными блоками, 
    // а слайдер добавить поверх этого пустого места
    const additional = 7 - data.length > 0 ? 7 - data.length : 0
    for (let i = 0; i < additional; i++) {
      result.push(<div className="streamItem streamItem_empty" key={`stream_empty_${i}`} />)
    }

    return result
  }
  handleSearchChange = (e) => {
    const q = e.target.value;
    this.setState({
      searchInput: q
    })
    this.props.StreamActions.search(q, (error, message) => {
      this.setState({
        suggestions: message
      })
    })
  }
  handleSliderClick = (direction) => () => {
    this.setState(prevState => ({
      selectedIndex: (prevState.selectedIndex + direction) < 0 ? 7 :(prevState.selectedIndex + direction)%7 
    }))
  }

  // dragMouseDown = (e) => {
  //   const element = document.querySelector('.selected')
  //   this.setState({
  //     pos3: e.touches[0].clientX,
  //     startPos: element.offsetLeft
  //   }, () => {
  //     document.ontouchmove = this.elementDrag
  //      document.ontouchend = this.closeDragElement
  //   })
   
  // }
  // closeDragElement = (e) => {
  //   document.ontouchend = null;
  //   document.ontouchmove = null;
  //   const element = document.querySelector('.selected')
  //   element.style.left = this.state.startPos + 'px'
  // }
  // elementDrag = (e) => {
  //   const element = document.querySelector('.selected')
  //   this.setState(prevState => ({
  //     pos1: prevState.pos3 - e.touches[0].clientX,
  //     pos3: e.touches[0].clientX,
  //     }), () => {
  //       // if(Math.abs(this.state.startPos - (element.offsetLeft - this.state.pos1) > 20))
  //       element.style.left = (element.offsetLeft - this.state.pos1) + "px";
  //     })
  // }
  selectStream = (i) => (e) => {
    if(i == this.state.selectedIndex) return
    this.setState({
      selectedIndex: i
    })
  }
  swipe = (el, callback) => {
      var touchsurface = el,
      swipedir,
      startX,
      startY,
      distX,
      distY,
      threshold = 50, 
      restraint = 100, 
      allowedTime = 300, 
      elapsedTime,
      startTime,
      dist,
      handleswipe = callback || function(swipedir){}
    
      touchsurface.addEventListener('touchstart', function(e){
          var touchobj = e.changedTouches[0]
          swipedir = 'none'
          dist = 0
          startX = touchobj.pageX
          startY = touchobj.pageY
          startTime = new Date().getTime() 
          // e.preventDefault()
      }, false)
    
      touchsurface.addEventListener('touchmove', function(e){
          // e.preventDefault() 
      }, false)
    
      touchsurface.addEventListener('touchend', function(e){
          var touchobj = e.changedTouches[0]
          distX = touchobj.pageX - startX 
          distY = touchobj.pageY - startY 
          elapsedTime = new Date().getTime() - startTime 
          if (elapsedTime <= allowedTime){ 
              if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ 
                  swipedir = (distX < 0)? 'left' : 'right' 
              }
              else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ 
                  swipedir = (distY < 0)? 'up' : 'down' 
              }
          }
          handleswipe(swipedir)
          // e.preventDefault()
      }, false)
  }
 

  render() {
    const streamList = this.props.streamlist || []
    const {selectedIndex} = this.state
    const live = streamList.filter(s => s.spectators > 0)
   
    const {t} = this.props
    if(!live.length) return ('')
    return (
      <div style={{
        width: '100%',
      }}>
      <MediaQuery query="(max-device-width: 480px)">
      <div className='streamSlider__actions'>
      <div className='streamSlider__become-streamer' onClick={() => this.setState({
        showModal: true,
      })}>
        {t('global_become_streamer')}
      </div>
      {
        this.state.showModal ? <div onClick={() => 
          this.setState({
            showModal: false,
          })
        } className='become-streamer__wrapper'><BecomeStreamerModal /></div> : null
      }
      
      <Link className='streamSlider__search' to={`/streams`}/>
     
      </div>
      <div className='streamSlider__mobile'>
      <div ref="carousel" id="carousel">
      <div ref='touchSurface' style={{
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                    zIndex: '2000'
                  }} />
      {
        live.map((live, i) => {
          let cardClass
          if(i == selectedIndex) cardClass = 'selected'
          if(i == selectedIndex - 1) cardClass = 'prev'
          if(i == selectedIndex + 1) cardClass = 'next'
          if(i == selectedIndex - 2) cardClass = 'prevLeftSecond'
          if(i == selectedIndex + 2) cardClass = 'nextRightSecond'
          if(i - selectedIndex > 2) cardClass = 'hideLeft'
          if(selectedIndex - i > 2) cardClass = 'hideRight'
          
          return <div ref={`stream${i}`} key={`stream${i}`} className={'card ' + cardClass}>
        
              {
                selectedIndex == i ? <React.Fragment>
                 <iframe  title="Stream For Fun" frameBorder="0" controls="false" height="220px" width="100%" src={`https://player.twitch.tv/?channel=${live.stream || ''}&controls=false`} frameborder="0" allowfullscreen="" allow="autoplay; encrypted-media" scrolling="no"></iframe>
                    <img src = {live.image} style={{
                    position: 'absolute',
                    left: '0px',
                    zIndex: '-1'
                  }} />
                  </ React.Fragment>
                : <img style={{
                  objectFit: 'cover'
                }} src = {live.image} onTouchStart={this.selectStream(i)}/>
              }
          </div>
        })
      }
      </div>
     
      
      </div>
      </MediaQuery>
      </div>
    )
  }
}


export default withRouter(trans(MobileStreamSlider))
