import React from "react"
import { Link } from "react-router-dom"
import FormValidator from "components/Common/FormValidator"
import { required, email, gte } from "utils/validator"
import user from "reducers/user"
import {trans} from "utils/translator"

export default trans(class SignUpForm extends FormValidator {
  constructor(props){
    super(props)
    this.state = {
      form: {
        referal: {
          value: props.referalCode,
          rules: [],
          isValid: true,
          isDirty: false,
        }
      }
    }
  }
  gtag_report_conversion = (url) => {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-770917246/Qw5eCJbT5ZMBEP6Gze8C',
        'event_callback': callback
    });
    return false;
  }
  authConfirm = () =>  {
    if (confirm('Auth with twitch?')) {
      localStorage.setItem('regInfo', 'Streamer')
      this.gtag_report_conversion()
      window.location.href = `/api/auth/twitch`
    }
  }
  handleFormSubmit = (e) => {
    e.preventDefault()
    if (this.state.form.terms.value != true) {
      this.sendNotification({
        title: "Sign Up",
        message: "Please, confirm terms first",
        status: "warning",
        dismissible: true,
      })
      return
    }
    if (!this.isValid()) return
    const { email, password, referal } = this.state.form
    // this.props.onSubmit(email.value, password.value, referal.value)
   
    this.createUserAPI(email.value, password.value, referal.value)
  }

  sendNotification(data) {
    this.props.createNotification(data)
  }

  createUserAPI(email, password, promo) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    const body = { email, password, referralCode: this.state.form.referal.value }
    if (promo != null && promo != "") {
      body.promo = promo
    }
    
    fetch("/api/user", {
      method: "POST",
      headers,
      body: JSON.stringify(body)
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        
        if (json.code == "WRONG_CREDENTIALS") {
          this.sendNotification({
            title: json.message,
            message: "Check credentionals or sign up first",
            status: "warning",
            dismissible: true,
          })
          return
        }
        if (json.code == "UNKNOWN_ERROR" && json.message == "Validation error: Validation isEmail on email failed") {
          this.sendNotification({
            title: "Wrong email",
            message: "Please, type correct email address",
            status: "warning",
            dismissible: true,
          })
          return
        }
        if (json.code == "UNKNOWN_ERROR" && json.message == "Validation error: Promocode not valid") {
          this.sendNotification({
            title: "Wrong promocode",
            message: "Please, use another promocode",
            status: "warning",
            dismissible: true,
          })
          return
        }
        if (json.code == "ALREADY_EXISTS") {
          this.sendNotification({
            title: json.message,
            message: "Please, use Sign In form",
            status: "warning",
            dismissible: true,
          })
          return
        }
        

        // валидация уровня бог
        if (json.id != null) {
          this.loginUserAPI(json.email, password, json)
        }
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("handleFormSubmit error:", error)
        this.sendNotification({
          title: "Server Error",
          message: "Server Error, try again or contact us via feedback form",
          status: "warning",
          dismissible: true,
        })
      })
  }

  loginUserAPI(email, password, user) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body

    fetch("/api/auth/signin", {
      method: "POST",
      headers,
      body: JSON.stringify({ email, password })
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {

        if (json.code == "UNKNOWN_ERROR" || json.code == "WRONG_CREDENTIALS") {
          this.sendNotification({
            title: "Server Error",
            message: "Server Error, try again or contact us via feedback form",
            status: "warning",
            dismissible: true,
          })
          return
        }
        user.token = json.token

        // this.sendNotification({
        //   title: "Registration Succees",
        //   message: "You have successfully registered",
        //   status: "success",
        //   dismissible: true,
        // })
        
        const { email, password, referal } = this.state.form
        this.props.onSubmit(email.value, password.value, referal.value, user)

        gtag("event", "sign_up", { method: "Predictoria" })

        // setTimeout(() => {
        //   window.location.href = "/"
        // }, 500)
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.log("loginUserAPI error:", error)
        this.sendNotification({
          title: "Server Error",
          message: "Server Error, try again or contact us via feedback form",
          status: "warning",
          dismissible: true,
        })
      })
  }

  render() {
    const {t} = this.props
    return (
      <form className="formContainerForm" noValidate onSubmit={this.handleFormSubmit}>
     
        <div className="inputBlock">
          <input
            {...this.bindInput("referal")}
            className={`authInput ${this.isValid("referal") || "authInput authInput_error"}`}
            placeholder={t('global_enter_referral')}
          />
          <span className="inputBlock-icon">
            <div className="inputBlock-img">
              <div className="svgIcon-referral svgIcon-referral-dims" />
            </div>
          </span>
        </div>
        <div className="inputBlock">
          <p className="checkBox">
            <input
              {...this.bindInput("terms", "checkbox")}
              id="test2"
            />
            <label htmlFor="test2" dangerouslySetInnerHTML={{__html: t('global_is_18_yo')}} />
          </p>
        </div>
        <div className="inputBlock">
          <input disabled={!this.isValid()} className="authInput"  onClick={this.authConfirm} type="submit" value={t('global_twitch_auth')} />
        
        </div>
      </form>
    )
  }
}
)