import * as SocketAPI from "api/SocketAPI"
import { store } from "libs/redux"
import "whatwg-fetch"

export const updated = async (callback) => {
  const token = store.getState().user.model.token || ""

  SocketAPI.notifications(({ error, response, type, socket }) => {
    if (error) {
      return
    }

    if (callback && type == "notify") {
      console.log('test1231231', response, type, socket);
      const notify = response
      notify.createdAt = Date.now()
      callback(notify)
    }
    if (callback && type == "betty") {
      const notify = response
      notify.createdAt = Date.now()
      callback(notify)
    }
  })
}

export const get = async (callback) => {
  const token = store.getState().user.model.token || ""
  
  const headers = new Headers()
  headers.append("Accept", "application/json") // This one is enough for GET requests
  headers.append("Content-Type", "application/json") // This one sends body
  headers.append("Authorization", token)

  fetch("/api/user/notifications", {
    method: "GET",
    headers
  })
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.code == 500 || json.code == 1001) {
        callback(null, json.message)
        return
      }


      const result = json
        .sort((a, b) => { return b.id - a.id })
        .slice(0, 5)
      // .map(item => { return { ...item } })

      // success
      callback(result)
    })
    .catch((error) => {
      // AHHHH! An Error!
      callback(null, error)
    })
}
