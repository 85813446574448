
import React, { Component } from "react";
import {trans} from "utils/translator";
class Referral extends Component{  
  render(){
  const { t, handleCubeClick, handleShareClick } = this.props;
  return (
      <div className="depositMenu" id="depositMenu">
        <ul className="depositMenuList"> 
          <li className="depositMenuList-item">
            <div className="img icon svgIcon-clock svgIcon-clock-dims" />
            <h3 className="depositMenuList-header">{t('global_daily_cubes')}</h3>
            <p className="depositMenuList-desc">
            {t('global_daily_description')}
            </p>
            <button
              onClick={handleCubeClick}
              className="depositMenuList-btn pre-btn pre-btn_blue"
            >
            {t('global_daily_action')}
            </button>  
          </li>
          <li className="depositMenuList-item">
            <div className="img icon svgIcon-share svgIcon-share-dims" />
            <h3 className="depositMenuList-header">{t('global_by_share')}</h3>
            <p className="depositMenuList-desc">
            {t('global_by_share_description')}
            </p>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A//predictoria.com"
              className="depositMenuList-btn pre-btn pre-btn_blue"
              target="_blank"
              onClick={handleShareClick}
            >
            {t('global_by_share_action')}
            </a>
          </li>
        </ul>
      </div>
  )
  }
}

export default trans(Referral);