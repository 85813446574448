import React from 'react';
import cn from 'classnames';


const Panel = props => {
  const {
    children,
    classes,
    className,
    appearance,
    bRadius,
    shouldShowCloser,
  } = props;
  return (
    <div className={cn('panel', classes, className, appearance, bRadius)}>
      {shouldShowCloser && (
        <div className="panel__closer-layout">{Closer(props)}</div>
      )}
      {children}
    </div>
  );
};

export default Panel;
