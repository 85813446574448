import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import FormValidator from "components/Common/FormValidator"


import { trans } from "utils/translator"
import { val_gte, val_lte, lte, gte, isNum, isInt } from "utils/validator"
import { notificClick } from "utils/notifications"

import * as UserActions from "actions/UserActions"
import * as PredictionsActions from "actions/PredictionsActions"
import * as ModalActions from "actions/ModalActions"

import ExpandableInput from 'components/Common/ExpandableInput'
import AuthForm from "components/AuthForm"
import Button from 'components/ds/Button';
import { StackLayout } from './../ds/Layouts/Layouts';

class ChallengeCreate extends FormValidator {
  static get descMaxLength() { return 200 }
  constructor(props) {
    super(props)
    const { props: { streamID, t} } = this
    this.state = {
      form: {
        displayName: { value: t('cc_def_user'), rules: [lte(20), gte(0)], checkImmediately: true},
        donate: { value: 10, rules: [val_gte(0), val_lte(9999), isInt], checkImmediately: true },
        description: { value: t('cc_def_desc'), rules: [lte(200), gte(0)], checkImmediately: true },
        stream: { value: streamID}
      },
      isOpenChallenge: false,
    }
  }
  preventEnter = (e)  => {
    if(e.keyCode==13) e.preventDefault()
  }
  handleMsg = (e) => {
    const { 
      props: {
         PredictionsActions: { createChallenge }, ModalActions: { toggleAuthModal }, UserActions, streamID: stream } } = this
    let { displayName, description, donate } = this.getFormData()
    
    if(!description.length) { 
      notificClick('Error', 'Please, fill the description field', 'error', [])
      return 
    }
    
    // if(isNaN(+donate) || +donate < 10){
    //   notificClick('Error', 'Wrong donation value', 'error', [])
    //   return 
    // }

    if(!displayName.length) displayName = 'Anonymous'

    if(donate < 1){
      notificClick('Error', 'Minimal value of donation must be greater or equal 1')
      return 
    }

    if(!confirm(`${donate} orbs will be debited from your account. Do you want to proceed?`)) return

    createChallenge({ displayName, description, donate, stream }, (error, prd) => {
      if (error) {
        const { code, message } = error
          if(code == 'WRONG_TOKEN')
          notificClick('Error', 'Вы должны зарегистироваться чтобы создаваться челленджи', "error", [{
            name: 'Зарегистрироваться',
            primary:true,
            onClick: () => {
              toggleAuthModal(true, { activeTab: AuthForm.VIEWER_TAB })
            }
          }] )
          else notificClick('Error', message, 'error', [])
          gtag("event", "challenge", { 
            event_category: 'user_actions',
            event_action: 'error',
            error: code
          })
      } else {
        gtag("event", "challenge", { 
          event_category: 'user_actions',
          event_action: 'challenge_create',
          prd
        })
        notificClick("Succsess", "Your offer is being processed, it may take a few minutes for your offer to be processed")
        UserActions.updateUserBalance();
      }
    })
  }

  render() {
    const { description, donate } = this.getFormData()
    const { props: { handleCloseChallenge, t }, handleMsg, preventEnter } = this
    return (
      <div className="feedbackModal create-challenge">
        <div className="row-container row-container_space">
          <p className="feedbackModal-title">{t('cc_offer')}</p>
          <div className='icon icon_close' onClick={handleCloseChallenge} />
        </div>
        <form action="#" className="feedbackModal-form">
         <div className="form__input-block create-challenge__input-block">
            <label htmlFor='displayName' className="text form__label">{t('cc_username')}</label>
            <input className="form__input" {...this.bindInput('displayName','text')}/>
          </div>
          <div className="form__input-block create-challenge__input-block">
            <label htmlFor='description' className="text form__label ">{t('cc_description')}</label>
            <ExpandableInput className="form__input create-challenge__textarea" 
             {...this.bindInput('description','text')}
             onKeyDown={preventEnter}
             />
            <div className='text text_medium'>{`${ChallengeCreate.descMaxLength - description.length} ${t('cc_symbols_left')}`}</div>
          </div>
          <div className="form__input-block create-challenge__input-block">
            <label htmlFor='donate' className="text form__label ">{t('cc_donate')}</label>
            <input className="form__input" {...this.bindInput('donate','text', [isInt])} />
          </div>
          <div className="form__input-block create-challenge__input-block">
            <label className="text text_medium create-challenge__agreement">
            {t('cc_agree')}
            <Link className='link link_gold link_underline create-challenge__link' to='/help/'>{t('cc_rules')}</Link>
            </label>
            <div className="row-container center">
            <StackLayout gutter='small'>
              <Button
                  isDisabled={isNaN(+donate) || donate < 10}
                  onClick={handleMsg}
                  appearance="accent">
                <span className="text">{t('cc_offer')}</span>
                <span className="text--small">{t('cc_offer_min')}</span>
                </Button>
                <Button
                  isDisabled={isNaN(+donate) || donate < 100}
                  onClick={handleMsg}
                  appearance="accent">
                  <span className="text">{t('cc_offer_direct')}</span>
                  <span className="text--small">{t('cc_offer_direct_min')}</span>
                </Button>
              </StackLayout>
            </div>
          </div>
        </form>
      </div>
    )
  }

  
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
    ModalActions: bindActionCreators(ModalActions, dispatch)
  }
}

export default trans(connect(mapStateToProps, mapDispatchToProps)(ChallengeCreate))
