import { set, cloneDeep } from "lodash"

export default (namespace) => {
  const reducers = {}

  reducers.set = (path, data) => {
    return {
      namespace,
      type: "COLLECTION_SET",
      handle: (state) => {
        state = cloneDeep(state)
        if (typeof path === "object") {
          Object.entries(path).forEach(el => set(state, el[0], el[1]))
        } else {
          set(state, path, data)
        }
        return state
      }
    }
  }

  return reducers
}
