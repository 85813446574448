import React, { Component } from "react"
// import FacebookLoginButton from "./FacebookLoginButton";


export default class AuthSocial extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  render() {
    const href = `/api/auth/${this.props.provider}`
    if (this.props.provider == "twitch") {
      const src = `/assets/images/main/main-social/${this.iconFor(this.props.provider)}`
      return (
        <a href={href}>
          <img className="socialContainer-img" src={src} alt={this.props.provider} />
        </a>
      )
    }
    // if (this.props.provider == "facebook") {
    //   return (
    //     <a href={href}>
    //       <FacebookLoginButton />
    //     </a>
    //   )
    // }
    const iconClassName = `svgIcon-${this.iconFor(this.props.provider)} svgIcon-${this.iconFor(this.props.provider)}-dims`
    return (
      <a href={href}>
        <div className="socialContainer-img">
          <div className={iconClassName} />
        </div>
      </a>
    )
  }

  // iconFor(provider) {
  //   switch (provider) {
  //     case "facebook": return "fb.svg"
  //     case "twitch": return "twitch.png"
  //     case "steam": return "steam.svg"
  //     case "google": return "google.svg"
  //     default: return "fb.svg"
  //   }
  // }
  iconFor(provider) {
    switch (provider) {
      case "facebook": return "fb"
      case "twitch": return "twitch.png"
      case "steam": return "steam"
      case "google": return "google"
      default: return "fb"
    }
  }
  handleClick(button) {
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
    this.loginSocialAPI(button, user.accessToken)
  }

  loginSocialAPI(provider, code) {

    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Access-Control-Expose-Headers", "Location") // for oauth purposes
    headers.append("Authorization", code)

    fetch(`/api/auth/${provider}`, {
      method: "POST",
      headers
    })
      .then((response) => {
        return response
      })
      .then((response) => {
        const location = response.headers.get("Location")

        // const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
        // user.accessToken = json.token
        // localStorage.setItem("predictoria_currentUser", JSON.stringify(user))

        // this.props.history.push("/")
      })
      .catch((error) => {
        // AHHHH! An Error!
        console.dir(error)
        // alert("Server Error, try again or contact us via feedback form")
      })
  }
}
