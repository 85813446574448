import React, { Component } from "react"
import { Switch, Route, withRouter } from "react-router"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { trans } from "utils/translator"
import i18next, { languages } from "i18n"

import * as UserActions from "actions/UserActions"
import * as PredictionsActions from "actions/PredictionsActions"
import * as ChallengeActions from "actions/ChallengeActions"
import * as VotingActions from "actions/VotingActions"
import * as PaidActionActions from "actions/PaidActionActions"
import * as PaidActionTemplateActions from "actions/PaidActionTemplateActions"
import * as ModalActions from "actions/ModalActions"
import * as ThemesActions from "actions/ThemesActions"
import * as StreamActions from "actions/StreamActions"

import MainContent from "components/MainContent"

import Header from 'components/_Header';
import Footer from "components/Footer";
import About from "components/About"
import Faq from "components/Faq"
import BuyOrbs from "components/BuyOrbs"

import ProfileContainer from "components/ProfilePage/"
import Notification from "components/Notifications"

import ConfirmRegistration from "components/ConfirmRegistration"
import CompleteProfileContainer from "containers/ProfileContainer/CompleteProfileContainer"
import ResetPassword from "components/ResetPassword"
import ConfirmPassword from "components/ConfirmPassword"

import NoMatchedRouteComponent from "components/NotFound"
import Bonuscode from "components/Deposit/Bonuscode"
import DepositMobile from "components/Deposit/DepositMobile"
import GamePage from "components/GamePage"
import Leaderboard from "containers/Leaderboard"
import AdminContainer from "containers/AdminContainer"
import HowItWorks from "components/HowItWorks"
//betty
import BettyContainer from "containers/BettyContainer"



import PostEditor from "components/PostEditor/PostEditor"
import HelpCenter  from "components/HelpCenter"
import Login from 'components/Auth/Login';
import Register from 'components/Auth/Register';
import Landing from './../Landing';
import Profile from 'components/Profile';
import Streams from 'components/Streams';
import ProfileHeader from 'components/Profile/ProfileHeader';
import ProfileHello from 'components/Profile/ProfileHello';
import StreamPage from 'components/StreamPage';

import { appRoutes } from 'utils/config';
import ModalContainer from 'components/ModalContainer';
import { getUserFromLocalStore } from 'utils/helpers';

 class RouteComponent extends Component {
  constructor(props) {
    super(props)
    const path = this.props.location.pathname
    const showModal = localStorage.getItem('helloModalFlag') 
    const user = JSON.parse(localStorage.getItem("predictoria_currentUser")) || {}
   
    const isAuth = !!user.token
    const isLoggedIn = !!user.id
    const isAlreadySubscribed = isAuth && Array.isArray(user.push) && user.push.length

    const showSubscriptionLine = isAuth && !isAlreadySubscribed
    // const showSubscriptionLine = false
    

    
    if(!showModal) { 
      localStorage.setItem('helloModalFlag', true)
    }
    this.state = {
      isModalOn: !showModal && !path.includes("/ref"),
      isSubscriptionOn: false,
      showSubscriptionLine,
      userLoaded: false,
      isAuth: false,
      // isModalOn: true, 
    }

    this.checkLogin = this.checkLogin.bind(this)
    this.readNewUser = this.readNewUser.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleTriggerClick = this.handleTriggerClick.bind(this)
    this.onSubscribeLineOn = this.onSubscribeLineOn.bind(this)
  }
  onSubscribeLineOn = () => {
    this.setState({
      isSubscriptionOn: true,
    })
  }
  onSubscribeLineOff = () => {
    this.setState({
      isSubscriptionOn: false,
    })
  }
  handleClick = () => {
    this.setState({
      isModalOn: false,
    })
  }

  handleTriggerClick = () => {
    this.setState({
      isModalOn: true,
    })
  }
  
  componentDidMount() {
  
    const { ThemesActions, StreamActions, UserActions} = this.props
    const { isModalOn, isAuth } = this.state
    // init socket. move init here, because if user visit admin via direct link, init wont be triggered 
    const theme = localStorage.getItem("theme")

    //mb use sagas, and make init app saga
    StreamActions.get();
    ThemesActions.applyTheme(theme)


    /**
    |--------------------------------------------------
    | READ USER PROFILE
    |--------------------------------------------------
    */
    const { token } = getUserFromLocalStore();
    
    if(token){
      UserActions.readUser(token);
    }
    

    
    
    // Logic for Push API
    // if ("serviceWorker" in navigator) {
    //   subscribe().catch(err => console.error(err))
    // }
    // Logic for Push API
    
  	 fetch(`/api/i18n/`)
 	.then(res => res.json())
 	.then(json => { 
 		json.map((lang, i) => {
 		i18next.addResourceBundle(lang.lang,'translation', lang.translation, true, true)
 		})
    })
    this.checkLogin()
  }

  render() {
    // validate user
      return ( 
      <div className={`root ${this.props.theme}`} >
      <div className='app__top bg-dark-magenta'>
        <Header />
        <Switch>
          <Route path={appRoutes.STREAM} render={(location) => {
           return <ProfileHeader streamName={location.match.params.id || 'Nickname'} createdAt={'2019'}/> 
          }} />
          <Route path='/profile' render={ProfileHello} />
        </Switch>
        <Notification />
        <ModalContainer />
      </div>
      <div className="app__main">
        <Switch>
          <Route path="^(?!.*(\/leaderboard)).*$" render={() =>  <div></div>} />
          <Route path='/ref/:ref' component={MainContent} />

          <Route path='/payments/:status' component={MainContent}  />

          <Route path="/info" component={Faq} />
          <Route path="/about" component={About} />
          {/* <Route path="/profile/" component={ProfileContainer} /> */}
          <Route path="/buy" component={BuyOrbs} />

          {/* <Route path="/stream" component={MainContent} /> */}
          {/* <Route path="/streams/" component={StreamsContainer} /> */}

          <Route exact path="/leaderboard" render={() => <Leaderboard/>} />

          <Route path="/leaderboard/streamers" render={() => <Leaderboard streamers/>} />

          <Route path="/games/" component={GamePage} />

          <Route exact path="/" component={Landing} />
          <Route exact path="/main" component={MainContent} />
          <Route path="/complete-profile" component={CompleteProfileContainer} />
          <Route path="/confirm-registration" component={ConfirmRegistration} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/confirm-password" component={ConfirmPassword} />
          <Route path="/receive-bonus" component={Bonuscode} />
          <Route path="/deposit" component={DepositMobile} />
          <Route path="/test" component={HowItWorks} />
          {/* Betty */}
          <Route path="/betty" component={BettyContainer} />
          <Route path="/test-editor/:id" component={PostEditor} />
          <Route path="/test-editor/" component={PostEditor} />
          <Route path="/admin/" component={AdminContainer} />

          <Route path="/help" component={HelpCenter} />
          <Route path={appRoutes.LOGIN} component={Login} />
          <Route path={appRoutes.REGISTRATION} component={Register} />
          <Route path={appRoutes.PROFILE} component={Profile} />
          <Route path={appRoutes.STREAMS} component={Streams} />
          <Route path={appRoutes.STREAM} component={StreamPage} />
          <Route component={NoMatchedRouteComponent} />
          
          
        </Switch>
        </div>
        <div className="app__bottom">
        <Footer />
        </div>
      </div>
      )
  }


  checkLogin() {

    if (location.pathname != "/") {
      return
    }

    const queryString = require("query-string")
    const parsed = queryString.parse(this.props.location.search)
    const token = parsed.token
    const status = parsed.status
    const provider = parsed.provider
    const redirect = localStorage.getItem('regInfo') == 'Streamer' ? '/profile/stream' : '/'
    localStorage.removeItem('regInfo')
    if (status == "created" && token) {
      this.readNewUser(token)
      gtag("event", "sign_up", { method: provider })
    } else if (status == "updated" && token) {
      this.readNewUser(token, false, redirect)
      gtag("event", "login", { method: provider })

      // const id = parsed.id
      // const provider = parsed.provider
      // this.props.userActions.updateSocial(provider, id)
      // this.props.history.push("/profile")
    } else if (token) {
      // complete profile
      this.readNewUser(token, true)
    }
  }

  readNewUser(token, completeProfile, redirect) {
    const headers = new Headers()
    headers.append("Accept", "application/json") // This one is enough for GET requests
    headers.append("Content-Type", "application/json") // This one sends body
    headers.append("Authorization", token)

    fetch("/api/user/", {
      method: "GET",
      headers
    })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json.status == 1002 || json.status == 1001) {
          window.location.href = "/"
          return
        }

        if (json.message === "invalid signature") {
          localStorage.clear()
          location.href = "/"
  
          return
        }

        const user = json
        console.log('debug 123123', user)
        user.token = token
        this.props.userActions.signIn(json.email, json.password, user)

        if (completeProfile) {
          this.props.history.push("/complete-profile")
        } else {
          if(redirect)
          window.location.href = redirect
          else
          window.location.href = '/'
        }
      })
      .catch((error) => {
        // AHHHH! An Error!
        window.location.href = "/"
      })
  }


  static mapDispatchToProps(dispatch) {
    return {
      userActions: bindActionCreators(UserActions, dispatch),
      PredictionsActions: bindActionCreators(PredictionsActions, dispatch),
      UserActions: bindActionCreators(UserActions, dispatch),
      ChallengeActions: bindActionCreators(ChallengeActions, dispatch),
      VotingActions: bindActionCreators(VotingActions, dispatch),
      PaidActionActions: bindActionCreators(PaidActionActions, dispatch),
      PaidActionTemplateActions: bindActionCreators(PaidActionTemplateActions, dispatch), 
      ModalActions: bindActionCreators(ModalActions, dispatch),
      ThemesActions: bindActionCreators(ThemesActions, dispatch),
      StreamActions: bindActionCreators(StreamActions, dispatch)
    }
  }

  static mapStateToProps(state) {
    return {
      user: state.user,
      theme: state.theme
    }
  }
}
export default trans(withRouter(connect(
  RouteComponent.mapStateToProps,
  RouteComponent.mapDispatchToProps
)(RouteComponent)))
