import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Route, Switch } from "react-router-dom"
import * as StreamActions from "actions/StreamActions"
import PredictionStreamItem from "components/PredictionStreamItem"

class PredictionsStreamContainer extends Component {
  renderSliderItems(data) {
    const { StreamActions } = this.props
    return data.map(el => (
      <Route
        path={`/stream/${StreamActions.streamerID(el.type, el.stream).trim()}`}
        key={StreamActions.streamerID(el.type, el.stream).trim()}
        render={() => <PredictionStreamItem streamlist={(this.props.streams.streamlist)}  stream={el} StreamActions={this.props.StreamActions} />}
      />
    ))
  }

  render() {
    const streamList = this.props.streams.streamlist || []
    return (
      <div>{this.renderSliderItems(streamList)}</div>
    )
  }
}

function mapStateToProps(state) {
  return {
    streams: state.streamList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    StreamActions: bindActionCreators(StreamActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PredictionsStreamContainer)
