import React, { Component } from "react"

import {history} from "libs/router"
import {trans} from "utils/translator"

export default trans(class StreamingPlatformSelector extends Component {

constructor(props) {
    super(props)
    this.state = {
        platforms: ['twitch','youtube']
    }  
  }
  
  requestAuthorization = (platform) => {
    window.location.href = `/api/auth/${platform}`
  }

  checkAuthorization = (platform) => {
      const { user } = this.props
      return !!user.model[platform]
  }
  
  render() {
    const { props: { t, user }, state: { platforms } } = this
   
   return <div className='block streaming-platform-selector'>
    <div className="block">
        <h3 className="head head_center head_large streaming-platform-selector__header">{t('profile_choose_platform')}</h3>
        <h2 className="text head_center head_small">{t('profile_you_can')}</h2>
        <div className="row-container row-container_around row-container_wrap">
            {
                platforms.map((platform, i) => {
                    const isPlatformAuthorized = this.checkAuthorization(platform)
                    return <div className={`block streaming-platform streaming-platform_${platform}`}>
                        <button className="button button_gold streaming-platform__button pre-btn" onClick={ (e) => {
                             e.preventDefault() 
                             if(isPlatformAuthorized) history.push({
                                 pathname: `/profile/stream/${platform}`,
                                 state: { platform }
                             })
                             else this.requestAuthorization(platform)
                             } } >
                             { isPlatformAuthorized ? `Setup ${platform}` : `Add ${platform}` }
                        </button>
                   </div>
                })
            }
        </div>
    </div>
  
   </div>
  }
})
