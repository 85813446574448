import collectionReducers from "utils/collectionReducers"

const initialState = {
  list: [], 
  listStreamers: [],
}

const NAMESPACE = "LEADERBOARD"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function setNewList(list) {
  return {
    namespace: NAMESPACE,
    type: "SET_LEADERBOARD_LIST",
    handle: (state) => {
      return {
        ...state,
        list, 
      }
    }
  }
}


export function setNewListStreamers(listStreamers) {
  return {
    namespace: NAMESPACE,
    type: "SET_LEADERBOARD_LIST_STREAMERS",
    handle: (state) => {
      return { ...state, listStreamers }
    }
  }
}