import collectionReducers from "utils/collectionReducers"

const initialState = []

const NAMESPACE = "VOTINGTEMPLATELIST"

export default (state = initialState, action) => {
  return action.namespace === NAMESPACE && action.handle ? action.handle(state) : state
}

export const collection = collectionReducers(NAMESPACE)

export function setTemplates(list) {
  return {
    namespace: NAMESPACE,
    type: "SET_VOTING_TEMPLATE_LIST",
    handle: (state) => {
      return [...list]
    }
  }
}

export function add(item) {
  return {
    namespace: NAMESPACE,
    type: "ADD_VOTING_TEMPLATE_ITEM",
    handle: (state) => {
      state.push(item)
      return [...state]
    }
  }
}


export function remove(id) {
  return {
    namespace: NAMESPACE,
    type: "REMOVE_VOTING_TEMPLATE_ITEM",
    handle: (state) => {
      const list = state.filter((o) => { return o.id !== id })
      return [...list]
    }
  }
}

export function update(votingTemplate) {
  function _update(votingTemplates) {
    const index = votingTemplates.findIndex(item => item.id === votingTemplate.id)

    // заменяем/добавляем
    if (index != -1) {
      votingTemplates[index] = votingTemplate
    } else {
      votingTemplates.push(votingTemplate)
    }
    return votingTemplates
  }

  return {
    namespace: NAMESPACE,
    type: "UPDATE_VOTINGS_TEMPLATES",
    handle: (state) => {
      const newlist = _update(state)
      const copyNewlist = newlist.map(item => Object.assign({}, item))
      return [ ...copyNewlist ]
    }
  }
}