import React, { Component, Fragment } from 'react';
import { connect } from "react-redux"

import StubPage from "./StubPage"

class RenderWithPermission extends Component {
  constructor(props) {
    super(props)
    const { props: { requiredPermissions, optionalPermissions,  user: { model: { permissions } } }, checkPermissions, tick } = this
    const hasPermission = checkPermissions(permissions, requiredPermissions, optionalPermissions)
    if(!hasPermission) setTimeout(() => window.location = '/', 3000)
    this.state = {
        hasPermission,
    }
  }

checkPermissions = (userPermissions=[], requiredPermissions=[], optionalPermissions=[]) => {
    return requiredPermissions.every(permission => userPermissions.includes(permission)) 
    || optionalPermissions.some(permission => userPermissions.includes(permission)) 
}
 render() {
 const { props: { children }, state: { hasPermission } } = this
 return (<Fragment>
    {
        hasPermission ?  
        <Fragment>{ children } </Fragment>
         : <StubPage title={'YOU ARE TRYING TO ACCESS FORBIDDEN PAGE'} message={'YOU WILL BE REDIRECTED TO THE MAIN PAGE IN A FEW MOMENTS'}/>
    }
    </Fragment>)
 }
}

function mapStateToProps(state) {
    return {
      user: state.user
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {}
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(RenderWithPermission)
  