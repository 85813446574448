import React, { Component } from "react"
import { trans } from "utils/translator"
import { Link, Redirect } from 'react-router-dom'
import FormValidator from "components/Common/FormValidator"
import ModalWindow from "components/ModalWindow"
import * as StreamAPI from "api/StreamAPI"
import {history} from "libs/router"
import { notify } from "reapop"

export default trans(class StreamManagementNew extends FormValidator{
  
    constructor(props) {
    super(props)
    const defaultStreamSettings = {
        online:  false,
        title:  'Choose a name for your stream',
        image: ''
    }
    const { streams } = this.props 
    const userHasStream = !!(Array.isArray(streams) && streams.length)

    const { title, online, image } = userHasStream ? streams[0] : defaultStreamSettings

    this.state = {
        form: {
            online: {
                value: online,
                rules: [],
            },
            title: {
                value: title,
                rules: []
            },
            image: {
                value: image,
                rules: []
            }
        },
        userHasStream,
        isImageLinkModalOpened: false
    }  
  }

  componentDidMount(){
    setTimeout(() => jivo_api.open("Нужна помощь, консультация или совет? Обращайтесь ко мне."), 5000)
    }
    
  componentWillReceiveProps(nextProps){
    const { streams } = nextProps

    if(!!(Array.isArray(streams) && streams.length)){
        const { title, online, image } = streams[0]
        this.setFieldData('title', title)
        this.setFieldData('online', online)
        this.setFieldData('image', image)
        this.setState({
            userHasStream: true
        })
    }
  }
  handleFormSubmit = (e) => {

  }
  changeStreamStatus = (e) => {
    e.preventDefault()
    const newValue = !this.state.form.online.value
    this.setFieldData('online', newValue)
  }

  handleSelectedImage = (e) => {
    this.setFieldData('image', e.target.files[0])
  }

  selectImage = (e) => {
    e.preventDefault()
    const fileInput = this.refs.fileInput
    fileInput.click()
    // not finished
  }
  
  convertObjectToFormData = (object) => {
    const formData = new FormData()
    for ( let key in object ) {
        formData.append(key, object[key]);
    }
    return formData
  }
  toggleImageLinkModal = (e) => {
      e.preventDefault()
      this.setState(prevState => ({
        isImageLinkModalOpened: !prevState.isImageLinkModalOpened,
      }))
  }

  notifyAlert = (status, msg) => {
      return this.props.notify({
        title: status,
        message: msg,
        status: status.toLowerCase(),
        dismissible: true,
        dismissAfter: 1200,
      })
  }

  createOrUpdateStream = (e) => {
    const { StreamActions, streams } = this.props 
    const { form, userHasStream } = this.state
    const { create, updateStream } = StreamActions
    const { platform } = this.props.location.state
    const { title, online, image } = this.getFormData()
    e.preventDefault()
    
    if(userHasStream)
    updateStream({ title, online, image, type: platform }, streams[0].id, (err, stream) => {
        if (!err) {
            //alert('Success. Update')
            this.notifyAlert('Success', 'Stream info updated');
            // Just for Dev
            const streamerName = stream.stream;
            setTimeout(() => history.push(`/admin/stream/${streamerName}`), 1200);
        } else{
            // alert('Error. Update')
            this.notifyAlert('Error', `Updating error, pls try again. ${err.message || ''}`);
            // this.notifyAlert('Error', `Updating error, pls try again \n ${err.code}`);
        }
    })
    else create({ title, online, image, type: platform }, (err, stream) => {
        if (!err) {
            // alert('Success. Create')
            const streamerName = stream.stream;
            this.notifyAlert('Success', 'Stream created');
            setTimeout(() => history.push(`/admin/stream/${streamerName}`), 1200);
            // console.warn(stream);
        } else{
            // alert('Error. Create')
            this.notifyAlert('Error', `Error, can't create stream. Please try again. ${err.message || ''}`);
        }
    })
  }

  inviteModerator = (e) => {
    const { givePermissions } = StreamAPI
    const { props: { streams } } = this
    const id = streams[0].id 
    const { moderators } = this.getFormData()
    e.preventDefault()
    givePermissions(id, { id: moderators }, (err, msg) => {
        if(!err) alert('Success')
        else alert('Error')
    })
  }
  render() {
    const { props, state, toggleImageLinkModal, inviteModerator } = this
    const { t, streamList } = props
    const { form, userHasStream, isImageLinkModalOpened } = state
    const { online } = form
    return (<div className="profile-stream-management">
   <div className="row-container">
        <div className="block">
            <div className="profile-stream-management__bg-image"/>    
        </div>
        <div className="block">
            <div className="profile-stream-management__stream-settings">
                <div className="col-container profile-stream-management__settings-container">
                    <h3 className="head head_medium head_center">{t('profile_stream_settings')}</h3>
                    <form ref='form' className="form profile-stream-management__settings-form">
                        <div className="form__input-block">
                            <div className="row-container">
                                <label className="text form__label form__label_icon" htmlFor="title">
                                {t('profile_stream_name')}
                                </label>
                                <i className='icon icon_exclamation profile-stream-management__icon' tooltip={t('profile_stream_name_tooltip')} />
                            </div>
                            <input className="form__input" {...this.bindInput("title", "text")}/>
                        </div>
                        <div className="form__input-block">
                            <div className="row-container">
                                <label className="text form__label form__label_icon" htmlFor="moderators">
                                {t('profile_direct_invite')}
                                </label>
                                <i className='icon icon_exclamation profile-stream-management__icon' tooltip={t('profile_direct_invite_tooltip')} flow='right' />
                            </div>
                            <input className="form__input" {...this.bindInput("moderators", "text")}/>
                            <button 
                            className="button button_blue profile-stream-management__invite-button pre-btn pre-btn_blue"
                            onClick={inviteModerator}
                            >{t('profile_direct_invite_button')}</button>
                        </div>
                        <div className="row-container">
                            <div className="form__input-block">
                                <div className="row-container">
                                    <label className="text form__label form__label_icon" htmlFor="status">
                                    {t('profile_online_status')}
                                    </label>
                                    <i className='icon icon_exclamation profile-stream-management__icon' tooltip={t('profile_online_status_tooltip')} />
                                </div>
                                <button
                                    className={`profile-stream-management__status-button button ${online.value ? 'button_blue' : 'button_grey'}`}
                                    name="status"
                                    onClick={this.changeStreamStatus}
                                    >{online.value ? t('profile_status_online') : t('profile_status_offline')}
                                </button>
                            </div>
                            <div className="form__input-block profile-stream-management__choose-image">
                                <div className="row-container">
                                    <label className="text form__label" htmlFor="image">
                                    {t('profile_choose_image')}
                                    </label>
                                    <i className='icon icon_exclamation profile-stream-management__icon' tooltip={t('profile_choose_image_tooltip')} />
                                </div>
                                <input 
                                ref="fileInput"
                                type="file"
                                style={{ display: 'none'}}
                                onChange={this.handleSelectedImage}/>
                                <button 
                                className="button button_blue profile-stream-management__choose-image-button pre-btn pre-btn_blue" 
                                name="image"
                                onClick={toggleImageLinkModal}
                                >
                                {t('profile_choose_image')}
                                </button>
                                   <ModalWindow show={isImageLinkModalOpened} hideModal={toggleImageLinkModal} userClass='profile-stream-management__modal'>
                                          <div className="form__input-block profile-stream-management__modal-content">
                                            <div className="row-container">
                                                <label className="text form__label form__label_icon" htmlFor="title">
                                                {t('profile_choose_image')}
                                                </label>
                                                <i className='icon icon_exclamation profile-stream-management__icon' tooltip={t('profile_choose_image_modal_tooltip')} flow="right" />
                                            </div>
                                            <input className="form__input" {...this.bindInput("image", "text")}/>
                                            <button className="button button_blue profile-stream-management__ok-button" onClick={toggleImageLinkModal}>Ok</button>
                                        </div>
                                    </ModalWindow>
                            </div>
                        </div>
                        {/* <a href="/help/view/article/Rules%20of%20activities" className="link">
                            {t('profile_read_terms')}
                        </a> */}
                        <div className="profile-stream-management__helpers">
                            <Link to={`/help/view/${t('header_how_it_works')}`}>{t('profile_read_terms')}</Link>
                            { userHasStream ? <Link className="profile-stream-management__admin-link" to={`/admin/stream/${this.props.streams[0].stream}`}>{t('admin_panel')}</Link> : null }
                        </div>
                        <button 
                            className="button button_gold profile-stream-management__save-button pre-btn" 
                            name="save"
                            onClick = {this.createOrUpdateStream}
                        >
                            { userHasStream ? t('profile_save') : t('profile_create')}
                        </button>
                    </form>
                    
                </div>
            </div>
        </div>
   </div>
   </div>)
  }
})
