import React, {Component} from "react"
import { trans } from "utils/translator";
import  {Editor, convertFromRaw, EditorState} from "draft-js";

export default trans(class About extends Component{
  render(){
    const { t } = this.props
    return (
      <div className="aboutContent">
      <div className="tabContent activeTab" id="tab2" dangerouslySetInnerHTML={{
        __html: t('about-global')
      }}>
      </div>
      </div>
    )
}
})

