import Panel from './Panel';
import PropTypes from 'prop-types'
import './panel.scss';

export const PanelBorderRadiusStyles = {
    default: 'panel-b-radius_default', 
    topLeft: 'panel-b-radius_top-left', 
    topRight: 'panel-b-radius_top-right',
    bottomLeft: 'panel-b-radius_bottom-left',
    bottomRight: 'panel-b-radius_bottom-right', 
    top: 'panel-b-radius_top', 
    bottom: 'panel-b-radius_bottom',
    none: 'panel-b-radius--none'
}

export const PanelAppearanceStyles = {
    white: 'panel-appearance_white', 
}


Panel.propTypes = {
    component: PropTypes.string,
    classes: PropTypes.array,
    appearance: PropTypes.oneOf(['white', 'prewhite', 'panel-appearance--pattern-red']),
    bRadius: PropTypes.oneOf(['default', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'top', 'bottom']),
}
  
Panel.defaultProps = {
      component: 'div', 
      appearance: PanelAppearanceStyles['white'], 
      bRadius: PanelBorderRadiusStyles['default']
}

export default Panel;