import { Component } from "react"
import validate from "utils/validator"

export default class FormValidator extends Component {
  state = { form: {} }

  setFieldData(name, value, changeType) {
    const field = this.state.form[name]
    field.isValid = validate(value, field.rules) 

    if(field.isValid && field.checkImmediately) field.value = value
    else if(!field.checkImmediately) field.value = value
    
    field.isDirty = field.isDirty ? true : (value !== "" && changeType === "blur")
    this.setState({ ...this.state, form: { ...this.state.form, [name]: field } })
  }

  getFormData() {
    const { state: { form } } = this
    return Object.keys(form).reduce((formData, field) => {
      formData[field] = form[field].value
      return formData
    }, {})
  }
  bindInput(name, type = "text", rules = [], value = "") {
    const field = this.state.form[name] || { 
      value, 
      rules, 
      isValid: validate(value, rules), 
      isDirty: false 
    }
    this.state.form = { ...this.state.form, [name]: field }
    return {
      name,
      value: field.value,
      type,
      onChange: this.handleFormInputChange,
      onBlur: this.handleInputBlur
    }
  }

  isValid(name) {
    if (!name) {
      return Object.keys(this.state.form)
        .filter(key => !!this.state.form[key].rules)
        .every(key => this.state.form[key].isValid)
    }
    const { isDirty, isValid } = this.state.form[name]
    return isDirty ? isValid : true
  }
  // to do create validate method 
  // validate(callback){
  //   calback(invalidFields, rules) 
        // inform user what fiedls are wrong
  // }
  handleInputBlur = (event) => {
    const { type, checked, value, name } = event.target
    const newValue = type === "checkbox" ? checked : value
    this.setFieldData(name, newValue, "blur")
  }

  handleFormInputChange = (event) => {
    const { type, checked, value, name } = event.target
    const newValue = type === "checkbox" ? checked : value
    this.setFieldData(name, newValue)
  }
  
}
