import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo';
import Hamburger from './Hamburger';
import MenuDropdown from './MenuDropdown';
import ModalWindow from 'components/ModalWindow';
import { HAMBURGER_MENU_ITEMS_LOGGED_IN, HAMBURGER_MENU_ITEMS_LOGGED_OUT } from '../constants';
import { appRoutes } from 'utils/config';

const Header = props => {
    // temp
    const { isAuthenticated } = props;
    const [isMenuOpen, setMenuOpen] = useState(false);
    return (
        <div className='header-mobile flex justify-between p2 white'>
            <div className="_header__nav flex items-center">
                <Logo size='small' />
            </div>
            {
                isAuthenticated ? <div className="header-mobile__auth flex items-center">
                    <Hamburger onClick={() => setMenuOpen(!isMenuOpen)} /> 
                    </div> : <div className="header-mobile__auth flex items-center">
                        <Link to={appRoutes.REGISTRATION} className='bold h6'>SIGN UP</Link>
                        <Link to={appRoutes.LOGIN} className='bold h6'>SIGN IN</Link>
                    </div>
            }

            {
                <ModalWindow show={isMenuOpen} hideModal={() => setMenuOpen(false)}><MenuDropdown
                    menuItems={HAMBURGER_MENU_ITEMS_LOGGED_IN}
                    onClose={() => setMenuOpen(false)}
                /></ModalWindow>
            }
        </div>
    )
}

export default Header
