
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as ChallengeActions from 'actions/ChallengeActions';
import ChallengeCard from './ChallengeCard';
import './challenge-card.scss';

function mapStateToProps(state) {
    return {
 
    }
  }

function mapDispatchToProps(dispatch) {
    return {
        ChallengeActions: bindActionCreators(ChallengeActions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChallengeCard);