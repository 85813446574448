import React from "react"
import Gravatar from "react-gravatar"
import Dropzone from "react-dropzone"
import LangSwitcher from "components/LangSwitcher"
import ThemeSwitcher from "components/ThemeSwitcher"
import CashboxModal from "./CashboxModal"
import AskForPartershipModal from "./AskForPartnershipModal"
import ModalWindow from "components/ModalWindow"

import { Link } from "react-router-dom"
import { trans } from "utils/translator"
import { groupBy } from "lodash"

 class Main extends React.Component {
   constructor(props){
    super(props)
    this.state={
        isCashboxModalOpen: false,
        payoutMethod: 'CARD',
        isPartnershipModalOpen: false,
    }
    this.handleClick = this.handleClick.bind(this)
   }
   componentDidMount(){
    gtag("event", "profile", { 
      event_category: 'webpage_interraction',
      event_action: 'profile_main_page_visited',
    })
   }
   handleClick = (method) => () => {
    gtag("event", "profile", { 
      event_category: 'webpage_interraction',
      event_action: 'cashbox_clicked',
    })
    this.setState(prevState => ({
      isCashboxModalOpen: !prevState.isCashboxModalOpen,
      payoutMethod: method
    }))
  }
  showJivoChat = () => {
    // jivo_api.sendMessage({name:'Partnership request'}, 'test data')
  } 

  showPartnershipRequestModal = (e) => {
    e.preventDefault()
    this.setState({ isPartnershipModalOpen: true})
  }

  hidePartnershipRequestModal = (e) => {
    e.preventDefault()
    this.setState({ isPartnershipModalOpen: false })
  }
  
  render(){
  let { props: { user: { model: { email, password, name } } } } = this
  const { 
    state: {
      isPartnershipModalOpen
    },
    props: {
      saveAction,
      UserActions: { updateUser },
      user, 
      stream,
      user: {
         model: {
            id,
            facebook,
            steam,
            twitch,
            google,
            avatar: avatarURL,
            permissions = []
        }
      },
      editPhoto,
      isUploading,
      t
    },
    showPartnershipRequestModal,
    hidePartnershipRequestModal
  } = this
  
  const isPartner = permissions.includes('PARTNER') || permissions.includes('PARTNER_PLUS')
  
  const socials = { facebook, steam, twitch, google }
  const { added, notAdded } = groupBy(Object.keys(socials), i => socials[i] ? 'added' : 'notAdded')

  function iconFor(provider) {
    switch (provider) {
      case "facebook": return "fb.svg"
      case "twitch": return "twitch.png"
      case "steam": return "steam.svg"
      case "google": return "google.svg"
      default: return "fb.svg"
    }
  }

  // const authConfirmText = "Due to alpha testing stage, if you want to connect social profiles, registered on different email addresses or your privacy setting blocks Predictoria from access to email field, it will create new account on Predictoria"
  const authConfirmText=t('profile_auth_confirm')
  
  function authConfirm(provider) {
    if (confirm(authConfirmText)) {
      window.location.href = `/api/auth/${provider}`
    }
  }

  const avatar = avatarURL == "/assets/images/avatar.svg" || avatarURL == null ?
  <Gravatar email={email} size={160} default="mm" /> : <img src={avatarURL} alt="avatar" />

  return (
    <div className="profilePage">
      {
        this.state.isCashboxModalOpen ?  
        <div onClick={this.handleClick()} className='cashbox-modal__wrapper'><CashboxModal method={this.state.payoutMethod} notify={this.props.notify} user={this.props.user}/></div>
        :
        null
      }
      <div className="profileContainer">
        <div className="profilePageBlock">
          <div className="profilePageBlock-avatar">
            {avatar}
            <Dropzone
              accept="image/jpeg, image/png"
              multiple={false}
              maxSize={3145728}
              onDropAccepted={editPhoto}
            >
              <div className="overlayEditPhoto">
                <p>{t('profile_upload_photo')}</p>
              </div>

              {isUploading ? (
                <div><img src="/assets/images/loading-spin.svg" alt="loading" /></div>
              ) : null}

            </Dropzone>

          </div>
          <div className="profilePageBlock-info">
            <form action="#" className="profilePageForm" method="post">
              <div className="profilePageInputBlock">
                <label className="profilePageInput-label" htmlFor="fullname">{t('profile_full_name')}
                  <input type="text" ref={(input) => { name = input }} className="profileInput" name="fullname" id="fullname" defaultValue={name} />
                </label>
              </div>
              <div className="profilePageInputBlock">
                <label className="profilePageInput-label" htmlFor="profileEmail">{t('profile_email')}
                  <input ref={(input) => { email = input }} type="text" className="profileInput" name="profileEmail" id="profileEmail" defaultValue={email} />
                </label>
              </div>

              <div className="profilePageInputBlock">
                <label className="profilePageInput-label" htmlFor="profilePassword">{t('profile_current_password')}
                  <input type="password" className="profileInput profileInput_pass" name="profilePassword" id="profilePassword" defaultValue={password} />
                </label>
              </div>

              <div className="profilePageInputBlock">
                <label className="profilePageInput-label" htmlFor="profileNewPassword">{t('profile_new_password')}
                  <input type="password" ref={(input) => { password = input }} className="profileInput profileInput_pass" name="profilePassword" id="profileNewPassword" />
                </label>
              </div>
            </form>
            <div className="connectedServices">
              <p className="connectedServices-title">{t('profile_user_identifier')} { id }</p>     
            </div>
            {
            (added || []).length > 0 ? (
                <div className="connectedServices">
                  <p className="connectedServices-title">{t('profile_connect_services')}</p>
                  <div className="connectedServicesSocial">
                    {
                     added.map((item) => {
                        const src = `/assets/images/main/main-social/${iconFor(item)}`
                        return <img className="connectedServicesSocial-img" src={src} alt={item} />
                      })
                    }
                  </div>
                </div>
              ) : null
            }
            <div className="addServices">
              <p className="connectedServices-title">{t('profile_add_services')}</p>
              <div className="connectedServicesSocial">
              <div>
                {
                  (notAdded || []).map(social => {
                    return (
                    <a onClick={() => authConfirm(social)}>
                    <img className="connectedServicesSocial-img" src={`/assets/images/main/main-social/${iconFor(social)}`} alt="facebook" />
                    </a>)
                  })
                }
             </div>
          <div className='profilePage__buy-orbs'>
          <div className="become-streamer">
          <Link onClick = {() =>  gtag("event", "profile", { 
                  event_category: 'webpage_interractions',
                  event_action: 'buyorbs_clicked',
                
                }) } to="/buy" className="become-streamer__button buy-orbs__button pre-btn pre-btn_gold">
             {t('profile_buy_orbs')}
           </Link>
           </div>
          </div>
              </div>
              {
                isPartner ? 
                <div>
              <div className='profilePage__cashbox'>                
                  <div className='profilePage__label'>{t('profile_cashbox')}</div>
                  <div className="profilePage__cashbox-button-group">
                  {
                    ['CARD', 'YANDEX', 'QIWI'].map(method => {
                      return <div className='profilePage__cashbox-button pre-btn pre-btn_gold' 
                      onClick={this.handleClick(method)} tooltip={t('profile_stream_cashout_tooltip')} flow='right'>
                        {t(`profile_cashbox_${method.toLowerCase()}`)}
                      </div>
                    })
                  }
                  </div>      
              </div>
              
              </div>
              : <div className='profilePage__ask-partner'>
              <button className='button button_gold button_glow' onClick={showPartnershipRequestModal} >
               {t('profile_ask_for_partnership')}
              </button>
                <ModalWindow show={isPartnershipModalOpen} hideModal={hidePartnershipRequestModal}>
                  <AskForPartershipModal notify={this.props.notify} closeModal={this.hidePartnershipRequestModal} user={user} stream={stream} />
                </ModalWindow>
              <div></div></div>
              }
              <div className='profilePage__change-lang'>
                  <div className='profilePage__label'>{t('profile_select_language')}</div>
                  <div className='profilePage__switcher'>
                    <LangSwitcher />
                  </div>
              </div>
              <div className='profilePage__change-theme'>
                  <div className='profilePage__label'>{t('profile_select_theme')}</div>
                  <div className='profilePage__switcher'>
                    <ThemeSwitcher ThemesActions={this.props.ThemesActions}/>
                  </div>
              </div>
              <div className='profilePage__save'>
              <button onClick={() => {
                
                gtag("event", "profile", { 
                  event_category: 'user_actions',
                  event_action: 'user_updated',
                })  
                if(password.value.length > 0 && password.value.length < 6){
                  alert('Wrong new password value');
                  return;
                }
                updateUser(name.value, email.value, password.value, saveAction)
              }
                } className="profileSaveBtn pre-btn pre-btn_blue">{t('profile_save')}</button>
              </div>
            </div>
          
          </div>
       
        </div>
      </div>
    </div>
  )
}
 }

 export default trans(Main)