import React, { Component } from "react"
import {trans} from "utils/translator"

export default trans(({t}) => {
  return (
    <div className="tabContent activeTab" id="tab2">
      
      <p className="tabContent-paragraph">
        Predictoria is committed to protecting your personal information. This Privacy Policy lets you know what information we collect when you use our services,  including information that personally identifies you (‘Personal Information’), why and how we collect this information and how we use it or otherwise process it. This Privacy Policy also describes how you can control the use, access, collection, correction and/or deletion of such information when you use our Website: <a target="_blank" href="https://predictoria.com/">https://www.predictoria.com/</a>, including on a mobile device, mobile application and related services (the ‘Service or the ‘Services) or any other service to which this policy is linked.
        <br /><br />
        Please note that this Privacy Policy will be agreed between you and Predictoria (‘Predictoria’, ‘we’, ‘us’ or ‘our’, as appropriate). This Privacy Policy is an integrated part of Predictoria Terms and Conditions (in Progress). By using the Site, you consent to the use of your user information in accordance with this Privacy Policy. If you are located in the EU, you will also be asked to provide clear and unequivocal affirmative consent to the collection, processing, and sharing of your user information via cookies and similar technologies.
        <br /><br />
        You should be aware that when you provide information to us using a third-party site or platform, the information you provide may be separately collected by the third-party site or platform. The information we collect is covered by the Predictoria Privacy Policy only, and the information the third-party site or platform collects is subject to the third-party site or platform’s privacy practices. Privacy choices you have made on the third-party site or platform will not apply to our use of the information we have collected directly through our Site or Services. Please also keep in mind that our Site and Services may contain links to other sites not owned or controlled by us and we are not responsible for the privacy practices of those sites. We encourage you to be aware when you leave our Site or Services and to read the policies of other sites that may collect your personal information.
        <br /><br />
        As a general reminder, please think carefully before you decide to put anything on the internet. For example, if you voluntarily disclose user information in chat areas or bulletin boards (e.g., comments sections), that information could be seen and used by others, and may result in unsolicited messages from others. We are not responsible for the personally identifiable information or other user information you may choose to submit in these public forums. To request removal of your personal information from our blog or community forum, please contact us as provided below. In some cases, we may not be able to remove personal information voluntarily disclosed by you in such forums for legal retention purposes or technical limitations (e.g., the data may not be searchable), in which case, we will notify you if we are unable to do so.
        <br /><br />
        To enhance, personalize, and support your experience on the Site, among other things, we collect, use, and share your user information. For example, we use aggregated information to develop content targeted to user interests and to generally improve the Site's user experience. As detailed below, your user information may also be used by Predictoria  and its partners to tailor content and ads, provide social media features (e.g., features that allow you to share content directly to your preferred social media platform), track your preferences, analyze traffic, and provide similar services and content. Predictoria is committed to your privacy and will not share any user information with any third party without your consent, except as outlined in this Privacy Policy or as permitted by law. If you have any requests, comments, or questions about this Privacy Policy or your user information, please contact us in the manner provided below.
        <br /><br />
        We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our platforms and/or sending you an appropriate email (e-mail address specified in your account). We recommend that you revisit this Privacy Policy regularly.
        <br /><br />
        For your convenience, we may also make translated versions of this Privacy Policy available on the Site. In the event that the terms of any such translated version conflict with the terms of the English version, the terms of the English version of this Privacy Policy will control in all instances.
        <br /><br />
        Notice to EU Individuals: this Privacy Plicy and its enumerated policies are intended to comply with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (the “General Data Protection Regulation” or “GDPR”) and provide appropriate protection and care with respect to the treatment of your user information in accordance with the GDPR. 
      </p>

      <h3 className="tabContent-header">1. INFORMATION COLLECTED</h3>
      <p className="tabContent-paragraph">
        We collect user information that may be used to identify an individual when you use the Site. Sometimes you may submit it to us yourself, other times it may be collected automatically. You can manage and change your preferences as outlined below.We collect information you provide directly to us, information from third parties, and information that we collect through automated means. For example, we collect:
        <br /><br />
        -	registration information you provide when you create an account, enter a promotion, or link your profile on a third-party site or platform with your registration account, such as, including, but not limited to, first and last name, date of birth, home or other physical address, email address, phone number, username and password, or other relevant information to be Personal Information (‘Personal Information’);<br />
-	information you provide when you request information, send feedback regarding the Services, contact Predictoria, create or join prediction or challenge;<br />
-	shipping and billing information, transaction history;<br />
-	information you provide in public forums on our sites and applications, such as your public posts;<br />
-	information sent directly to another user, post, or similar functionality, where we are permitted by applicable law to collect this information;<br />
-	information we obtain from a third party, such as a site or platform provider, about use of our applications on third-party platforms or devices;<br />
-	location information, including location information provided by a mobile or other device interacting with one of our sites or applications (including through beacon technologies), or associated with your IP address, where we are permitted by law to process this information;<br />
-	activity information about your use, and the use  by any person(s) you authorize through your account, of our sites and applications, such as the content you view or post, how often you use our services, and your preferences;<br />
-	usage, viewing, technical, and device data when you visit our sites, use our applications on third-party sites or platforms, or open emails we send, including your browser or device type, unique device identifier, IP address, time of access, date of access, web page(s) visited, language use, software crash reports.<br />
        <br /><br />
        This information is essential for the provision and quality of our services. We do not collect Personal Information about you without your knowledge.You may decline to provide us with your information. However, this will limit your ability to use our Services. If you choose not to provide the information we request, you can still visit most of the Site, but you may be unable to access certain options and services. When you register or become a member of the Site or provide information to Predictoria in any other manner, you agree to provide only true, accurate, current and complete information.You may review, change or remove your information through your <a target="_blank" href="/profile">account settings</a>.
      </p>

      <h3 className="tabContent-header">2. MEANS OF COLLECTING AND PROCESSING DATA</h3>
      <p className="tabContent-paragraph">We may automatically collect certain data as discussed above and receive Personal Information about you where you provide such information through the services or other communications and interactions on the Predictoria site. We may also receive Personal Information from online vendors and service providers, and from customer lists lawfully acquired from third-party vendors. In addition, we may engage the services of third-party service providers to provide technical support process your online transactions and maintain your account. We will have access to any information you provide to such vendors, service providers and third-party e-commerce services, and we will use the Personal Information as set out in this Privacy Policy below. This information will only be disclosed to third parties outside the company in accordance with this Privacy Policy. We take steps to ensure that our arrangements with third-party service providers and online vendors protect your privacy.
        <br /><br />
We collect information through a variety of technologies, such as cookies, Flash cookies, pixels, tags, software development kits, application program interfaces, and Web beacons, including when you visit our sites and applications or use our applications on third-party sites or platforms using one or more devices, whether or not you are logged in or registered. We collect information using analytics tools, including when you visit our sites and applications or use our applications on third-party sites or platforms. We acquire information from other trusted sources to update or supplement the information you provided or we collected automatically, such as when we validate postal address information using third party services. Applicable law may require that you authorize the third party to share your information with us before we can acquire it. If you access the Service through a third-party connection or log-in (like a social network), we may collect certain information from your social network profile. For a description of how social networking sites handle your information, please refer to their privacy policies and terms of use, which may permit you to modify your privacy settings. If you do not want to share information from your social network profile, do not access the Service through that third-party service.

      </p>

      <h3 className="tabContent-header">3. INFORMATION USE</h3>
      <p className="tabContent-paragraph">Our primary goal in collecting, using, and sharing user information is to enhance your experience on the Site, support the Site, and improve its features, performance, and other elements. We will not use your user information without a valid legal basis to do so. Consistent with applicable law and choices and controls that may be available to you, we use the Personal Information we collect from you to deliver, optimize and  improve our  services, promote our Services and services and/or products of third-party, to provide customer support, to undertake necessary security and identify verification checks, to process any of your online transactions, to assist your participation in third-party promotions, meet certain business requirements and for any other purpose related to the operation of the Services, to detect, investigate and prevent activities that may violate our policies or be illegal.
        <br /><br />
Your Personal Information may also be used by us to provide you with: (1) promotional offers and information regarding our products and services; and (2) promotional offers and information regarding the products and services of our partners, in order to enlarge the range of provided products and improve our customer service; and (3) advertising and content that is targeted to your interests, (4) interactive communications, such as notifications of activity by topic or contributor to the e-mail address that you provide to us under your permission to receive them, as well as e-mails highlighting new features, promotions or contests on the website to those users who have expressly provided us with their permission to send such communications as part of their user profile.
        <br /><br />
From time-to-time, we may request information from you via surveys or contests. Participation in these surveys or contests is voluntary and you have the choice of whether or not to disclose such information. Information requested may include contact information (such as name, correspondence address and telephone number), and demographic information (such as zip or postal code or age). By accepting any contest prize or winnings from us, you consent to use of your name for ads and promotional purposes without additional compensation, except where prohibited by law. Unless you have elected not to receive promotional information, we may also use your Personal Information (including your email address and phone number) to provide you with information regarding our products, services and products and services from third parties carefully selected by us.
        <br /><br />
We may share your Personal Information with our carefully selected partners (including any other parties that have data sharing arrangements with the latter).  Please, note that once we share your personal information with another company, the information received by the other company is controlled by that company and becomes subject to the other company’s privacy practices. We may share your information in the following ways:
-	when you allow us to share your personal information with another company;<br />
-	electing to share your personal information with carefully selected companies so that they can send you offers and promotions about their products and services;<br />
-	directing us to share your personal information with third-party sites or platforms, such as social networking sites;<br />
-	when companies perform services on our behalf, like marketing and advertising, and customer service, credit card processing, e-mailing and hosting services on the Site; however, these companies are prohibited from using your personal information for purposes other than those requested by us or required by law; these companies are subject to agreements that require them to maintain the confidentiality of your information and they are prohibited from using that information for any other purpose; with respect to credit card processing, we do not collect, store, or process any credit card information, if you do not want your information to be shared, you can choose not to allow the transfer of your information by not using that particular service;<br />
-	when we share your personal information with third parties in connection with the sale of a business, to enforce our Terms of Use or rules, to ensure the safety and security of our Users and third parties, to protect our rights and property and the rights and property of our Users and third parties, to comply with legal process (including to comply with national security or law enforcement requirements), or in other cases if we believe in good faith that disclosure is required by law;<br />
-	when we share your personal information with your consent, including when you post personal information on public forums or blogs accessible to third parties.<br />
      </p>

      <h3 className="tabContent-header">4. CERTAIN EXCLUDED DISCLOSURES</h3>
      <p className="tabContent-paragraph">We may disclose your Personal Information if required to do so by law, or if we believe in good faith that such action is necessary to: (1) comply with any legal process served on us, any of our sites or the services or in circumstances where we are under a substantially similar legal obligation; (2) protect and defend our rights or property; or (3) act to protect the personal safety of users of the services or the public. If, in our sole determination, you are found to have cheated or attempted to defraud us, the company , or any other user of the services in any way including but not limited to game manipulation or payment fraud, or if we suspect you of fraudulent payment, including use of stolen credit cards, or any other fraudulent activity (including any chargeback or other reversal of a payment) or prohibited transaction (including money laundering), we reserve the right to share this information (together with your identity) with other online gaming sites, banks, credit card companies, appropriate agencies and relevant authorities; (4) for the purpose of research on the prevention of addiction, data can be made anonymous and passed on to the respective institutions.
        <br /><br />
Notice to EU Individuals: As outlined in this Privacy Policy, Predictoria will not process your user information without a lawful basis to do so, as such bases are defined in Article 6 of the GDPR. In general, Predictoria will process your user information on the legal bases of consent, contract (if you have entered into an agreement with us and such processing is a necessary part thereof), or legal obligation (as noted above). Predictoria may also process certain user information on the basis of the following legitimate interests, provided that such interests are not overridden by your privacy rights and interests: delivering and continuing to develop and improve the Site, making and receiving payments, learning from your behavior on the Site (e.g., analyzing traffic) to better serve you and other Site users, responding to your questions, helping us modify or enhance the Site and its content, receiving insight as to what users do (and don't) like about our Site or aspects thereof, and providing a stable, consistent, and secure user experience in connection with the Site.

      </p>

      <h3 className="tabContent-header">5. ACCESS</h3>
      <p className="tabContent-paragraph">You may ‘opt out’ from receiving any promotional communications either by choosing to opt out via your account settings available on our sites or the services or in an email you receive from us, or at any time by sending an email, or by writing to us at Customer Service.<br />
In addition, you may contact us if you: 1) want to confirm the accuracy of the Personal Information we have collected about you; 2) would like to update your Personal Information; and/or 3) have any complaint regarding our use of your Personal Information. If requested, we will (1) update any information you have provided to us, in case you prove the necessity for such changes or (2) mark any information to prohibit future use for marketing purposes. For the avoidance of doubt, nothing in this Privacy Policy shall prevent us from retaining your Personal Information where we are required to do so by law.
        <br /><br />
In addition, Users in the European Economic Area and some other jurisdictions have certain legal rights to exercise certain controls and choices regarding our collection, use and sharing of your information.Your rights may include:<br />
-	updating, and deleting your registration account;<br />
-	choosing or changing your choices for subscriptions, newsletters, and alerts;<br />
-	choosing whether to receive from us offers and promotions for our products and services, or products and services that we think may be of interest to you;<br />
-	choosing whether we share your personal information with other companies so they can send you offers and promotions about their products and services;<br />
-	controlling targeted advertising from many ad networks and partners; data exchanges; and marketing analytics and digital advertising and marketing service providers (by visiting the Digital Advertising Alliance);<br />
-	controlling whether you want us to access location information, such as by controlling or disabling location services in your mobile device’s settings menu;<br />
-	choosing to control targeted advertising you receive within applications by using the settings and choices made available to you through your device(s), for example, by re-setting your device’s advertising identifier and/or opting out of interest based ads;<br />
-	requesting removal of your personal information from a public forum on one of our sites or application.<br />
To exercise these rights, you may contact us <a target="_blank" href="https://predictoria.zendesk.com/">here</a>.<br />
        <br /><br />
The rights and options described above are subject to limitations and exceptions under applicable law. In situations in which we process personal data on behalf of our User, we may refer the request to the relevant User and cooperate with their handling of the request, subject to any special contractual arrangement with that User.
        <br /><br />
In addition to these rights, Users in the European Economic Area have the right to lodge a complaint with the relevant supervisory authority. However, we encourage you to contact us first, and we will do our very best to resolve your concern.
        <br /><br />
Please be aware that if you do not allow us to collect personal information from you, we may not be able to deliver certain experiences, products, and services to you, and some of our Services may not be able to take account of your interests and preferences. If you have questions about the specific personal information about you that we process or retain, and your rights regarding that personal information, please <a target="_blank" href="https://predictoria.zendesk.com/">contact us</a>.
      </p>

      <h3 className="tabContent-header">6. SECURITY</h3>
      <p className="tabContent-paragraph">We understand the importance of security and the techniques needed to secure information. We store all of the Personal Information we receive directly from you in an encrypted and password-protected database residing within our secure network behind active state-of-the-art firewall software. Our servers located in Azure Cloud Service (Microsoft) We use industry standard physical, electronic, and organizational safeguards to protect your user information against loss or theft as well as unauthorized access and disclosure, and use all our reasonable endeavours to protect your privacy and ensure your safety, and we regularly review our security procedures to consider appropriate new technology and methods. However, we are unable to provide you with 100 % guarantee of Internet data transmission safety, as it does not exist. If you are concerned that your privacy may have been breached, please contact us in the manner provided below.
      </p>


      <h3 className="tabContent-header">7. INTERNATIONAL TRANSFERS</h3>
      <p className="tabContent-paragraph">To bring you the Services, we operate globally. Personal Information collected on the services may be stored and processed in any country in which we or our affiliates, suppliers or agents maintain facilities. By using our services, you expressly consent to any transfer of information outside of your country (including to countries that may not be assessed as having adequate privacy laws). Nevertheless, we take steps to ensure that our agents, affiliates and suppliers comply with our standards of privacy regardless of their location.
      </p>

      <h3 className="tabContent-header">8. THIRD-PARTY PRACTICES</h3>
      <p className="tabContent-paragraph">We cannot ensure the protection of any information that you provide to a third-party online site that links to or from the services or any information collected by any third party administering our affiliate program (if applicable) or any other program, since these third-party online sites are owned and operated independently from us. Any information collected by these third parties is governed by the privacy policy, if any, of such third party.
      </p>


      <h3 className="tabContent-header">9. PARTNERSHIP WITH ADVERTISERS AND OTHER COMPANIES</h3>
      <p className="tabContent-paragraph">We may use Aggregate/Anonymous Information for any purpose and share such data with any third parties, including advertisers. We gather such information (as well as information from third-party market researchers) to describe our services to partners and to assist our advertisers and sponsors to better understand our audience. We do not disclose personally identifying information about our users to these parties.
        <br /><br />
We partner with a third party ad network to either display advertising on our Web site or to manage our advertising on other sites. Our ad network partner uses cookies and Web beacons to collect non-personally identifiable information about your activities on this and other Web sites to provide you targeted advertising based upon your interests.
        <br /><br />
If you wish to not have this information used for the purpose of serving you targeted ads, you may opt-out or modify your choices by clicking here, or if located in the European Union by clicking here. Please note this does not opt you out of being served advertising. You will continue to receive generic ads.
      </p>

      <h3 className="tabContent-header">10. CHILDREN’S PRIVACY</h3>
      <p className="tabContent-paragraph">People under 18 (or the legal age in your jurisdiction) are not permitted to use Predictoria on their own. Predictoria does not knowingly collect any personal information from children under the age of 13 and children under 13 are not permitted to register for an account or use our Services. If you believe that a child has provided us with personal information, please contact us <a target="_blank" href="https://predictoria.zendesk.com/">here</a>. If we become aware that a child under age 13 has provided us with personally identifiable information, we will delete that information from our database and terminate the corresponding account, if any.
        <br /><br />
We do not knowingly process data of EU residents under the age of 16 without parental consent. If we become aware that we have collected data from an EU resident under the age of 16 without parental consent, we will take reasonable steps to delete it as quickly as possible. We also comply with other age restrictions and requirements in accordance with applicable local laws.
        <br /><br />
We encourage you to discuss the Site with your parent or guardian.

      </p>

      
      <h3 className="tabContent-header">11  . LEGAL DISCLAIMER</h3>
      <p className="tabContent-paragraph">The Services operate ‘AS-IS’ and ‘AS-AVAILABLE’ without liability of any kind. We are not responsible for events beyond our direct control. Due to the complex and ever-changing nature of our technology and business, we cannot guarantee nor do we claim that there will be error-free performance regarding the privacy of your Personal Information, and we will not be liable for any indirect, incidental, consequential or punitive damages relating to the use or release of said Personal Information.
      </p>
      

      <h3 className="tabContent-header">12. CONTACT DETAILS</h3>
      <p className="tabContent-paragraph">If you have any questions, requests or concerns about our Privacy Policy, or how to remove or modify your user information or related consents, please contact us at: <a target="_blank" href="mailto:support@predictoria.com">support@predictoria.com</a>
      </p>      
    </div>
  )
}
)