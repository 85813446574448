import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { trans } from "utils/translator"
import HelpCenter from './HelpCenter';
import './help-center.scss'

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
    streamList: state.streamList.adminStreamlist
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}


export default trans(connect(mapStateToProps, mapDispatchToProps)(HelpCenter));