import React, { Component } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { notify } from "reapop"

import * as userActions from "actions/UserActions"
import * as LeaderboardActions from "actions/LeaderboardActions"
import * as StreamActions from "actions/StreamActions"

import StreamList from "components/StreamListSlider/StreamList"
import LeaderboardPage from "components/Leaderboard"

import { siteTitle } from "utils/constants"

class Leaderboard extends Component {
  state = { title: "Leaderboard - Predictoria.com" }

  constructor(props) {
    super(props)

    document.title = this.state.title
  }

  componentWillUnmount() {
    if (document.title == this.state.title) { document.title = siteTitle }
  }

  componentDidMount() {
    const {streamers, LeaderboardActions } = this.props
    streamers ?  LeaderboardActions.getStreamers(new Date(0).getTime()) : LeaderboardActions.get(new Date(0).getTime())
  }

  render() {
    const { props } = this
    const { leaderboard, leaderboardStreamers, LeaderboardActions, streamList, user } = props
    // const { list, user, games } = leaderboard
    // const streams = streamList.streamlist 
    // if(streams.length < 2) return ('')
    // const streamers = list.map(u => u.streamer).filter((elem, pos, arr) => {
    //   return arr.indexOf(elem) == pos;
    // });
    // const topStreams = streamers.map(s => {
    //  const topstream =  streams.find(st => st.stream == s)
    //  if(topstream) return topstream
    // })

    // console.log("Leaderboard page container", streams, streamers, topStreams)
    return (
      <main className="containerWrapper containerWrapper_absolute">
          <div className="leaderboard">
            <div className="leaderboardUsers">
              <LeaderboardPage {...props} users={props.streamers ? leaderboardStreamers : leaderboard} user={user} LeaderboardActions={LeaderboardActions} />
            </div>
            {/* <div className="sortByGames">
              <p className="predictionParagraph">Top Streamers</p>
                <StreamList streamlist={topStreams} StreamActions={StreamActions}/>
              
            </div> */}
          </div>
      </main>
    )
  }
}


function mapStateToProps(state) {
  return {
    user: state.user,
    leaderboard: state.leaderboard.list,
    leaderboardStreamers: state.leaderboard.listStreamers,
    streamList: state.streamList,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    LeaderboardActions: bindActionCreators(LeaderboardActions, dispatch),
    StreamActions: bindActionCreators(StreamActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    notify: bindActionCreators(notify, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard)
