import React, { Component } from "react"
import ReferralBlock from "./ReferralBlock"
import * as UsersApi from "../../api/UsersApi"
import * as UserActions from "actions/UserActions"
import { notify } from "reapop"

export default class DepositMobile extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      isOpen: false
    }

    this.handleCubeClick = this.handleCubeClick.bind(this)
    this.handleShareClick = this.handleShareClick.bind(this)
    this.notificClick = this.notificClick.bind(this)
  }

  handleCubeClick() {
    UsersApi.getFreeCubes((user, error) => {
      if (error != null) {
        console.error(error)
        this.notificClick("Free cubes", error, "error")
        gtag("event", "deposit", { getFreeCubes: "error" })
        return
      }
      gtag("event", "deposit", { getFreeCubes: "success" })
      UserActions.updateUserBalance()
      this.props.notificClick("Free cubes", "Congrats! You receive free 200 cubes!", "success")
    })
  }

  handleShareClick() {
    gtag("event", "share", {
      method: "facebook",
      event_action: "share",
      content_id: "https://predictoria.com/"
    })
    
    UsersApi.getShareCubes("facebooksharing", (user, error) => {
      if (error != null) {
        console.error(error)
        this.notificClick("Free cubes", error, "error")
        gtag("event", "deposit", { getShareCubes: "error" })
        return
      }
      gtag("event", "deposit", { getShareCubes: "success" })
      this.props.UserActions.updateUserBalance()
      this.props.notificClick("Free cubes", "Congrats! You receive free 350 cubes for sharing!", "success")
    })
  }

  notificClick(title, message, status) {
    notify({
      title: title || "Welcome",
      message: message || "you clicked on the button",
      status: status || "success",
      dismissible: true,
      dismissAfter: 5000
    })
  }

  render() {
  	const user = {
  		model: JSON.parse(localStorage.getItem("predictoria_currentUser")) || Object()
  	}
    return (
      <div className="depositMobile">
        <ReferralBlock user={user} handleShareClick={this.handleShareClick} handleCubeClick={this.handleCubeClick} />
      </div>
    )
  }
}
